import React from 'react'
import { JustDot } from 'src/assets/inboxHub/InboxHubIcons'

const TextWithDotBetween = ({ textArray, dotSize, dotColor }) => {
  return (
    <div style={{ display: 'flex', gap: 3, height: 'fit-content' }}>
      {textArray.map((text, i) => (
        <span
          key={i}
          style={{ display: 'flex', alignItems: 'center', gap: 3, height: 'fit-content' }}
        >
          {text} {i < textArray.length - 1 && <JustDot color={dotColor} size={dotSize} />}{' '}
        </span>
      ))}
    </div>
  )
}

export default TextWithDotBetween
