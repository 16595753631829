import { useTheme } from '@mui/material'

export const JustDot = ({ color, size }) => {
  return (
    <svg
      width={size ?? '6'}
      height={size ?? '6'}
      viewBox='0 0 6 6'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='3' cy='3' r='3' fill={color} />
    </svg>
  )
}

export const IHAllIcon = () => {
  const theme = useTheme()
  return (
    <>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M3 13.1429V17.7143C3 18.9767 4.00736 20 5.25 20H18.75C19.9926 20 21 18.9767 21 17.7143V13.1429M3 13.1429L5.82751 5.48315C6.15683 4.59102 6.99635 4 7.93425 4H16.0657C17.0037 4 17.8432 4.59102 18.1725 5.48315L21 13.1429M3 13.1429H7.5L9 14.7429H15L16.5 13.1429H21'
          stroke={theme.palette.primary.primaryText}
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </>
  )
}

export const IHAttachment = () => {
  return (
    <>
      <svg
        width='14'
        height='14'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M6.6668 8.66599C6.9531 9.04875 7.31837 9.36545 7.73783 9.59462C8.1573 9.82379 8.62114 9.96007 9.0979 9.99422C9.57466 10.0284 10.0532 9.95957 10.501 9.79251C10.9489 9.62546 11.3555 9.36404 11.6935 9.02599L13.6935 7.02599C14.3007 6.39732 14.6366 5.55531 14.629 4.68132C14.6215 3.80733 14.2709 2.97129 13.6529 2.35326C13.0348 1.73524 12.1988 1.38467 11.3248 1.37708C10.4508 1.36948 9.60881 1.70547 8.98013 2.31266L7.83347 3.45266M9.33347 7.33266C9.04716 6.94991 8.68189 6.6332 8.26243 6.40403C7.84297 6.17486 7.37913 6.03858 6.90237 6.00444C6.4256 5.97029 5.94708 6.03908 5.49924 6.20614C5.0514 6.3732 4.64472 6.63461 4.3068 6.97266L2.3068 8.97266C1.69961 9.60133 1.36363 10.4433 1.37122 11.3173C1.37881 12.1913 1.72938 13.0274 2.3474 13.6454C2.96543 14.2634 3.80147 14.614 4.67546 14.6216C5.54945 14.6292 6.39146 14.2932 7.02013 13.686L8.16013 12.546'
          stroke='#101010'
          stroke-width='1.6'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </>
  )
}

export const IHCampaignsIcon = () => {
  const theme = useTheme()
  return (
    <>
      <svg
        width='16'
        height='16'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M21.0688 2.92949L10.4049 13.5935M3.26953 8.23517L19.8754 2.47394C20.8981 2.11915 21.8792 3.10028 21.5244 4.12291L15.7632 20.7288C15.3685 21.8664 13.771 21.8976 13.3322 20.7763L10.6953 14.0375C10.5636 13.701 10.2974 13.4347 9.96079 13.303L3.22207 10.6661C2.10073 10.2273 2.13192 8.62985 3.26953 8.23517Z'
          stroke={theme.palette.primary.primaryText}
          stroke-width='2'
          stroke-linecap='round'
        />
      </svg>
    </>
  )
}

export const IHCheck = () => {
  return (
    <>
      <svg
        width='17'
        height='16'
        viewBox='0 0 17 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M9.83268 7.33398H5.83268M7.16602 10.0007H5.83268M11.166 4.66732H5.83268M13.8327 7.00065V4.53398C13.8327 3.41388 13.8327 2.85383 13.6147 2.426C13.4229 2.04968 13.117 1.74372 12.7407 1.55197C12.3128 1.33398 11.7528 1.33398 10.6327 1.33398H6.36602C5.24591 1.33398 4.68586 1.33398 4.25803 1.55197C3.88171 1.74372 3.57575 2.04968 3.384 2.426C3.16602 2.85383 3.16602 3.41388 3.16602 4.53398V11.4673C3.16602 12.5874 3.16602 13.1475 3.384 13.5753C3.57575 13.9516 3.88171 14.2576 4.25803 14.4493C4.68586 14.6673 5.24591 14.6673 6.36602 14.6673H8.16602M15.166 14.6673L14.166 13.6673M14.8327 12.0007C14.8327 13.2893 13.788 14.334 12.4993 14.334C11.2107 14.334 10.166 13.2893 10.166 12.0007C10.166 10.712 11.2107 9.66732 12.4993 9.66732C13.788 9.66732 14.8327 10.712 14.8327 12.0007Z'
          stroke='white'
          stroke-width='1.6'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </>
  )
}

export const IHCode = () => {
  return (
    <>
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M10.6673 12L14.6673 8L10.6673 4M5.33398 4L1.33398 8L5.33398 12'
          stroke='#101010'
          stroke-width='1.6'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </>
  )
}

export const IHEmailBox = () => {
  return (
    <>
      <svg
        width='369'
        height='158'
        viewBox='0 0 369 158'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g filter='url(#filter0_d_4818_73385)'>
          <rect
            x='34.7578'
            y='26.5039'
            width='300.242'
            height='89'
            rx='12'
            fill='white'
            shape-rendering='crispEdges'
          />
          <rect
            x='35.2578'
            y='27.0039'
            width='299.242'
            height='88'
            rx='11.5'
            stroke='#D7D9E6'
            shape-rendering='crispEdges'
          />
          <rect x='50.7578' y='42.5039' width='32' height='32' rx='8' fill='#F2F4F6' />
          <rect x='90.7578' y='46.5039' width='162' height='10' rx='5' fill='#F2F4F6' />
          <rect x='90.7578' y='60.5039' width='177.242' height='10' rx='5' fill='#F2F4F6' />
          <rect x='50.7578' y='89.5039' width='175' height='10' rx='5' fill='#F2F4F6' />
          <rect x='293' y='89.5039' width='26' height='10' rx='5' fill='#F2F4F6' />
        </g>
        <defs>
          <filter
            id='filter0_d_4818_73385'
            x='0.757812'
            y='0.503906'
            width='368.242'
            height='157'
            filterUnits='userSpaceOnUse'
            color-interpolation-filters='sRGB'
          >
            <feFlood flood-opacity='0' result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset dy='8' />
            <feGaussianBlur stdDeviation='17' />
            <feComposite in2='hardAlpha' operator='out' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0.156863 0 0 0 0 0.156863 0 0 0 0 0.482353 0 0 0 0.1 0'
            />
            <feBlend
              mode='normal'
              in2='BackgroundImageFix'
              result='effect1_dropShadow_4818_73385'
            />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_dropShadow_4818_73385'
              result='shape'
            />
          </filter>
        </defs>
      </svg>
    </>
  )
}

export const IHEmailsIcon = () => {
  const theme = useTheme()
  return (
    <>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M3 9.99403C3 13.0587 3 17.8416 3 17.8416C3 17.8416 3 17.8416 3 17.8416C3 19.0337 4.00736 20 5.25 20H18.75C19.9926 20 21 19.0337 21 17.8416V9.48746M3 9.99403C3 9.62194 3.19982 9.27609 3.52875 9.07889L12 4L20.4265 8.54687C20.7808 8.73802 21 9.09758 21 9.48746M3 9.99403C3.45 9.99403 12 15.3314 12 15.3314L21 9.48746'
          stroke={theme.palette.primary.primaryText}
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </>
  )
}

export const IHForward = () => {
  return (
    <>
      <svg
        width='16'
        height='17'
        viewBox='0 0 16 17'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M2.66602 13.5319V12.5986C2.66602 10.3584 2.66602 9.23825 3.10199 8.38261C3.48548 7.62996 4.09741 7.01803 4.85005 6.63454C5.7057 6.19857 6.82581 6.19857 9.06602 6.19857H13.3327M13.3327 6.19857L9.99935 9.5319M13.3327 6.19857L9.99935 2.86523'
          stroke='#8181B0'
          stroke-width='1.6'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </>
  )
}

export const IHForwardIcon = () => {
  const theme = useTheme()
  return (
    <>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M13.3333 5L20 12M20 12L13.3333 19M20 12L4 12'
          stroke={theme.palette.primary.primaryText}
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </>
  )
}

export const IHGallery = () => {
  return (
    <>
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M2.84871 13.8186L7.24641 9.42091C7.51042 9.1569 7.64242 9.0249 7.79464 8.97544C7.92854 8.93193 8.07277 8.93193 8.20666 8.97544C8.35888 9.0249 8.49089 9.15691 8.7549 9.42091L13.1233 13.7893M9.33398 10L11.2464 8.08758C11.5104 7.82357 11.6424 7.69156 11.7946 7.6421C11.9285 7.5986 12.0728 7.5986 12.2067 7.6421C12.3589 7.69156 12.4909 7.82357 12.7549 8.08758L14.6673 10M6.66732 6C6.66732 6.73638 6.07036 7.33333 5.33398 7.33333C4.5976 7.33333 4.00065 6.73638 4.00065 6C4.00065 5.26362 4.5976 4.66667 5.33398 4.66667C6.07036 4.66667 6.66732 5.26362 6.66732 6ZM4.53398 14H11.4673C12.5874 14 13.1475 14 13.5753 13.782C13.9516 13.5903 14.2576 13.2843 14.4493 12.908C14.6673 12.4802 14.6673 11.9201 14.6673 10.8V5.2C14.6673 4.0799 14.6673 3.51984 14.4493 3.09202C14.2576 2.71569 13.9516 2.40973 13.5753 2.21799C13.1475 2 12.5874 2 11.4673 2H4.53398C3.41388 2 2.85383 2 2.426 2.21799C2.04968 2.40973 1.74372 2.71569 1.55197 3.09202C1.33398 3.51984 1.33398 4.0799 1.33398 5.2V10.8C1.33398 11.9201 1.33398 12.4802 1.55197 12.908C1.74372 13.2843 2.04968 13.5903 2.426 13.782C2.85383 14 3.41388 14 4.53398 14Z'
          stroke='#101010'
          stroke-width='1.6'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </>
  )
}

export const IHOptimizeStar = () => {
  return (
    <>
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clip-path='url(#clip0_7094_81908)'>
          <path
            d='M3.00065 14.6673V11.334M3.00065 4.66732V1.33398M1.33398 3.00065H4.66732M1.33398 13.0007H4.66732M8.66732 2.00065L7.5112 5.00656C7.32319 5.49538 7.22919 5.73979 7.083 5.94537C6.95344 6.12758 6.79425 6.28678 6.61204 6.41634C6.40646 6.56252 6.16205 6.65652 5.67322 6.84453L2.66732 8.00065L5.67323 9.15677C6.16205 9.34478 6.40646 9.43878 6.61204 9.58496C6.79425 9.71452 6.95344 9.87372 7.083 10.0559C7.22919 10.2615 7.32319 10.5059 7.5112 10.9947L8.66732 14.0007L9.82344 10.9947C10.0114 10.5059 10.1054 10.2615 10.2516 10.0559C10.3812 9.87372 10.5404 9.71452 10.7226 9.58496C10.9282 9.43878 11.1726 9.34478 11.6614 9.15677L14.6673 8.00065L11.6614 6.84453C11.1726 6.65652 10.9282 6.56252 10.7226 6.41634C10.5404 6.28678 10.3812 6.12758 10.2516 5.94537C10.1054 5.73979 10.0114 5.49538 9.82344 5.00656L8.66732 2.00065Z'
            stroke='#8181B0'
            stroke-width='1.6'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </g>
        <defs>
          <clipPath id='clip0_7094_81908'>
            <rect width='16' height='16' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </>
  )
}

export const IHReply = () => {
  return (
    <>
      <svg
        width='16'
        height='17'
        viewBox='0 0 16 17'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M5.99935 9.5319L2.66602 6.19857M2.66602 6.19857L5.99935 2.86523M2.66602 6.19857H6.93268C9.17289 6.19857 10.293 6.19857 11.1486 6.63454C11.9013 7.01803 12.5132 7.62996 12.8967 8.38261C13.3327 9.23825 13.3327 10.3584 13.3327 12.5986V13.5319'
          stroke='white'
          stroke-width='1.6'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </>
  )
}

export const IHReplyIcon = () => {
  const theme = useTheme()
  return (
    <>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M8.91557 4.20005L3.60156 9.51406M3.60156 9.51406L8.91557 14.8281M3.60156 9.51406L16.4016 9.51406C18.6107 9.51406 20.4016 11.3049 20.4016 13.5141L20.4016 19.8001'
          stroke={theme.palette.primary.primaryText}
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </>
  )
}

export const IHSend = () => {
  return (
    <>
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M7.00028 7.99986H3.33362M3.27718 8.19419L1.72057 12.844C1.59828 13.2093 1.53713 13.3919 1.58101 13.5044C1.61912 13.6021 1.70096 13.6761 1.80195 13.7043C1.91824 13.7368 2.09388 13.6577 2.44517 13.4997L13.5862 8.48622C13.929 8.33192 14.1005 8.25477 14.1535 8.1476C14.1995 8.05449 14.1995 7.94524 14.1535 7.85212C14.1005 7.74495 13.929 7.6678 13.5862 7.5135L2.44129 2.49834C2.09106 2.34074 1.91595 2.26194 1.79977 2.29426C1.69888 2.32234 1.61704 2.39619 1.57881 2.49369C1.53478 2.60595 1.59527 2.7882 1.71625 3.15271L3.27761 7.85688C3.29839 7.91948 3.30878 7.95079 3.31288 7.9828C3.31652 8.01121 3.31649 8.03997 3.31277 8.06837C3.30859 8.10037 3.29812 8.13164 3.27718 8.19419Z'
          stroke='white'
          stroke-width='1.6'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </>
  )
}

export const IHSendForward = () => {
  return (
    <>
      <svg
        width='16'
        height='17'
        viewBox='0 0 16 17'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M6.99903 9.50012L13.999 2.50012M7.08408 9.71883L8.83616 14.2242C8.99051 14.6211 9.06768 14.8195 9.17888 14.8774C9.27528 14.9277 9.39012 14.9277 9.48658 14.8776C9.59785 14.8198 9.67525 14.6215 9.83007 14.2247L14.2236 2.96625C14.3634 2.60813 14.4333 2.42907 14.395 2.31465C14.3618 2.21528 14.2839 2.1373 14.1845 2.10411C14.0701 2.06588 13.891 2.13576 13.5329 2.27552L2.2744 6.66908C1.87768 6.82389 1.67933 6.9013 1.62152 7.01257C1.57141 7.10903 1.57148 7.22386 1.6217 7.32026C1.67964 7.43146 1.87809 7.50864 2.27498 7.66299L6.78032 9.41506C6.86089 9.44639 6.90117 9.46206 6.93509 9.48625C6.96515 9.5077 6.99145 9.53399 7.01289 9.56406C7.03709 9.59798 7.05275 9.63826 7.08408 9.71883Z'
          stroke='white'
          stroke-width='1.6'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </>
  )
}

export const AddLeadsToCampaigns = () => {
  return (
    <>
      <svg
        width='12'
        height='12'
        viewBox='0 0 12 12'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M1.33398 6.00065H10.6673M10.6673 6.00065L6.00065 1.33398M10.6673 6.00065L6.00065 10.6673'
          stroke='white'
          stroke-width='1.6'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </>
  )
}

export function ForwardEmailIcon () {
  return (
    <svg width='16' height='15' viewBox='0 0 16 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.99903 8.50012L13.999 1.50012M7.08408 8.71883L8.83616 13.2242C8.99051 13.6211 9.06768 13.8195 9.17888 13.8774C9.27528 13.9277 9.39012 13.9277 9.48658 13.8776C9.59785 13.8198 9.67525 13.6215 9.83007 13.2247L14.2236 1.96625C14.3634 1.60813 14.4333 1.42907 14.395 1.31465C14.3618 1.21528 14.2839 1.1373 14.1845 1.10411C14.0701 1.06588 13.891 1.13576 13.5329 1.27552L2.2744 5.66908C1.87768 5.82389 1.67933 5.9013 1.62152 6.01257C1.57141 6.10903 1.57148 6.22386 1.6217 6.32026C1.67964 6.43146 1.87809 6.50864 2.27498 6.66299L6.78032 8.41506C6.86089 8.44639 6.90117 8.46206 6.93509 8.48625C6.96515 8.5077 6.99145 8.53399 7.01289 8.56406C7.03709 8.59798 7.05275 8.63826 7.08408 8.71883Z'
        stroke='white'
        stroke-width='1.6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export const MoreOptionsMarkUnread = () => {
  return (
    <svg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.334 10.9993L9.90541 6.99935M6.09589 6.99935L1.66734 10.9993M1.33398 3.66602L6.77726 7.47631C7.21804 7.78486 7.43844 7.93913 7.67816 7.99889C7.88991 8.05167 8.11139 8.05167 8.32314 7.99889C8.56287 7.93913 8.78326 7.78486 9.22404 7.47631L14.6673 3.66602M4.53398 12.3327H11.4673C12.5874 12.3327 13.1475 12.3327 13.5753 12.1147C13.9516 11.9229 14.2576 11.617 14.4493 11.2407C14.6673 10.8128 14.6673 10.2528 14.6673 9.13268V4.86602C14.6673 3.74591 14.6673 3.18586 14.4493 2.75803C14.2576 2.38171 13.9516 2.07575 13.5753 1.884C13.1475 1.66602 12.5874 1.66602 11.4673 1.66602H4.53398C3.41388 1.66602 2.85383 1.66602 2.426 1.884C2.04968 2.07575 1.74372 2.38171 1.55197 2.75803C1.33398 3.18586 1.33398 3.74591 1.33398 4.86602V9.13268C1.33398 10.2528 1.33398 10.8128 1.55197 11.2407C1.74372 11.617 2.04968 11.9229 2.426 12.1147C2.85383 12.3327 3.41388 12.3327 4.53398 12.3327Z'
        stroke='#8181B0'
        stroke-width='1.6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export const MoreOptionsEditLead = () => {
  return (
    <svg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.00065 9.33333H5.00065C4.07028 9.33333 3.60509 9.33333 3.22656 9.44816C2.37429 9.70669 1.70734 10.3736 1.44881 11.2259C1.33398 11.6044 1.33398 12.0696 1.33398 13M9.66732 4C9.66732 5.65685 8.32417 7 6.66732 7C5.01046 7 3.66732 5.65685 3.66732 4C3.66732 2.34315 5.01046 1 6.66732 1C8.32417 1 9.66732 2.34315 9.66732 4ZM7.33398 13L9.40155 12.4093C9.50056 12.381 9.55007 12.3668 9.59624 12.3456C9.63724 12.3268 9.67622 12.3039 9.71259 12.2772C9.75354 12.2471 9.78995 12.2107 9.86276 12.1379L14.1673 7.83336C14.6276 7.37311 14.6276 6.62689 14.1673 6.16665C13.7071 5.70642 12.9609 5.70642 12.5007 6.16666L8.1961 10.4712C8.12328 10.544 8.08688 10.5804 8.05681 10.6214C8.03011 10.6578 8.00718 10.6967 7.98835 10.7377C7.96715 10.7839 7.95301 10.8334 7.92472 10.9324L7.33398 13Z'
        stroke='#8181B0'
        stroke-width='1.6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export const MoreOptionsRemoveLead = () => {
  return (
    <svg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.6673 11H14.6673M8.00065 9.33333H5.00065C4.07028 9.33333 3.60509 9.33333 3.22656 9.44816C2.37429 9.70669 1.70734 10.3736 1.44881 11.2259C1.33398 11.6044 1.33398 12.0696 1.33398 13M9.66732 4C9.66732 5.65685 8.32417 7 6.66732 7C5.01046 7 3.66732 5.65685 3.66732 4C3.66732 2.34315 5.01046 1 6.66732 1C8.32417 1 9.66732 2.34315 9.66732 4Z'
        stroke='#8181B0'
        stroke-width='1.6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export const MoreOptionsDelete = () => {
  return (
    <svg width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.66667 4.00065V3.46732C9.66667 2.72058 9.66667 2.34721 9.52134 2.062C9.39351 1.81111 9.18954 1.60714 8.93865 1.47931C8.65344 1.33398 8.28007 1.33398 7.53333 1.33398H6.46667C5.71993 1.33398 5.34656 1.33398 5.06135 1.47931C4.81046 1.60714 4.60649 1.81111 4.47866 2.062C4.33333 2.34721 4.33333 2.72058 4.33333 3.46732V4.00065M1 4.00065H13M11.6667 4.00065V11.4673C11.6667 12.5874 11.6667 13.1475 11.4487 13.5753C11.2569 13.9516 10.951 14.2576 10.5746 14.4493C10.1468 14.6673 9.58677 14.6673 8.46667 14.6673H5.53333C4.41323 14.6673 3.85318 14.6673 3.42535 14.4493C3.04903 14.2576 2.74307 13.9516 2.55132 13.5753C2.33333 13.1475 2.33333 12.5874 2.33333 11.4673V4.00065'
        stroke='#FF4545'
        stroke-width='1.6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export function OptimizeEmailIcon () {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.00065 14.6673V11.334M3.00065 4.66732V1.33398M1.33398 3.00065H4.66732M1.33398 13.0007H4.66732M8.66732 2.00065L7.5112 5.00656C7.32319 5.49538 7.22919 5.73979 7.083 5.94537C6.95344 6.12758 6.79425 6.28678 6.61204 6.41634C6.40646 6.56252 6.16205 6.65652 5.67322 6.84453L2.66732 8.00065L5.67323 9.15677C6.16205 9.34478 6.40646 9.43878 6.61204 9.58496C6.79425 9.71452 6.95344 9.87372 7.083 10.0559C7.22919 10.2615 7.32319 10.5059 7.5112 10.9947L8.66732 14.0007L9.82344 10.9947C10.0114 10.5059 10.1054 10.2615 10.2516 10.0559C10.3812 9.87372 10.5404 9.71452 10.7226 9.58496C10.9282 9.43878 11.1726 9.34478 11.6614 9.15677L14.6673 8.00065L11.6614 6.84453C11.1726 6.65652 10.9282 6.56252 10.7226 6.41634C10.5404 6.28678 10.3812 6.12758 10.2516 5.94537C10.1054 5.73979 10.0114 5.49538 9.82344 5.00656L8.66732 2.00065Z'
        stroke='#8181B0'
        stroke-width='1.6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
export function RecipientCrossIcon () {
  return (
    <svg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7 1L1 7M1 1L7 7'
        stroke='#101010'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
export function RecipientDownArrowIcon () {
  return (
    <svg width='8' height='4' viewBox='0 0 8 4' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.964881 0.853553C0.649899 0.538571 0.872983 0 1.31843 0H4.00042H6.68239C7.12785 0 7.35093 0.538571 7.03595 0.853553L4.35397 3.53553C4.15871 3.7308 3.84212 3.7308 3.64686 3.53553L0.964881 0.853553Z'
        fill='#101010'
      />
    </svg>
  )
}

export function SendAReplyCrossIcon () {
  return (
    <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11 1L1 11M1 1L11 11'
        stroke='white'
        stroke-width='1.6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export function SendAReplyDownArrowIcon () {
  return (
    <svg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1 1.5L6 6.5L11 1.5'
        stroke='white'
        stroke-width='1.6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export const SendAReplyEmailIcon = () => {
  return (
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.99935 8.33268L1.66602 4.99935M1.66602 4.99935L4.99935 1.66602M1.66602 4.99935H5.93268C8.17289 4.99935 9.293 4.99935 10.1486 5.43532C10.9013 5.81882 11.5132 6.43074 11.8967 7.18339C12.3327 8.03903 12.3327 9.15914 12.3327 11.3993V12.3327'
        stroke='white'
        stroke-width='1.6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export const TestEmailIcon = () => {
  return (
    <svg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.33398 3.66602L6.77726 7.47631C7.21804 7.78486 7.43844 7.93913 7.67816 7.99889C7.88991 8.05167 8.11139 8.05167 8.32314 7.99889C8.56287 7.93913 8.78326 7.78486 9.22404 7.47631L14.6673 3.66602M4.53398 12.3327H11.4673C12.5874 12.3327 13.1475 12.3327 13.5753 12.1147C13.9516 11.9229 14.2576 11.617 14.4493 11.2407C14.6673 10.8128 14.6673 10.2528 14.6673 9.13268V4.86602C14.6673 3.74591 14.6673 3.18586 14.4493 2.75803C14.2576 2.38171 13.9516 2.07575 13.5753 1.884C13.1475 1.66602 12.5874 1.66602 11.4673 1.66602H4.53398C3.41388 1.66602 2.85383 1.66602 2.426 1.884C2.04968 2.07575 1.74372 2.38171 1.55197 2.75803C1.33398 3.18586 1.33398 3.74591 1.33398 4.86602V9.13268C1.33398 10.2528 1.33398 10.8128 1.55197 11.2407C1.74372 11.617 2.04968 11.9229 2.426 12.1147C2.85383 12.3327 3.41388 12.3327 4.53398 12.3327Z'
        stroke='#8181B0'
        stroke-width='1.6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export const CheckEmailIcon = () => {
  return (
    <svg width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.83268 7.33398H3.83268M5.16602 10.0007H3.83268M9.16602 4.66732H3.83268M11.8327 7.00065V4.53398C11.8327 3.41388 11.8327 2.85383 11.6147 2.426C11.4229 2.04968 11.117 1.74372 10.7407 1.55197C10.3128 1.33398 9.75279 1.33398 8.63268 1.33398H4.36602C3.24591 1.33398 2.68586 1.33398 2.25803 1.55197C1.88171 1.74372 1.57575 2.04968 1.384 2.426C1.16602 2.85383 1.16602 3.41388 1.16602 4.53398V11.4673C1.16602 12.5874 1.16602 13.1475 1.384 13.5753C1.57575 13.9516 1.88171 14.2576 2.25803 14.4493C2.68586 14.6673 3.24591 14.6673 4.36602 14.6673H6.16602M13.166 14.6673L12.166 13.6673M12.8327 12.0007C12.8327 13.2893 11.788 14.334 10.4993 14.334C9.21068 14.334 8.16602 13.2893 8.16602 12.0007C8.16602 10.712 9.21068 9.66732 10.4993 9.66732C11.788 9.66732 12.8327 10.712 12.8327 12.0007Z'
        stroke='white'
        stroke-width='1.6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export function EmailSettingsAttachmentIcon () {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.6668 8.66599C6.9531 9.04875 7.31837 9.36545 7.73783 9.59462C8.1573 9.82379 8.62114 9.96007 9.0979 9.99422C9.57466 10.0284 10.0532 9.95957 10.501 9.79251C10.9489 9.62546 11.3555 9.36404 11.6935 9.02599L13.6935 7.02599C14.3007 6.39732 14.6366 5.55531 14.629 4.68132C14.6215 3.80733 14.2709 2.97129 13.6529 2.35326C13.0348 1.73524 12.1988 1.38467 11.3248 1.37708C10.4508 1.36948 9.60881 1.70547 8.98013 2.31266L7.83347 3.45266M9.33347 7.33266C9.04716 6.94991 8.68189 6.6332 8.26243 6.40403C7.84297 6.17486 7.37913 6.03858 6.90237 6.00444C6.4256 5.97029 5.94708 6.03908 5.49924 6.20614C5.0514 6.3732 4.64472 6.63461 4.3068 6.97266L2.3068 8.97266C1.69961 9.60133 1.36363 10.4433 1.37122 11.3173C1.37881 12.1913 1.72938 13.0274 2.3474 13.6454C2.96543 14.2634 3.80147 14.614 4.67546 14.6216C5.54945 14.6292 6.39146 14.2932 7.02013 13.686L8.16013 12.546'
        stroke='#101010'
        stroke-width='1.6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export function EmailSettingsCodeIcon () {
  return (
    <svg width='16' height='10' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.6673 9L14.6673 5L10.6673 1M5.33398 1L1.33398 5L5.33398 9'
        stroke='#101010'
        stroke-width='1.6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export function EmailSettingsPictureIcon () {
  return (
    <svg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.84871 12.8186L7.24641 8.42091C7.51042 8.1569 7.64242 8.0249 7.79464 7.97544C7.92854 7.93193 8.07277 7.93193 8.20666 7.97544C8.35888 8.0249 8.49089 8.15691 8.7549 8.42091L13.1233 12.7893M9.33398 9L11.2464 7.08758C11.5104 6.82357 11.6424 6.69156 11.7946 6.6421C11.9285 6.5986 12.0728 6.5986 12.2067 6.6421C12.3589 6.69156 12.4909 6.82357 12.7549 7.08758L14.6673 9M6.66732 5C6.66732 5.73638 6.07036 6.33333 5.33398 6.33333C4.5976 6.33333 4.00065 5.73638 4.00065 5C4.00065 4.26362 4.5976 3.66667 5.33398 3.66667C6.07036 3.66667 6.66732 4.26362 6.66732 5ZM4.53398 13H11.4673C12.5874 13 13.1475 13 13.5753 12.782C13.9516 12.5903 14.2576 12.2843 14.4493 11.908C14.6673 11.4802 14.6673 10.9201 14.6673 9.8V4.2C14.6673 3.0799 14.6673 2.51984 14.4493 2.09202C14.2576 1.71569 13.9516 1.40973 13.5753 1.21799C13.1475 1 12.5874 1 11.4673 1H4.53398C3.41388 1 2.85383 1 2.426 1.21799C2.04968 1.40973 1.74372 1.71569 1.55197 2.09202C1.33398 2.51984 1.33398 3.0799 1.33398 4.2V9.8C1.33398 10.9201 1.33398 11.4802 1.55197 11.908C1.74372 12.2843 2.04968 12.5903 2.426 12.782C2.85383 13 3.41388 13 4.53398 13Z'
        stroke='#101010'
        stroke-width='1.6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export function EmailSettingsSendReplyIcon () {
  return (
    <svg width='15' height='14' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.00028 6.99986H3.33362M3.27718 7.19419L1.72057 11.844C1.59828 12.2093 1.53713 12.3919 1.58101 12.5044C1.61912 12.6021 1.70096 12.6761 1.80195 12.7043C1.91824 12.7368 2.09388 12.6577 2.44517 12.4997L13.5862 7.48622C13.929 7.33192 14.1005 7.25477 14.1535 7.1476C14.1995 7.05449 14.1995 6.94524 14.1535 6.85212C14.1005 6.74495 13.929 6.6678 13.5862 6.5135L2.44129 1.49834C2.09106 1.34074 1.91595 1.26194 1.79977 1.29426C1.69888 1.32234 1.61704 1.39619 1.57881 1.49369C1.53478 1.60595 1.59527 1.7882 1.71625 2.15271L3.27761 6.85688C3.29839 6.91948 3.30878 6.95079 3.31288 6.9828C3.31652 7.01121 3.31649 7.03997 3.31277 7.06837C3.30859 7.10037 3.29812 7.13164 3.27718 7.19419Z'
        stroke='white'
        stroke-width='1.6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export function EmailSettingsTextIcon () {
  return (
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.66602 3.66602C1.66602 3.04476 1.66602 2.73413 1.76751 2.4891C1.90283 2.1624 2.1624 1.90283 2.4891 1.76751C2.73413 1.66602 3.04476 1.66602 3.66602 1.66602H10.3327C10.9539 1.66602 11.2646 1.66602 11.5096 1.76751C11.8363 1.90283 12.0959 2.1624 12.2312 2.4891C12.3327 2.73413 12.3327 3.04476 12.3327 3.66602M4.99935 12.3327H8.99935M6.99935 1.66602V12.3327'
        stroke='#101010'
        stroke-width='1.6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

// variables menu icons

export function VariablesMenuEmailIcon () {
  return (
    <svg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.33398 3.66602L6.77726 7.47631C7.21804 7.78486 7.43844 7.93913 7.67816 7.99889C7.88991 8.05167 8.11139 8.05167 8.32314 7.99889C8.56287 7.93913 8.78326 7.78486 9.22404 7.47631L14.6673 3.66602M4.53398 12.3327H11.4673C12.5874 12.3327 13.1475 12.3327 13.5753 12.1147C13.9516 11.9229 14.2576 11.617 14.4493 11.2407C14.6673 10.8128 14.6673 10.2528 14.6673 9.13268V4.86602C14.6673 3.74591 14.6673 3.18586 14.4493 2.75803C14.2576 2.38171 13.9516 2.07575 13.5753 1.884C13.1475 1.66602 12.5874 1.66602 11.4673 1.66602H4.53398C3.41388 1.66602 2.85383 1.66602 2.426 1.884C2.04968 2.07575 1.74372 2.38171 1.55197 2.75803C1.33398 3.18586 1.33398 3.74591 1.33398 4.86602V9.13268C1.33398 10.2528 1.33398 10.8128 1.55197 11.2407C1.74372 11.617 2.04968 11.9229 2.426 12.1147C2.85383 12.3327 3.41388 12.3327 4.53398 12.3327Z'
        stroke='#8181B0'
        stroke-width='1.6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
export function VariablesMenuFirstNameIcon () {
  return (
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1 12.3333C2.55719 10.6817 4.67134 9.66667 7 9.66667C9.32866 9.66667 11.4428 10.6817 13 12.3333M10 4C10 5.65685 8.65685 7 7 7C5.34315 7 4 5.65685 4 4C4 2.34315 5.34315 1 7 1C8.65685 1 10 2.34315 10 4Z'
        stroke='#8181B0'
        stroke-width='1.6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
export function VariablesMenuLastNameIcon () {
  return (
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1 12.3333C2.55719 10.6817 4.67134 9.66667 7 9.66667C9.32866 9.66667 11.4428 10.6817 13 12.3333M10 4C10 5.65685 8.65685 7 7 7C5.34315 7 4 5.65685 4 4C4 2.34315 5.34315 1 7 1C8.65685 1 10 2.34315 10 4Z'
        stroke='#8181B0'
        stroke-width='1.6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
export function VariablesMenuPhoneIcon () {
  return (
    <svg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.58685 5.90223C5.05085 6.86865 5.68337 7.77441 6.48443 8.57546C7.28548 9.37651 8.19124 10.009 9.15765 10.473C9.24078 10.5129 9.28234 10.5329 9.33494 10.5482C9.52184 10.6027 9.75135 10.5636 9.90963 10.4502C9.95417 10.4183 9.99228 10.3802 10.0685 10.304C10.3016 10.071 10.4181 9.95443 10.5353 9.87824C10.9772 9.59091 11.5469 9.59091 11.9889 9.87824C12.106 9.95443 12.2226 10.071 12.4556 10.304L12.5856 10.4339C12.9398 10.7882 13.117 10.9654 13.2132 11.1556C13.4046 11.534 13.4046 11.9809 13.2132 12.3592C13.117 12.5495 12.9399 12.7266 12.5856 13.0809L12.4805 13.186C12.1274 13.5391 11.9508 13.7156 11.7108 13.8505C11.4445 14.0001 11.0308 14.1077 10.7253 14.1068C10.45 14.1059 10.2619 14.0525 9.8856 13.9457C7.86333 13.3718 5.95509 12.2888 4.36311 10.6968C2.77112 9.10479 1.68814 7.19655 1.11416 5.17429C1.00735 4.79799 0.953949 4.60984 0.953131 4.33455C0.952222 4.02906 1.0598 3.6154 1.20941 3.34907C1.34424 3.10904 1.52078 2.9325 1.87386 2.57942L1.97895 2.47433C2.33325 2.12004 2.5104 1.94289 2.70065 1.84666C3.07903 1.65528 3.52587 1.65528 3.90424 1.84666C4.0945 1.94289 4.27164 2.12004 4.62594 2.47433L4.75585 2.60424C4.98892 2.83732 5.10546 2.95385 5.18165 3.07104C5.46898 3.51296 5.46898 4.08268 5.18165 4.52461C5.10546 4.6418 4.98892 4.75833 4.75585 4.9914C4.67964 5.06761 4.64154 5.10571 4.60965 5.15026C4.4963 5.30854 4.45717 5.53805 4.51165 5.72495C4.52698 5.77754 4.54694 5.81911 4.58685 5.90223Z'
        stroke='#8181B0'
        stroke-width='1.6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
export function VariablesMenuLinkedInIcon () {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48' width='24' height='24'>
      <path
        fill='#0078d4'
        d='M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z'
      />
      <path
        d='M30,37V26.901c0-1.689-0.819-2.698-2.192-2.698c-0.815,0-1.414,0.459-1.779,1.364c-0.017,0.064-0.041,0.325-0.031,1.114L26,37h-7V18h7v1.061C27.022,18.356,28.275,18,29.738,18c4.547,0,7.261,3.093,7.261,8.274L37,37H30z M11,37V18h3.457C12.454,18,11,16.528,11,14.499C11,12.472,12.478,11,14.514,11c2.012,0,3.445,1.431,3.486,3.479C18,16.523,16.521,18,14.485,18H18v19H11z'
        opacity='.05'
      />
      <path
        d='M30.5,36.5v-9.599c0-1.973-1.031-3.198-2.692-3.198c-1.295,0-1.935,0.912-2.243,1.677c-0.082,0.199-0.071,0.989-0.067,1.326L25.5,36.5h-6v-18h6v1.638c0.795-0.823,2.075-1.638,4.238-1.638c4.233,0,6.761,2.906,6.761,7.774L36.5,36.5H30.5z M11.5,36.5v-18h6v18H11.5z M14.457,17.5c-1.713,0-2.957-1.262-2.957-3.001c0-1.738,1.268-2.999,3.014-2.999c1.724,0,2.951,1.229,2.986,2.989c0,1.749-1.268,3.011-3.015,3.011H14.457z'
        opacity='.07'
      />
      <path
        fill='#fff'
        d='M12,19h5v17h-5V19z M14.485,17h-0.028C12.965,17,12,15.888,12,14.499C12,13.08,12.995,12,14.514,12c1.521,0,2.458,1.08,2.486,2.499C17,15.887,16.035,17,14.485,17z M36,36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698c-1.501,0-2.313,1.012-2.707,1.99C24.957,25.543,25,26.511,25,27v9h-5V19h5v2.616C25.721,20.5,26.85,19,29.738,19c3.578,0,6.261,2.25,6.261,7.274L36,36L36,36z'
      />
    </svg>
  )
}
export function VariablesMenuCompanyNameIcon () {
  return (
    <svg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.66732 6.33333H11.8673C12.6141 6.33333 12.9874 6.33333 13.2726 6.47866C13.5235 6.60649 13.7275 6.81046 13.8553 7.06135C14.0007 7.34656 14.0007 7.71993 14.0007 8.46667V13M8.66732 13V3.13333C8.66732 2.3866 8.66732 2.01323 8.52199 1.72801C8.39416 1.47713 8.19019 1.27316 7.93931 1.14532C7.65409 1 7.28072 1 6.53398 1H4.13398C3.38725 1 3.01388 1 2.72866 1.14532C2.47778 1.27316 2.27381 1.47713 2.14598 1.72801C2.00065 2.01323 2.00065 2.3866 2.00065 3.13333V13M14.6673 13H1.33398M4.33398 3.66667H6.33398M4.33398 6.33333H6.33398M4.33398 9H6.33398'
        stroke='#8181B0'
        stroke-width='1.6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
export function VariablesMenuSenderIcon () {
  return (
    <svg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.6673 5L14.6673 3M14.6673 3L12.6673 1M14.6673 3L10.6673 3M10.6673 13V12.2C10.6673 11.0799 10.6673 10.5198 10.4493 10.092C10.2576 9.71569 9.95162 9.40973 9.5753 9.21799C9.14748 9 8.58742 9 7.46732 9H4.53398C3.41388 9 2.85383 9 2.426 9.21799C2.04968 9.40973 1.74372 9.71569 1.55197 10.092C1.33398 10.5198 1.33398 11.0799 1.33398 12.2V13M8.33398 4C8.33398 5.28866 7.28932 6.33333 6.00065 6.33333C4.71199 6.33333 3.66732 5.28866 3.66732 4C3.66732 2.71134 4.71199 1.66667 6.00065 1.66667C7.28932 1.66667 8.33398 2.71134 8.33398 4Z'
        stroke='#8181B0'
        stroke-width='1.6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export function FontSizeIcon () {
  return (
    <svg width='30' height='14' viewBox='0 0 30 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.99935 1.66602V12.3327M10.9993 1.66602V12.3327M4.33268 1.66602H1.66602M10.9993 6.99935L2.99935 6.99935M4.33268 12.3327H1.66602M12.3327 12.3327H9.66602M12.3327 1.66602H9.66602'
        stroke='#101010'
        stroke-width='1.6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M22.9649 6.85355C22.6499 6.53857 22.873 6 23.3184 6H26.0004H28.6824C29.1278 6 29.3509 6.53857 29.0359 6.85355L26.354 9.53553C26.1587 9.7308 25.8421 9.7308 25.6469 9.53553L22.9649 6.85355Z'
        fill='#101010'
      />
    </svg>
  )
}
export function FontBoldIcon () {
  return (
    <svg width='11' height='14' viewBox='0 0 11 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1 6.99935H6.33333C7.80609 6.99935 9 5.80544 9 4.33268C9 2.85992 7.80609 1.66602 6.33333 1.66602H1V6.99935ZM1 6.99935H7C8.47276 6.99935 9.66667 8.19326 9.66667 9.66602C9.66667 11.1388 8.47276 12.3327 7 12.3327H1V6.99935Z'
        stroke='#101010'
        stroke-width='1.6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
export function FontItalicIcon () {
  return (
    <svg width='12' height='14' viewBox='0 0 12 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.6673 1.66602H4.66732M7.33398 12.3327H1.33398M8.00065 1.66602L4.00065 12.3327'
        stroke='#101010'
        stroke-width='1.6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
export function FontUnderlineIcon () {
  return (
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.9993 1.66602V6.33268C10.9993 8.54182 9.20849 10.3327 6.99935 10.3327C4.79021 10.3327 2.99935 8.54182 2.99935 6.33268V1.66602M1.66602 12.9993H12.3327'
        stroke='#101010'
        stroke-width='1.6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
export function StrikeThroughIcon () {
  return (
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3 9.66602C3 11.1388 4.19391 12.3327 5.66667 12.3327H8.33333C9.80609 12.3327 11 11.1388 11 9.66602C11 8.19326 9.80609 6.99935 8.33333 6.99935M11 4.33268C11 2.85992 9.80609 1.66602 8.33333 1.66602H5.66667C4.19391 1.66602 3 2.85992 3 4.33268M1 6.99935H13'
        stroke='#101010'
        stroke-width='1.6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
export function TextColorIcon () {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.33398 8.00065C1.33398 11.6826 4.31875 14.6673 8.00065 14.6673C9.10522 14.6673 10.0007 13.7719 10.0007 12.6673V12.334C10.0007 12.0244 10.0007 11.8696 10.0178 11.7396C10.1359 10.8421 10.8421 10.1359 11.7396 10.0178C11.8696 10.0007 12.0244 10.0007 12.334 10.0007H12.6673C13.7719 10.0007 14.6673 9.10522 14.6673 8.00065C14.6673 4.31875 11.6826 1.33398 8.00065 1.33398C4.31875 1.33398 1.33398 4.31875 1.33398 8.00065Z'
        stroke='#101010'
        stroke-width='1.6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M4.66732 8.66732C5.03551 8.66732 5.33398 8.36884 5.33398 8.00065C5.33398 7.63246 5.03551 7.33398 4.66732 7.33398C4.29913 7.33398 4.00065 7.63246 4.00065 8.00065C4.00065 8.36884 4.29913 8.66732 4.66732 8.66732Z'
        stroke='#101010'
        stroke-width='1.6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M10.6673 6.00065C11.0355 6.00065 11.334 5.70217 11.334 5.33398C11.334 4.96579 11.0355 4.66732 10.6673 4.66732C10.2991 4.66732 10.0007 4.96579 10.0007 5.33398C10.0007 5.70217 10.2991 6.00065 10.6673 6.00065Z'
        stroke='#101010'
        stroke-width='1.6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M6.66732 5.33398C7.03551 5.33398 7.33398 5.03551 7.33398 4.66732C7.33398 4.29913 7.03551 4.00065 6.66732 4.00065C6.29913 4.00065 6.00065 4.29913 6.00065 4.66732C6.00065 5.03551 6.29913 5.33398 6.66732 5.33398Z'
        stroke='#101010'
        stroke-width='1.6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
export function TextPaintBucketIcon () {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.6665 7.33398L1.33313 7.33398M6.66647 2.66732L5.33313 1.33398M9.33313 14.6673L1.33313 14.6673M14.6665 10.6673C14.6665 11.4037 14.0695 12.0007 13.3331 12.0007C12.5968 12.0007 11.9998 11.4037 11.9998 10.6673C11.9998 9.93094 13.3331 8.66732 13.3331 8.66732C13.3331 8.66732 14.6665 9.93094 14.6665 10.6673ZM5.9998 2.00065L10.5789 6.57974C10.8429 6.84375 10.9749 6.97575 11.0244 7.12797C11.0679 7.26187 11.0679 7.4061 11.0244 7.54C10.9749 7.69222 10.8429 7.82422 10.5789 8.08823L7.50829 11.1588C6.98027 11.6868 6.71626 11.9509 6.41182 12.0498C6.14403 12.1368 5.85557 12.1368 5.58778 12.0498C5.28334 11.9509 5.01933 11.6868 4.4913 11.1588L2.17496 8.84248C1.64694 8.31446 1.38293 8.05045 1.28401 7.74601C1.197 7.47822 1.197 7.18975 1.28401 6.92196C1.38293 6.61752 1.64694 6.35351 2.17496 5.82549L5.9998 2.00065Z'
        stroke='#101010'
        stroke-width='1.6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
export function FormatRightIcon () {
  return (
    <svg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.9993 3.66667H4.33268M12.9993 1H1.66602M12.9993 6.33333H1.66602M12.9993 9H4.33268'
        stroke='#101010'
        stroke-width='1.6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
export function FormatCenterIcon () {
  return (
    <svg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11 3.66667H3M13 1H1M13 6.33333H1M11 9H3'
        stroke='#101010'
        stroke-width='1.6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
export function FormatLeftIcon () {
  return (
    <svg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.66667 3.66667H1M12.3333 1H1M12.3333 6.33333H1M9.66667 9H1'
        stroke='#101010'
        stroke-width='1.6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
export function BulletsIcon () {
  return (
    <svg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.66667 6.66732C2.03486 6.66732 2.33333 6.36884 2.33333 6.00065C2.33333 5.63246 2.03486 5.33398 1.66667 5.33398C1.29848 5.33398 1 5.63246 1 6.00065C1 6.36884 1.29848 6.66732 1.66667 6.66732Z'
        fill='#101010'
      />
      <path
        d='M1.66667 2.66732C2.03486 2.66732 2.33333 2.36884 2.33333 2.00065C2.33333 1.63246 2.03486 1.33398 1.66667 1.33398C1.29848 1.33398 1 1.63246 1 2.00065C1 2.36884 1.29848 2.66732 1.66667 2.66732Z'
        fill='#101010'
      />
      <path
        d='M1.66667 10.6673C2.03486 10.6673 2.33333 10.3688 2.33333 10.0007C2.33333 9.63246 2.03486 9.33398 1.66667 9.33398C1.29848 9.33398 1 9.63246 1 10.0007C1 10.3688 1.29848 10.6673 1.66667 10.6673Z'
        fill='#101010'
      />
      <path
        d='M13 6.00065L5 6.00065M13 2.00065L5 2.00065M13 10.0007L5 10.0007M2.33333 6.00065C2.33333 6.36884 2.03486 6.66732 1.66667 6.66732C1.29848 6.66732 1 6.36884 1 6.00065C1 5.63246 1.29848 5.33398 1.66667 5.33398C2.03486 5.33398 2.33333 5.63246 2.33333 6.00065ZM2.33333 2.00065C2.33333 2.36884 2.03486 2.66732 1.66667 2.66732C1.29848 2.66732 1 2.36884 1 2.00065C1 1.63246 1.29848 1.33398 1.66667 1.33398C2.03486 1.33398 2.33333 1.63246 2.33333 2.00065ZM2.33333 10.0007C2.33333 10.3688 2.03486 10.6673 1.66667 10.6673C1.29848 10.6673 1 10.3688 1 10.0007C1 9.63246 1.29848 9.33398 1.66667 9.33398C2.03486 9.33398 2.33333 9.63246 2.33333 10.0007Z'
        stroke='#101010'
        stroke-width='1.6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
export function SubscriptIcon () {
  return (
    <svg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1 1.33398L7.66667 8.00065M7.66667 1.33398L1 8.00065M13 10.6674H10.3333C10.3333 9.66737 10.6267 9.33404 11.3333 9.0007C12.04 8.66737 13 8.2207 13 7.33404C13 7.0207 12.8867 6.71404 12.68 6.47404C12.4666 6.23237 12.1766 6.07136 11.8586 6.01796C11.5407 5.96457 11.214 6.02202 10.9333 6.1807C10.6533 6.3407 10.44 6.59404 10.3333 6.89404'
        stroke='#101010'
        stroke-width='1.6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
export function SuperScriptIcon () {
  return (
    <svg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1 2.3348L7.66667 9.00147M7.66667 2.3348L1 9.00147M13 6.33485H10.3333C10.3333 5.33485 10.6267 5.00152 11.3333 4.66819C12.04 4.33485 13 3.88819 13 3.00152C13 2.68818 12.8867 2.38152 12.68 2.14152C12.4666 1.89985 12.1766 1.73884 11.8586 1.68544C11.5407 1.63205 11.214 1.6895 10.9333 1.84819C10.6533 2.00819 10.44 2.26152 10.3333 2.56152'
        stroke='#101010'
        stroke-width='1.6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
export function HashtagIcon () {
  return (
    <svg width='15' height='14' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.33267 1L4.33267 13M11.666 1L9.66599 13M13.666 4.33333H2.33268M12.9993 9.66667H1.66602'
        stroke='#101010'
        stroke-width='1.6'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export const ChevronDownIcon = () => {
  const theme = useTheme()

  return (
    <>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M17 14L11.9992 9.42L7 14'
          stroke={theme.palette.primary.primaryText}
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </>
  )
}

export const EmailList = () => {
  return (
    <>
      <svg
        width='369'
        height='158'
        viewBox='0 0 369 158'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g filter='url(#filter0_d_4818_73385)'>
          <rect
            x='34.7578'
            y='26.5039'
            width='300.242'
            height='89'
            rx='12'
            fill='white'
            shape-rendering='crispEdges'
          />
          <rect
            x='35.2578'
            y='27.0039'
            width='299.242'
            height='88'
            rx='11.5'
            stroke='#D7D9E6'
            shape-rendering='crispEdges'
          />
          <rect x='50.7578' y='42.5039' width='32' height='32' rx='8' fill='#F2F4F6' />
          <rect x='90.7578' y='46.5039' width='162' height='10' rx='5' fill='#F2F4F6' />
          <rect x='90.7578' y='60.5039' width='177.242' height='10' rx='5' fill='#F2F4F6' />
          <rect x='50.7578' y='89.5039' width='175' height='10' rx='5' fill='#F2F4F6' />
          <rect x='293' y='89.5039' width='26' height='10' rx='5' fill='#F2F4F6' />
        </g>
        <defs>
          <filter
            id='filter0_d_4818_73385'
            x='0.757812'
            y='0.503906'
            width='368.242'
            height='157'
            filterUnits='userSpaceOnUse'
            color-interpolation-filters='sRGB'
          >
            <feFlood flood-opacity='0' result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset dy='8' />
            <feGaussianBlur stdDeviation='17' />
            <feComposite in2='hardAlpha' operator='out' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0.156863 0 0 0 0 0.156863 0 0 0 0 0.482353 0 0 0 0.1 0'
            />
            <feBlend
              mode='normal'
              in2='BackgroundImageFix'
              result='effect1_dropShadow_4818_73385'
            />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_dropShadow_4818_73385'
              result='shape'
            />
          </filter>
        </defs>
      </svg>
    </>
  )
}

export const PaintPour = () => {
  return (
    <>
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clip-path='url(#clip0_8186_94378)'>
          <path
            d='M10.6665 7.33398L1.33313 7.33398M6.66647 2.66732L5.33313 1.33398M9.33313 14.6673L1.33313 14.6673M14.6665 10.6673C14.6665 11.4037 14.0695 12.0007 13.3331 12.0007C12.5968 12.0007 11.9998 11.4037 11.9998 10.6673C11.9998 9.93094 13.3331 8.66732 13.3331 8.66732C13.3331 8.66732 14.6665 9.93094 14.6665 10.6673ZM5.9998 2.00065L10.5789 6.57974C10.8429 6.84375 10.9749 6.97575 11.0244 7.12797C11.0679 7.26187 11.0679 7.4061 11.0244 7.54C10.9749 7.69222 10.8429 7.82422 10.5789 8.08823L7.50829 11.1588C6.98027 11.6868 6.71626 11.9509 6.41182 12.0498C6.14403 12.1368 5.85557 12.1368 5.58778 12.0498C5.28334 11.9509 5.01933 11.6868 4.4913 11.1588L2.17496 8.84248C1.64694 8.31446 1.38293 8.05045 1.28401 7.74601C1.197 7.47822 1.197 7.18975 1.28401 6.92196C1.38293 6.61752 1.64694 6.35351 2.17496 5.82549L5.9998 2.00065Z'
            stroke='#101010'
            stroke-width='1.6'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </g>
        <defs>
          <clipPath id='clip0_8186_94378'>
            <rect width='16' height='16' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </>
  )
}

export const Palette = () => {
  return (
    <>
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clip-path='url(#clip0_8186_88827)'>
          <path
            d='M1.33398 8.00065C1.33398 11.6826 4.31875 14.6673 8.00065 14.6673C9.10522 14.6673 10.0007 13.7719 10.0007 12.6673V12.334C10.0007 12.0244 10.0007 11.8696 10.0178 11.7396C10.1359 10.8421 10.8421 10.1359 11.7396 10.0178C11.8696 10.0007 12.0244 10.0007 12.334 10.0007H12.6673C13.7719 10.0007 14.6673 9.10522 14.6673 8.00065C14.6673 4.31875 11.6826 1.33398 8.00065 1.33398C4.31875 1.33398 1.33398 4.31875 1.33398 8.00065Z'
            stroke='#101010'
            stroke-width='1.6'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M4.66732 8.66732C5.03551 8.66732 5.33398 8.36884 5.33398 8.00065C5.33398 7.63246 5.03551 7.33398 4.66732 7.33398C4.29913 7.33398 4.00065 7.63246 4.00065 8.00065C4.00065 8.36884 4.29913 8.66732 4.66732 8.66732Z'
            stroke='#101010'
            stroke-width='1.6'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M10.6673 6.00065C11.0355 6.00065 11.334 5.70217 11.334 5.33398C11.334 4.96579 11.0355 4.66732 10.6673 4.66732C10.2991 4.66732 10.0007 4.96579 10.0007 5.33398C10.0007 5.70217 10.2991 6.00065 10.6673 6.00065Z'
            stroke='#101010'
            stroke-width='1.6'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M6.66732 5.33398C7.03551 5.33398 7.33398 5.03551 7.33398 4.66732C7.33398 4.29913 7.03551 4.00065 6.66732 4.00065C6.29913 4.00065 6.00065 4.29913 6.00065 4.66732C6.00065 5.03551 6.29913 5.33398 6.66732 5.33398Z'
            stroke='#101010'
            stroke-width='1.6'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </g>
        <defs>
          <clipPath id='clip0_8186_88827'>
            <rect width='16' height='16' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </>
  )
}

export const BasicInfoIcon = () => {
  return (
    <svg width='20' height='17' viewBox='0 0 20 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M9.99935 11.4167H6.24935C5.08638 11.4167 4.50489 11.4167 4.03173 11.5602C2.96639 11.8834 2.13272 12.717 1.80955 13.7824C1.66602 14.2555 1.66602 14.837 1.66602 16M15.8327 16V11M13.3327 13.5H18.3327M12.0827 4.75C12.0827 6.82107 10.4037 8.5 8.33268 8.5C6.26161 8.5 4.58268 6.82107 4.58268 4.75C4.58268 2.67893 6.26161 1 8.33268 1C10.4037 1 12.0827 2.67893 12.0827 4.75Z' stroke='#3F4FF8' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round' />
    </svg>
  )
}

export const AdditionalInfoIcon = () => {
  return (
    <svg width='18' height='20' viewBox='0 0 18 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M16.5 4.16602C16.5 5.54673 13.1421 6.66602 9 6.66602C4.85786 6.66602 1.5 5.54673 1.5 4.16602M16.5 4.16602C16.5 2.7853 13.1421 1.66602 9 1.66602C4.85786 1.66602 1.5 2.7853 1.5 4.16602M16.5 4.16602V15.8327C16.5 17.216 13.1667 18.3327 9 18.3327C4.83333 18.3327 1.5 17.216 1.5 15.8327V4.16602M16.5 8.09953C16.5 9.48286 13.1667 10.5995 9 10.5995C4.83333 10.5995 1.5 9.48286 1.5 8.09953M16.5 12.0327C16.5 13.416 13.1667 14.5327 9 14.5327C4.83333 14.5327 1.5 13.416 1.5 12.0327' stroke='#3F4FF8' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round' />
    </svg>
  )
}

export const EditLeadVerified = () => {
  return (
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M4.3763 7.0013L6.1263 8.7513L9.6263 5.2513M12.8346 7.0013C12.8346 10.223 10.223 12.8346 7.0013 12.8346C3.77964 12.8346 1.16797 10.223 1.16797 7.0013C1.16797 3.77964 3.77964 1.16797 7.0013 1.16797C10.223 1.16797 12.8346 3.77964 12.8346 7.0013Z' stroke='#00AA38' stroke-width='1.4' stroke-linecap='round' stroke-linejoin='round' />
    </svg>
  )
}

export const EditLeadQuestionable = () => {
  return (
    <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M11.25 11.25L8.7125 8.7125M10.0833 5.41667C10.0833 7.994 7.994 10.0833 5.41667 10.0833C2.83934 10.0833 0.75 7.994 0.75 5.41667C0.75 2.83934 2.83934 0.75 5.41667 0.75C7.994 0.75 10.0833 2.83934 10.0833 5.41667Z' stroke='#FFA945' stroke-width='1.4' stroke-linecap='round' stroke-linejoin='round' />
    </svg>
  )
}

export const EditLeadInvalid = () => {
  return (
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M7.0013 4.66797V7.0013M7.0013 9.33464H7.00714M12.8346 7.0013C12.8346 10.223 10.223 12.8346 7.0013 12.8346C3.77964 12.8346 1.16797 10.223 1.16797 7.0013C1.16797 3.77964 3.77964 1.16797 7.0013 1.16797C10.223 1.16797 12.8346 3.77964 12.8346 7.0013Z' stroke='#FF4545' stroke-width='1.4' stroke-linecap='round' stroke-linejoin='round' />
    </svg>
  )
}

export const EditLeadNoStatus = () => {
  return (
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M5.13583 5.24935C5.27298 4.85949 5.54367 4.53074 5.89997 4.32134C6.25628 4.11194 6.67519 4.0354 7.08252 4.10526C7.48985 4.17513 7.85931 4.38691 8.12546 4.70307C8.39161 5.01924 8.53728 5.4194 8.53667 5.83268C8.53667 6.99935 6.78667 7.58268 6.78667 7.58268M6.83333 9.91602H6.83917M12.6667 6.99935C12.6667 10.221 10.055 12.8327 6.83333 12.8327C3.61167 12.8327 1 10.221 1 6.99935C1 3.77769 3.61167 1.16602 6.83333 1.16602C10.055 1.16602 12.6667 3.77769 12.6667 6.99935Z' stroke='#616161' stroke-width='1.4' stroke-linecap='round' stroke-linejoin='round' />
    </svg>
  )
}
