import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Menu,
  TextField,
  Typography,
  List,
  ListItem,
  Checkbox,
  Avatar
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { OffCheckboxCustomIcon } from 'src/assets/general/OffCheckboxCustomIcon'
import { OnCheckboxCustomIcon } from 'src/assets/general/OnCheckboxCustomIcon'

const MailboxDropdown = ({ onFilter, mailboxes }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [localMailboxes, setMailboxes] = useState([])
  // const [filterOption, setFilterOption] = useState("sender");

  useEffect(() => {
    if (searchQuery === '') {
      setMailboxes(mailboxes)
    } else {
      setMailboxes(mailboxes.filter((user) => user.email.includes(searchQuery)))
    }
  }, [searchQuery])

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    onFilter({
      selectedMailbox: selectedUsers
    })
    setAnchorEl(null)
  }

  const handleUserSelect = (user) => {
    setSelectedUsers((prev) =>
      prev.includes(user) ? prev.filter((selected) => selected !== user) : [...prev, user]
    )
  }

  const handleClearAll = () => {
    setSelectedUsers([])
  }

  return (
    <Box position='relative'>
      {/* Mailbox Button */}
      <Button
        onClick={handleOpen}
        endIcon={<ExpandMoreIcon />}
        sx={{
          textTransform: 'none',
          padding: '8px 16px',
          border: '1px solid #E5E7EB',
          borderRadius: '8px',
          color: '#111827',
          backgroundColor: selectedUsers.length > 0 ? '#EEF2FF' : '',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          '&:hover': {
            borderColor: '#3F50F8',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)'
          }
        }}
      >
        <Typography sx={{ fontWeight: '500', color: selectedUsers.length > 0 ? '#3F50F8' : '' }}>
          Mailbox
        </Typography>
        {selectedUsers.length > 0 && (
          <Box
            sx={{
              backgroundColor: '#3F50F8',
              color: '#FFFFFF',
              fontSize: '12px',
              fontWeight: '600',
              padding: '2px 6px',
              borderRadius: '12px',
              display: 'inline-block'
            }}
          >
            {selectedUsers.length}
          </Box>
        )}
      </Button>

      {/* Mailbox Dropdown Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: '350px',
            borderRadius: '8px',
            padding: '16px',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
          }
        }}
      >
        <Box>
          {/* Header with Title and Clear All */}
          <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
            <Typography variant='subtitle1' fontWeight='bold'>
              Mailbox
            </Typography>
            <Button
              onClick={handleClearAll}
              sx={{
                textTransform: 'none',
                color: '#3F50F8',
                fontWeight: 'bold',
                fontSize: '14px'
              }}
            >
              Clear all
            </Button>
          </Box>

          {/* Radio Buttons for Sender(s) and Receiver(s) */}
          {/* <RadioGroup
                        row
                        value={filterOption}
                        onChange={(e) => setFilterOption(e.target.value)}
                        sx={{
                            marginBottom: "16px",
                            "& .MuiFormControlLabel-root": {
                                marginRight: "16px",
                            },
                        }}
                    >
                        <FormControlLabel
                            value="sender"
                            control={<Radio sx={{ color: "#3F50F8" }} />}
                            label="Sender(s)"
                        />

                        <FormControlLabel
                            value="receiver"
                            control={<Radio sx={{ color: "#3F50F8" }} />}
                            label="Receiver(s)"
                        />
                    </RadioGroup> */}

          {/* Search Field */}
          <TextField
            placeholder='Search user...'
            fullWidth
            size='small'
            variant='outlined'
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{
              marginBottom: '16px',
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px'
              }
            }}
          />

          {/* List of Users */}
          <List
            sx={{
              maxHeight: '200px',
              overflowY: 'auto',
              padding: 0
            }}
          >
            {localMailboxes.map((user, index) => (
              <ListItem
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '8px 0'
                }}
              >
                <Checkbox
                  size='small'
                  icon={<OffCheckboxCustomIcon />}
                  checkedIcon={<OnCheckboxCustomIcon />}
                  checked={selectedUsers.includes(user._id)}
                  onChange={() => handleUserSelect(user._id)}
                  sx={{
                    color: '#3F50F8',
                    '&.Mui-checked': {
                      color: '#3F50F8'
                    }
                  }}
                />
                <Avatar
                  src='https://avatar.iran.liara.run/public/boy?username=Ash'
                  alt={user.email}
                  sx={{ width: 32, height: 32, marginRight: '8px' }}
                />
                <Typography variant='body2'>{user.email}</Typography>
              </ListItem>
            ))}
          </List>

          {/* Apply Button */}
          <Button
            variant='contained'
            fullWidth
            onClick={handleClose}
            sx={{
              marginTop: '16px',
              textTransform: 'none',
              backgroundColor: '#3F50F8',
              color: '#FFFFFF',
              fontWeight: '600',
              padding: '10px 0',
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: '#3F50F8'
              }
            }}
          >
            Apply
          </Button>
        </Box>
      </Menu>
    </Box>
  )
}

export default MailboxDropdown
