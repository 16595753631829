import { Button, Card, CardContent, Grid, TextField, Typography, CircularProgress } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React, { useState, useEffect } from 'react'
import EmailTable from './singleEmailDataTable'
import { useValidateSingleEmailMutation, useLazyFetchEmailStatusQuery, useClearHistoryMutation } from 'src/services/email-validation-service'
import TerminologyDialog from './TerminologyDialog'
import toast from 'react-hot-toast'
import SingleEmailResultDialog from './singleEmailResultDialog'

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

const SingleEmailPage = () => {
  const [openTerminologyDialog, setOpenTerminologyDialog] = useState(false)
  const [singleEmailInput, setSingleEmailInput] = useState('')
  const [triggerFetchEmailStatus, { data: emails, isLoading: isFetchEmailStatusLoading }] = useLazyFetchEmailStatusQuery()
  const [clearHistory, { isLoading: isClearHistoryLoading }] = useClearHistoryMutation()
  const [validateSingleEmail, { isLoading: isValidateSingleEmailLoading }] = useValidateSingleEmailMutation()
  const [openSingleEmailResultModal, setOpenSingleEmailResultModal] = useState(false)
  const [singleEmailResponse, setSingleEmailResponse] = useState(null)

  const verifyButtonHandler = async () => {
    const toastId = toast.loading('Validating the email.Please wait!', { duration: Infinity }) // Show loading toast
    try {
      const result = await validateSingleEmail(singleEmailInput)
      if (result.data.message === 'You don\'t have sufficient credits') {
        toast.error('You don\'t have sufficient credits to validate the email. Please buy more credits to continue!')
        return
      }
      toast.success('Successfully validated the email!', { id: toastId })
      setSingleEmailResponse(result.data)
      setOpenSingleEmailResultModal(true)
      setSingleEmailInput('')
    } catch (error) {
      toast.error('Failed to validate the email. Please try again!', { id: toastId })
      throw error
    } finally {
      toast.dismiss(toastId)
    }
  }

  const clearHistoryHandler = async () => {
    try {
      await clearHistory()
      toast.success('Successfully deleted the email!')
    } catch (error) {
      toast.error(error?.data?.error?.message ?? 'Something went wrong')
      throw error
    }
  }

  useEffect(() => {
    triggerFetchEmailStatus()
  }, [])

  return (
    <>
      {/* Verification Cards */}
      <Grid container spacing={3} mb={4}>

        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography sx={{ fontSize: '16px', fontWeight: 500 }} gutterBottom>
                Enter email address that you want to verify
              </Typography>
              <Stack direction='row' spacing={2}>
                <TextField
                  fullWidth
                  placeholder='Email'
                  value={singleEmailInput}
                  onChange={(e) => setSingleEmailInput(e.target.value.trim())}
                />
                <Button
                  variant='contained'
                  color='primary'
                  disabled={!emailRegex.test(singleEmailInput) || isValidateSingleEmailLoading}
                  onClick={verifyButtonHandler}
                >
                  Verify
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card sx={{ height: '100%' }}>
            <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>Would you like to learn more about the status of verified emails?</Typography>
              <Button onClick={() => setOpenTerminologyDialog(true)} sx={{ border: '1px solid ', borderRadius: '25px', maxWidth: '100px', fontSize: '14px', fontWeight: 500, padding: '5px' }}>Terminology</Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Data display  */}
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, width: '100%' }}>
          <Typography variant='h6' component='h2'>
            Emails
          </Typography>
          <div style={{ display: 'flex', gap: '10px' }}>
            {
              emails?.length > 0 &&
              (
                isClearHistoryLoading
                  ? (<CircularProgress />)
                  : (
                    <Button
                      variant='outlined'
                      disabled={isFetchEmailStatusLoading || isValidateSingleEmailLoading}
                      size='small'
                      onClick={clearHistoryHandler}
                    >
                      Clear History
                    </Button>
                    )
              )
            }
            <Button
              variant='outlined'
              disabled={isFetchEmailStatusLoading || isValidateSingleEmailLoading}
              size='small'
              onClick={() => triggerFetchEmailStatus()}
            >
              Refresh
            </Button>
          </div>
        </Box>
        {
          isFetchEmailStatusLoading
            ? (<CircularProgress />)
            : (emails?.length > 0 ? (<EmailTable emails={emails} />) : (<Typography sx={{ mt: '100px' }}>No emails found</Typography>))
        }
      </Box>
      {
        openTerminologyDialog &&
        (<TerminologyDialog openTerminologyDialog={openTerminologyDialog} setOpenTerminologyDialog={setOpenTerminologyDialog} />)
      }
      {
        openSingleEmailResultModal &&
        (<SingleEmailResultDialog open={openSingleEmailResultModal} setOpenSingleEmailResultModal={setOpenSingleEmailResultModal} singleEmailResponse={singleEmailResponse} />)
      }
    </>
  )
}

export default SingleEmailPage
