import React, { useState, useEffect, useContext } from 'react'
import { Box, Divider, Stack, Typography } from '@mui/material'
import { useGetCampaignEmailsReplyMutation } from 'src/services/unibox-service'
import RecipientInfo from 'src/components/inboxHubV2/RecipientInfo'
import Reply from 'src/components/inboxHubV2/Reply'
import NoDataAndWaitingWrapper from 'src/components/inboxHubV2/NoDataAndWaitingWrapper'
import moment from 'moment'
import ScrollablePopup from 'src/components/inboxHubV2/ScrollablePopup'
import EditContactForm from 'src/components/inboxHubV2/EditContactsForm'
import { usePopups } from 'src/hooks/InboxHubHooks'
import AddToCampaignForm from 'src/components/inboxHubV2/AddToCampaignForm'
import ForwardEmail from 'src/components/inboxHubV2/ForwardEmail'
import DeleteEmailThread from 'src/components/inboxHubV2/DeleteEmailThread'
import RemoveLead from 'src/components/inboxHubV2/RemoveLead'
import CreateNewLabel from 'src/components/inboxHubV2/CreateNewLabel'
import { InboxHubContext } from 'src/pages/InboxHub'
import toast from 'react-hot-toast'
import EditLeadKeepEditing from './EditLeadKeepEditing'
// import MailIcon from '@mui/icons-material/Mail';

const EmailContent = ({ email }) => {
  // const modifiedEmailBody = email?.body ? replacePTags(email?.body) : replacePTags(email?.campaign_email_id?.body)

  const { currentlyOpenedEmail, selectedMainFilter } = useContext(InboxHubContext)

  let previousDate // to render the between the replies

  const [
    getCampaignEmailsReply,
    { isLoading: isCampaignsEmailReplyLoading, isError: isCampaignsEmailReplyError }
  ] = useGetCampaignEmailsReplyMutation()
  const [mailReplies, setMailReplies] = useState([])
  const { leads, label, subject } = currentlyOpenedEmail ?? {}

  useEffect(() => {
    if (currentlyOpenedEmail && selectedMainFilter !== 'sent') {
      try {
        getCampaignEmailsReply({
          campaignEmailId: currentlyOpenedEmail._id
        })
          .unwrap()
          .then((data) => {
            setMailReplies(data.docs)
          })
      } catch (e) {
        toast.error('Some problems fetching email replies.')
      }
    } else {
      setMailReplies([
        {
          date: new Date(),
          accountId: '',
          bodyText: currentlyOpenedEmail?.campaign_email_id?.body ?? '',
          bodyTextHtml: '',
          campaignEmailId: '',
          campaignId: '',
          from: currentlyOpenedEmail?.fromAccount?.email ?? '',
          inReplyTo: '',
          subject: currentlyOpenedEmail?.campaign_email_id?.subject ?? '',
          to: currentlyOpenedEmail?.leads?.email ?? ''
        }
      ])
    }
  }, [currentlyOpenedEmail])

  useEffect(() => {
    return () => {
      previousDate = null
    }
  }, [])

  const { handleClosePopup } = usePopups()
  const { currentPopUpOpened } = useContext(InboxHubContext)

  return (
    <NoDataAndWaitingWrapper
      data={mailReplies.length && currentlyOpenedEmail}
      isLoading={isCampaignsEmailReplyLoading}
      loadingText='Loading Replies'
      isError={isCampaignsEmailReplyError}
      errorText='Something went wrong while fetching the replies.'
      noDataText='This email chain is empty'
    >
      <Box
        sx={{
          width: '100%',
          // height: { xs: '100vh', sm: 'calc(100vh - 110px)' },
          height: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          boxShadow: '0px 12px 15px 0px #4B71970D',
          borderRadius: '12px',
          flexDirection: 'column',
          overflowY: 'hidden',
          gap: 2,
          '&::-webkit-scrollbar': { width: '14px' },
          '&::-webkit-scrollbar-track': { borderRadius: '60px' },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#E4E4E5',
            borderRadius: '10px',
            border: '4px solid rgba(0, 0, 0, 0)',
            backgroundClip: 'padding-box'
          },
          '&::-webkit-scrollbar-thumb:hover': { backgroundColor: '#d5d5d5' },
          position: 'relative'
        }}
      >
        <RecipientInfo recipientInfo={{ leads, label, emailSubject: subject }} />

        <Stack
          gap={3}
          sx={{
            width: '100%',
            height: '100%',
            overflowY: 'auto',
            '&::-webkit-scrollbar': { width: '14px' },
            '&::-webkit-scrollbar-track': { borderRadius: '60px' },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#E4E4E5',
              borderRadius: '10px',
              border: '4px solid rgba(0, 0, 0, 0)',
              backgroundClip: 'padding-box'
            },
            '&::-webkit-scrollbar-thumb:hover': { backgroundColor: '#d5d5d5' }
          }}
        >
          {Array.isArray(mailReplies) &&
            mailReplies.map((reply, i) => {
              const { date: currentDate } = reply
              let shouldRenderDateSeparator = false

              if (previousDate) {
                if (previousDate !== currentDate) {
                  shouldRenderDateSeparator = true
                }
              } else {
                previousDate = currentDate
              }

              return (
                <Stack key={i}>
                  {shouldRenderDateSeparator ?? (
                    <Typography sx={{ fontSize: '10px' }}>
                      {moment(currentDate).format('DD-MMMM-YYYY')}
                    </Typography>
                  )}
                  <Reply key={i} reply={reply} email={email} />
                  {i === mailReplies.length - 1 && (
                    <Divider sx={{ color: '#8181B0', fontSize: '12px' }}>
                      {moment(currentDate).format('DD-MMMM-YYYY')}
                    </Divider>
                  )}
                </Stack>
              )
            })}
        </Stack>
      </Box>
      <ScrollablePopup
        open={currentPopUpOpened.length}
        onClose={handleClosePopup}
        title='Scrollable'
      >
        {(currentPopUpOpened === 'editlead' || currentPopUpOpened === 'editleadkeepediting') && (
          <EditContactForm handleClosePopup={handleClosePopup} />
        )}
        {currentPopUpOpened === 'addtocampaign' && (
          <AddToCampaignForm handleClosePopup={handleClosePopup} />
        )}
        {currentPopUpOpened === 'delete' && (
          <DeleteEmailThread handleClosePopup={handleClosePopup} />
        )}
        {currentPopUpOpened === 'removelead' && <RemoveLead handleClosePopup={handleClosePopup} />}
        {currentPopUpOpened === 'forward' && <ForwardEmail handleClosePopup={handleClosePopup} />}
        {currentPopUpOpened === 'createNewLabel' && (
          <CreateNewLabel handleClosePopup={handleClosePopup} />
        )}

      </ScrollablePopup>
      {currentPopUpOpened === 'editleadkeepediting' && (
        <ScrollablePopup
          open={currentPopUpOpened.length}
          // onClose={handleClosePopup}
          title='Scrollable'
        >
          <EditLeadKeepEditing handleClosePopup={handleClosePopup} />
        </ScrollablePopup>
      )}

    </NoDataAndWaitingWrapper>
  )
}

export default EmailContent
