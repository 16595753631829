import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { SideNav } from './side-nav'
import TopBar from './top-bar'
import { useState, createContext, useEffect, useMemo } from 'react'
import { IconButton, Box, useTheme, useMediaQuery, Alert } from '@mui/material'
import { ChevronLeftOutlined, ChevronRightOutlined } from '@mui/icons-material'
import { useGetMeQuery } from 'src/services/user-service.js'
import { useSearchParams, useLocation } from 'react-router-dom'
import VerificationLink from '../verification-link'
import { useGetCustomAttributesQuery } from 'src/services/intercom-service'

const SIDE_NAV_WIDTH = 264
const TOP_NAV_HEIGHT = 64

const LayoutRoot = styled('div')(({ theme, user, isLoading }) => ({
  backgroundColor: 'rgba(0,0,0,0.01)',
  // backgroundColor: theme.palette.background.default,
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  // paddingTop: TOP_NAV_HEIGHT,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: SIDE_NAV_WIDTH
  },
  [theme.breakpoints.down('lg')]: {
    marginTop: user?.emailVerified || isLoading ? TOP_NAV_HEIGHT : 0
  }
}))

const LayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  width: '100%',
  paddingTop: 32,
  paddingBottom: 32
})

export const SidebarContext = createContext()
export const Layout = (props) => {
  const { children } = props
  const theme = useTheme()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'))
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [navMenuOpen, setNavMenuOpen] = useState(false)
  const [searchParams] = useSearchParams()
  const { pathname } = useLocation()
  const iframe = searchParams.get('iframe')
  const handleDrawerToggle = () => {
    setMobileMenuOpen(!mobileMenuOpen)
  }

  const [completionPercentage, setCompletionPercentage] = useState(80)
  const [refresh, setRefresh] = useState(false)
  const { data: user, isLoading } = useGetMeQuery()
  const { data, refetch } = useGetCustomAttributesQuery(undefined, {
    skip: user?.isWhiteLabelUser
  })

  useEffect(() => {
    if (refresh && !user?.isWhiteLabelUser) {
      refetch().then(() => {
        setRefresh(false)
      })
    }
  }, [refresh, refetch, user?.isWhiteLabelUser])
  const customAttributes = useMemo(() => (!user.isWhiteLabelUser && data?.IntercomCustomAttributes) ?? {}, [!user.isWhiteLabelUser && data?.IntercomCustomAttributes])
  useEffect(() => {
    const conditions = [
      customAttributes.email_account_linked === 'true',
      customAttributes.contacts_added === 'true',
      customAttributes.sequence_created === 'true',
      customAttributes.sending_schedule_created === 'true',
      customAttributes.campaign_reviewed === 'true',
      customAttributes.campaign_launched === 'true',
      customAttributes.team_members_invited === 'true',
      customAttributes.lead_finder_search === 'true',
      customAttributes.consultation_clicked === 'true'
    ]
    const counter = conditions.filter(Boolean).length
    setCompletionPercentage(Math.floor((counter / conditions.length) * 100))
  }, [
    customAttributes.email_account_linked,
    customAttributes.contacts_added,
    customAttributes.sequence_created,
    customAttributes.sending_schedule_created,
    customAttributes.campaign_reviewed,
    customAttributes.campaign_launched,
    customAttributes.team_members_invited
  ])

  return (
    <>
      {!iframe && !pathname.includes('workSpace') && (
        <>
          <TopBar
            handleDrawerToggle={handleDrawerToggle}
            user={user}
            isMobile={isMobile}
            isLoading={isLoading}
          />

          {!isMobile && (
            <>
              {user && !isLoading && !user?.emailVerified && (
                <Box
                  sx={{
                    backgroundColor: '#f9f9fa',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Alert
                    variant='outlined'
                    severity='warning'
                    sx={{
                      backgroundColor: '#fff',
                      width: '50%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: '14px'
                    }}
                  >
                    Your account needs verification to access all features.
                    <VerificationLink />
                  </Alert>
                </Box>
              )}
              <IconButton
                onClick={() => setNavMenuOpen(!navMenuOpen)}
                sx={{
                  color: theme.palette.primary.contrastText,
                  backgroundColor: theme.palette.primary.main,
                  position: 'fixed',
                  left: navMenuOpen ? SIDE_NAV_WIDTH - 18 : `calc(${theme.spacing(8)} - 15px)`,
                  top: 45,
                  border: `2px solid ${theme.palette.primary.contrastText} `,
                  borderRadius: '50%',
                  padding: '2px',
                  zIndex: 1001,
                  transition: 'left 0.25s ease',
                  '&:hover': {
                    backgroundColor: theme.palette.primary.main
                  }
                }}
              >
                {navMenuOpen
                  ? (
                    <ChevronLeftOutlined sx={{ fontSize: '28px' }} />
                    )
                  : (
                    <ChevronRightOutlined sx={{ fontSize: '28px' }} />
                    )}
              </IconButton>
            </>
          )}
          <SidebarContext.Provider
            value={{
              completionPercentage,
              setCompletionPercentage,
              onboardingData: data,
              refresh,
              setRefresh
            }}
          >
            <SideNav
              mobileMenuOpen={mobileMenuOpen}
              setMobileMenuOpen={setMobileMenuOpen}
              navMenuOpen={navMenuOpen}
            />
          </SidebarContext.Provider>
        </>
      )}
      <LayoutRoot
        user={user}
        isLoading={isLoading}
        style={{
          paddingLeft: isMobile
            ? 0
            : navMenuOpen
              ? SIDE_NAV_WIDTH
              : `calc(${theme.spacing(8)} + 1px)`
        }}
      >
        <LayoutContainer>
          <SidebarContext.Provider
            value={{
              completionPercentage,
              setCompletionPercentage,
              onboardingData: data,
              refresh,
              setRefresh
            }}
          >
            {children}
          </SidebarContext.Provider>
        </LayoutContainer>
      </LayoutRoot>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node
}
