import React from 'react'
import { Dialog, DialogContent } from '@mui/material'

const ScrollablePopup = ({ open, onClose, title, children }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='sm'
      fullWidth
      PaperProps={{
        style: {
          maxHeight: '80vh'
        }
      }}
    >
      {/* <DialogTitle>
                {title}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle> */}
      <DialogContent
        dividers
        sx={{
          overflowY: 'auto',
          maxHeight: '70vh'
        }}
      >
        {children}
      </DialogContent>
    </Dialog>
  )
}

export default ScrollablePopup
