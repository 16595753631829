import { Stack, Typography } from '@mui/material'
import { EmailListingContext, InboxHubContext } from 'src/pages/InboxHub'
import React, { useContext } from 'react'
import { OrangeButton } from './DeleteEmailThread'
import { WhiteButton } from './CreateAnEmail'
import { useFetchCampaignEmailsQuery, useOpenedBulkEmailMutation } from 'src/services/unibox-service'
import toast from 'react-hot-toast'

function DialogMarkAsReadSelectedEmails ({ handleClosePopup }) {
  const { markedEmailIds, setmarkedEmailIds } = useContext(InboxHubContext)
  const [openBulkEmails] = useOpenedBulkEmailMutation()
  const { getCampaignEmailPayload } = useContext(EmailListingContext)
  const { refetch: refetchCampaignEmails } = useFetchCampaignEmailsQuery(getCampaignEmailPayload)
  async function handleClickYes () {
    try {
      const res = await openBulkEmails({ body: { ids: markedEmailIds, value: true } }).unwrap()
      toast.success(res?.message ?? 'Email(s) marked read successfully')
      setmarkedEmailIds([])
      refetchCampaignEmails()
      handleClosePopup()
    } catch (error) {
      toast.error(error?.data?.error?.message)
    }
  }
  function handleClickNo () {
    handleClosePopup()
  }
  return (
    <Stack gap={2}>
      <Stack width='100%' justifyContent='space-between'>
        <Typography fontWeight={700} fontSize='18px'>
          Are you sure?
        </Typography>
      </Stack>
      <Typography fontWeight={300} fontSize='18px'>
        You want to mark {markedEmailIds.length} email as read?
      </Typography>
      <Stack width='100%' direction={{ xs: 'column', mmd: 'row' }} justifyContent='space-between' gap={2}>
        <WhiteButton onClick={handleClickNo}>No</WhiteButton>
        <OrangeButton onClick={handleClickYes}>
          Yes
        </OrangeButton>
      </Stack>
    </Stack>
  )
}

export default DialogMarkAsReadSelectedEmails
