import React, { useState } from 'react'
import {
  Box,
  Container,
  Tabs,
  Tab
} from '@mui/material'
import SingleEmailPage from 'src/components/emailValidation/singleEmailPage'
import VerifyListPage from 'src/components/emailValidation/verifyListPage'
import { styled } from '@mui/system'
import { useGetMeAndWorkspaceQuery } from 'src/services/user-service'
import HeaderWithPopover from 'src/components/HeaderWithPopover'
// import ApiPage from 'src/components/emailValidation/apiPage'

const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: '#E1E3EC',
  borderRadius: theme.shape.borderRadius,
  minHeight: '48px',
  padding: '4px',
  '& .MuiTabs-indicator': {
    height: '100%',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'white',
    zIndex: 0
  }
}))

const StyledTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.text.secondary,
  minHeight: '40px',
  zIndex: 1,
  textTransform: 'none',
  fontWeight: 500,
  '&.Mui-selected': {
    color: theme.palette.primary.main
  }
}))

const Page = () => {
  const [, setWorkspace] = useState(null)

  const handleWorkspaceChange = (newWorkspace) => {
    setWorkspace(newWorkspace)
  }

  const { data } = useGetMeAndWorkspaceQuery()
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  // const handleClearHistory = () => {
  //   console.log('Clearing history...');
  // };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '100%',
        height: '100%'
      }}
    >
      <Box
        sx={{
          width: '90%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center'
        }}
      >
        <HeaderWithPopover title='Email Validation' onWorkspaceChange={handleWorkspaceChange} selectedTimeline={data?.workspace} />

        <Container maxWidth='lg'>
          {/* types of verification  */}
          <Box sx={{ width: '100%' }}>
            <StyledTabs
              value={value}
              onChange={handleChange}
              variant='fullWidth'
              aria-label='subscription tabs'
            >
              <StyledTab
                label='Single Email'
              />
              <StyledTab
                label='Verify List'
              />
              {/* <StyledTab
                label='API'
              /> */}
            </StyledTabs>

            <Box sx={{ mt: 3 }}>
              {
                value === 0
                  ? (
                    <SingleEmailPage />
                    )
                  : (
                    <VerifyListPage />
                    // value === 1 ? (<VerifyListPage />) : (<ApiPage />)
                    )
              }
            </Box>
          </Box>

        </Container>
      </Box>
    </Box>

  )
}

export default Page
