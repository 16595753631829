import React, { useState, useEffect } from 'react'
import { Modal, Box, TextField, Typography, Button } from '@mui/material'
import { toast } from 'react-hot-toast'

const EditColumnModal = ({ isOpen, onClose, columnData, onSave }) => {
  const [title, setTitle] = useState('')
  const [value, setValue] = useState('')

  useEffect(() => {
    if (columnData) {
      setTitle(columnData.title || '')
      setValue(columnData.defaultValuePerDeal || '')
    }
  }, [columnData])

  useEffect(() => {
    if (!isOpen) {
      setTitle('')
      setValue('')
    }
  }, [isOpen])

  const handleSave = () => {
    if (!title.trim()) {
      toast.error('Column name cannot be empty!')
      return
    }
    if (isNaN(value) || value < 0) {
      toast.error('Value per deal must be a positive number!')
      return
    }
    onSave({
      columnId: columnData?.columnId,
      title,
      valuePerDeal: parseFloat(value)
    })

    setTitle('')
    setValue('')
  }

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          borderRadius: '10px',
          boxShadow: 24,
          p: 4
        }}
      >
        <Typography variant='h6' sx={{ fontWeight: 700, mb: 2 }}>
          Edit Column
        </Typography>
        <TextField
          fullWidth
          label='Name of Column'
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label='Value per Deal'
          type='number'
          value={value}
          onChange={(e) => {
            const value = e.target.value
            if (value === '' || (Number(value) > 0 && !isNaN(value))) {
              setValue(value)
            }
          }}
          InputProps={{
            startAdornment: (
              <Typography variant='body2' sx={{ mr: 1 }}>
                $
              </Typography>
            )
          }}
          sx={{ mb: 3 }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={onClose} variant='outlined'>
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            variant='contained'
            sx={{ bgcolor: '#3F50F8', color: '#fff' }}
          >
            Save Changes
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default EditColumnModal
