import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import PopoverMenu from "./settings/PopoverMenu.js";
import {
  setWorkspace,
  useGetWorkSpacesMutation,
  useUpdateWorkSpaceStatusMutation,
} from "src/services/workSpace-service";
import { useDispatch } from "react-redux";
import useUserVerification from 'src/hooks/useUserVerification';
import PremiumFeatureDialog from "src/utils/premiumFeatureDialog";
import PhoneIcon from "@mui/icons-material/Phone";
import ExportIcon from "@mui/icons-material/ImportExport";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import toast from "react-hot-toast";
const HeaderWithPopoverUpdated = ({ title, onWorkspaceChange, selectedTimeline }) => {
  const { isFreeUser } = useUserVerification();
  const [getWorkSpaces] = useGetWorkSpacesMutation();
  const [updateWorkSpaceStatus] = useUpdateWorkSpaceStatusMutation();
  const [openModal, setOpenModal] = useState(false);
  const [timelineButtons, setTimeLineButtons] = useState([]);
  const [activeWorkspace, setActiveWorkspace] = useState({});
  const [, setTimeline] = useState(null);
  const location = useLocation();
  const dispatch = useDispatch();

  const isAppSumoUser = (workspace) => {
    return workspace && workspace?.assignedPlan && workspace?.assignedPlan.length > 0;
  };

  const isEarlyBirdAccessUser = (workspace) => {
    return workspace && workspace?.isEarlyBirdAccessUser;
  };

  const isPaidUser = (workspace, currentPlan) => {
    return (
      workspace &&
      currentPlan &&
      (currentPlan?.subscription?.sendingWarmup?.expiresAt ||
        currentPlan?.subscription?.leads?.expiresAt)
    );
  };

  const checkFreeWorkspace = async (docs) => {
    const currentWorkspace = docs?.currentWorkspace;
    const currentPlan = docs?.currentPlan;
    const uniqueWorkspaces = docs?.uniqueWorkspaces?.length;

    const isEarlyBird = isEarlyBirdAccessUser(currentWorkspace);
    const isPaid = isPaidUser(currentWorkspace, currentPlan);
    const isAppSumo = isAppSumoUser(currentWorkspace);
    if (
      (!currentPlan && !isAppSumo) ||
      (uniqueWorkspaces > 1 && !isPaid && !isEarlyBird && !isAppSumo)
    ) {
      navigate("/workSpace/billing", { state: { fromCreateWorkspace: true }, replace: true });
    }
  };

  useEffect(() => {
    const callGetWorkSpaces = async () => {
      try {
        const docs = await getWorkSpaces().unwrap();
        setTimeLineButtons(docs?.uniqueWorkspaces);
        setActiveWorkspace(docs?.currentWorkspace);
        checkFreeWorkspace(docs);
      } catch (error) {
        toast.error("Error fetching workspaces");
      }
    };
    callGetWorkSpaces();
  }, [getWorkSpaces]);

  useEffect(() => {
    setTimeline(activeWorkspace);
    dispatch(setWorkspace(activeWorkspace));
    if (activeWorkspace) {
      if (typeof window !== "undefined" && typeof localStorage !== "undefined") {
        window.localStorage.setItem("workspace", JSON.stringify(activeWorkspace));
      }
    }
  }, [timelineButtons]);

  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateToCreateWorkspace = () => {
    if (isFreeUser) {
      setOpenModal(true);
      return;
    }
    navigate("/new/workSpace/create", { state: { from: location.pathname } });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    setTimeline(selectedTimeline);
  }, [selectedTimeline]);

  const handleReload = () => {
    navigate(0);
  };

  const handleWorkspaceChange = async (newTimeline) => {
    // const currentWorkspace = timeline
    const { data } = await updateWorkSpaceStatus({ workspaceId: newTimeline?._id });
    const updatedWorkspace = data?.updatedWorkspace;
    const currentPlan = data?.currentPlan;
    if (currentPlan || updatedWorkspace?.appSumoCode) {
      navigate("/accounts");
      handleReload();
    } else {
      navigate("/workSpace/billing", { state: { fromCreateWorkspace: true }, replace: true });
    }
    setTimeline(updatedWorkspace);
    onWorkspaceChange(updatedWorkspace);
    dispatch(setWorkspace(updatedWorkspace));
  };

  return (
    <Box
      sx={{
        width:
          title === "InboxHub" || title === "Onboarding Hub"
            ? { xs: "100%", lg: "calc(100% - 30px)", xl: "calc(100% - 70px)" }
            : { xs: "100%", xl: "calc(100% + 90px)" },
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        ml: { xs: "0px", lg: "-10px" },
      }}
    >
      <Typography
        sx={{
          color: "#28287B",
          fontSize: { xs: "18px", sm: "32px", md: "32px", lg: "32px" },
          fontWeight: 700,
          lineHeight: "40px",
          letterSpacing: "0px",
        }}
      >
        {title}
      </Typography>
      {title === "Lead Finder" && (
        <Box
          sx={{
            width: { xs: "100%", sm: "100%", md: "38%" },
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#fff",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: 1,
            py: 1,
            px: 2,
            my: 2,
            ml: "auto",
            rowGap: 1,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", mb: { xs: 1, sm: 0 } }}>
            <Typography variant="subtitle1" sx={{ mr: 2 }}>
              Credits balance:
            </Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", mb: { xs: 1, sm: 0 } }}>
            <AccountBalanceIcon sx={{ fontSize: 20, color: "#0000FF" }} />
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                color: "#6B6B6B",
                ml: 1,
              }}
            >
              Enrich: 2,000
            </Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", mb: { xs: 1, sm: 0 } }}>
            <ExportIcon sx={{ fontSize: 20, color: "#0000FF" }} />
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                color: "#6B6B6B",
                ml: 1,
              }}
            >
              Export: 10
            </Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <PhoneIcon sx={{ fontSize: 20, color: "#0000FF" }} />
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                color: "#6B6B6B",
                ml: 1,
              }}
            >
              Phone: 39,211
            </Typography>
          </Box>
        </Box>
      )}

      {/* {title !== "Lead Finder" && (
        <Button
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "left",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "16.38px",
            color: "#28287B",
            backgroundColor: "#fff",
            px: 5.6,
            mb: 1,
            "&:hover": {
              backgroundColor: "#fff",
            },
            border: "1px solid #E4E4E5",
            height: "36px",
          }}
          onClick={handleClick}
        >
          {timeline?.name}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              ml: 1,
            }}
          >
            <DropDown />
          </Box>
        </Button>
      )} */}

      <PopoverMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        timelineButtons={timelineButtons}
        setTimeline={handleWorkspaceChange}
        navigateToCreateWorkspace={navigateToCreateWorkspace}
      />
      <PremiumFeatureDialog open={openModal} onClose={handleCloseModal} />
    </Box>
  );
};

export default HeaderWithPopoverUpdated;
