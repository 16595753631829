import React, { useState } from 'react'
import {
  Button,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Select,
  FormControl
} from '@mui/material'
import TuneIcon from '@mui/icons-material/Tune'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import AddIcon from '@mui/icons-material/Add'
import MoreVertIcon from '@mui/icons-material/MoreVert'

const PipelineHeaderActions = ({ onModalOpen, onAddNewDealModalOpen, onPipelineHeaderAction }) => {
  // States for applied sort option and temporary menu selection
  const [appliedSortOption, setAppliedSortOption] = useState('Name')
  const [appliedOrderOption, setAppliedOrderOption] = useState('Ascending')
  const [menuSortOption, setMenuSortOption] = useState('Name')
  const [menuOrderOption, setMenuOrderOption] = useState('Ascending')

  const [anchorEl, setAnchorEl] = useState(null)

  // Reference for the three dots button
  const threeDotsRef = React.useRef(null)

  const handleMenuOpen = (event, useThreeDotsPosition = false) => {
    // Dynamically set anchorEl
    setAnchorEl(useThreeDotsPosition ? threeDotsRef.current : event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleApply = () => {
    // Apply the selected options
    setAppliedSortOption(menuSortOption)
    setAppliedOrderOption(menuOrderOption)
    onPipelineHeaderAction({
      sortBy: menuSortOption,
      order: menuOrderOption
    })
    handleMenuClose()
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      {/* Button reflects the applied sort option */}
      <Button
        startIcon={<AccessTimeIcon />}
        onClick={(e) => handleMenuOpen(e, true)} // Opens menu at the three dots
        sx={{
          backgroundColor: 'white',
          borderRadius: '10px',
          padding: '5px 15px',
          color: '#111927',
          fontWeight: '500',
          textTransform: 'none',
          border: '1px solid #d1d5db'
        }}
      >
        {appliedSortOption} ({appliedOrderOption})
      </Button>
      <Button
        startIcon={<TuneIcon />}
        sx={{
          backgroundColor: 'white',
          borderRadius: '10px',
          padding: '5px 15px',
          color: '#111927',
          fontWeight: '500',
          textTransform: 'none',
          border: '1px solid #d1d5db'
        }}
        onClick={onModalOpen}
      >
        Manage Columns
      </Button>
      <Button
        startIcon={<AddIcon />}
        onClick={onAddNewDealModalOpen}
        sx={{
          backgroundColor: '#3F50F8',
          borderRadius: '10px',
          padding: '5px 15px',
          color: 'white',
          fontWeight: '500',
          textTransform: 'none',
          '&:hover': {
            backgroundColor: '#3F50F8'
          }
        }}
      >
        Add New Deal
      </Button>
      <IconButton
        sx={{ border: '1px solid #d1d5db', borderRadius: '8px' }}
        id='sort-leads-by'
        onClick={(e) => handleMenuOpen(e)}
        ref={threeDotsRef}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            padding: '16px',
            width: '250px',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px'
          }
        }}
      >
        <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>
          Sort leads by...
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <FormControl fullWidth>
            <Select
              labelId='sort-by-label'
              value={menuSortOption}
              onChange={(e) => setMenuSortOption(e.target.value)}
            >
              <MenuItem value='Last Activity'>Last Activity</MenuItem>
              <MenuItem value='Name'>Name</MenuItem>
              <MenuItem value='Date Created'>Date Created</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <Select
              labelId='order-label'
              value={menuOrderOption}
              onChange={(e) => setMenuOrderOption(e.target.value)}
            >
              <MenuItem value='Ascending'>Ascending</MenuItem>
              <MenuItem value='Descending'>Descending</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Button
          variant='contained'
          sx={{
            backgroundColor: '#3F50F8',
            color: '#fff',
            borderRadius: '8px',
            fontWeight: '600',
            marginTop: '10px',
            width: '100%'
          }}
          onClick={handleApply}
        >
          Apply
        </Button>
      </Menu>
    </Box>
  )
}

export default PipelineHeaderActions
