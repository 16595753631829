import React, { useState } from 'react'
import { Box, Typography, Button, Modal, Paper, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import SvgRenderer from '../SvgRenderer'

import EmailRepliedSvg from 'src/assets/dealPipeline/email-replied.svg'
import LinkClickedSvg from 'src/assets/dealPipeline/link-clicked.svg'
import EmailOpenedSvg from 'src/assets/dealPipeline/email-opened.svg'
import EmailSentSvg from 'src/assets/dealPipeline/email-sent.svg'

const iconsMap = {
  reply: <SvgRenderer src={EmailRepliedSvg} width='40' height='40' alt='icon' />,
  link: <SvgRenderer src={LinkClickedSvg} width='40' height='40' alt='icon' />,
  email_open: <SvgRenderer src={EmailOpenedSvg} width='40' height='40' alt='icon' />,
  send: <SvgRenderer src={EmailSentSvg} width='40' height='40' alt='icon' />
}

const ActivityCard = ({ type, icon, title, timestamp, tooltip, emailData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleShowEmail = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  return (
    <Box
      sx={{
        '&:not(:last-child)': {
          borderBottom: '1px solid #E0E0E0'
        }
      }}
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        p={2}
        mb={1}
        sx={{
          backgroundColor: '#FFFFFF',
          cursor: tooltip ? 'pointer' : 'default',
          '&:hover': { backgroundColor: tooltip ? '#F9FAFB' : '#FFFFFF' }
        }}
      >
        <Box display='flex' alignItems='center' gap={2}>
          {iconsMap[icon]}
          <Box>
            <Typography variant='body2' fontWeight='bold' sx={{ color: '#111827' }}>
              {type}
            </Typography>
            <Typography variant='caption' sx={{ color: '#6B7280', display: 'block' }}>
              {title}
            </Typography>
            <Typography variant='caption' sx={{ color: '#6B7280', display: 'block' }}>
              {timestamp}
            </Typography>
          </Box>
        </Box>
      </Box>
      {tooltip && (
        <Button
          variant='text'
          size='small'
          fullWidth
          onClick={handleShowEmail}
          sx={{
            textTransform: 'none',
            color: 'black',
            border: '1px solid #E5E7EB',
            borderRadius: '8px',
            width: '95%',
            margin: '0 10px 10px 10px',
            '&:hover': { backgroundColor: '#F9FAFB' }
          }}
        >
          Show
        </Button>
      )}
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Paper
          elevation={3}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: '600px',
            bgcolor: 'background.paper',
            borderRadius: '10px',
            p: 4,
            overflow: 'hidden'
          }}
        >
          <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
            <Typography variant='h6'>Subject: {emailData?.subject || 'No Subject'}</Typography>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography variant='body2' sx={{ mb: 2, color: '#6B7280' }}>
            Date: {timestamp}
          </Typography>
          <Box
            sx={{
              maxHeight: '400px',
              overflowY: 'auto',
              border: '1px solid #E5E7EB',
              borderRadius: '8px',
              p: 2
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: emailData?.body || '<p>No Content</p>' }} />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant='contained'
              onClick={handleCloseModal}
              sx={{
                mt: 3,
                backgroundColor: '#3F50F8',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#3B49E1'
                }
              }}
            >
              Close
            </Button>
          </Box>
        </Paper>
      </Modal>
    </Box>
  )
}

export default ActivityCard
