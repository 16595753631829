import React, { useRef, useState, useEffect } from 'react'
import {
  Box,
  Button,
  Typography,
  useTheme,
  CircularProgress,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton
} from '@mui/material'

import { Google } from 'src/assets/Google'
// import qrcode from 'qrcode-generator'
import { MicrosoftIcon } from 'src/assets/MicrosoftIcon'
import { EmailProviderIcon } from 'src/assets/EmailProviderIcon'
import ConnectGoogleAccount from './ConnectGoogleAccount'
import ConnectAnyProvider from './ConnectAnyProvider'
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded'
import { config } from 'src/config.js'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useWindowOpener from 'src/hooks/use-window-opener.js'
import {
  useConnectMicrosoftAccountMutation,
  useGetAccountMutation
} from 'src/services/account-service.js'
import toast from 'react-hot-toast'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import { ArrowLeftIconBlue } from 'src/assets/emailAccounts/connect/ArrowLeftIconBlue'
import useUserVerification from 'src/hooks/useUserVerification'
// import { ErrorMsg } from 'src/utils/contants'
import ConnectMicrosoftAccount from './ConnectMicrosoftAccount'
import { useAuth } from 'src/auth/auth'
import { useDispatch, useSelector } from 'react-redux'
import { paths } from 'src/paths'
// import { WhatsApp } from '@mui/icons-material'
// import { getAuthEmail } from "src/services/auth-service";
import axios from 'axios'
import { useGetMeAndWorkspaceQuery, useGetMeQuery } from 'src/services/user-service'
// import { useBillingUsageQuery } from 'src/services/billing-service'
import { setWorkspace } from 'src/services/workSpace-service'
import { useGetCustomAttributesQuery } from 'src/services/intercom-service'
import { useCheckLinkedInAccountAlreadyExistsMutation, useGetLinkedinLimitsQuery, useGetUnipileHostedAuthUrlMutation, useUpdateLinkedinLimitsMutation } from 'src/services/multichannel-service'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import { useBillingUsageQuery } from 'src/services/billing-service'
import { useErrorMessage } from 'src/utils/contants'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})
const TransitionStep1 = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})
const TransitionStep2 = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='down' ref={ref} {...props} />
})

const Page = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const [searchParams] = useSearchParams()
  const [activeStep, setActiveStep] = useState(0)
  const isCanceled = useRef(false)
  const [activeStepGAcc, setActiveStepGAcc] = useState(0)
  const { data: usage } = useBillingUsageQuery()
  const { data: fetchedLinkedinLimits, refetch: refetchLinkedinLimits } = useGetLinkedinLimitsQuery()
  const [updateLinkedinLimits, { isLoading: updatinglinkedinLimits }] = useUpdateLinkedinLimitsMutation()
  const [checkLinkedInAccountAlreadyExists] = useCheckLinkedInAccountAlreadyExistsMutation()
  const [getUnipileHostedAuthUrl] = useGetUnipileHostedAuthUrlMutation()
  const [connectMicrosoftAccount] = useConnectMicrosoftAccountMutation()
  // const [generatingQR, setGeneratingQR] = useState(false)
  const googleAccountRef = useRef(null)
  // const [whatsAppConnectionCheckCount, setWhatsAppConnectionCheckCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [showLinkedinLimitsInfo, setShowLinkedinLimitsInfo] = useState(false)
  const [showWhatsappQR, setShowWhatsappQR] = useState(false)
  const [openConnectLinkedInAccountModal, setOpenConnectLinkedInAccountModal] = useState(false)
  const [openManageLinkedinLimitsModal, setOpenManageLinkedinLimitsModal] = useState(false)
  const [openRemoveLinkedInAccountModal, setOpenRemoveLinkedInAccountModal] = useState(false)
  const [removeLinkedInAccountId] = useState(null)
  const [linkedInEmail, setLinkedInEmail] = useState('')
  const [linkedinLimits, setLinkedinLimits] = useState({
    connectionRequests: {
      daily: 0,
      weekly: 0,
      monthly: 0
    },
    messages: {
      daily: 0,
      weekly: 0,
      monthly: 0
    },
    inMail: {
      daily: 0,
      weekly: 0,
      monthly: 0
    }
  })
  // const [whatsAppAccountId,setWhatsAppAccountId] = useState('')
  const workspace = useSelector((state) => state.workspace)
  const [isLinkedInConnecting, setIsLinkedInConnecting] = useState(false)
  const [provider, setProvider] = useState('')
  const [, setWhatsAppQRstring] = useState('')
  const [getAccount] = useGetAccountMutation()
  const reconnect = searchParams.get('reconnect')
  const iframe = searchParams.get('iframe')
  const errorMsg = useErrorMessage()
  const { canEdit } = useAuth()
  const dispatch = useDispatch()
  const clearFields = () => {
    setLinkedInEmail('')
    setIsLinkedInConnecting(false)
    const captchaFrame = document.getElementById('captcha-frame')
    if (captchaFrame) {
      captchaFrame.innerHTML = ''
    }
  }
  const { shouldDisableButton: isDisabledButton, isLoading: isVerificationLoading } = useUserVerification()
  const { data: user } = useGetMeQuery()
  const { refetch } = useGetCustomAttributesQuery(undefined, {
    skip: user.isWhiteLabelUser
  })
  useEffect(() => {
    if (reconnect) {
      setIsLoading(true)
      const timer = setTimeout(async () => {
        try {
          const { data } = await getAccount({
            search: reconnect
          })
          setProvider(data.account.provider)
          if (data.account.provider.includes('google')) {
            setActiveStep(1)
          } else if (data.account.provider.includes('microsoft')) {
            handleConnectMicrosoftAccount()
          } else if (data.account.provider.includes('custom')) {
            setActiveStep(3)
          }
        } catch (error) {
          console.error('Error fetching account:', error)
        } finally {
          setIsLoading(false)
        }
      }, 500)
      return () => {
        clearTimeout(timer)
      }
    }
    return () => { }
  }, [reconnect, getAccount])

  // const retrieveAnAccount = async (account_id) => {
  //   const options = {
  //     method: 'GET',
  //     headers: {
  //       accept: 'application/json',
  //       'X-API-KEY': config.UNIPILE_ACCESS_TOKEN
  //     }
  //   }

  //   const response = await fetch(`${config.UNIPILE_DSN}/api/v1/accounts/${account_id}`, options)
  //   const data = await response.json()
  //   console.log('data', data)
  //   return data
  // }
  // const handleCheckWhatsAppConnection = async () => {
  //   console.log('whatsAppAccountId', whatsAppAccountId)
  //   const whatsAppAccount = await retrieveAnAccount(whatsAppAccountId)
  //   console.log('whatsAppAccount', whatsAppAccount)
  //   if (whatsAppAccount.sources[0].status === 'OK') {
  //     setWhatsAppConnectionCheckCount(0)
  //     handleSaveLinkedAccountData(whatsAppAccount)
  //   } else {
  //     if (whatsAppConnectionCheckCount < 5) {
  //       setWhatsAppConnectionCheckCount((prev) => prev + 1)
  //       setTimeout(() => {
  //         handleCheckWhatsAppConnection()
  //       }, 2000)
  //     }
  //   }
  // }

  // const handleConnectWhatsAppAccount = async () => {
  //   const options = {
  //     method: 'POST',
  //     headers: {
  //       accept: 'application/json',
  //       'content-type': 'application/json',
  //       'X-API-KEY': config.UNIPILE_ACCESS_TOKEN
  //     },
  //     body: JSON.stringify({ provider: 'WHATSAPP' })
  //   }
  //   // setGeneratingQR(true);
  //   setShowWhatsappQR(true)
  //   return
  //   const resp = await fetch(`${config.UNIPILE_DSN}/api/v1/accounts`, options)
  //   const response = await resp.json()
  //   console.log('response', response)
  //   if (response.checkpoint && response.checkpoint.qrcode) {
  //     setWhatsAppAccountId(response.account_id)
  //     const qrCodeData = response.checkpoint.qrcode
  //     const typeNumber = 0
  //     const errorCorrectionLevel = 'M' // Error correction level (L, M, Q, H)
  //     const qrObj = qrcode(typeNumber, errorCorrectionLevel)
  //     qrObj.addData(qrCodeData)
  //     qrObj.make()
  //     const imgString = qrObj.createImgTag(4)
  //     if (imgString) {
  //       setWhatsAppQRstring(imgString)
  //     } else {
  //       toast.error('Please try again')
  //     }
  //   }
  //   setGeneratingQR(false)
  // }

  const resetLinkedinLimits = () => {
    if (isLimitChanged()) {
      if (fetchedLinkedinLimits?.limits) {
        setLinkedinLimits(fetchedLinkedinLimits?.limits)
      } else {
        setLinkedinLimits(
          {
            connectionRequests: {
              daily: 0,
              weekly: 0,
              monthly: 0
            },
            messages: {
              daily: 0,
              weekly: 0,
              monthly: 0
            },
            inMail: {
              daily: 0,
              weekly: 0,
              monthly: 0
            }
          }
        )
      }
    }
  }

  const isLimitChanged = () => {
    const fetchedLimits = fetchedLinkedinLimits?.limits
    if (fetchedLimits) {
      if (JSON.stringify(fetchedLimits) !== JSON.stringify(linkedinLimits)) {
        return true
      } else return false
    }
    return true
  }

  const handleUpdateLinkedinLimits = async () => {
    const response = await updateLinkedinLimits({
      connectionRequests: linkedinLimits?.connectionRequests,
      messages: linkedinLimits?.messages,
      inMail: linkedinLimits?.inMail
    })
    if (response.data.success) {
      toast.success('Limits Updated!')
      setOpenConnectLinkedInAccountModal(true)
      refetchLinkedinLimits()
    } else {
      toast.error(response.data.error)
    }
    setOpenManageLinkedinLimitsModal(false)
  }
  function checkAllZeroes (numString) {
    if (/^0+$/.test(numString)) {
      return '0'
    }
    return numString
  }
  const handleChangeLinkedinLimits = (event) => {
    let changedLimit = event.target.value
    if (changedLimit < 0) {
      return
    }
    changedLimit = checkAllZeroes(changedLimit)
    changedLimit = changedLimit.replace(/^0+/, '')
    const [key1, key2] = event.target.name.split('.')
    setLinkedinLimits((prevLimits) => ({
      ...prevLimits,
      [key1]: {
        ...prevLimits[key1],
        [key2]: parseInt(changedLimit)
      }
    }))
  }

  const checkIfAnyValueIsZeroOrNull = () => {
    return Object.values(linkedinLimits).some(category =>
      Object.values(category).some(value => value === 0 || !value)
    )
  }

  const handleCheckLinkedinAccountExists = async (linkedInEmail) => {
    const response = await checkLinkedInAccountAlreadyExists(linkedInEmail)
    return response.data.exists
  }
  const handleConnectLinkedInAccount = async () => {
    setIsLinkedInConnecting(true)
    try {
      const accountExists = await handleCheckLinkedinAccountExists(linkedInEmail)
      if (accountExists) {
        toast.error('Account already exists')
        setOpenConnectLinkedInAccountModal(false)
        clearFields()
        return
      }
      const response = await getUnipileHostedAuthUrl({ provider: 'linkedin' })
      const { object, url, detail } = response.data
      if (object !== 'HostedAuthUrl') {
        toast.error(detail)
        return
      }
      window.open(url, '_blank')
    } catch (e) {
      toast.error('Error connecting LinkedIn account')
    }
    setIsLinkedInConnecting(false)
    navigate('/accounts')
  }

  const [accountDeleteLoading, setAccountDeleteLoading] = useState(null)

  const handleDeleteLinkedAccountFromDB = async () => {
    const response = await axios.delete(`${config.API_BASE_URL}/multichannel/deleteLinkedAccount/${removeLinkedInAccountId}`)
    if (response.data.success) {
      toast.success('Account Deleted!')
    } else {
      toast.error(response.data.error)
    }
    setAccountDeleteLoading(false)
    setOpenConnectLinkedInAccountModal(false)
    clearFields()
  }
  const handleDeleteLinkedAccount = async () => {
    if (!removeLinkedInAccountId) return
    const options = {
      method: 'DELETE',
      headers: {
        accept: 'application/json',
        'X-API-KEY': config.UNIPILE_ACCESS_TOKEN
      }
    }
    setAccountDeleteLoading(true)
    const response = await fetch(`${config.UNIPILE_DSN}/api/v1/accounts/${removeLinkedInAccountId}`, options)
    const data = await response.json()
    if (data?.object === 'AccountDeleted' || data?.status === 404 || data?.status === 503) {
      handleDeleteLinkedAccountFromDB(removeLinkedInAccountId)
    } else {
      setAccountDeleteLoading(false)
      toast.error('Error Deleting Account')
    }
  }

  const handleOnMessage = async (event) => {
    if (event?.data?.provider === 'microsoft_oauth') {
      closeWindow()
      const { code } = event.data
      const toastId = toast.loading('Loading...', { duration: Infinity })
      try {
        const reconnect = searchParams.get('reconnect')
        const { message } = await connectMicrosoftAccount({ code, reconnect }).unwrap()
        toast.success(message, { id: toastId, duration: 2000 })
      } catch (error) {
        toast.error(error.data.error.message, { id: toastId, duration: 2000 })
      } finally {
        navigate('/accounts')
      }
    }
  }

  const { openWindow, closeWindow } = useWindowOpener({ onMessage: handleOnMessage })
  // const workspace = useSelector((state) => state.workspace);
  const handleConnectMicrosoftAccount = async () => {
    const customParams = {
      workspaceId: workspace?._id,
      iframe: iframe || false
    }
    const state = JSON.stringify(customParams)
    const params = new URLSearchParams({
      client_id: config.MICROSOFT_OAUTH_CLIENT_ID,
      scope: config.MICROSOFT_OAUTH_SCOPE,
      redirect_uri: config.MICROSOFT_OAUTH_REDIRECT_URI,
      response_mode: 'query',
      response_type: 'code',
      prompt: 'login',
      state
    })

    if (reconnect) {
      params.append('login_hint', reconnect)
    }
    // window.location.href = `${config.MICROSOFT_OAUTH_AUTHORIZATION_URL}?${params.toString()}`;
    if (!user?.isWhiteLabelUser) refetch()
    const url = `${config.MICROSOFT_OAUTH_AUTHORIZATION_URL}?${params.toString()}`
    openWindow(url)
    if (typeof window !== 'undefined' && typeof window.localStorage !== 'undefined') {
      iframe && window.localStorage.setItem('iframe', iframe)
    }
    setTimeout(() => {
      iframe ? setActiveStep(0) : navigate('/accounts')
    }, 6000)
  }

  const handleCancelClick = () => {
    isCanceled.current = true

    navigate('/accounts')
  }
  const handleSignIn = () => {
    googleAccountRef?.current?.handleConnectGoogleAccount()
  }

  const [activeStepMAcc, setActiveStepMAcc] = useState(0)
  const handleAddMicrosoftAccounts = () => {
    setActiveStep(2)
  }

  const { data } = useGetMeAndWorkspaceQuery()
  useEffect(() => {
    dispatch(setWorkspace(data?.workspace))
    if (data?.workspace) {
      if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
        window.localStorage.setItem('workspace', JSON.stringify(data?.workspace))
      }
    }
  }, [data])

  useEffect(() => {
    if (fetchedLinkedinLimits?.limits) { setLinkedinLimits(fetchedLinkedinLimits?.limits) }
  }, [fetchedLinkedinLimits])

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          width: '100%',
          height: '100%'
          // pb: 12,
        }}
      >
        <Box
          sx={{
            width: '90%',
            height: '100%',
            // py: 4,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              width: '100%',
              flexDirection: 'column'
            }}
          >
            {' '}
            <Typography
              sx={{
                // fontFamily: "Noto Serif Vithkuqi, serif",
                color: `${theme.palette.primary.primaryText}`,
                fontSize: '32px',
                fontWeight: 700,
                lineHeight: '40px',
                letterSpacing: '0px'
              }}
            >
              {reconnect ? 'Reconnect your Account' : 'Connect a New Account'}
            </Typography>
            {!reconnect && activeStep !== 0 && (
              <>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 700,
                    lineHeight: '20px',
                    color: `${theme.palette.primary.main}`,
                    cursor: 'pointer',
                    mt: 1.5,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                  onClick={() => {
                    if (activeStepGAcc !== 0) {
                      setActiveStepGAcc(0)
                    }

                    setActiveStep(0)
                  }}
                >
                  <Box
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}
                  >
                    <ArrowLeftIconBlue />
                  </Box>
                  Choose a Different Email Provider
                </Typography>
              </>
            )}
          </Box>

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {activeStep === 0
              ? (
                  reconnect
                    ? (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '80vh',
                          width: '100%'
                        }}
                      >
                        <CircularProgress />
                      </Box>
                      )
                    : (
                      <>
                        <Grid
                          container
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            mt: 3,
                            width: '100%',
                            mb: 3,
                            gap: 3
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={2.5}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'flex-start',
                              borderRadius: '12px',

                              cursor: 'pointer',
                              width: { xs: '100%', sm: 'fit-content' },
                              border: '1px solid #CECECE',
                              p: 3,
                              flexDirection: 'column',
                              height: { xs: 'fit-content', sm: '100%' },
                              backgroundColor: '#fff',
                              boxShadow: '0px 12px 15px 0px #4B71970D',
                              opacity: canEdit ? 1 : 0.5
                            // mx: 3,
                            }}
                            onClick={() => {
                              navigate(paths.emailAccounts.orderStatus)
                            }}
                          >
                            <LanguageRoundedIcon
                              sx={{
                                background: theme.palette.primary.light,
                                padding: '5px',
                                height: '40px',
                                width: '40px',
                                borderRadius: '100%'
                              }}
                            />
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start'
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: 700,
                                  lineHeight: '26px',
                                  color: `${theme.palette.primary.primaryText}`,
                                  mt: 1.5
                                }}
                              >
                                Done For You
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: '13px',
                                  fontWeight: 400,
                                  lineHeight: '20px',
                                  color: '#8181B0',
                                  mt: 1
                                }}
                              >
                                We setup your account with optimized email delivery.Save time and Money.
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={2.5}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'flex-start',

                              cursor: 'pointer',
                              width: { xs: '100%', sm: 'fit-content' },
                              p: 3,
                              border: `1px solid ${theme.palette.primary.main}`,
                              flexDirection: 'column',
                              height: { xs: 'fit-content', sm: '100%' },
                              backgroundColor: '#fff',
                              borderRadius: '12px',
                              boxShadow: '0px 12px 15px 0px #4B71970D',
                              opacity: canEdit ? 1 : 0.5
                            }}
                            onClick={() => {
                              if (!canEdit || isVerificationLoading) {
                                return
                              }
                              if (isDisabledButton && !iframe) {
                                toast.error(errorMsg)
                                return
                              }
                              setActiveStep(1)
                            }}
                          >
                            <Google />
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start'
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: 700,
                                  lineHeight: '26px',
                                  color: theme.palette.primary.primaryText,
                                  mt: 1.5
                                }}
                              >
                                Google
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: '13px',
                                  fontWeight: 400,
                                  lineHeight: '20px',
                                  color: '#8181B0',
                                  mt: 1
                                }}
                              >
                                G-Suite / Gmail
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={2.5}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'flex-start',
                              borderRadius: '12px',

                              cursor: 'pointer',
                              width: { xs: '100%', sm: 'fit-content' },
                              border: '1px solid #FF7000',
                              p: 3,
                              flexDirection: 'column',
                              height: { xs: 'fit-content', sm: '100%' },
                              backgroundColor: '#fff',
                              boxShadow: '0px 12px 15px 0px #4B71970D',
                              opacity: canEdit ? 1 : 0.5
                            // mx: 3,
                            }}
                            onClick={() => {
                              if (!canEdit || isVerificationLoading) {
                                return
                              }
                              if (isDisabledButton && !iframe) {
                                toast.error(errorMsg)
                                return
                              }
                              handleAddMicrosoftAccounts()
                            }}
                          >
                            <MicrosoftIcon />
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                borderRadius: '12px',
                                cursor: usage?.linkedinCredits && usage?.linkedinCredits > 0 ? 'pointer' : 'not-allowed',
                                width: { xs: '100%', sm: 'fit-content' },
                                p: 3,
                                flexDirection: 'column',
                                height: { xs: 'fit-content', sm: '100%' },
                                backgroundColor: usage?.linkedinCredits && usage?.linkedinCredits > 0 ? '#fff' : '#D3D3D3',
                                opacity: canEdit ? 1 : 0.5
                              // mx: 3,
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: 700,
                                  lineHeight: '26px',
                                  color: `${theme.palette.primary.primaryText}`,
                                  mt: 1.5
                                }}
                              >
                                Microsoft
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: '13px',
                                  fontWeight: 400,
                                  lineHeight: '20px',
                                  color: '#8181B0',
                                  mt: 1
                                }}
                              >
                                Outlook / Office 365
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={2.5}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'flex-start',
                              borderRadius: '12px',

                              cursor: 'pointer',
                              width: { xs: '100%', sm: 'fit-content' },
                              border: '1px solid #CECECE',
                              p: 3,
                              flexDirection: 'column',
                              height: { xs: 'fit-content', sm: '100%' },
                              backgroundColor: '#fff',
                              boxShadow: '0px 12px 15px 0px #4B71970D',
                              opacity: canEdit ? 1 : 0.5
                            }}
                            onClick={() => {
                              if (!canEdit || isVerificationLoading) {
                                return
                              }
                              if (isDisabledButton && !iframe) {
                                toast.error(errorMsg)
                                return
                              }
                              setActiveStep(3)
                            }}
                          >
                            <EmailProviderIcon />
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start'
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: 700,
                                  lineHeight: '26px',
                                  color: `${theme.palette.primary.primaryText}`,
                                  mt: 1.5
                                }}
                              >
                                Any Email Provider
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: '13px',
                                  fontWeight: 400,
                                  lineHeight: '20px',
                                  color: '#8181B0',
                                  mt: 1
                                }}
                              >
                                IMAP / SMTP
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            mt: 3,
                            width: '100%',
                            mb: 3,
                            gap: 3
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={2.5}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'flex-start',
                              borderRadius: '12px',

                              cursor: usage?.linkedinCredits && usage?.linkedinCredits > 0 ? 'pointer' : 'not-allowed',
                              width: { xs: '100%', sm: 'fit-content' },
                              border: '1px solid #CECECE',
                              p: 3,
                              flexDirection: 'column',
                              height: { xs: 'fit-content', sm: '100%' },
                              backgroundColor: usage?.linkedinCredits && usage?.linkedinCredits > 0 ? '#fff' : '#D3D3D3',
                              boxShadow: '0px 12px 15px 0px #4B71970D',
                              opacity: canEdit ? 1 : 0.5
                            // mx: 3,
                            }}
                            onClick={() => {
                              if (!usage?.linkedinCredits || usage?.linkedinCredits <= 0) {
                                toast.error("You don't have any LinkedIn credits left.")
                                return
                              }
                              setOpenManageLinkedinLimitsModal(true)
                            }}
                          >
                            <div
                              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}
                            >
                              <LinkedInIcon sx={{ background: theme.palette.primary.light, padding: '5px', height: '40px', width: '40px', borderRadius: '100%' }} />
                            </div>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start'
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: 700,
                                  lineHeight: '26px',
                                  color: `${theme.palette.primary.primaryText}`,
                                  mt: 1.5
                                }}
                              >
                                Connect with LinkedIn
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: '13px',
                                  fontWeight: 400,
                                  lineHeight: '20px',
                                  color: '#8181B0',
                                  mt: 1
                                }}
                              >
                                Connect with your linkedin account and send invitations and messages to your leads.
                              </Typography>
                            </Box>
                          </Grid>
                          {/* <Grid
                        item
                        xs={12}
                        sm={2.5}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          borderRadius: "12px",

                          cursor: "pointer",
                          width: { xs: "100%", sm: "fit-content" },
                          border: "1px solid #CECECE",
                          p: 3,
                          flexDirection: "column",
                          height: { xs: "fit-content", sm: "100%" },
                          backgroundColor: "#fff",
                          boxShadow: "0px 12px 15px 0px #4B71970D",
                          opacity: canEdit ? 1 : 0.5,
                          // mx: 3,
                        }}
                        onClick={() => {
                          // setOpenConnectLinkedInAccountModal(true);
                          handleConnectWhatsAppAccount()
                        }}
                      >
                        <WhatsApp sx={{ background: "#E7F0FF", padding: '5px', height: '40px', width: '40px', borderRadius: '100%' }} />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: 700,
                              lineHeight: "26px",
                              color: "#28287B",
                              mt: 1.5,
                            }}
                          >
                            Connect with WhatsApp
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: 400,
                              lineHeight: "20px",
                              color: "#8181B0",
                              mt: 1,
                            }}
                          >
                            Connect with your WhatsApp account and send invitations and messages to your leads.
                          </Typography>
                        </Box>
                      </Grid> */}
                          <Grid
                            item
                            xs={12}
                            sm={2.5}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'flex-start',
                              borderRadius: '12px',
                              width: { xs: '100%', sm: 'fit-content' },
                              p: 3,
                              flexDirection: 'column',
                              height: { xs: 'fit-content', sm: '100%' }
                            }}
                          />
                          <Grid
                            item
                            xs={12}
                            sm={2.5}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'flex-start',
                              borderRadius: '12px',
                              width: { xs: '100%', sm: 'fit-content' },
                              p: 3,
                              flexDirection: 'column',
                              height: { xs: 'fit-content', sm: '100%' }
                            }}
                          />
                        </Grid>
                        <Dialog open={showWhatsappQR}>
                          {/* <DialogTitle>{generatingQR ? "" : "Scan the QR code to connect with your WhatsApp account "}</DialogTitle> */}
                          <DialogContent
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              alignItems: 'center'
                            }}
                          >
                            {/* {generatingQR ? <CircularProgress /> : <div dangerouslySetInnerHTML={{ __html: whatsAppQRstring }} />}
                        {!generatingQR && <div>
                          *Don't click on Done or Cancel the process until the QR code is scanned in your WhatsApp
                        </div>} */}
                            <Typography
                              variant='h4'
                            >
                              This feature is under Development
                            </Typography>
                          </DialogContent>
                          <DialogActions
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <Button
                              onClick={() => {
                                setShowWhatsappQR(false)
                                setWhatsAppQRstring('')
                              }}
                            >
                              Cancel
                            </Button>
                          </DialogActions>
                          {/* {!generatingQR && <DialogActions sx={
                        {
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }
                      }>
                        <Button onClick={() => { setShowWhatsappQR(false); setWhatsAppQRstring("") }}>Cancel</Button>
                        <Button onClick={() => { handleCheckWhatsAppConnection(); setShowWhatsappQR(false) }}>Done</Button>
                      </DialogActions>} */}
                        </Dialog>
                        <Dialog
                          open={openConnectLinkedInAccountModal}
                          TransitionComponent={TransitionStep2}
                          keepMounted
                          onClose={() => setOpenConnectLinkedInAccountModal(false)}
                          aria-describedby='alert-dialog-slide-description'
                          sx={{ zIndex: '10' }}
                        >
                          <DialogTitle>Connect Your LinkedIn Account</DialogTitle>
                          <DialogContent>
                            <DialogContentText id='alert-dialog-slide-description'>
                              Connect with your linkedin account to send messages and connection requests
                            </DialogContentText>
                            <TextField
                              autoFocus
                              required
                              margin='dense'
                              id='email'
                              name='email'
                              label='LinkedIn Email Address'
                              value={linkedInEmail}
                              type='email'
                              fullWidth
                              variant='standard'
                              onChange={(e) => setLinkedInEmail(e.target.value)}
                            />
                            {/* <TextField
                            autoFocus
                            required
                            margin='dense'
                            id='password'
                            name='password'
                            label='LinkedIn Password'
                            value={linkedInPassword}
                            type='password'
                            fullWidth
                            variant='standard'
                            onChange={(e) => setLinkedInPassword(e.target.value)}
                          /> */}
                            <div id='captcha-frame' />
                          </DialogContent>
                          <DialogActions>
                            <Button
                              onClick={() => {
                                clearFields()
                                setOpenConnectLinkedInAccountModal(false)
                              }}
                            >
                              Cancel
                            </Button>
                            {isLinkedInConnecting
                              ? (
                                <CircularProgress disableShrink size={30} />
                                )
                              : (
                                <Button onClick={() => handleConnectLinkedInAccount()}>Connect</Button>
                                )}
                          </DialogActions>
                        </Dialog>
                        <Dialog
                          open={showLinkedinLimitsInfo}
                          keepMounted
                          onClose={() => setShowLinkedinLimitsInfo(false)}
                          aria-describedby='alert-dialog-slide-description'
                          sx={{ zIndex: '10' }}
                        >
                          <DialogContent>
                            <strong>Connection Requests (Invitations):</strong> A paid and active LinkedIn connected
                            account can send between 80 and 100 invitations per day and approximately
                            200 per week (300 characters) without being rejected by LinkedIn. For a
                            free account with some history, it's about 15 invitations per week (200
                            characters) or 150 without a note. For new or inactive accounts, begin
                            with low quantities and gradually increase. Some accounts with fewer
                            than ~150 connections or recently created can't send many invitations,
                            and some invitations might not be delivered.
                          </DialogContent>
                          <DialogActions>
                            <Button
                              onClick={() => {
                                setShowLinkedinLimitsInfo(false)
                              }}
                            >
                              Close
                            </Button>

                          </DialogActions>
                        </Dialog>
                        <Dialog
                          open={openManageLinkedinLimitsModal}
                          TransitionComponent={TransitionStep1}
                          keepMounted
                          onClose={() => {
                            setOpenManageLinkedinLimitsModal(false)
                            resetLinkedinLimits()
                          }}
                          aria-describedby='alert-dialog-slide-description'
                          sx={{ zIndex: '9' }}
                        >
                          <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography>
                              Manage Limits
                            </Typography>
                            <IconButton onClick={() => setShowLinkedinLimitsInfo(true)}>
                              <InfoOutlined />
                            </IconButton>
                          </DialogTitle>
                          <DialogContent>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell />
                                  <TableCell>Daily</TableCell>
                                  <TableCell>Weekly</TableCell>
                                  <TableCell>Monthly</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell>Connection<br />Requests</TableCell>
                                  <TableCell>
                                    <TextField
                                      type='number'
                                      name='connectionRequests.daily'
                                      value={linkedinLimits?.connectionRequests?.daily}
                                      onChange={handleChangeLinkedinLimits}
                                      sx={{ width: '30px', textAlign: 'center', '& input': { textAlign: 'center' } }}
                                      variant='standard'
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <TextField
                                      type='number'
                                      name='connectionRequests.weekly'
                                      value={linkedinLimits?.connectionRequests?.weekly}
                                      onChange={handleChangeLinkedinLimits}
                                      sx={{ width: '30px', textAlign: 'center', '& input': { textAlign: 'center' } }}
                                      variant='standard'
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <TextField
                                      type='number'
                                      name='connectionRequests.monthly'
                                      value={linkedinLimits?.connectionRequests?.monthly}
                                      onChange={handleChangeLinkedinLimits}
                                      sx={{ width: '30px', textAlign: 'center', '& input': { textAlign: 'center' } }}
                                      variant='standard'
                                    />
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Messages</TableCell>
                                  <TableCell>
                                    <TextField
                                      type='number'
                                      name='messages.daily'
                                      value={linkedinLimits?.messages?.daily}
                                      onChange={handleChangeLinkedinLimits}
                                      sx={{ width: '30px', textAlign: 'center', '& input': { textAlign: 'center' } }}
                                      variant='standard'
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <TextField
                                      type='number'
                                      name='messages.weekly'
                                      value={linkedinLimits?.messages?.weekly}
                                      onChange={handleChangeLinkedinLimits}
                                      sx={{ width: '30px', textAlign: 'center', '& input': { textAlign: 'center' } }}
                                      variant='standard'
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <TextField
                                      type='number'
                                      name='messages.monthly'
                                      value={linkedinLimits?.messages?.monthly}
                                      onChange={handleChangeLinkedinLimits}
                                      sx={{ width: '30px', textAlign: 'center', '& input': { textAlign: 'center' } }}
                                      variant='standard'
                                    />
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>InMail</TableCell>
                                  <TableCell>
                                    <TextField
                                      type='number'
                                      name='inMail.daily'
                                      value={linkedinLimits?.inMail?.daily}
                                      onChange={handleChangeLinkedinLimits}
                                      sx={{ width: '30px', textAlign: 'center', '& input': { textAlign: 'center' } }}
                                      variant='standard'
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <TextField
                                      type='number'
                                      name='inMail.weekly'
                                      value={linkedinLimits?.inMail?.weekly}
                                      onChange={handleChangeLinkedinLimits}
                                      sx={{ width: '30px', textAlign: 'center', '& input': { textAlign: 'center' } }}
                                      variant='standard'
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <TextField
                                      type='number'
                                      name='inMail.monthly'
                                      value={linkedinLimits?.inMail?.monthly}
                                      onChange={handleChangeLinkedinLimits}
                                      sx={{ width: '30px', textAlign: 'center', '& input': { textAlign: 'center' } }}
                                      variant='standard'
                                    />
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              onClick={() => {
                                setOpenManageLinkedinLimitsModal(false)
                                resetLinkedinLimits()
                              }}
                            >
                              Cancel
                            </Button>
                            {updatinglinkedinLimits
                              ? (
                                <CircularProgress disableShrink size={30} />
                                )
                              : (
                                <Button
                                  disabled={checkIfAnyValueIsZeroOrNull() || !isLimitChanged()}
                                  onClick={() => {
                                    handleUpdateLinkedinLimits()
                                  }}
                                >Update
                                </Button>
                                )}
                            {fetchedLinkedinLimits?.limits &&
                              <Button
                                variant='contained'
                                onClick={() => {
                                  setOpenManageLinkedinLimitsModal(false)
                                  setOpenConnectLinkedInAccountModal(true)
                                  resetLinkedinLimits()
                                }}
                              >
                                Continue with Account Connection
                              </Button>}
                          </DialogActions>
                        </Dialog>
                        <Dialog
                          open={openRemoveLinkedInAccountModal}
                          TransitionComponent={Transition}
                          keepMounted
                          onClose={() => setOpenRemoveLinkedInAccountModal(false)}
                          aria-describedby='alert-dialog-slide-description'
                          sx={{ zIndex: '10' }}
                        >
                          <DialogTitle>Remove Your LinkedIn Account</DialogTitle>
                          <DialogContent>
                            <DialogContentText id='alert-dialog-slide-description'>
                              Your LinkedIn account is already in use. Do you want to delete the previous account and all its data?
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={() => setOpenRemoveLinkedInAccountModal(false)}>
                              No
                            </Button>
                            {accountDeleteLoading
                              ? (
                                <CircularProgress disableShrink size={30} />
                                )
                              : (
                                <Button variant='contained' color='error' onClick={handleDeleteLinkedAccount}>Yes</Button>
                                )}
                          </DialogActions>
                        </Dialog>
                      </>
                      )
                )
              : activeStep === 1
                ? (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        flexDirection: 'column',
                        mt: 3,
                        boxShadow: '0px 12px 15px 0px #4B71970D',
                        backgroundColor: 'white',
                        borderRadius: '12px',
                        p: 3
                      }}
                    >
                      {' '}
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                          mb: 3
                        }}
                      >
                        <Google />
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'space-around',
                            ml: 1.5
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: '14px',
                              fontWeight: 700,
                              lineHeight: '18px',
                              color: `${theme.palette.primary.primaryText}`
                            }}
                          >
                            Connect your Google Account
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '13px',
                              fontWeight: 400,
                              lineHeight: '16px',
                              color: '#8181B0',
                              mt: 1
                            }}
                          >
                            Gmail / G-Suite
                          </Typography>
                        </Box>
                      </Box>
                      <ConnectGoogleAccount
                        iframe={iframe}
                        activeStepGAcc={activeStepGAcc}
                        setActiveStepGAcc={setActiveStepGAcc}
                        setActiveStep={setActiveStep}
                        ref={googleAccountRef}
                      />
                    </Box>
                  </>
                  )
                : activeStep === 2
                  ? (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        flexDirection: 'column',
                        mt: 3,
                        boxShadow: '0px 12px 15px 0px #4B71970D',
                        backgroundColor: 'white',
                        borderRadius: '12px',
                        p: 3
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                          mb: 3
                        }}
                      >
                        <MicrosoftIcon />
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            justifyContent: 'space-around',
                            ml: 1.5
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: '14px',
                              fontWeight: 700,
                              lineHeight: '18px',
                              color: `${theme.palette.primary.primaryText}`
                            }}
                          >
                            Connect your Microsoft Account
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '13px',
                              fontWeight: 400,
                              lineHeight: '16px',
                              color: '#8181B0',
                              mt: 1
                            }}
                          >
                            Outlook / Office 365
                          </Typography>
                        </Box>
                      </Box>
                      <ConnectMicrosoftAccount
                        iframe={iframe}
                        activeStepMAcc={activeStepMAcc}
                        setActiveStepMAcc={setActiveStepMAcc}
                        setActiveStep={setActiveStep}
                      />
                    </Box>
                    )
                  : activeStep === 2
                    ? (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: { xs: 'column-reverse', sm: 'row' },
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                          gap: 2,
                          mt: activeStep !== 0 ? 3 : 0
                        }}
                      >
                        {activeStep === 2 && (
                          <Button
                            variant='contained'
                            sx={{
                              fontSize: '14px',
                              px: 4,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              backgroundColor: theme.palette.primary.main,
                              color: theme.palette.primary.contrastText,
                              border: `1px solid ${theme.palette.primary.main}`,
                              py: 1.5,
                              width: { xs: '100%', sm: 'fit-content' },

                              '&:hover': {
                                boxShadow: 15
                              }
                            }}
                            onClick={() => {
                              setActiveStepMAcc()
                            }}
                          >
                            I confirm that IMAP and Auth-SMTP is enabled
                          </Button>
                        )}
                      </Box>
                      )
                    : activeStep === 2
                      ? (
                        <></>
                        )
                      : activeStep === 3
                        ? (
                          <ConnectAnyProvider
                            isCanceled={isCanceled}
                            iframe={iframe}
                            setActiveStep={setActiveStep}
                          />
                          )
                        : null}
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column-reverse', sm: 'row' },
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                gap: 2,
                mt: activeStep !== 0 ? 3 : 0
              }}
            >
              {' '}
              {!isLoading && provider !== 'microsoft_oauth' && !iframe
                ? (
                  <Button
                    variant='outlined'
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: 'white',
                      border: `1px solid ${theme.palette.primary.main}`,
                      py: 1.5,
                      px: 9,
                      width: { xs: '100%', sm: 'fit-content' }
                    }}
                    onClick={handleCancelClick}
                  >
                    Cancel
                  </Button>
                  )
                : (
                    ''
                  )}
              {(activeStep === 1 || activeStep === 2) && activeStepGAcc === 0 && (
                <Button
                  variant='contained'
                  sx={{
                    fontSize: '14px',
                    px: 4,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    border: `1px solid ${theme.palette.primary.main}`,
                    py: 1.5,
                    width: { xs: '100%', sm: 'fit-content' },

                    '&:hover': {
                      boxShadow: 15
                    }
                  }}
                  onClick={() => {
                    if (activeStep === 1) {
                      setActiveStepGAcc(1)
                    } else if (activeStep === 2) {
                      handleConnectMicrosoftAccount()
                    }
                  }}
                >
                  I confirm that IMAP and Auth-SMTP is enabled
                </Button>
              )}
              {activeStep === 1 && activeStepGAcc === 3 && (
                <Button
                  sx={{
                    fontSize: '14px',
                    px: 4,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    border: `1px solid ${theme.palette.primary.main}`,
                    py: 1.5,
                    width: { xs: '100%', sm: 'fit-content' },
                    '&:hover': {
                      boxShadow: 15
                    }
                  }}
                  onClick={handleSignIn}
                  variant='contained'
                >
                  Sign In
                </Button>
              )}
              {activeStep === 1 && activeStepGAcc === 4 && (
                <Button
                  sx={{
                    fontSize: '14px',
                    px: 4,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    border: `1px solid ${theme.palette.primary.main}`,
                    py: 1.5,
                    width: { xs: '100%', sm: 'fit-content' },
                    '&:hover': {
                      boxShadow: 15
                    }
                  }}
                  variant='contained'
                  onClick={() => setActiveStepGAcc(5)}
                >
                  Next
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Page
