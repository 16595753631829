import React from 'react'
import { Box, Typography, IconButton, Button } from '@mui/material'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useNavigate, useLocation } from 'react-router-dom'
import UserCard from './UserCard'
import EmptyColumnState from './EmptyColumnState'
import { renderTitleWithIcon } from './renderTitleWithIcon'

const PipelineColumn = ({
  column,
  columnId,
  onUserCardClick,
  onMenuOpen,
  availableHeight,
  onAddDeal
}) => {
  const navigate = useNavigate()
  const location = useLocation()

  const handleAddDealClick = () => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('labelId', columnId)
    navigate({ search: searchParams.toString() }, { replace: true })
    onAddDeal()
  }

  return (
    <Box
      sx={{
        position: 'relative',
        flex: '0 0 300px',
        height: `${availableHeight}px`,
        backgroundColor: '#FFFFFF',
        padding: '16px',
        borderRadius: '8px',
        boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
        marginRight: '16px',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {/* Column Header */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '16px'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {renderTitleWithIcon({ name: column.title })}
          <Typography variant='h6' sx={{ color: '#111927', fontWeight: '700' }}>
            <Box
              component='span'
              sx={{
                backgroundColor: '#3F50F8',
                color: '#FFFFFF',
                padding: '2px 10px',
                borderRadius: '8px',
                fontSize: '14px',
                fontWeight: '600',
                display: 'inline-block'
              }}
            >
              {column.cards.length}
            </Box>
          </Typography>
        </Box>
        <IconButton onClick={(event) => onMenuOpen(event, columnId)}>
          <MoreVertIcon />
        </IconButton>
      </Box>

      {/* Card Container: Wrapped in Droppable */}
      <Droppable droppableId={columnId} type='CARD'>
        {(provided, snapshot) => (
          <Box
            ref={provided.innerRef}
            {...provided.droppableProps}
            sx={{
              flex: 1,
              overflowY: 'scroll',
              scrollbarWidth: 'none',
              '-ms-overflow-style': 'none',
              paddingBottom: '16px',
              '&::-webkit-scrollbar': {
                width: '8px'
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'transparent',
                borderRadius: '4px'
              },
              '&:hover::-webkit-scrollbar-thumb': {
                backgroundColor: '#E1E3EC'
              },
              '&:hover::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#C1C3D1'
              }
            }}
          >
            {column.cards.length === 0
              ? (
                <>
                  <Button
                    variant='contained'
                    onClick={handleAddDealClick}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      backgroundColor: '#3F50F8',
                      color: '#FFFFFF',
                      fontWeight: '600',
                      borderRadius: '8px',
                      textTransform: 'none',
                      '&:hover': {
                        backgroundColor: '#2C3CD0'
                      },
                      marginBottom: '20px'
                    }}
                  >
                    Add New Deal
                  </Button>
                  <EmptyColumnState />
                </>
                )
              : (
                  column.cards.map((card, index) => (
                    <Draggable draggableId={card.id} index={index} key={card.id}>
                      {(provided, snapshot) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          sx={{ marginBottom: '8px' }}
                        >
                          <UserCard
                            name={card.name}
                            email={card.email}
                            date={card.date}
                            time={card.time}
                            avatar={
                          card.additionalData?.additionalData?.photo_url ||
                          'https://avatar.iran.liara.run/public/boy?username=Ash'
                        }
                            onClick={() => onUserCardClick(card, columnId)}
                          />
                        </Box>
                      )}
                    </Draggable>
                  ))
                )}
            {provided.placeholder}
            {/* Optionally, if there are cards already, show an "Add New Deal" button at the bottom */}
            {column.cards.length > 0 && (
              <Button
                variant='contained'
                onClick={handleAddDealClick}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  backgroundColor: '#3F50F8',
                  color: '#FFFFFF',
                  fontWeight: '600',
                  borderRadius: '8px',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: '#2C3CD0'
                  },
                  marginBottom: '20px'
                }}
              >
                Add New Deal
              </Button>
            )}
          </Box>
        )}
      </Droppable>

      {column.cards.length > 0 && (column.valuePerDeal || column.valuePerDeal === 0) && (
        <Button
          variant='contained'
          sx={{
            position: 'absolute',
            bottom: '13px',
            left: '16px',
            right: '16px',
            backgroundColor: '#FFFFFF',
            color: 'black',
            fontWeight: '600',
            borderRadius: '8px',
            textTransform: 'none',
            border: '1px solid #E0E0E0',
            '&:hover': {
              backgroundColor: '#F0F0F0'
            },
            margin: '0 auto'
          }}
        >
          Total: ${column.valuePerDeal}
        </Button>
      )}
    </Box>
  )
}

export default PipelineColumn
