import React, { useState } from 'react'
import {
  Chip,
  Box,
  Stack,
  Typography,
  styled,
  CircularProgress,
  Menu,
  MenuItem
} from '@mui/material'
import { BlueButton, WhiteButton } from './CreateAnEmail'
import { useMenu } from 'src/hooks/InboxHubHooks'
import { useCreateLabelMutation } from 'src/services/campaign-service'
import toast from 'react-hot-toast'

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
export const StyledChip = styled(Chip)(() => ({
  border: '1px solid #3F4FF836',
  backgroundColor: '#E6F0FF',
  borderRadius: 30
}))

const CreateNewLabel = ({ handleClosePopup }) => {
  // const { currentlyOpenedEmail } = useContext(InboxHubContext);
  const [labelName, setLabelName] = useState('')
  const [selectedInterest, setSelectedInterest] = useState('')
  const [createLabel, { isLoading: isCreatingLabel }] = useCreateLabelMutation()

  const {
    anchorEl: interestOptionsAnchorEl,
    open: interestOptionsOpen,
    handleClick: handleClickField,
    handleClose: handleInterestOptionsClose
  } = useMenu()

  async function handleClickCreateLabel () {
    try {
      if (!labelName.trim() || !selectedInterest.trim()) {
        throw new Error({ data: { error: { message: 'Please Add Label & Status Type' } } })
      }
      const { message } = await createLabel({
        name: labelName,
        type: selectedInterest.toLowerCase()
      }).unwrap()
      toast.success(message)
      handleClosePopup()
    } catch (err) {
      toast.error(err?.data?.error?.message ?? 'Problem with creating label')
    }
  }

  function handleClickInterestOption (interest) {
    handleInterestOptionsClose()
    setSelectedInterest(interest)
  }

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <Stack width='100%' justifyContent='space-between' mb={3}>
        <Typography fontWeight={700} fontSize='18px'>
          Create New Label
        </Typography>
      </Stack>
      <Stack gap={1}>
        <Stack gap={1}>
          <Typography>Label:</Typography>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              border: '1px solid #ccc',
              borderRadius: 2,
              paddingY: '3px',
              paddingX: '6px',
              cursor: 'pointer',
              minHeight: '3rem'
            }}
          >
            <input
              style={{
                height: '100%',
                outline: 'none',
                fontSize: '1rem',
                border: 'none',
                width: '100%'
              }}
              placeholder='Type label name'
              value={labelName}
              onChange={(e) => {
                setLabelName(e.target.value)
              }}
            />
          </Box>
        </Stack>
        <Stack gap={1}>
          <Typography>Interest:</Typography>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              border: '1px solid #ccc',
              borderRadius: 2,
              paddingY: '3px',
              paddingX: '6px',
              cursor: 'pointer',
              minHeight: '3rem'
            }}
            onClick={(e) => {
              handleClickField(e)
            }}
          >
            {selectedInterest}
          </Box>
        </Stack>
        <Stack direction={{ xs: 'column', mmd: 'row' }} justifyContent='flex-end' gap={1}>
          <WhiteButton onClick={handleClosePopup}>Cancel</WhiteButton>
          {isCreatingLabel
            ? (
              <CircularProgress />
              )
            : (
              <BlueButton disabled={false} onClick={handleClickCreateLabel}>
                Create
              </BlueButton>
              )}
        </Stack>
      </Stack>

      <MoreOptionsMenu
        open={interestOptionsOpen}
        moreOptionsAnchorEl={interestOptionsAnchorEl}
        handleClose={handleInterestOptionsClose}
        handleOptionClick={handleClickInterestOption}
      />
    </Box>
  )
}

export default CreateNewLabel

const optionsList = ['Positive', 'Negative', 'Neutral']

const MoreOptionsMenu = ({ moreOptionsAnchorEl, handleClose, open, handleOptionClick }) => {
  // const options = selectedField === 'campaign' ? campaignsOptions : emailsOptions

  return (
    <Menu
      id='interests-list'
      anchorEl={moreOptionsAnchorEl}
      open={open}
      onClose={handleClose}
      sx={{ width: '100%' }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      MenuListProps={{
        'aria-labelledby': 'basic-button'
      }}
      PaperProps={{
        style: {
          maxHeight: 400, // Set the maximum height
          overflow: 'auto' // Enable scrolling
        }
      }}
    >
      <Stack>
        {optionsList.map((option, key) => (
          <MenuItem
            key={key}
            onClick={() => {
              handleOptionClick(option)
            }}
          >
            <Typography textOverflow='ellipsis' overflow='hidden' width='20rem'>
              {option ?? ''}
            </Typography>
          </MenuItem>
        ))}
      </Stack>
    </Menu>
  )
}
