import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Typography,
  styled,
  CircularProgress,
  IconButton
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import InfoIcon from '@mui/icons-material/Info'
import CloseIcon from '@mui/icons-material/Close'
import VerifyListHistoryTable from './verifyListHistoryTable'
import VerlifyListDialog from './verlifyListDialog'
import { toast } from 'react-hot-toast'
import { useLazyGetEmailBatchesQuery, useValidateBulkEmailMutation } from 'src/services/email-validation-service'

// Styled components
const UploadBox = styled(Box)(({ theme }) => ({
  border: '2px dashed #E5E7EB',
  borderRadius: '8px',
  padding: theme.spacing(6),
  textAlign: 'center',
  backgroundColor: '#FFFFFF',
  cursor: 'pointer',
  '&:hover': {
    borderColor: theme.palette.primary.main
  }
}))

const VerifyListPage = () => {
  const [openModal, setOpenModal] = useState(false)
  const [dragActive, setDragActive] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [allEmailBatches, setAllEmailBatches] = useState([])
  const [validateBulkEmail, { isLoading: isValidatingBulkEmail }] = useValidateBulkEmailMutation() // for validating bulk email
  const [triggerGetEmailBatches, { data: allBatchesData, isLoading: isAllBatchesLoading }] = useLazyGetEmailBatchesQuery() // get data of all batches

  useEffect(() => {
    const fetchData = async () => {
      try {
        await triggerGetEmailBatches().unwrap()
      } catch (error) {
        toast.error(error?.data?.error?.message ?? 'Something went wrong while fetching data from server')
        throw error
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    setAllEmailBatches(allBatchesData?.data || [])
  }, [allBatchesData])

  // const allEmailBatches = allBatchesData?.data || []

  const handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)

    const files = e.dataTransfer.files
    handleFiles(files)
  }

  const handleFileInput = (e) => {
    const files = e.target.files
    handleFiles(files)
  }

  const handleFiles = (files) => {
    if (files.length) {
      const file = files[0]
      setSelectedFile(file)
    }
  }

  const handleRemoveFile = () => {
    setSelectedFile(null) // Remove selected file
  }

  const handleStartBulkValidation = async () => {
    toast.loading('Validating the emails.Please wait!')
    try {
      if (selectedFile) {
        const result = await validateBulkEmail({
          file: selectedFile,
          batchName: selectedFile.name
        })
        if (result.data.message === 'You don\'t have sufficient credits') {
          setSelectedFile(null)
          toast.error('You don\'t have sufficient credits to validate the email. Please buy more credits to continue!')
          return
        }
      }
      setOpenModal(false)
      setSelectedFile(null) // Remove selected file
    } catch (error) {
      toast.error(error?.data?.error?.message ?? 'Something went wrong')
      throw error
    }
  }

  return (
    <>
      <Box sx={{ p: 0, maxWidth: '1200px', display: 'flex', flexDirection: 'column' }}>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          {
            allEmailBatches.length > 0 &&
            (
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Button
                  variant='contained'
                  startIcon={<AddIcon />}
                  onClick={() => setOpenModal(true)}
                  sx={{
                    backgroundColor: '#4F46E5',
                    '&:hover': {
                      backgroundColor: '#4338CA'
                    }
                  }}
                >
                  ADD NEW LIST
                </Button>
                {
                  isAllBatchesLoading
                    ? (
                      <CircularProgress />
                      )
                    : (
                      <Button
                        variant='outlined'
                        onClick={() => triggerGetEmailBatches()}
                      >
                        Refresh
                      </Button>
                      )
                }
              </Box>
            )
          }
        </Box>
        {
          isAllBatchesLoading
            ? (<CircularProgress />)
            : (
                allEmailBatches.length > 0 &&
              (
                <VerifyListHistoryTable allEmailBatches={allEmailBatches} />
              )
              )

        }

      </Box>

      {
        allEmailBatches.length === 0 &&
        (
          <Box fullWidth>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pb: 1 }}>
              <Box>
                <Typography variant='h6' component='h2'>
                  Add new list
                </Typography>
              </Box>
            </Box>
            <Box>
              <UploadBox
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
                sx={{
                  backgroundColor: dragActive ? '#F9FAFB' : '#FFFFFF'
                }}
              >
                <Typography variant='body1' sx={{ mb: 2 }}>
                  Drag and drop file here
                </Typography>
                <Typography variant='body1' sx={{ mb: 2 }}>
                  or
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                  <Button
                    variant='contained'
                    component='label'
                    sx={{ backgroundColor: '#4F46E5' }}
                    disabled={!!selectedFile} // Disable if file is already uploaded
                  >
                    UPLOAD FILE
                    <input
                      type='file'
                      hidden
                      accept='.csv,.txt'
                      onChange={handleFileInput}
                    />
                  </Button>
                </Box>
                {selectedFile && (
                  <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                    <Typography>Selected: {selectedFile.name}</Typography>
                    <IconButton size='small' onClick={handleRemoveFile} color='error'>
                      <CloseIcon fontSize='small' />
                    </IconButton>
                  </Box>
                )}
                {
                  selectedFile && (
                    <Box sx={{ mt: 2 }}>
                      <Button
                        variant='contained'
                        sx={{ mt: 2, backgroundColor: '#4F46E5' }}
                        onClick={handleStartBulkValidation}
                        disabled={isValidatingBulkEmail}
                      >
                        Start Validation
                      </Button>
                    </Box>
                  )
                }
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
                  <InfoIcon sx={{ fontSize: 16, mr: 1, color: '#6B7280' }} />
                  <Typography variant='body2' color='text.secondary'>
                    We support .csv, .txt up to 1M emails/100 MB or XLSX up to 100k rows
                  </Typography>
                </Box>
              </UploadBox>
            </Box>
          </Box>
        )
      }

      {
        allEmailBatches.length > 0 &&
        (
          <VerlifyListDialog
            openModal={openModal}
            setOpenModal={setOpenModal}
            handleDrag={handleDrag}
            handleDrop={handleDrop}
            dragActive={dragActive}
            handleFileInput={handleFileInput}
            selectedFile={selectedFile}
            handleStartBulkValidation={handleStartBulkValidation}
            isValidatingBulkEmail={isValidatingBulkEmail}
            handleRemoveFile={handleRemoveFile}
          />
        )
      }

    </>
  )
}

export default VerifyListPage
