import React, { useState, useMemo } from 'react'
import {
  Box,
  Button,
  Menu,
  TextField,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { OffCheckboxCustomIcon } from 'src/assets/general/OffCheckboxCustomIcon'
import { OnCheckboxCustomIcon } from 'src/assets/general/OnCheckboxCustomIcon'

const CampaignDropdown = ({ campaigns, onFilter }) => {
  const [campaignAnchorEl, setCampaignAnchorEl] = useState(null)
  const [selectedCampaigns, setSelectedCampaigns] = useState([])
  const [searchQuery, setSearchQuery] = useState('')

  const handleCampaignClick = (event) => {
    setCampaignAnchorEl(event.currentTarget)
  }

  const handleCampaignClose = () => {
    setCampaignAnchorEl(null)
  }

  const handleCampaignSelect = (campaignId) => {
    setSelectedCampaigns((prev) =>
      prev.includes(campaignId)
        ? prev.filter((selected) => selected !== campaignId)
        : [...prev, campaignId]
    )
  }

  const handleClearAllCampaigns = () => {
    setSelectedCampaigns([])
  }

  const handleApply = () => {
    onFilter({
      campaignId: selectedCampaigns
    })
    setCampaignAnchorEl(null) // Close the dropdown
  }

  const filteredCampaigns = useMemo(() => {
    const trimmedQuery = searchQuery.trim().toLowerCase()
    if (!trimmedQuery) return campaigns
    return campaigns.filter((campaign) => campaign.name?.toLowerCase().includes(trimmedQuery))
  }, [campaigns, searchQuery])

  return (
    <Box position='relative'>
      {/* Campaign Button */}
      <Button
        onClick={handleCampaignClick}
        endIcon={<ExpandMoreIcon />}
        sx={{
          textTransform: 'none',
          padding: '8px 16px',
          border: '1px solid #E5E7EB',
          borderRadius: '8px',
          color: '#111827',
          backgroundColor: selectedCampaigns.length > 0 ? '#EEF2FF' : '',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          '&:hover': {
            borderColor: '#3F50F8',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)'
          }
        }}
      >
        <Typography
          sx={{ fontWeight: '500', color: selectedCampaigns.length > 0 ? '#3F50F8' : '' }}
        >
          Campaign
        </Typography>
        {selectedCampaigns.length > 0 && (
          <Box
            sx={{
              backgroundColor: '#3F50F8',
              color: '#FFFFFF',
              fontSize: '12px',
              fontWeight: '600',
              padding: '2px 6px',
              borderRadius: '12px',
              display: 'inline-block'
            }}
          >
            {selectedCampaigns.length}
          </Box>
        )}
      </Button>

      {/* Campaign Menu */}
      <Menu
        anchorEl={campaignAnchorEl}
        open={Boolean(campaignAnchorEl)}
        onClose={handleCampaignClose}
        PaperProps={{
          sx: {
            width: '400px',
            borderRadius: '8px',
            padding: '16px',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
          }
        }}
      >
        <Box>
          <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
            <Typography variant='subtitle1' fontWeight='bold'>
              Campaign ({campaigns.length})
            </Typography>
            <Button
              onClick={handleClearAllCampaigns}
              sx={{
                textTransform: 'none',
                color: '#3F50F8',
                fontWeight: 'bold',
                fontSize: '14px'
              }}
            >
              Clear all
            </Button>
          </Box>
          <TextField
            placeholder='Search campaign...'
            fullWidth
            size='small'
            variant='outlined'
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{
              marginBottom: '16px',
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px'
              }
            }}
          />
          <List
            sx={{
              maxHeight: '200px',
              overflowY: 'auto',
              padding: 0
            }}
          >
            {filteredCampaigns.length > 0
              ? (
                  filteredCampaigns.map((campaign) => (
                    <ListItem
                      key={campaign._id}
                      sx={{
                        padding: '8px 0'
                      }}
                    >
                      <Checkbox
                        size='small'
                        icon={<OffCheckboxCustomIcon />}
                        checkedIcon={<OnCheckboxCustomIcon />}
                        checked={selectedCampaigns.includes(campaign._id)}
                        onChange={() => handleCampaignSelect(campaign._id)}
                        sx={{
                          color: '#3F50F8',
                          '&.Mui-checked': {
                            color: '#3F50F8'
                          }
                        }}
                      />
                      <ListItemText primary={campaign.name} />
                    </ListItem>
                  ))
                )
              : (
                <ListItem>
                  <ListItemText primary='No campaigns found' />
                </ListItem>
                )}
          </List>
          <Button
            variant='contained'
            fullWidth
            onClick={handleApply}
            sx={{
              marginTop: '16px',
              textTransform: 'none',
              backgroundColor: '#3F50F8',
              color: '#FFFFFF',
              fontWeight: '600',
              padding: '10px 0',
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: '#3F50F8'
              }
            }}
          >
            Apply
          </Button>
        </Box>
      </Menu>
    </Box>
  )
}

export default CampaignDropdown
