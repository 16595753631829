import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  TextField,
  Button,
  Typography,
  Box,
  IconButton,
  Grid,
  Divider,
  useTheme
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
const FeedbackPopup = ({ isOpen, onClose }) => {
  const [open, setOpen] = useState(true) // Opens the dialog by default
  const [selectedReasons, setSelectedReasons] = useState([])
  const [rating, setRating] = useState('')
  const theme = useTheme()
  const [otherReason, setOtherReason] = useState('')

  const handleCheckboxChange = (event) => {
    const value = event.target.value
    setSelectedReasons((prev) =>
      prev.includes(value) ? prev.filter((reason) => reason !== value) : [...prev, value]
    )
  }

  const handleRatingChange = (event) => {
    setRating(event.target.value)
    if (event.target.value < 2 || event.target.value > 9) {
      setOtherReason('')
    }
  }

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth='md' fullWidth>
      <Box sx={{ paddingX: 6 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, ml: 2 }}>
          <Button
            sx={{
              border: '1px solid #ccc',
              borderRadius: '10%',
              padding: '4px',
              backgroundColor: 'white',
              '&:hover': {
                backgroundColor: '#f0f0f0'
              },
              px: 4,
              color: '#101010'
            }}
          >
            {' '}
            Back
          </Button>
          <IconButton
            onClick={onClose}
            sx={{
              border: '1px solid #ccc', // Adds a border around the IconButton
              borderRadius: '20%', // Makes the border circular
              padding: '4px', // Adjusts the spacing inside the button
              backgroundColor: 'white', // Optional: Background for contrast
              '&:hover': {
                backgroundColor: '#f0f0f0' // Hover effect
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogTitle>
          <Typography style={{ fontWeight: 700, fontSize: '14px', marginTop: '20px' }}>
            Please share your reason for leaving <span style={{ color: '#8181B0' }}>Required</span>
          </Typography>
        </DialogTitle>
        <DialogContent style={{}}>
          <Grid container spacing={0}>
            {[
              'Product has bugs/issues',
              "I couldn't test the platform",
              'Missing features or integrations',
              'Creatives need to be improved',
              'Too complicated or hard to use',
              'Customer service was unsatisfactory',
              'Too expensive',
              'Other'
            ].map((reason, index) => (
              <Grid item xs={12} sm={6} key={index} sx={{ paddingTop: '2px' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={reason}
                      onChange={handleCheckboxChange}
                      sx={{
                        '& .MuiSvgIcon-root': {
                          borderRadius: '10px',
                          border: '#D0D5DD'
                        }
                      }}
                    />
                  }
                  label={reason}
                />
              </Grid>
            ))}
            {selectedReasons.includes('Other') && (
              <Grid item xs={12}>
                <TextField
                  multiline
                  rows={3}
                  placeholder='Please specify...'
                  variant='outlined'
                  fullWidth
                  margin='normal'
                  value={otherReason}
                  sx={{ border: '#DCDEE0' }}
                  onChange={(e) => setOtherReason(e.target.value)}
                />
              </Grid>
            )}
          </Grid>

          <Typography variant='h6' style={{ marginTop: '16px', fontWeight: 'bold' }}>
            How likely are you to return? <span style={{ color: '#8181B0' }}> Required </span>
          </Typography>
          <div style={{ marginTop: '20px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={{ fontWeight: 500, fontSize: '14px' }}>No chance</Typography>
              <Typography sx={{ fontWeight: 500, fontSize: '14px' }}>Absolutely will!</Typography>
            </Box>
            <RadioGroup
              row
              value={rating}
              onChange={handleRatingChange}
              style={{ justifyContent: 'space-between', padding: '8px 0' }}
            >
              {Array.from({ length: 10 }, (_, i) => (
                <Box
                  key={i + 1}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',

                    textAlign: 'center'
                  }}
                >
                  {/* {i + 1 === 1 && <Typography sx={{ mt: 0 }}>No chance</Typography>}

                  {i + 1 === 10 && <Typography sx={{ mb: 1 }}>Absolutely will!</Typography>} */}

                  {/* Radio button and number */}
                  <FormControlLabel
                    value={String(i + 1)}
                    control={<Radio />}
                    label={i + 1}
                    labelPlacement='top'
                  />
                </Box>
              ))}
            </RadioGroup>

            {/* Conditionally display TextField for numbers 2 to 9 */}
            {rating >= 1 && rating <= 10 && (
              <>
                <Typography variant='h6' style={{ marginTop: '16px', fontWeight: 'bold' }}>
                  What can we do better? <span style={{ color: '#8181B0' }}> Required </span>
                </Typography>
                <TextField
                  multiline
                  rows={3}
                  placeholder='Please specify...'
                  variant='outlined'
                  fullWidth
                  margin='normal'
                  value={otherReason}
                  sx={{ border: '#DCDEE0', mb: 3 }}
                  onChange={(e) => setOtherReason(e.target.value)}
                />

                <Divider
                  sx={{
                    my: 2,
                    // marginTop: "50px",
                    borderColor: '#e0e0e0',
                    borderWidth: '1px',
                    width: '100%',
                    margin: '0 auto',
                    position: 'relative',
                    '::before, ::after': {
                      borderColor: '#e0e0e0'
                    },
                    color: '#555',
                    fontWeight: 500,
                    fontSize: '13px'
                  }}
                />
                <Typography
                  sx={{
                    fontSize: '13px',
                    fontWeight: 500,
                    color: '#818180',
                    mt: 3,
                    textAlign: 'center'
                  }}
                >
                  Your feedback is crucial to improving your experience.
                </Typography>
                <Typography
                  sx={{
                    fontSize: '22px',
                    fontWeight: 700,
                    color: '#101010',
                    mt: 1,
                    textAlign: 'center'
                  }}
                >
                  Thanks! Our team will review your feedback.
                </Typography>
                <Box
                  sx={{
                    textAlign: 'center',
                    // mt: 3,
                    // borderTop: "1px solid #e0e0e0",
                    pt: 2
                  }}
                >
                  <Button
                    // onClick={handleOpenFeedbackPopup}
                    variant='text'
                    sx={{
                      textTransform: 'none',
                      color: '#101010',
                      border: '1px solid #E4E4E5',
                      borderRadius: '12px',
                      px: 14,
                      py: '10px',
                      ':hover': {
                        backgroundColor: '#f9f9f9', // Optional: Light hover background
                        borderColor: '#0070f3' // Optional: Change border color on hover
                      },
                      fontWeight: 600,
                      fontSize: '14px',
                      mb: 2
                    }}
                  >
                    I’d like to request a call to discuss this further
                  </Button>
                </Box>
                <Box
                  sx={{
                    textAlign: 'center'
                  }}
                >
                  <Button
                    // onClick={handleOpenFeedbackPopup}
                    variant='contaiend'
                    sx={{
                      textTransform: "none",
                      color: theme.palette.primary.contrastText,
                      fontWeight: "bold",
                      border: "1px solid #E4E4E5",
                      borderRadius: "12px",
                      px: 22.5,
                      py: '13px',
                      backgroundColor: '#3F4FF8',
                      ':hover': {
                        backgroundColor: '#3F4FF8', // Optional: Light hover background
                        borderColor: '#0070f3' // Optional: Change border color on hover
                      },
                      fontWeight: 600,
                      fontSize: '14px',
                      mb: 1
                    }}
                  >
                    Submit survey and close
                  </Button>
                </Box>
              </>
            )}
          </div>
        </DialogContent>
      </Box>
    </Dialog>
  )
}

export default FeedbackPopup
