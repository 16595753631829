import { Dialog, DialogTitle, IconButton, Typography, Box, DialogContent, alpha, Alert } from '@mui/material'
import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import WarningIcon from '@mui/icons-material/Warning'
import HelpIcon from '@mui/icons-material/Help'
import CloseIcon from '@mui/icons-material/Close'
import { capitalizeFirstLetter } from 'src/utils/helperFunctions'

const SingleEmailResultDialog = ({ open, setOpenSingleEmailResultModal, singleEmailResponse }) => {
  // Status icon and color mapping
  const getStatusInfo = (status) => {
    switch (status) {
      case 'deliverable':
        return {
          icon: <CheckCircleIcon fontSize='large' sx={{ color: '#48BF84' }} />,
          color: '#48BF84',
          bgColor: alpha('#48BF84', 0.1),
          text: 'Email is valid and deliverable.',
          severity: 'success',
          severityMessage: 'This email address is valid and active. It\'s safe to send emails to this recipient.'
        }
      case 'undeliverable':
        return {
          icon: <ErrorIcon fontSize='large' sx={{ color: '#F04438' }} />,
          color: '#F04438',
          bgColor: alpha('#F04438', 0.1),
          text: 'Email cannot be delivered.',
          severity: 'error',
          severityMessage: 'This email address is invalid or no longer exists. Messages sent will not be delivered.'
        }
      case 'risky':
        return {
          icon: <WarningIcon fontSize='large' sx={{ color: '#F8810D' }} />,
          color: '#F8810D',
          bgColor: alpha('#F8810D', 0.1),
          text: 'Email delivery may be unreliable.',
          severity: 'warning',
          severityMessage: 'This email address has been flagged as potentially risky. Please verify the recipient\'s address.'
        }
      case 'unknown':
        return {
          icon: <HelpIcon fontSize='large' sx={{ color: '#667285' }} />,
          color: '#667285',
          bgColor: alpha('#667285', 0.1),
          text: 'Email status could not be determined.',
          severity: 'info',
          severityMessage: 'Unable to verify this email address. Proceed with caution.'
        }
      default:
        return {
          icon: <HelpIcon fontSize='large' />,
          color: 'text.secondary',
          text: 'Status not available.'
        }
    }
  }
  const status = singleEmailResponse[0].status
  const statusInfo = getStatusInfo(status)
  const handleClose = () => setOpenSingleEmailResultModal(false)

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth='xs'>
      <DialogTitle>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='h6'>Email Validation Result</Typography>
          <IconButton aria-label='close' onClick={handleClose} size='small'>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          textAlign='center'
        >
          <Box sx={{ bgcolor: `${statusInfo.bgColor}`, p: 2, borderRadius: '50%', mb: 2 }}>
            {statusInfo.icon}
          </Box>

          <Typography variant='h6' sx={{ color: `${statusInfo.color}` }}>
            {capitalizeFirstLetter(status)}
          </Typography>

          <Typography variant='body1' color='text.secondary' sx={{ fontSize: '14px', fontWeight: 600 }}>
            {statusInfo.text}
          </Typography>
          <Alert
            severity={statusInfo.severity}
            sx={{
              mt: 2,
              borderRadius: 2,
              fontSize: '13px',
              fontWeight: 500,
              bgcolor: `${statusInfo.bgColor}`,
              '& .MuiAlert-icon': {
                fontSize: '24px'
              }
            }}
          >
            {statusInfo.severityMessage}
          </Alert>

          {singleEmailResponse[0].email && (
            <Typography variant='body2' sx={{ fontSize: '13px', fontWeight: 500, mt: 2 }}>
              Email: {singleEmailResponse[0].email}
            </Typography>
          )}
        </Box>
      </DialogContent>

    </Dialog>
  )
}

export default SingleEmailResultDialog
