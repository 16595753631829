import useUserVerification from 'src/hooks/useUserVerification'

export const useErrorMessage = () => {
  const { isWhiteLabelUser } = useUserVerification()
  return isWhiteLabelUser
    ? 'Email Accounts Limit has been reached'
    : 'You have reached the accounts limit for your current plan. Please upgrade to continue.'
}

// const HubSpotLink =
