import React from 'react'
import { Typography, Box } from '@mui/material'

const HighlightedText = ({
  label,
  value,
  sx = {},
  labelSx = {},
  valueSx = {},
  children,
  ...props
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '12px',
        ...sx // Allow external overrides for the container
      }}
    >
      {label && (
        <Typography
          variant='body2'
          sx={{
            marginBottom: '5px',
            ...labelSx // Allow external label overrides
          }}
        >
          <strong>{label}</strong>
        </Typography>
      )}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        {value && (
          <Typography
            component='span'
            sx={{
              display: 'inline-block',
              backgroundColor: '#E7F0FF',
              color: '#3F50F8',
              borderRadius: '4px',
              padding: '2px 8px',
              maxWidth: 'fit-content',
              whiteSpace: 'nowrap',
              ...valueSx // Allow external value style overrides
            }}
          >
            {value}
          </Typography>
        )}
        {children}
      </Box>
    </Box>
  )
}

export default HighlightedText
