import React from 'react'
import { Button, Tooltip } from '@mui/material'

export const ActionButton = ({ type, value, onClick, icon, label }) => (
  <Tooltip title={value || `No ${type} provided`}>
    <Button
      startIcon={icon}
      variant='outlined'
      onClick={onClick}
      sx={{
        mr: 1,
        textTransform: 'none',
        border: '1px solid #D1D5DB',
        borderRadius: '8px',
        color: value ? '#6B7280' : '#D1D5DB',
        fontWeight: '500',
        padding: '8px 12px',
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: value ? '#F9FAFB' : 'white'
        },
        pointerEvents: value ? 'auto' : 'none'
      }}
    >
      {label}
    </Button>
  </Tooltip>
)
