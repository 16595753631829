import { jsx } from 'slate-hyperscript'
import escapeHtml from 'escape-html'
import { Text } from 'slate'

export const deserialize = (el, markAttributes = {}) => {
  if (el.nodeType === window.Node.TEXT_NODE) {
    return jsx('text', markAttributes, el.textContent)
  } else if (el.nodeType !== window.Node.ELEMENT_NODE) {
    return null
  }

  const nodeAttributes = { ...markAttributes }

  // define attributes for text nodes
  switch (el.nodeName) {
    case 'STRONG':
      nodeAttributes.bold = true
  }

  const children = Array.from(el.childNodes)
    .map((node) => deserialize(node, nodeAttributes))
    .flat()

  if (children.length === 0) {
    children.push(jsx('text', nodeAttributes, ''))
  }

  switch (el.nodeName) {
    case 'BODY':
      return jsx('fragment', {}, children)
    case 'BR':
      return '\n'
    case 'BLOCKQUOTE':
      return jsx('element', { type: 'quote' }, children)
    case 'P':
      return jsx('element', { type: 'paragraph' }, children)
    case 'A':
      return jsx('element', { type: 'link', url: el.getAttribute('href') }, children)
    default:
      return children
  }
}

export const serialize = (node) => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text)

    if (node.bold) {
      string = `<strong>${string}</strong>`
    }
    if (node.code) {
      string = `<code>${string}</code>`
    }
    if (node.italic) {
      string = `<em>${string}</em>`
    }
    if (node.underline) {
      string = `<u>${string}</u>`
    }
    if (node.strikethrough) {
      string = `<s>${string}</s>`
    }
    if (node.superscript) {
      string = `<sup>${string}</sup>`
    }
    if (node.subscript) {
      string = `<sub>${string}</sub>`
    }
    if (node.heading) {
      string = `<h1>${string}</h1>`
    }
    if (node.textcolor) {
      string = `<span style="color:rgb(218, 43, 43)">${string}</span>`
    }
    if (node.highlight) {
      string = `<span style="background-color:rgb(146, 255, 182)">${string}</span>`
    }

    return string
  }

  // console.log(node);

  const children = node.children.map((n) => serialize(n)).join('')

  switch (node.type) {
    case 'block-quote':
      return `<blockquote>${children}</blockquote>`
    case 'paragraph':
      return `<p>${children}</p>`
    case 'link':
      return `<a href="${escapeHtml(node.url)}">${children}</a>`
    case 'bulleted-list':
      return `<ul>${children}</ul>`
    case 'numbered-list':
      return `<ol>${children}</ol>`
    case 'list-item':
      return `<li>${children}</li>`
    case 'size-10':
      return `<h5>${children}</h5>`
    case 'size-12':
      return `<h4>${children}</h4>`
    case 'size-14':
      return `<h3>${children}</h3>`
    case 'size-16':
      return `<h2>${children}</h2>`
    default:
      return `<p>${children}</p>`
  }
}
