import React from "react";
import { Box } from "@mui/material";
import {
  Email,
  Today,
  Done,
  Close,
  Circle,
  Home,
  Cached,
  Favorite,
  EmojiEvents,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";

export const renderTitleWithIcon = (label) => {
  switch (label.name) {
    case "Lead":
      return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Email sx={{ fontSize: 20, color: "#ffcf3c", mr: 1 }} />
          <span>{label.name}</span>
        </Box>
      );
    case "Interested":
      return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Favorite sx={{ fontSize: 20, color: "#689f38", mr: 1 }} />
          <span>{label.name}</span>
        </Box>
      );
    case "Not interested":
      return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Close sx={{ fontSize: 20, color: "#ee3131", mr: 1 }} />
          <span>{label.name}</span>
        </Box>
      );
    case "Meeting booked":
      return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Today sx={{ fontSize: 20, color: "#97c7f3", mr: 1 }} />
          <span>{label.name}</span>
        </Box>
      );
    case "Meeting completed":
      return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Done sx={{ fontSize: 20, color: "#689f38", mr: 1 }} />
          <span>{label.name}</span>
        </Box>
      );
    case "Out of office":
      return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Home sx={{ fontSize: 20, color: "#852f02", mr: 1 }} />
          <span>{label.name}</span>
        </Box>
      );
    case "Wrong person":
      return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Circle sx={{ fontSize: 20, color: "#272727", mr: 1 }} />
          <span>{label.name}</span>
        </Box>
      );
    case "Won":
      return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <EmojiEvents sx={{ fontSize: 20, color: "#c98d09", mr: 1, marginTop: "5px" }} />
          <span>{label.name}</span>
        </Box>
      );
    case "Lost":
      return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <RadioButtonUnchecked sx={{ fontSize: 20, color: "#ee3131", mr: 1 }} />
          <span>{label.name}</span>
        </Box>
      );
    case "Unsubscribed":
      return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <UnsubscribeIcon sx={{ fontSize: 20, color: "#ee3131", mr: 1 }} />
          <span>{label.name}</span>
        </Box>
      );
    default:
      return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Cached sx={{ fontSize: 20, color: "#ffcf3c", mr: 1 }} />
          <span>{label.name}</span>
        </Box>
      );
  }
};
