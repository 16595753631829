import React, { useState } from 'react'
import { Drawer } from '@mui/material'

import TabNavigation from './TabNavigation'
import { PersonTab } from './PersonTab'
import CompanyTab from './CompanyTab'
import ActivityTab from './ActivityTab'

const SlidingModal = ({ isOpen, onClose, user, workspaceId, labels, loggedInUser, onUpdateComplete, updateDealInPipelineData, selectedUserColumnId }) => {
  const [activeTab, setActiveTab] = useState('person')

  return (
    <Drawer
      anchor='right'
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '600px',
          height: '100%',
          marginTop: '40px',
          borderRadius: '16px',
          boxShadow: '-26px -22px 9px -12px #E1E3EC'
        }
      }}
      ModalProps={{
        keepMounted: true,
        BackdropProps: {
          sx: {
            background: 'none'
          }
        }
      }}
    >
      <TabNavigation activeTab={activeTab} onTabChange={setActiveTab} />

      {user && (
        <>
          {activeTab === 'person' && (
            <PersonTab user={user} labels={labels} onUpdateComplete={onUpdateComplete} selectedUserColumnId={selectedUserColumnId} updateDealInPipelineData={updateDealInPipelineData} />
          )}

          {activeTab === 'company' && <CompanyTab user={user} />}

          {activeTab === 'activity' && (
            <ActivityTab user={user} workspaceId={workspaceId} loggedInUser={loggedInUser} />
          )}
        </>
      )}
    </Drawer>
  )
}

export default SlidingModal
