import React, { useState, useEffect, useMemo } from 'react'
import { Link as RouterLink, matchPath, useLocation, useNavigate } from 'react-router-dom'
import {
  Box,
  Drawer,
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useTheme,
  alpha,
  Stack,
  Typography,
  useMediaQuery,
  IconButton,
  LinearProgress,
  linearProgressClasses,
  Collapse
} from '@mui/material'
import { items, OnboardingHub } from './config'
import { CloseOutlined, ExpandLess, ExpandMore } from '@mui/icons-material'
import { Logo } from 'src/components/logo'
import SideNavFooter from './side-nav-footer'
import { useDispatch, useSelector } from 'react-redux'
import { LogoSymbol } from 'src/components/logoSymbol'
import { useGetMeQuery } from 'src/services/user-service.js'
import { useAuth } from 'src/auth/auth'
import { setActualTotalCount, useGetCampaignEmailsMutation } from 'src/services/unibox-service'
import UpgradeToPremiumButton from './UpgradeToPremiumBtn'
import useUserVerification from 'src/hooks/useUserVerification'
import LockIcon from '@mui/icons-material/Lock'
import { styled } from '@mui/material/styles'
import { useGetCustomAttributesQuery } from 'src/services/intercom-service'
import WorkspaceSelector from 'src/components/WorkspaceSelector'
import { motion } from 'framer-motion'

const SIDE_NAV_WIDTH = 264
const scrollBarStyle = {
  // width
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px'
  },

  // Track
  '&::-webkit-scrollbar-track': {
    borderRadius: '60px'
  },

  // /* Handle */
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    borderRadius: '10px',
    border: '3px solid rgba(0, 0, 0, 0)',
    backgroundClip: 'padding-box'
  },

  // /* Handle on hover */
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.5)'
  }
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#FFFFFF4D',
    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.grey[800]
    })
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.contrastText,
    ...theme.applyStyles('dark', {
      backgroundColor: '#308fe8'
    })
  }
}))

const openedMixin = (theme) => ({
  width: SIDE_NAV_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(8)} + 1px)`
})

export const SideNav = ({ mobileMenuOpen, setMobileMenuOpen, navMenuOpen }) => {
  const location = useLocation()
  const theme = useTheme()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'))
  const actualTotalCount = useSelector((state) => state.inboxhub.actualTotalCount)
  const [workspaceId, setWorkspaceId] = useState()
  const [open, setOpen] = useState(false)
  const workspace = useSelector((state) => state.workspace)
  const [completionPercentage, setCompletionPercentage] = useState(80)
  const { isFreeUser, isWhiteLabelUser } = useUserVerification()
  const { data: user, isLoading, isError } = useGetMeQuery()
  const [getCampaignEmails] = useGetCampaignEmailsMutation()
  const dispatch = useDispatch()
  const { data } = useGetCustomAttributesQuery(undefined, {
    skip: isWhiteLabelUser
  })

  useEffect(() => {
    setWorkspaceId(workspace?._id)
  }, [workspace])
  const customAttributes = useMemo(() => {
    return isWhiteLabelUser ? {} : data?.IntercomCustomAttributes ?? {}
  }, [isWhiteLabelUser, JSON.stringify(data?.IntercomCustomAttributes)])

  useEffect(() => {
    const attributeKeys = [
      'email_account_linked',
      'contacts_added',
      'sequence_created',
      'sending_schedule_created',
      'campaign_reviewed',
      'campaign_launched',
      'team_members_invited'
    ]

    const counter = attributeKeys.reduce((acc, key) => {
      return acc + (customAttributes[key] === 'true' ? 1 : 0)
    }, 0)

    setCompletionPercentage(Math.floor((counter / attributeKeys.length) * 100))
  }, [customAttributes])

  const actualTotalCountValue = actualTotalCount
  const [totalCount, setTotalCount] = useState(0)
  const getUnreadCount = async () => {
    const docs = await getCampaignEmails({
      unread: true,
      workspaceId
    })
    setTotalCount(docs?.data?.totalEmailCount)
    dispatch(setActualTotalCount(docs?.data?.totalEmailCount))
  }

  useEffect(() => {
    if (workspaceId) {
      getUnreadCount()
    }
  }, [workspaceId])

  const actualTotalCountApi = totalCount
  const navigate = useNavigate()

  const userFirstLogin = useMemo(() => user?.firstLogin, [user?.firstLogin])
  const userEmailVerified = useMemo(() => user?.emailVerified, [user?.emailVerified])

  useEffect(() => {
    if (isLoading || isError) return
    const fetchData = async () => {
      if (user?.firstLogin === true && user.emailVerified === false) {
        navigate('/loginProtection')
      }
    }
    fetchData()
  }, [userFirstLogin, userEmailVerified, isLoading, isError])

  const { canEdit } = useAuth()
  const handleClick = (event, item) => {
    if (item.subItems) {
      event.preventDefault()
      setOpen(!open)
    } else {
      setMobileMenuOpen(false)
    }
  }

  const navItems = useMemo(() => {
    return items.filter((item) => {
      // Hide "Support Center" and "Integrations" for white-label users
      if (isWhiteLabelUser && (item.id === 'Support Center' || item.id === 'Integrations')) {
        return false
      }

      // Show "Partner/White Label" menu only if user is a partner
      if (!user.isPartner && item.id === 'Partner/White Label') {
        return false
      }

      return true // Keep the item
    })
  }, [items, isWhiteLabelUser, user.isPartner]) // Dependencies to recompute only when they change

  return (
    <Drawer
      open={isMobile ? mobileMenuOpen : navMenuOpen}
      onClose={() => setMobileMenuOpen(false)}
      variant={isMobile ? 'temporary' : 'permanent'}
      sx={{
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        width: SIDE_NAV_WIDTH,
        flexShrink: 0,
        // "& .MuiDrawer-paper": {
        //   boxSizing: "border-box",
        //   width: SIDE_NAV_WIDTH,
        //   borderWidth: 0,
        // },
        ...(navMenuOpen &&
          !isMobile && {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': openedMixin(theme)
        }),
        ...(!navMenuOpen &&
          !isMobile && {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme)
        })
      }}
      PaperProps={{
        sx: {
          backgroundColor: theme.palette.primary.main,
          // backgroundColor: "#2B38C6",
          display: 'flex',
          flexDirection: 'column',
          // height: `calc(100% - ${TOP_NAV_HEIGHT}px)`,
          height: '100%',
          // p: 1,

          // width: navMenuOpen ? SIDE_NAV_WIDTH : `calc(${theme.spacing(7)} + 1px)`,
          zIndex: (theme) => theme.zIndex.appBar - 100,

          // borderRight: `1px solid ${theme.palette.primary.main}`,
          border: 'none'
        }
      }}
    >
      {isMobile && (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton
            onClick={() => setMobileMenuOpen(false)}
            sx={{ color: theme.palette.primary.contrastText }}
          >
            <CloseOutlined />
          </IconButton>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          px: 1,
          py: { xs: 0, md: 3 },
          overflowY: { xs: 'auto', lg: 'hidden' },
          ':hover': {
            overflowY: 'auto'
          },
          overflowX: 'hidden',

          ...scrollBarStyle
        }}
      >
        <Stack spacing={4}>
          <Box
            component={RouterLink}
            to='/'
            sx={{
              display: 'flex',
              justifyContent: !theme.themeLogo ? 'center' : 'start',
              alignItems: !theme.themeLogo ? 'center' : 'normal',
              width: '150px',
              height: '30px',
              px: 1,
              textDecoration: 'none'
            }}
          >
            {navMenuOpen || isMobile
              ? (
                  !theme.themeLogo
                    ? (
                      <Logo
                        color={theme.palette.primary.contrastText}
                        logoTextColor={theme.palette.primary.contrastText}
                      />
                      )
                    : (
                      <>
                        <img src={theme.themeLogo} alt='company' width={100} height={50} />
                      </>
                      )
                )
              : !theme.themeLogo
                  ? (
                    <LogoSymbol color={theme.palette.primary.contrastText} />
                    )
                  : (
                    <Avatar alt='company' src={theme.themeLogo} className='!mx-0' />
                    )}
          </Box>
          <Stack spacing={1}>
            <Typography
              sx={{
                color: alpha(theme.palette.primary.contrastText, 0.5),
                fontWeight: '700',
                fontSize: '12px',
                px: 1
              }}
            >
              MENU
            </Typography>
            <List sx={{ width: '100%', m: 0, p: 0, px: navMenuOpen || isMobile ? 0 : '4px' }}>
              {navItems.map((item, i) => {
                const active = matchPath({ path: item.href, end: true }, location.pathname)
                if (item?.label === 'Lead Finder' && !canEdit) {
                  return null
                }
                return (
                  <React.Fragment key={i}>
                    <Tooltip
                      key={i}
                      title={!navMenuOpen && item.label}
                      placement='right-start'
                      arrow
                    >
                      <ListItem
                        id={item.id}
                        disablePadding
                        component={RouterLink}
                        key={i}
                        to={
                          !(
                            (user?.isPartner || user?.partnerAccountId) &&
                            item.href === '/settings/billing'
                          )
                            ? item.href
                            : '/settings/profile'
                        }
                        onClick={(e) => handleClick(e, item)}
                        sx={{
                          // flexDirection: "column",
                          // px: "16px",
                          ml: `${item.subItems && !navMenuOpen && '-8px'}`,
                          pb: { xs: '10px', md: '16px' },
                          zIndex: 999
                        }}
                      >
                        {' '}
                        <Tooltip title='' placement='right' arrow>
                          <>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                backgroundColor:
                                  active && alpha(theme.palette.background.paper, 0.2),
                                borderRadius: '10px',
                                px: 1,
                                minWidth: '40px',
                                width: '100%',
                                '&:hover': {
                                  backgroundColor:
                                    !active && alpha(theme.palette.background.paper, 0.05)
                                }
                              }}
                            >
                              {' '}
                              <ListItemIcon
                                sx={{
                                  minWidth: 'auto',
                                  color: theme.palette.primary.contrastText,
                                  py: '8px'
                                }}
                              >
                                {item.icon(theme.palette.primary.contrastText)}
                              </ListItemIcon>{' '}
                              <ListItemText
                                primary={item.label}
                                primaryTypographyProps={{
                                  variant: 'caption',
                                  sx: {
                                    color: theme.palette.primary.contrastText,
                                    fontSize: '14px',
                                    fontWeight: 700,
                                    lineHeight: '18px',
                                    letterSpacing: '0px',
                                    textAlign: 'left',
                                    ml: 1
                                  }
                                }}
                                sx={{ ml: 1, display: navMenuOpen || isMobile ? 'block' : 'none' }}
                              />
                              {(isMobile || navMenuOpen) &&
                                  isFreeUser &&
                                  item.id === 'Integrations' && (
                                    <LockIcon sx={{ color: '#fff', fontSize: '18px' }} />
                              )}
                              {item.label === 'InboxHub' &&
                                actualTotalCount !== 0 &&
                                actualTotalCountApi !== 0 &&
                                ((workspace?.analytics?.uiForUnibox && workspace?.enterprise) ||
                                  !workspace?.enterprise) && (
                                    <Box
                                      sx={{
                                        minWidth: '1.6em',
                                        height: '1.6em',
                                        borderRadius: '0.8em',
                                        border: '0.05em solid white',
                                        backgroundColor: theme.palette.primary.contrastText,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        fontSize: '0.8em',
                                        fontWeight: '700',
                                        color: theme.palette.primary.main,
                                        padding: '5px'
                                      }}
                                    >
                                      {actualTotalCountValue == null
                                        ? actualTotalCountApi
                                        : actualTotalCountValue}
                                    </Box>
                              )}
                              {item.subItems && (!navMenuOpen || navMenuOpen || isMobile) && (
                                <ListItemIcon
                                  sx={{
                                    minWidth: 'auto',
                                    color: theme.palette.primary.contrastText,
                                    py: '8px'
                                  }}
                                >
                                  {open
                                    ? (
                                      <ExpandLess
                                        sx={{ color: theme.palette.primary.contrastText }}
                                      />
                                      )
                                    : (
                                      <ExpandMore
                                        sx={{ color: theme.palette.primary.contrastText }}
                                      />
                                      )}
                                </ListItemIcon>
                              )}
                            </Box>
                          </>
                        </Tooltip>
                      </ListItem>
                    </Tooltip>
                    {item.subItems && (
                      <Collapse in={open} timeout='auto' unmountOnExit>
                        <List disablePadding>
                          {item.subItems.map((subItem) => (
                            <ListItem
                              id={subItem.id}
                              disablePadding
                              component={RouterLink}
                              key={subItem.href}
                              to={subItem.href}
                              onClick={(e) => handleClick(e, subItem)}
                              sx={{
                                px: `${navMenuOpen && '24px'}`,
                                pb: { xs: '10px', md: '16px' },
                                zIndex: 999
                              }}
                            >
                              <Tooltip
                                title={!navMenuOpen && subItem.label}
                                placement='right'
                                arrow
                              >
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    backgroundColor:
                                      active && alpha(theme.palette.background.paper, 0.2),
                                    borderRadius: '10px',
                                    px: 1,
                                    minWidth: '40px',
                                    width: '100%',
                                    '&:hover': {
                                      backgroundColor:
                                        (!active || !navMenuOpen) &&
                                        alpha(theme.palette.background.paper, 0.05)
                                    }
                                  }}
                                >
                                  <ListItemIcon
                                    sx={{
                                      minWidth: 'auto',
                                      color: theme.palette.primary.contrastText,
                                      py: '8px'
                                    }}
                                  >
                                    {subItem.icon(theme.palette.primary.contrastText)}
                                  </ListItemIcon>{' '}
                                  <ListItemText
                                    primary={subItem.label}
                                    primaryTypographyProps={{
                                      variant: 'caption',
                                      sx: {
                                        color: theme.palette.primary.contrastText,
                                        fontSize: '14px',
                                        fontWeight: 700,
                                        lineHeight: '18px',
                                        letterSpacing: '0px',
                                        textAlign: 'left',
                                        ml: 1

                                        // fontWeight: active && 600,
                                      }
                                    }}
                                    sx={{
                                      ml: 1,
                                      display: navMenuOpen || isMobile ? 'block' : 'none'
                                    }}
                                  />
                                </Box>
                              </Tooltip>
                            </ListItem>
                          ))}
                        </List>
                      </Collapse>
                    )}
                  </React.Fragment>
                )
              })}
            </List>
          </Stack>
        </Stack>

        <Stack sx={{ display: 'flex', gap: '20px' }}>
          {/* Upgrade to premium button  */}

          <Stack direction='column' spacing={1} sx={{ px: navMenuOpen || isMobile ? 1 : 0 }}>
            <Tooltip title='Onboarding Hub' placement='right' arrow>
              <Box
                component={RouterLink}
                to='/onboardinghub'
                sx={{ textDecoration: 'none', cursor: 'pointer' }}
              >
                <Box
                  component={motion.div}
                  sx={{
                    p: 1,
                    borderRadius: '10px',
                    minWidth: '40px',
                    width: '100%',
                    '&:hover': {
                      backgroundColor: alpha(theme.palette.background.paper, 0.1)
                    }
                  }}
                  animate={{
                    boxShadow: [
                      '0px 0px 10px rgba(89, 222, 255, 0.72)', // Small shadow
                      '0px 0px 30px rgba(0, 153, 255, 0.8)', // Bigger shadow
                      '0px 0px 60px rgba(33, 150, 243, 0.6)', // Bigger shadow
                      '0px 0px 30px rgba(0, 153, 255, 0.8)', // Bigger shadow
                      '0px 0px 10px rgba(89, 222, 255, 0.72)' // Back to small shadow
                    ]
                  }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    ease: 'easeInOut'
                  }}
                >
                  <Stack
                    direction='row'
                    spacing={1}
                    sx={{
                      display: 'flex',
                      justifyContent: navMenuOpen || isMobile ? 'left' : 'center'
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: '0',
                        color: theme.palette.primary.contrastText,
                        py: '0px'
                      }}
                    >
                      {OnboardingHub.icon(true, theme.palette.primary.contrastText, 24)}
                    </ListItemIcon>
                    <Typography
                      sx={{
                        color: alpha(theme.palette.primary.contrastText, 1),
                        fontWeight: '700',
                        fontSize: '16px',
                        display: navMenuOpen || isMobile ? 'block' : 'none'
                      }}
                    >
                      {OnboardingHub.label}
                    </Typography>
                  </Stack>
                  <Typography
                    sx={{
                      color: theme.palette.primary.contrastText,
                      fontWeight: '300',
                      fontSize: '14px',
                      marginY: '8px',
                      display: navMenuOpen || isMobile ? 'block' : 'none'
                    }}
                  >
                    {completionPercentage}% complete
                  </Typography>

                  <BorderLinearProgress
                    variant='determinate'
                    value={completionPercentage}
                    sx={{ display: navMenuOpen || isMobile ? 'block' : 'none' }}
                  />
                </Box>
              </Box>
            </Tooltip>
            {isFreeUser && (
              <Stack
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <UpgradeToPremiumButton navMenuOpen={navMenuOpen} isMobile={isMobile} />
              </Stack>
            )}
          </Stack>

          <Stack spacing={2} sx={{ px: navMenuOpen || isMobile ? 1 : 0 }}>
            <Typography
              sx={{
                color: alpha(theme.palette.primary.contrastText, 0.5),
                fontWeight: '700',
                fontSize: '12px'
              }}
            >
              PROFILE
            </Typography>
            <WorkspaceSelector
              isFreeUser={isFreeUser}
              navMenuOpen={navMenuOpen}
              isMobile={isMobile}
            />
            <SideNavFooter navMenuOpen={navMenuOpen} isMobile={isMobile} />
          </Stack>
        </Stack>
      </Box>
    </Drawer>
  )
}
