import { Clear } from '@mui/icons-material'
import { IconButton, InputAdornment, TextField, useTheme } from '@mui/material'
import React, { useContext } from 'react'
import { SBSearch } from 'src/assets/sidebar/SBSearch'
import { EmailListingContext, InboxHubContext } from 'src/pages/InboxHub'

const EmailSearchBox = () => {
  const theme = useTheme()
  const { markedEmailIds, setmarkedEmailIds, isMobile } = useContext(InboxHubContext)
  const { searchEmailValue, setSearchEmailValue } = useContext(EmailListingContext)
  const handleSearchChange = (e) => {
    if (markedEmailIds.length) {
      setmarkedEmailIds([])
    }
    setSearchEmailValue(e.target.value)
  }
  return (
    <TextField
      placeholder='Search by email'
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <IconButton sx={{ width: 32, height: 32 }}>
              {searchEmailValue
                ? (
                  <Clear onClick={() => setSearchEmailValue('')} />
                  )
                : (
                  <SBSearch color='rgba(40, 40, 123, 0.5)' />
                  )}
            </IconButton>
          </InputAdornment>
        )
      }}
      variant='outlined'
      sx={{
        width: { xs: '100%', md: '100%' },
        height: isMobile ? 'fit-content' : '6%',
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        backgroundColor: theme.palette.primary.contrastText,
        // padding: "8px 10px",
        '& div': { pl: 0.3 },

        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            border: 'none' // Removes the border
          },
          '&:hover fieldset': {
            border: 'none' // Removes the border on hover
          },
          '&.Mui-focused fieldset': {
            border: 'none' // Removes the border when focused
          }
        }
      }}
      value={searchEmailValue}
      onChange={handleSearchChange}
    />
  )
}

export default EmailSearchBox
