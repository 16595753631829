import React from 'react'
import { Box, Typography, Avatar } from '@mui/material'
import VerifiedBadgeIcon from 'src/assets/dealPipeline/verified-badge.svg'

import SvgRenderer from '../SvgRenderer'

const CompanyHeader = ({ name, title, email, logo, isVerified }) => (
  <Box display='flex' alignItems='center' mb={2}>
    <Avatar
      src={logo || 'https://cdn-icons-png.flaticon.com/512/1465/1465438.png'}
      alt='User'
      sx={{ width: 50, height: 50, mr: 2 }}
    />
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2
        }}
      >
        <Typography variant='h6' sx={{ fontWeight: '700' }}>
          {name}
        </Typography>
        {isVerified && <SvgRenderer src={VerifiedBadgeIcon} width='16' height='16' alt='icon' />}
      </Box>
      <Typography variant='body2' color='text.secondary' display='flex' alignItems='center'>
        {email}
      </Typography>
      <Typography
        variant='body2'
        color='text.secondary'
        display='flex'
        alignItems='center'
        sx={{
          color: '#8181B0',
          fontWeight: '500'
        }}
      >
        {title}
      </Typography>
    </Box>
  </Box>
)

export default CompanyHeader
