import React from 'react'
import { Box } from '@mui/material'
import CampaignDropdown from './CampaignDropdown'
import MailboxDropdown from './MailboxDropdown'
import DateDropdown from './DateDropdown'
import TypeDropdown from './TypeDropdown'

const FilterDropdowns = ({ campaigns, onFilter, mailboxes }) => {
  return (
    <Box display='flex' gap={2} mb={3}>
      {/* Campaign Dropdown */}
      <CampaignDropdown campaigns={campaigns} onFilter={onFilter} />

      {/* Mailbox Dropdown */}
      {mailboxes && mailboxes.length > 0 && (
        <MailboxDropdown onFilter={onFilter} mailboxes={mailboxes} />
      )}

      {/* Date Dropdown */}
      <DateDropdown onFilter={onFilter} />

      {/* Type Dropdown */}
      <TypeDropdown onFilter={onFilter} />
    </Box>
  )
}

export default FilterDropdowns
