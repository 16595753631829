import React, { useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  Box,
  Typography,
  IconButton,
  Chip,
  Grid,
  alpha
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import WarningIcon from '@mui/icons-material/Warning'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import CancelIcon from '@mui/icons-material/Cancel'
import { capitalizeFirstLetter, removeUnderscoreAndCapitalize } from 'src/utils/helperFunctions'

// Row component for expandable details
const Row = ({ email }) => {
  const [open, setOpen] = useState(false)

  const getStatusInfo = (status) => {
    switch (status.toLowerCase()) {
      case 'deliverable':
        return { color: '#48BF84', icon: <CheckCircleIcon color='success' sx={{ fontSize: 16 }} /> }
      case 'undeliverable':
        return { color: '#F04438', icon: <CancelIcon color='error' sx={{ fontSize: 16 }} /> }
      case 'risky':
        return { color: '#F8810D', icon: <WarningIcon color='warning' sx={{ fontSize: 16 }} /> }
      case 'unknown':
        return { color: '#667285', icon: <HelpOutlineIcon color='disabled' sx={{ fontSize: 16 }} /> }
      default:
        return { color: 'default', icon: null }
    }
  }
  const statusInfo = getStatusInfo(email.status)
  const statusColor = statusInfo.color
  const statusBackgroundColor = alpha(statusColor, 0.1)
  const statusIcon = statusInfo.icon

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell width={30}>
          {statusIcon}
        </TableCell>
        <TableCell component='th' scope='row'>
          {email.email}
        </TableCell>
        <TableCell>
          <Typography sx={{ color: statusColor, fontSize: '14px', fontWeight: 600 }}>{capitalizeFirstLetter(email.status)}</Typography>
        </TableCell>
        <TableCell>
          <Chip
            label={removeUnderscoreAndCapitalize(email.reason)}
            size='small'
            sx={{
              color: statusColor,
              bgcolor: statusBackgroundColor,
              fontWeight: 600,
              fontSize: '12px',
              borderRadius: '43px',
              border: 'none'
            }}
          />
        </TableCell>
        <TableCell>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>

      {/* Emails details drawer  */}
      <TableRow sx={{ bgcolor: '#F9F9FA' }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6} sx={{ bgcolor: '#F9F9FA' }}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 2 }}>
              <Grid container spacing={2}>

                <Grid item xs={12} md={3}>
                  <Box sx={{
                    bgcolor: '#fff',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 2,
                    boxShadow: '0px 12px 15px 0px #4B71970D',
                    borderRadius: '12px',
                    height: '100%'
                  }}
                  >
                    <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>Domain</Typography>
                    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                      <Typography color='text.secondary' sx={{ fontSize: '13px', fontWeight: 500 }}>Name</Typography>
                      <Typography sx={{ fontSize: '13px', fontWeight: 500 }}>{email.domain?.name}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                      <Typography color='text.secondary' sx={{ fontSize: '13px', fontWeight: 500 }}>Accepted all</Typography>
                      <Typography sx={{ fontSize: '13px', fontWeight: 500 }}>{email.domain?.acceptAll}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                      <Typography color='text.secondary' sx={{ fontSize: '13px', fontWeight: 500 }}>Disposable</Typography>
                      <Typography sx={{ fontSize: '13px', fontWeight: 500 }}>{email.domain?.disposable}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                      <Typography color='text.secondary' sx={{ fontSize: '13px', fontWeight: 500 }}>Free</Typography>
                      <Typography sx={{ fontSize: '13px', fontWeight: 500 }}>{email.domain?.free}</Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} md={3}>
                  <Box sx={{
                    bgcolor: '#fff',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 2,
                    boxShadow: '0px 12px 15px 0px #4B71970D',
                    borderRadius: '12px',
                    height: '100%'
                  }}
                  >
                    <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>Account</Typography>
                    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                      <Typography color='text.secondary' sx={{ fontSize: '13px', fontWeight: 500 }}>Role</Typography>
                      <Typography sx={{ fontSize: '13px', fontWeight: 500 }}>{email.account?.role}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                      <Typography color='text.secondary' sx={{ fontSize: '13px', fontWeight: 500 }}>Disabled</Typography>
                      <Typography sx={{ fontSize: '13px', fontWeight: 500 }}>{email.account?.disabled}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                      <Typography color='text.secondary' sx={{ fontSize: '13px', fontWeight: 500 }}>Full mailbox</Typography>
                      <Typography sx={{ fontSize: '13px', fontWeight: 500 }}>{email.account?.fullMailbox}</Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} md={3}>
                  <Box sx={{
                    bgcolor: '#fff',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 2,
                    boxShadow: '0px 12px 15px 0px #4B71970D',
                    borderRadius: '12px',
                    height: '100%'
                  }}
                  >
                    <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>Provider</Typography>
                    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                      <Typography color='text.secondary' sx={{ fontSize: '13px', fontWeight: 500 }}>Domain</Typography>
                      <Typography sx={{ fontSize: '13px', fontWeight: 500 }}>{email?.domain?.name}</Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} md={3}>
                  <Box sx={{
                    bgcolor: '#fff',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 2,
                    boxShadow: '0px 12px 15px 0px #4B71970D',
                    borderRadius: '12px',
                    height: '100%'
                  }}
                  >
                    <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>Score</Typography>
                    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                      <Typography color='text.secondary' sx={{ fontSize: '13px', fontWeight: 500 }}>Score</Typography>
                      <Typography sx={{ fontSize: '13px', fontWeight: 500 }}>{email.score}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                      <Typography color='text.secondary' sx={{ fontSize: '13px', fontWeight: 500 }}>Toxicity</Typography>
                      <Typography sx={{ fontSize: '13px', fontWeight: 500 }}>{email.toxic}</Typography>
                    </Box>
                  </Box>
                </Grid>

              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

const EmailTable = ({ emails }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer component={Paper}>
        <Table aria-label='email verification results'>
          <TableHead color='yellow'>
            <TableRow>
              <TableCell width={30} />
              <TableCell>Email</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Reason</TableCell>
              <TableCell width={70}>Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {emails.map((email) => (
              <Row key={email?.email} email={email} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default EmailTable
