import { Button, Grid } from '@mui/material'
import React, { useContext } from 'react'
import { InboxHubContext } from 'src/pages/InboxHub'

const MainFilters = () => {
  const { selectedMainFilter, setSelectedMainFilter, totalInbox, totalUnread, totalSent } =
    useContext(InboxHubContext)
  const handleTabChange = (newTab) => {
    if (selectedMainFilter !== newTab) {
      setSelectedMainFilter(newTab)
    }
  }
  return (
    <Grid
      container
      sx={{
        backgroundColor: '#E1E3EC',
        width: '100%',
        height: '100%',
        borderRadius: '8px',
        p: 0.4,
        border: '1px solid #F2F4F7',
        flexShrink: 20
      }}
    >
      <FilterButton
        text={`Inbox ${totalInbox ? `(${totalInbox})` : ''}`}
        activeTab={selectedMainFilter}
        handleTabChange={handleTabChange}
        tab='inbox'
      />
      <FilterButton
        text={`Unread ${totalUnread ? `(${totalUnread})` : ''}`}
        activeTab={selectedMainFilter}
        handleTabChange={handleTabChange}
        tab='unread'
      />
      <FilterButton
        text={`Sent ${totalSent ? `(${totalSent})` : ''}`}
        activeTab={selectedMainFilter}
        handleTabChange={handleTabChange}
        tab='sent'
      />
    </Grid>
  )
}

export default MainFilters

const FilterButton = ({ text, tab, handleTabChange, activeTab }) => {
  return (
    <Button
      fullWidth
      sx={{
        backgroundColor: activeTab === tab ? 'white' : 'transparent',
        color: activeTab === tab ? '#000000' : '#8181B0',
        '&:hover': {
          backgroundColor: activeTab === tab ? 'white' : 'transparent'
        },
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '20px',
        letterSpacing: '0em',
        boxShadow: activeTab === tab && '0px 1px 2px 0px #1018280F',
        borderRadius: '5px',
        py: 1,
        flex: 1 // Ensure the buttons stretch evenly across the container
      }}
      onClick={() => handleTabChange(tab)}
    >
      {text}
    </Button>
  )
}
