import React, { useEffect, useState } from 'react'
import { Box, CircularProgress } from '@mui/material'
import ActivityEmailsGroup from './ActivityEmailsGroup'
import PersonHeader from './PersonHeader'
import FilterDropdowns from './FilterDropdowns'
import { useGetUserCampaignsMutation } from 'src/services/leads-service'
import UpgradeAccessModal from './UpgradeAccessModal'
import usePlan from 'src/hooks/usePlan'

const ActivityTab = ({ user, workspaceId, loggedInUser }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [campaigns, setCampaigns] = useState([])
  const [mailboxes, setMailboxes] = useState([])
  const [activityData, setActivityData] = useState({})
  const [selectedFilter, setSelectedFilter] = useState({
    campaignId: [],
    selectedTypes: [],
    selectedDates: { fromDate: null, toDate: null },
    selectedMailbox: []
  })
  const additionalData = user?.additionalData?.additionalData || {}
  const { email = '', photo_url: photoUrl = '', email_status: emailStatus = '' } = additionalData

  const [getUserCampaigns] = useGetUserCampaignsMutation()
  const { planDetails } = usePlan()

  useEffect(() => {
    const fetchUserCampaigns = async () => {
      setIsLoading(true)
      const {
        data: { campaigns, mailboxes }
      } = await getUserCampaigns({ email, workspaceId, loggedInUser })
      setCampaigns(campaigns)
      setMailboxes(mailboxes)
      setIsLoading(false)
    }

    fetchUserCampaigns()
  }, [email])

  useEffect(() => {
    const filteredCampaigns = campaigns.filter((campaign) =>
      selectedFilter.campaignId.length ? selectedFilter.campaignId.includes(campaign._id) : true
    )

    const newActivityData = filteredCampaigns.reduce((acc, campaign) => {
      campaign.emails
        .filter((email) => {
          // Filter by types
          const matchesType = selectedFilter.selectedTypes.length
            ? selectedFilter.selectedTypes.includes(getActivityType(email))
            : true

          // Filter by dates
          const emailDate = new Date(email.createdAt)
          const matchesDate =
            (!selectedFilter.selectedDates.fromDate ||
              emailDate >= new Date(selectedFilter.selectedDates.fromDate)) &&
            (!selectedFilter.selectedDates.toDate ||
              emailDate <= new Date(selectedFilter.selectedDates.toDate))

          // Filter by mailbox
          const matchesMailbox = selectedFilter.selectedMailbox.length
            ? selectedFilter.selectedMailbox.includes(email.from)
            : true

          return matchesType && matchesDate && matchesMailbox
        })
        .forEach((email) => {
          const timestamp = new Date(email.createdAt)
          const monthYear = timestamp.toLocaleString('en-US', {
            month: 'long',
            year: 'numeric'
          })

          if (!acc[monthYear]) {
            acc[monthYear] = []
          }

          acc[monthYear].push({
            type: getActivityType(email),
            icon: getActivityIcon(email),
            title: email.subject || 'No Subject',
            timestamp: timestamp.toLocaleString('en-US', {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true
            }),
            tooltip: campaign.name,
            emailData: email
          })
        })

      return acc
    }, {})

    setActivityData(newActivityData)
  }, [selectedFilter, campaigns])

  const handleFilter = (filter) => {
    setSelectedFilter((prev) => ({
      ...prev,
      ...filter
    }))
  }

  const getActivityType = (email) => {
    if (email.email_replied) return 'Email Replied'
    if (email.email_clicked) return 'Link Clicked'
    if (email.email_opened) return 'Email Opened'
    return 'Email Sent'
  }

  const getActivityIcon = (email) => {
    if (email.email_replied) return 'reply'
    if (email.email_clicked) return 'link'
    if (email.email_opened) return 'email_open'
    return 'send'
  }

  return (
    <Box>
      <Box p={2}>
        <PersonHeader
          name={user.name}
          title={user.title}
          email={user.email}
          emailStatus={emailStatus}
          logo={photoUrl}
        />
      </Box>

      {isLoading
        ? (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '300px'
              }}
            >
              <CircularProgress size={40} sx={{ color: '#8181B0' }} />
            </Box>
          </>
          )
        : (
          <Box sx={{ position: 'relative' }}>
            {!planDetails?.isInifinityPlan && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  zIndex: 10, // Ensure it appears above everything else
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'rgba(255, 255, 255, 0.8)' // Slight transparency
                }}
              >
                <UpgradeAccessModal />
              </Box>
            )}
            <Box
              id='here'
              sx={{
                ...(planDetails?.isInifinityPlan
                  ? {}
                  : {
                      filter: 'blur(1px)',
                      pointerEvents: 'none',
                      '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: '#E1E3EC',
                        opacity: 0.1,
                        zIndex: -1
                      }
                    })
              }}
            >
              <Box p={2}>
                <FilterDropdowns
                  campaigns={campaigns}
                  onFilter={handleFilter}
                  mailboxes={mailboxes}
                />
              </Box>

              {activityData && Object.keys(activityData).length > 0
                ? (
                  <>
                    {Object.entries(activityData).map(([date, activities]) => (
                      <ActivityEmailsGroup key={date} date={date} activities={activities} />
                    ))}
                  </>
                  )
                : (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '300px'
                    }}
                  >
                    <Box>No activities found</Box>
                  </Box>
                  )}
            </Box>
          </Box>
          )}
    </Box>
  )
}

export default ActivityTab
