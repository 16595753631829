import { Stack, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { OrangeButton } from './EditLeadKeepEditing'
import { WhiteButton } from './CreateAnEmail'
import { EmailListingContext, InboxHubContext } from 'src/pages/InboxHub'
import { useDeleteBulkEmailMutation, useFetchCampaignEmailsQuery } from 'src/services/unibox-service'
import toast from 'react-hot-toast'

function DialogDeleteSelectedEmails ({ handleClosePopup }) {
  const { markedEmailIds, setmarkedEmailIds } = useContext(InboxHubContext)
  const [deleteBulkEmails] = useDeleteBulkEmailMutation()
  const { getCampaignEmailPayload } = useContext(EmailListingContext)
  const { refetch: refetchCampaignEmails } = useFetchCampaignEmailsQuery(getCampaignEmailPayload)
  async function handleClickYes () {
    try {
      const res = await deleteBulkEmails({ ids: markedEmailIds }).unwrap()
      toast.success(res?.message ?? 'Email(s) deleted successfully')
      setmarkedEmailIds([])
      refetchCampaignEmails()
      handleClosePopup()
    } catch (error) {
      toast.error(error?.data?.error?.message)
    }
  }
  function handleClickNo () {
    handleClosePopup()
  }
  return (
    <Stack gap={2}>
      <Stack width='100%' justifyContent='space-between'>
        <Typography fontWeight={700} fontSize='18px'>
          Are you sure?
        </Typography>
      </Stack>
      <Typography fontWeight={300} fontSize='18px'>
        You want to delete {markedEmailIds.length} emails? This action is irreversible.
      </Typography>
      <Stack width='100%' direction={{ xs: 'column', mmd: 'row' }} justifyContent='space-between' gap={2}>
        <OrangeButton onClick={handleClickYes}>
          Yes, delete
        </OrangeButton>
        <WhiteButton onClick={handleClickNo}>No, keep</WhiteButton>
      </Stack>
    </Stack>
  )
}

export default DialogDeleteSelectedEmails
