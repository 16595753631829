import React from 'react'
import { CircularProgress, Container, Typography } from '@mui/material'

const NoDataAndWaitingWrapper = ({
  children,
  isLoading,
  loadingText,
  isError,
  errorText,
  data,
  noDataText,
  noDataUi
}) => {
  return (
    <>
      {isLoading || isError
        ? (
          <Container
            sx={{
              height: '100%',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: 5
            }}
          >
            <CircularProgress />
            <Typography color='rgba(0, 0, 0, 0.35)'>{isError ? errorText : loadingText}</Typography>
          </Container>
          )
        : !isError && !isLoading && !data
            ? (
              <Container
                sx={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  gap: 5
                }}
              >
                {noDataUi || (
                  <Typography color='rgba(0, 0, 0, 0.35)'>{noDataText}</Typography>
                )}
              </Container>
              )
            : (
                children
              )}
    </>
  )
}

export default NoDataAndWaitingWrapper
