import React, { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'

const DancingDotsSpinner = () => {
  const [loadingText, setLoadingText] = useState('Loading...')

  useEffect(() => {
    // After 4 seconds, change text to "hand tight..."
    const timer1 = setTimeout(() => {
      setLoadingText('Hang tight...')
    }, 1000)

    // After 6 seconds total, change text to "Getting Things ready"
    const timer2 = setTimeout(() => {
      setLoadingText('Getting Things ready...')
    }, 2000)

    // Clean up timers on unmount
    return () => {
      clearTimeout(timer1)
      clearTimeout(timer2)
    }
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#E7F0FF', // Light background
        padding: 2,
        borderRadius: 1,
        width: 'fit-content',
        boxShadow: '0 2px 5px rgba(0,0,0,0.1)'
      }}
    >
      {/* The Dots */}
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          '& div': {
            width: 16,
            height: 16,
            borderRadius: '50%',
            backgroundColor: '#3F50F8',
            animation: 'dot 1.2s infinite ease-in-out'
          },
          '& div:nth-of-type(2)': {
            animationDelay: '0.2s'
          },
          '& div:nth-of-type(3)': {
            animationDelay: '0.4s'
          },
          '@keyframes dot': {
            '0%, 80%, 100%': {
              opacity: 0,
              transform: 'scale(0.8)'
            },
            '40%': {
              opacity: 1,
              transform: 'scale(1.3)'
            }
          }
        }}
      >
        <div />
        <div />
        <div />
      </Box>
      {/* Animated loading text */}
      <Typography variant='body2' sx={{ mt: 1, fontWeight: 600, color: '#3F50F8' }}>
        {loadingText}
      </Typography>
    </Box>
  )
}

export default DancingDotsSpinner
