import React from 'react'
import { Typography, Box } from '@mui/material'

const HighlightedTextPills = ({
  label,
  values, // Array of values for pills
  pillSx = {}, // Styles for each pill
  containerSx = {}, // Styles for the container
  labelSx = {}, // Styles for the label
  children, // Optional children
  ...props
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '12px',
        ...containerSx // Allow external overrides for the container
      }}
      {...props}
    >
      {label && (
        <Typography
          variant='body2'
          sx={{
            marginBottom: '8px',
            fontWeight: 'bold',
            ...labelSx // Allow external label overrides
          }}
        >
          {label}
        </Typography>
      )}
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap', // Allow multiple pills to wrap
          gap: 1 // Spacing between pills
        }}
      >
        {values?.map((value, index) => (
          <Typography
            key={index}
            component='span'
            sx={{
              display: 'inline-block',
              backgroundColor: '#E7F0FF',
              color: '#3F50F8',
              borderRadius: '16px',
              padding: '4px 12px',
              fontSize: '14px',
              whiteSpace: 'nowrap',
              ...pillSx // Allow external pill style overrides
            }}
          >
            {value}
          </Typography>
        ))}
      </Box>
      {children && <Box mt={2}>{children}</Box>}
    </Box>
  )
}

export default HighlightedTextPills
