import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Stack,
  Typography
} from '@mui/material'
import React, { useContext } from 'react'
import { WhiteButton } from './CreateAnEmail'
import { OrangeButton } from './DeleteEmailThread'
import { useRemoveLeadsMutation } from 'src/services/leads-service'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { InboxHubContext } from 'src/pages/InboxHub'

export default function RemoveLead ({ handleClosePopup }) {
  const workspace = useSelector((state) => state.workspace)
  const { currentlyOpenedEmail } = useContext(InboxHubContext)

  const [removeLeads, { isLoading }] = useRemoveLeadsMutation()

  let removeAllLeads = true
  let removeAllCampaigns = true
  let blockList = true

  async function handleClickRemove () {
    const body = {
      email: currentlyOpenedEmail?.leads?.email,
      allDomain: removeAllLeads,
      allCampaign: removeAllCampaigns,
      blocklist: blockList,
      workspace: workspace._id
    }
    try {
      const { message } = await removeLeads(body).unwrap()
      toast.success(message)
      handleClosePopup()
    } catch (e) {
      toast.error(e?.data?.error?.message ?? 'Something not right')
    }
  }
  return (
    <Stack gap={2}>
      <Stack width='100%' justifyContent='space-between'>
        <Typography fontWeight={700} fontSize='18px'>
          Where would you like to remove the lead?
        </Typography>
      </Stack>
      <Typography fontWeight={300} fontSize='18px'>
        Please select at least one option from the choices provided. Note that this action is
        permanent, and you won’t be able to go back.
      </Typography>
      <Stack gap={1}>
        <Box sx={{ width: '100%', border: '1px solid #D7D9E6', borderRadius: 2, px: 2, py: 1 }}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked
                onChange={() => {
                  removeAllLeads = !removeAllLeads
                }}
              />
            }
            label='Remove all leads from the same company (email domain)'
          />
        </Box>
        <Box sx={{ width: '100%', border: '1px solid #D7D9E6', borderRadius: 2, px: 2, py: 1 }}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked
                onChange={() => {
                  removeAllCampaigns = !removeAllCampaigns
                }}
              />
            }
            label='Remove from all campaigns'
          />
        </Box>
        <Box sx={{ width: '100%', border: '1px solid #D7D9E6', borderRadius: 2, px: 2, py: 1 }}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked
                onChange={() => {
                  blockList = !blockList
                }}
              />
            }
            label='Add email to blocklist'
          />
        </Box>
      </Stack>
      <Stack width='100%' direction={{ xs: 'column', mmd: 'row' }} justifyContent='space-between' gap={2}>
        <WhiteButton onClick={handleClosePopup}>Cancel</WhiteButton>
        {isLoading
          ? (
            <CircularProgress />
            )
          : (
            <OrangeButton disabled={isLoading} onClick={handleClickRemove}>
              Remove lead from selections
            </OrangeButton>
            )}
      </Stack>
    </Stack>
  )
}
