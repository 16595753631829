import { Favorite, Today, Home, EmojiEvents, Cached, RadioButtonUnchecked, Circle, Done, Close, Email } from '@mui/icons-material'
import { Box } from '@mui/material'
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe'

export const renderLabelWithIcon = (label) => {
  switch (label.name) {
    case 'Lead':
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', maxHeight: '40px', height: '25px' }}>
          <Email sx={{ fontSize: 20, color: '#ffcf3c', mr: 1 }} />
          <span>{label.name}</span>
        </Box>
      )
    case 'Interested':
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', maxHeight: '40px', height: '25px' }}>
          <Favorite sx={{ fontSize: 20, color: '#689f38', mr: 1 }} />
          <span>{label.name}</span>
        </Box>
      )
    case 'Not interested':
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', maxHeight: '40px', height: '25px' }}>
          <Close sx={{ fontSize: 20, color: '#ee3131', mr: 1 }} />
          <span>{label.name}</span>
        </Box>
      )
    case 'Meeting booked':
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', maxHeight: '40px', height: '25px' }}>
          <Today sx={{ fontSize: 20, color: '#97c7f3', mr: 1 }} />
          <span>{label.name}</span>
        </Box>
      )
    case 'Meeting completed':
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', maxHeight: '40px', height: '25px' }}>
          <Done sx={{ fontSize: 20, color: '#689f38', mr: 1 }} />
          <span>{label.name}</span>
        </Box>
      )
    case 'Out of office':
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', maxHeight: '40px', height: '25px' }}>
          <Home sx={{ fontSize: 20, color: '#852f02', mr: 1 }} />
          <span>{label.name}</span>
        </Box>
      )
    case 'Wrong person':
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', maxHeight: '40px', height: '25px' }}>
          <Circle sx={{ fontSize: 20, color: '#272727', mr: 1 }} />
          <span>{label.name}</span>
        </Box>
      )
    case 'Won':
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', maxHeight: '40px', height: '25px' }}>
          <EmojiEvents sx={{ fontSize: 20, color: '#c98d09', marginTop: '5px', mr: 1 }} />
          <span>{label.name}</span>
        </Box>
      )
    case 'Lost':
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', maxHeight: '40px', height: '25px' }}>
          <RadioButtonUnchecked sx={{ fontSize: 20, color: '#ee3131', mr: 1 }} />
          <span>{label.name}</span>
        </Box>
      )
    case 'Unsubscribed':
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', maxHeight: '40px', height: '25px' }}>
          <UnsubscribeIcon sx={{ fontSize: 20, color: '#ee3131', mr: 1 }} />
          <span>{label.name}</span>
        </Box>
      )
    default:
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', maxHeight: '40px', height: '25px' }}>
          <Cached sx={{ fontSize: 20, color: '#ffcf3c', mr: 1 }} />
          <span>{label.name}</span>
        </Box>
      )
  }
}
