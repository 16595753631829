import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Stack, Typography, RadioGroup, FormControlLabel, Checkbox, TextField, Tooltip } from '@mui/material'
import { Tag, FilterDropdown, RadioBtnCollapse } from '../filterComponents'
import { employeeCountRangeData } from 'src/assets/newFiltersData'
import { UserProfile } from 'src/icons/userProfile'
import { useTheme } from '@emotion/react'
import { OffCheckboxCustomIcon } from 'src/assets/general/OffCheckboxCustomIcon'
import { OnCheckboxCustomIcon } from 'src/assets/general/OnCheckboxCustomIcon'
import InfoIcon from '@mui/icons-material/Info'
const checkboxOfIcon = <OffCheckboxCustomIcon />
const checkboxOnIcon = <OnCheckboxCustomIcon />

export default function EmployeeCountFilter ({ onChange, type, isOpen, onToggle }) {
  const theme = useTheme()
  const [selectedMethod, setSelectedMethod] = useState('')
  const [employeeCountRange, setEmployeeCountRange] = useState([])
  const [range, setRange] = useState({ min: '', max: '' })
  const [unknownRange, setUnknownRange] = useState(false)

  const employeeCount = useSelector((state) => state.search[type === 'company' ? 'companyFilter' : 'filter'].company_size)

  const handleEmployeeCountRange = useCallback((value, checked, updateRedux = true) => {
    let newValue = employeeCountRange
    if (checked) {
      if (!employeeCountRange.includes(value)) {
        newValue = [...employeeCountRange, value]
      }
    } else {
      newValue = employeeCountRange.filter((l) => l !== value)
    }
    setEmployeeCountRange(newValue)
    if (updateRedux) onChange('company_size', newValue)
  }, [onChange, employeeCountRange])

  const handleSelectedRange = useCallback((newValue, type, updateRedux = true) => {
    if (type !== 'both') {
      setRange({ ...range, [type]: newValue })
      if (updateRedux) onChange('company_size', [`${type === 'min' ? newValue : range.min}, ${type === 'max' ? newValue : range.max}`])
    } else {
      const value = { min: newValue.min, max: newValue.max }
      setRange(value)
      if (updateRedux) onChange('company_size', [])
    }
  }, [onChange, range])

  const clearAll = useCallback(() => {
    setEmployeeCountRange([])
    setRange({ min: '', max: '' })
    onChange('company_size', [])
  }, [onChange])

  const handleRadioBtn = useCallback((e) => {
    setSelectedMethod(e.target.value)
    setEmployeeCountRange([])
    setRange({ min: '', max: '' })
    onChange('company_size', [])
  }, [onChange])

  useEffect(() => {
    if (!employeeCount) {
      setRange({ min: '', max: '' })
      setEmployeeCountRange([])
      return
    }

    if (employeeCount.length === 1 && !employeeCountRangeData.some((range) => (
      employeeCount[0] === `${range.min}${range.max === 'Unknown' ? '' : Number.isFinite(Number(range.max)) ? `, ${range.max}` : ''}`
    ))) {
      setSelectedMethod('custom')
      const arr = employeeCount[0].split(', ')
      setRange({ min: arr[0], max: arr[1] })
    } else {
      setSelectedMethod('predefined')
      setEmployeeCountRange(employeeCount)
    }
  }, [employeeCount])

  const preview = (
    <>
      {(employeeCountRange.length > 0 || range.min || range.max) &&
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 1 }}>
          {employeeCountRange.length > 0 &&
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
              <Box sx={{ width: '95%', height: '0px', border: '0.5px solid', borderColor: '#E4E4E5', mx: 'auto' }} />
              <Typography variant='body2' sx={{ color: '#8181B0', fontSize: '12px', fontWeight: 600, paddingLeft: 1 }}>Predefined range</Typography>
              {employeeCountRange.map((range, index) => <Tag key={index} label={range.split(', ').join('-')} onDelete={(e) => handleEmployeeCountRange(range, false)} />)}
            </Box>}
          {(range.min || range.max) &&
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, alignItems: 'center' }}>
              <Box sx={{ width: '95%', height: '0px', border: '0.5px solid', borderColor: '#E4E4E5', mx: 'auto' }} />
              <Typography variant='body2' sx={{ color: '#8181B0', fontSize: '12px', fontWeight: 600, paddingLeft: 1 }}>Custom range</Typography>
              <Tag label={`${range.min}-${range.max}`} onDelete={clearAll} />
            </Box>}
        </Box>}
    </>
  )

  return (
    <FilterDropdown
      title={
        <Stack direction='row' spacing={0.5} alignItems='center'>
          <Typography variant='h6' sx={{ fontWeight: 700, fontSize: { sm: '10px', md: '12px', lg: '14px' } }}>
            Employee Count
          </Typography>
          <Tooltip
            title='The Employee Count reflects an estimated range of people at the company, and does not indicate the number of records in our database.'
            arrow
          >
            <InfoIcon fontSize='small' sx={{ color: theme.palette.text.secondary, cursor: 'pointer' }} />
          </Tooltip>
        </Stack>
      }
      filterCount={selectedMethod === 'predefined' ? employeeCountRange.length : (range.min || range.max ? 1 : 0)}
      clearAll={clearAll}
      icon={<UserProfile sx={{ stroke: `${theme.palette.primary.main}` }} />}
      preview={preview}
      isOpen={isOpen}
      onToggle={onToggle}
    >
      <Stack direction='column' gap={2} mt={2}>
        <RadioGroup
          value={selectedMethod}
          onChange={handleRadioBtn}
        >
          <Stack direction='column' gap={1}>
            {/* Predefined range */}
            <RadioBtnCollapse selected={selectedMethod} value='predefined' label='Predefined range'>
              <Stack direction='column'>
                {employeeCountRangeData.map((range) => {
                  const label = `${range.min}${range.max === 'Unknown' ? '' : Number.isFinite(Number(range.max)) ? `-${range.max}` : '+'}`
                  const value = `${range.min}${range.max === 'Unknown' ? '' : Number.isFinite(Number(range.max)) ? `, ${range.max}` : ''}`
                  return (
                    <FormControlLabel
                      key={label}
                      label={label}
                      sx={{ '& .MuiFormControlLabel-label': { fontSize: '13px', fontWeight: '600', color: `${theme.palette.primary.primaryText}` } }}
                      control={
                        <Checkbox
                          icon={checkboxOfIcon}
                          checkedIcon={checkboxOnIcon}
                          style={{ marginRight: 8 }}
                          checked={employeeCountRange.includes(value)}
                          onChange={(e) => handleEmployeeCountRange(value, e.target.checked)}
                        />
                  }
                    />
                  )
                })}
              </Stack>
            </RadioBtnCollapse>

            {/* Custom range */}
            <RadioBtnCollapse selected={selectedMethod} value='custom' label='Custom range'>
              <Stack direction='row' gap={1} mt={1}>
                <TextField type='number' placeholder='Min' value={range.min} onChange={(e) => handleSelectedRange(e.target.value, 'min')} />
                <span>-</span>
                <TextField type='number' placeholder='Max' value={range.max} onChange={(e) => handleSelectedRange(e.target.value, 'max')} />
              </Stack>

              <FormControlLabel
                label={<span style={{ fontSize: '13px', fontWeight: 500 }}>Include Unknown revenue</span>}
                control={
                  <Checkbox
                    icon={checkboxOfIcon}
                    checkedIcon={checkboxOnIcon}
                    style={{ marginRight: 8 }}
                    checked={unknownRange}
                    onChange={() => setUnknownRange(!unknownRange)}
                  />
                }
              />
            </RadioBtnCollapse>
          </Stack>
        </RadioGroup>
      </Stack>
    </FilterDropdown>
  )
}

// import React, { useEffect, useState } from "react";
// import {
//   Box,
//   Typography,
//   TextField,
//   IconButton,
//   Paper,
//   Collapse,
//   Checkbox,
//   FormControlLabel,
//   Radio,
//   RadioGroup,
//   Chip,
// } from "@mui/material";
// import {
//   KeyboardArrowDown,
//   KeyboardArrowUp,
//   Close as CloseIcon,
//   Person as PersonIcon,
//   Close,
// } from "@mui/icons-material";

// const EmployeeCount = ({ handleFilterChange, clear, setClear , value}) => {
//   const [isPanelOpen, setIsPanelOpen] = useState(false);
//   const [selectedRanges, setSelectedRanges] = useState(() => {
//     if (!value.company_size) return [];
//     return value.company_size;
//   });
//   const [rangeType, setRangeType] = useState("predefined");
//   const [minValue, setMinValue] = useState("");
//   const [maxValue, setMaxValue] = useState("");
//   const [includeUnknown, setIncludeUnknown] = useState(false);

//   useEffect(() => {
//     if (!value.company_size) return;
//     setSelectedRanges(value.company_size);
//   }, [value])

//   const ranges = [
//     "Unknown",
//     "1-10",
//     "11-20",
//     "21-50",
//     "51-100",
//     "100-200",
//     "201-500",
//     "501-1,000",
//     "1,001-2,000",
//     "2,001-5,000",
//     "5,001-10,000",
//     "10,001+",
//   ];

//   const toggleRange = (range) => {
//     const newRanges = selectedRanges.includes(range)
//       ? selectedRanges.filter((r) => r !== range)
//       : [...selectedRanges, range];

//     setSelectedRanges(newRanges);
//     handleFilterChange("company_size", newRanges);
//   };

//   const handleMinChange = (event) => {
//     const value = event.target.value.replace(/[^0-9]/g, "");
//     setMinValue(value);
//     if (value || maxValue) {
//       updateCustomRange();
//     }
//   };

//   const handleMaxChange = (event) => {
//     const value = event.target.value.replace(/[^0-9]/g, "");
//     setMaxValue(value);
//     if (minValue || value) {
//       updateCustomRange();
//     }
//   };

//   const updateCustomRange = () => {
//     if (rangeType === "custom") {
//       const customRangeText = getCustomRangeText();
//       if (customRangeText) {
//         setSelectedRanges([customRangeText]);
//       } else {
//         setSelectedRanges([]);
//       }
//     }
//   };

//   const getCustomRangeText = () => {
//     if (minValue && maxValue) {
//       return `${Number(minValue).toLocaleString()}-${Number(maxValue).toLocaleString()}`;
//     } else if (minValue) {
//       return `${Number(minValue).toLocaleString()}+`;
//     } else if (maxValue) {
//       return `0-${Number(maxValue).toLocaleString()}`;
//     }
//     return "";
//   };

//   useEffect(() => {
//     if (clear) {
//       clearRange();
//       setClear(false);
//     }
//   }, [clear, setClear]);

//   const clearRange = (e) => {
//     e?.stopPropagation();
//     setSelectedRanges([]);
//     setMinValue("");
//     setMaxValue("");
//     setIncludeUnknown(false);
//     handleFilterChange("company_size", []); // Clear filter by sending an empty array
//   };

//   return (
//     <Paper
//       sx={{
//         width: "100%",
//         p: 1,
//         boxShadow: "0 1px 3px rgba(0,0,0,0.12)",
//         mb: "13px",
//         border: "1px solid var(--Border-Light, #E4E4E5)",
//         borderRadius: 2,
//       }}
//     >
//       <Box
//         sx={{
//           display: "flex",
//           flexDirection: "column",
//           gap: 1,
//         }}
//       >
//         <Box
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "space-between",
//             cursor: "pointer",
//           }}
//           onClick={() => setIsPanelOpen(!isPanelOpen)}
//         >
//           <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
//             <PersonIcon sx={{ fontSize: 22, color: "#3F4FF8" }} />
//             <Typography variant="h6" sx={{ fontWeight: 700, fontSize: "15px" }}>
//               Employee Count
//             </Typography>
//             {selectedRanges.length > 0 && (
//               <Box
//                 onClick={(e) => {
//                   e.stopPropagation();
//                   clearRange(e);
//                 }}
//                 sx={{
//                   background: " #3F4FF8",

//                   display: "flex",
//                   alignItems: "center",

//                   color: "white",
//                   borderRadius: "6px",
//                   padding: "4px 8px",
//                   fontSize: "12px",
//                   fontWeight: 700,
//                 }}
//               >
//                 {selectedRanges.length}{" "}
//                 <IconButton
//                   size="small"
//                   onClick={clearRange}
//                   sx={{
//                     color: "white",
//                     p: 0,
//                     ml: 0.5,
//                     "&:hover": { color: "#90caf9" },
//                   }}
//                 >
//                   <Close sx={{ fontSize: 14 }} />
//                 </IconButton>
//               </Box>
//             )}
//           </Box>

//           <IconButton
//             size="small"
//             onClick={(e) => {
//               e.stopPropagation();
//               setIsPanelOpen(!isPanelOpen);
//             }}
//           >
//             {isPanelOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
//           </IconButton>
//         </Box>

//         {!isPanelOpen && selectedRanges.length > 0 && (
//           <Box sx={{ display: "flex", flexDirection: "column", gap: 1, mt: 1 }}>
//             <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, alignItems: "center" }}>
//               <Typography variant="body2" sx={{ color: "text.secondary" }}>
//                 {rangeType === "predefined" ? "Predefined Range" : "Custom Range"}
//               </Typography>
//               {selectedRanges.map((range) => (
//                 <Chip
//                   key={range}
//                   label={range}
//                   onDelete={() => {
//                     if (rangeType === "predefined") {
//                       toggleRange(range);
//                     } else {
//                       clearRange();
//                     }
//                   }}
//                   deleteIcon={<CloseIcon fontSize="small" />}
//                   size="small"
//                   sx={{
//                     borderRadius: 1,
//                     background: "#E6F0FF",
//                     fontWeight: 500,
//                     fontSize: "14px",
//                     borderRadius: "43px",
//                     border: "1px solid #3F4FF836",

//                     color: "black",
//                     "& .MuiChip-deleteIcon": {
//                       color: "black",
//                     },
//                   }}
//                 />
//               ))}
//             </Box>
//           </Box>
//         )}

//         <Collapse in={isPanelOpen}>
//           <Box sx={{ mt: 2 }}>
//             <RadioGroup
//               value={rangeType}
//               onChange={(e) => {
//                 setRangeType(e.target.value);
//                 clearRange();
//               }}
//             >
//               <Box
//                 sx={{
//                   mb: 2,
//                   mb: 1,
//                   border: "1px solid #D7D9E6",
//                   padding: "10px",
//                   borderRadius: "8px",
//                   mx: 1,
//                 }}
//               >
//                 <Box
//                   sx={{
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "space-between",
//                     mb: 1,
//                   }}
//                 >
//                   <FormControlLabel
//                     value="predefined"
//                     control={<Radio />}
//                     label={
//                       <Typography sx={{ fontWeight: 700, fontSize: "14px" }}>
//                         Predefined Range
//                       </Typography>
//                     }
//                   />
//                   {rangeType === "predefined" && selectedRanges.length > 0 && (
//                     <Typography
//                       onClick={() => clearRange()}
//                       sx={{
//                         color: "primary.main",
//                         cursor: "pointer",
//                         fontSize: "0.875rem",
//                       }}
//                     >
//                       Clear ({selectedRanges.length})
//                     </Typography>
//                   )}
//                 </Box>

//                 {rangeType === "predefined" && (
//                   <Box sx={{ pl: 4 }}>
//                     {ranges.map((range) => (
//                       <FormControlLabel
//                         key={range}
//                         control={
//                           <Checkbox
//                             checked={selectedRanges.includes(range)}
//                             onChange={() => toggleRange(range)}
//                             size="small"
//                           />
//                         }
//                         label={range}
//                         sx={{
//                           display: "block",
//                           "& .MuiFormControlLabel-label": {
//                             fontSize: "0.875rem",
//                           },
//                         }}
//                       />
//                     ))}
//                   </Box>
//                 )}
//               </Box>

//               <Box
//                 sx={{
//                   mt: "10px",
//                   mb: 1,
//                   border: "1px solid #D7D9E6",
//                   padding: "10px",
//                   borderRadius: "8px",
//                   mx: 1,
//                   bgcolor: "#F2F4F6",
//                 }}
//               >
//                 <FormControlLabel
//                   value="custom"
//                   control={<Radio />}
//                   label={<Typography sx={{ fontWeight: 50 }}>Custom Range</Typography>}
//                 />

//                 {rangeType === "custom" && (
//                   <Box sx={{ pl: 4, mt: 1 }}>
//                     <Box
//                       sx={{
//                         display: "flex",
//                         gap: 1,
//                         alignItems: "center",
//                         mb: 2,
//                       }}
//                     >
//                       <TextField
//                         placeholder="Min"
//                         value={minValue}
//                         onChange={handleMinChange}
//                         size="small"
//                         sx={{
//                           flex: 1,
//                           "& .MuiOutlinedInput-root": {
//                             borderRadius: 1,
//                           },
//                         }}
//                       />
//                       <Box sx={{ color: "text.secondary" }}>-</Box>
//                       <TextField
//                         placeholder="Max"
//                         value={maxValue}
//                         onChange={handleMaxChange}
//                         size="small"
//                         sx={{
//                           flex: 1,
//                           "& .MuiOutlinedInput-root": {
//                             borderRadius: 1,
//                           },
//                         }}
//                       />
//                     </Box>
//                     <FormControlLabel
//                       control={
//                         <Checkbox
//                           checked={includeUnknown}
//                           onChange={(e) => {
//                             setIncludeUnknown(e.target.checked);
//                             if (e.target.checked) {
//                               setSelectedRanges((prev) => [...prev, "Unknown"]);
//                             } else {
//                               setSelectedRanges((prev) => prev.filter((r) => r !== "Unknown"));
//                             }
//                           }}
//                           size="small"
//                         />
//                       }
//                       label="Include Unknown employee count"
//                       sx={{
//                         "& .MuiFormControlLabel-label": {
//                           fontSize: "0.875rem",
//                         },
//                       }}
//                     />
//                   </Box>
//                 )}
//               </Box>
//             </RadioGroup>
//           </Box>
//         </Collapse>
//       </Box>
//     </Paper>
//   );
// };

// export default EmployeeCount;
