import { useTheme } from '@mui/material'
export const SBEmailVerification = ({ color }) => {
  const theme = useTheme()
  const defaultColor = color || theme.palette.primary.primaryText
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        height='24px'
        viewBox='0 0 24 24'
        width='24px'
        fill={defaultColor}
      >
        <g>
          <rect />
          <path
            d='M20,4H4C2.9,4,2.01,4.9,2.01,6L2,18c0,1.1,0.9,2,2,2h8l0-2H4V8l8,5l8-5v5h2V6C22,4.9,21.1,4,20,4z M12,11L4,6h16L12,11z M17.34,22l-3.54-3.54l1.41-1.41l2.12,2.12l4.24-4.24L23,16.34L17.34,22z'
            stroke={defaultColor}
            stroke-width='0.1'
          />
        </g>
      </svg>
    </>
  )
}
