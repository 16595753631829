import NoDataSvg from 'src/assets/dealPipeline/no-data.svg'

import React from 'react'
import {
  Box,
  Typography
} from '@mui/material'

const EmptyColumnState = () => (
  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '90%', padding: '16px' }}>
    <img
      src={NoDataSvg}
      alt='No deals'
      style={{
        width: '80%',
        maxWidth: '200px',
        marginBottom: '16px'
      }}
    />
    <Typography variant='h6' sx={{ color: '#111927', fontWeight: '700', marginBottom: '8px' }}>
      No deals in this column
    </Typography>
    <Typography sx={{ color: '#8181B0' }}>
      You can add a deal to the column by clicking the button below or by dragging it in.
    </Typography>
  </Box>
)

export default EmptyColumnState
