import { Avatar, Box, Stack, Typography } from '@mui/material'
import moment from 'moment'
import React, { useContext } from 'react'
import { JustDot } from 'src/assets/inboxHub/InboxHubIcons'
import TextWithDotBetween from './TextWithDotBetween'
import { InboxHubContext } from 'src/pages/InboxHub'

const Reply = ({ reply, key }) => {
  const { date, from, to, bodyText } = reply

  const formattedDate = moment(date).format('DD/MM/YY')
  const formattedTime = moment(date).format('hh:mm A')

  return (
    <Box key={key} sx={{ backgroundColor: '#FFF', border: '1px solid #28287B1A', borderRadius: 1 }}>
      <Box sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.22)', px: 2 }}>
        <Box
          sx={{
            py: 1.5,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
            alignItems: { xs: 'left', md: 'center' },
            gap: { xs: 2, md: 0 },
            borderBottom: '1px solid #28287B1A'
          }}
        >
          <EmailFromTo from fromTo={from} />
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1 }}
          >
            <Typography sx={{ fontSize: '12px', color: '#8181B0' }}>
              <TextWithDotBetween
                dotColor='#8181B0'
                dotSize='3'
                textArray={[formattedTime, formattedDate]}
              />
            </Typography>
            <Stack
              direction='row'
              gap={1}
              sx={{
                display: 'flex',
                width: 'fit-content',
                height: 'fit-content',
                alignItems: 'center',
                border: '1px solid rgba(0, 0, 0, 0.14)',
                borderRadius: 1,
                px: 1
              }}
            >
              <JustDot color='#3F4FF8' />
              <Typography color='#344054' fontWeight={600} fontSize='14px'>
                Replied
              </Typography>
            </Stack>
          </Box>
        </Box>
        <Box sx={{ py: 1.5, borderBottom: '1px solid #28287B1A' }}>
          <EmailFromTo from={false} fromTo={to} />
        </Box>
        <Box sx={{ py: 1.5, display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography sx={{ color: '#8181B0', fontSize: '14px' }}>Subject:</Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>Subject Line Goes here</Typography>
        </Box>
      </Box>
      <Box sx={{ p: 2 }}>
        <Typography sx={{ fontSize: '14px', whiteSpace: 'pre-line' }}>{bodyText}</Typography>
      </Box>
    </Box>
  )
}

export default Reply

const EmailFromTo = ({ from, fromTo }) => {
  const { currentlyOpenedEmail, isMobile } = useContext(InboxHubContext)

  const fromAccountEmail = currentlyOpenedEmail?.fromAccount?.email ?? ''
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Typography sx={{ color: '#8181B0', fontSize: '14px' }}>{from ? 'From:' : 'To:'}</Typography>
      <Avatar
        alt='Remy Sharp'
        src='https://randomuser.me/api/portraits/women/75.jpg'
        sx={{ width: 22, height: 22 }}
      />
      <Box>
        {isMobile
          ? (
            <Stack>
              <Typography key={0} sx={{ fontWeight: 600, fontSize: '14px' }}>
                {getNameAndEmailForReply(fromTo, fromAccountEmail)?.[0]}
              </Typography>
              <Typography key={1} sx={{ fontSize: '14px' }}>
                {getNameAndEmailForReply(fromTo, fromAccountEmail)?.[1]}
              </Typography>
            </Stack>
            )
          : (
            <TextWithDotBetween
              dotColor='#000000'
              dotSize='3'
              textArray={[
                <Typography key={0} sx={{ fontWeight: 600, fontSize: '14px' }}>
                  {getNameAndEmailForReply(fromTo, fromAccountEmail)?.[0]}
                </Typography>,
                <Typography key={1} sx={{ fontSize: '14px' }}>
                  {getNameAndEmailForReply(fromTo, fromAccountEmail)?.[1]}
                </Typography>
              ]}
            />
            )}
      </Box>
      <Box />
    </Box>
  )
}

function getNameAndEmailForReply (inputFromTo, fromAccountEmail) {
  const cleanFromTo = inputFromTo.match(/"([^"]+)"\s+<([^>]+)>/)
  if (fromAccountEmail === cleanFromTo?.[2]) {
    return ['You', cleanFromTo?.[2]]
  }
  return [cleanFromTo?.[1], cleanFromTo?.[2]]
}
