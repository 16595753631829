import {
  Accordion,
  AccordionDetails,
  Grid,
  AccordionSummary,
  ListItemIcon,
  Paper,
  Stack,
  Typography,
  useTheme,
  LinearProgress,
  linearProgressClasses,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  Stepper,
  Step,
  StepLabel,
  Card,
  CardContent
} from '@mui/material'
import { Box, Container } from '@mui/system'
import React, { useContext, useEffect, useState, useMemo } from 'react'
import { OnboardingHub } from 'src/layouts/dashboard/config'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import {
  BlueUnderline,
  ChevronDownArrow,
  ClockIcon,
  OnboardingHubCheckedIcon,
  OnboardingHubUncheckedIcon
} from 'src/icons/OnboardingHubIcons'
import {
  useGetCustomAttributesQuery,
  useUpdateOnboardingAttributesMutation
} from 'src/services/intercom-service'
import { useUpdateUserNameMutation, useGetMeQuery } from 'src/services/user-service'
import { useSelector } from 'react-redux'
import { toast } from 'react-hot-toast'
import StepCompletitionConfirmation from 'src/components/onboardingHub/StepCompletitionConfirmation'
import { SidebarContext } from 'src/layouts/dashboard/layout'
import { ChevronRight } from 'lucide-react'
import { motion } from 'framer-motion'
const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputLabel-root': {
    textAlign: 'left',
    width: '100%',
    marginBottom: theme.spacing(1)
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.spacing(1)
  }
}))

const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: '500px',
  margin: '0 auto',
  padding: theme.spacing(4),
  borderRadius: theme.spacing(2),
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)'
}))

const StyledButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5, 4),
  borderRadius: theme.spacing(1),
  textTransform: 'none',
  fontWeight: 500,
  backgroundColor: '#1A73E8',
  '&:hover': {
    backgroundColor: '#1557B0'
  }
}))

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[300],
    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.grey[800]
    })
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary,
    ...theme.applyStyles('dark', {
      backgroundColor: '#308fe8'
    })
  }
}))

const Page = () => {
  const theme = useTheme()
  const totalPendingTasks = 0
  const { completionPercentage, onboardingData, setRefresh } = useContext(SidebarContext)
  const [isHovering, setIsHovering] = useState(null)
  const [openTaskCompletedConfirmation, setOpenTaskCompletedConfirmation] = useState(false)
  const [attributeToMark, setAttributeToMark] = useState('')
  const [attributeValue, setAttributeValue] = useState(false)
  const [tasks] = useState(totalPendingTasks)
  const { data: user, refetch } = useGetMeQuery()
  const { data } = useGetCustomAttributesQuery(undefined, {
    skip: user.isWhiteLabelUser
  })
  const [updateUserName] = useUpdateUserNameMutation()
  const navigate = useNavigate()
  const [updateOnboardingAttributes] = useUpdateOnboardingAttributesMutation()
  const { configs } = useSelector((state) => state.partnerDetails)

  const [onBoardingTasks, setOnBoardingTasks] = useState([...onBoardingTasksDefault])

  // onBoardingTasks.forEach((tasks) => {
  //   totalPendingTasks += tasks.subtasks.filter(subtasks => !subtasks.isCompleted).length
  // })

  const handleClickTaskCompleted = (attribute, currentAttributeValue) => {
    setAttributeToMark(attribute)
    setAttributeValue(!currentAttributeValue)
    setOpenTaskCompletedConfirmation(true)
  }

  async function handleClickCTA (stepNumber, attribute) {
    if (stepNumber === 0 || stepNumber === 4) {
      await updateOnboardingAttributes({ attribute, attributeValue: true }).unwrap()
    }
  }

  const handleRefresh = () => {
    setRefresh(true)
  }

  useEffect(() => {
    if (!user?.isWhiteLabelUser) return

    const customAttributes = onboardingData?.IntercomCustomAttributes ?? {}
    if (customAttributes.lead_finder_search === 'true') {
      onBoardingTasks[0].subtasks[0].isCompleted = true
    } else {
      onBoardingTasks[0].subtasks[0].isCompleted = false
    }
    if (customAttributes.consultation_clicked === 'true') {
      onBoardingTasks[1].subtasks[0].isCompleted = true
    } else {
      onBoardingTasks[1].subtasks[0].isCompleted = false
    }
    if (customAttributes.email_account_linked === 'true') {
      onBoardingTasks[2].subtasks[0].isCompleted = true
    } else {
      onBoardingTasks[2].subtasks[0].isCompleted = false
    }
    if (customAttributes.contacts_added === 'true') {
      onBoardingTasks[3].subtasks[0].isCompleted = true
    } else {
      onBoardingTasks[3].subtasks[0].isCompleted = false
    }
    if (customAttributes.sequences_created === 'true') {
      onBoardingTasks[3].subtasks[1].isCompleted = true
    } else {
      onBoardingTasks[3].subtasks[1].isCompleted = false
    }
    if (customAttributes.sending_schedule_created === 'true') {
      onBoardingTasks[3].subtasks[2].isCompleted = true
    } else {
      onBoardingTasks[3].subtasks[2].isCompleted = false
    }
    if (customAttributes.campaign_reviewed === 'true') {
      onBoardingTasks[3].subtasks[3].isCompleted = true
    } else {
      onBoardingTasks[3].subtasks[3].isCompleted = false
    }
    if (customAttributes.campaign_launched === 'true') {
      onBoardingTasks[3].subtasks[4].isCompleted = true
    } else {
      onBoardingTasks[3].subtasks[4].isCompleted = false
    }
    if (customAttributes.team_members_invited === 'true') {
      onBoardingTasks[4].subtasks[0].isCompleted = true
    } else {
      onBoardingTasks[4].subtasks[0].isCompleted = false
    }

    setOnBoardingTasks([...onBoardingTasks])
  }, [data, user?.isWhiteLabelUser])

  const [open, setOpen] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [activeStep, setActiveStep] = useState(0)
  // const isFormValid = useMemo(() => {
  //   return firstName.trim() !== '' && lastName.trim() !== '' && companyName.trim() !== ''
  // }, [firstName, lastName, companyName])

  const shouldOpen = useMemo(() => {
    const firstName = user?.name?.first?.trim() || ''
    return !firstName
  }, [user?.name])

  useEffect(() => {
    if (shouldOpen) {
      const timeout = setTimeout(() => setOpen(true), 3000)
      return () => clearTimeout(timeout)
    }
  }, [shouldOpen])

  useEffect(() => {
    handleRefresh()
  }, [])

  const handleClose = () => {
    setOpen(false)
  }

  const handleNext = () => setActiveStep((prevStep) => prevStep + 1)
  // const handleBack = () => setActiveStep((prevStep) => prevStep - 1)
  const handleSubmit = () => {
    const isExceedingLength = (value) => value && value.length > 15

    if ([firstName, lastName, companyName].some(isExceedingLength)) {
      toast.error('First name, last name, and company name cannot exceed 15 characters.')
      return
    }

    const emptyFields = [!firstName?.trim() && 'First name'].filter(Boolean)

    if (emptyFields.length > 0) {
      toast.error(`${emptyFields.join(', ')} cannot be empty.`)
      return
    }

    const userId = user?._id
    updateUserName({ firstName, lastName, companyName, userId }).then((response) => {
      toast.success(response?.data?.response?.message)
      refetch()
      handleClose()
    })
  }

  function isExceedingLength () {
    return false
  }

  const isInvalid = false

  return (
    <>
      <Box
        sx={{
          px: '2%',
          width: '100%',
          height: '100%'
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: '1px solid #E4E4E5',
              pb: 2,
              mb: { xs: 0, md: 3 }
            }}
          >
            <Typography
              sx={{
                color: theme.palette.primary.primaryText,
                fontSize: '32px',
                fontWeight: 700,
                lineHeight: '40px',
                letterSpacing: '0px'
              }}
            >
              Onboarding Hub
            </Typography>
          </Box>
          {/* <Typography display={{ xs: 'none', md: 'block' }} fontWeight={700}>{`Tasks(${tasks})`}</Typography> */}
        </Box>

        <Grid container spacing={4} my={1} direction={{ xs: 'column-reverse', md: 'row' }}>
          <Grid item xs={12} xl={8.5}>
            <Stack direction='column' spacing={1}>
              {onBoardingTasks.map((task, i) => {
                const inCompletedTasks = task.subtasks.filter((subtask) => !subtask.isCompleted)
                return (
                  <Accordion
                    key={i}
                    defaultExpanded
                    sx={{ borderRadius: '10px', border: '1px solid #D7D9E6' }}
                  >
                    <AccordionSummary
                      // expandIcon={<ExpandMoreIcon />}
                      aria-controls='panel1-content'
                      id='panel1-header'
                      sx={{
                        borderBottom: '1px solid',
                        borderColor: theme.palette.grey[200],
                        py: '5px'
                      }}
                    >
                      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <Stack direction='row' spacing={2} sx={{ alignItems: 'center' }}>
                          <ChevronDownArrow />
                          <Typography fontWeight={700}>{task.title}</Typography>
                        </Stack>
                        <Typography fontWeight={400} color={theme.palette.grey[500]}>
                          {inCompletedTasks.length} task(s) left
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      {task.subtasks.map((subtask, j) => {
                        if (subtask.isAnimated) {
                          return (
                            <MotionStack
                              key={j}
                              onClick={(e) => {
                                if (i !== 4) {
                                  navigate(subtask.href)
                                } else {
                                  window.open(subtask.href, '_blank', 'noopener,noreferrer')
                                }
                              }}
                            >
                              <Stack
                                direction='row'
                                spacing={2}
                                sx={{ display: 'flex', alignItems: 'center', p: '1%' }}
                              >
                                <div
                                  onClick={(e) => {
                                    handleClickTaskCompleted(
                                      subtask.attribute,
                                      subtask.isCompleted
                                    )
                                    e.stopPropagation()
                                  }}
                                  onMouseEnter={() => {
                                    setIsHovering(Number(`${i}${j}`))
                                  }}
                                  onMouseLeave={() => {
                                    setIsHovering(null)
                                  }}
                                >
                                  <ListItemIcon
                                    sx={{
                                      minWidth: '0',
                                      color: theme.palette.primary.contrastText,
                                      py: '0px',
                                      '&:active': {
                                        transform: 'scale(0.95)' // Shrink on active
                                      }
                                    }}
                                  >
                                    {subtask.isCompleted || isHovering === Number(`${i}${j}`)
                                      ? OnboardingHubCheckedIcon()
                                      : OnboardingHubUncheckedIcon()}
                                  </ListItemIcon>
                                </div>
                                <Box>
                                  <Typography
                                    sx={{ textDecoration: subtask.isCompleted && 'line-through' }}
                                    fontWeight={700}
                                  >
                                    {subtask.title}
                                  </Typography>
                                  <Typography fontWeight={400}>{subtask.subtitle}</Typography>
                                </Box>
                              </Stack>
                              {!subtask.isCompleted && (
                                <Button
                                  component={RouterLink}
                                  to={subtask.href}
                                  variant='contained'
                                  sx={{
                                    width: { xs: '100%', md: '15%' },
                                    textWrap: 'nowrap'
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    handleClickCTA(i, subtask.attribute)
                                  }}
                                >
                                  {subtask.buttonTitle}
                                </Button>
                              )}
                              {subtask.isCompleted && (
                                <Typography
                                  fontWeight={600}
                                  fontSize={14}
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    py: { xs: '2%', md: '0.2%' },
                                    px: '1%',
                                    backgroundColor: '#DAEFDF',
                                    color: '#00AA38',
                                    borderRadius: '5px',
                                    width: { xs: '100%', md: '10%' }
                                  }}
                                >
                                  Completed
                                </Typography>
                              )}
                            </MotionStack>
                          )
                        } else {
                          return (
                            <Stack
                              key={j}
                              direction={{
                                xs: 'column',
                                md: 'row'
                              }}
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                borderBottom: '1px solid #D7D9E6',
                                py: 2,
                                cursor: 'pointer'
                              }}
                              spacing={{ xs: 3, md: 0 }}
                              onClick={() => {
                                if (i !== 1) {
                                  navigate(subtask.href)
                                } else {
                                  window.open(subtask.href, '_blank', 'noopener,noreferrer')
                                }
                              }}
                            >
                              <Stack
                                direction='row'
                                spacing={2}
                                sx={{ display: 'flex', alignItems: 'center', p: '1%' }}
                              >
                                <div
                                  onClick={(e) => {
                                    handleClickTaskCompleted(
                                      subtask.attribute,
                                      subtask.isCompleted
                                    )
                                    e.stopPropagation()
                                  }}
                                  onMouseEnter={() => {
                                    setIsHovering(Number(`${i}${j}`))
                                  }}
                                  onMouseLeave={() => {
                                    setIsHovering(null)
                                  }}
                                >
                                  <ListItemIcon
                                    sx={{
                                      minWidth: '0',
                                      color: theme.palette.primary.contrastText,
                                      py: '0px',
                                      '&:active': {
                                        transform: 'scale(0.95)' // Shrink on active
                                      }
                                    }}
                                  >
                                    {subtask.isCompleted || isHovering === Number(`${i}${j}`)
                                      ? OnboardingHubCheckedIcon()
                                      : OnboardingHubUncheckedIcon()}
                                  </ListItemIcon>
                                </div>
                                <Box>
                                  <Typography
                                    sx={{ textDecoration: subtask.isCompleted && 'line-through' }}
                                    fontWeight={700}
                                  >
                                    {subtask.title}
                                  </Typography>
                                  <Typography fontWeight={400}>{subtask.subtitle}</Typography>
                                </Box>
                              </Stack>
                              {!subtask.isCompleted && (
                                <Button
                                  component={RouterLink}
                                  to={subtask.href}
                                  variant='contained'
                                  sx={{
                                    width: { xs: '100%', md: '15%' },
                                    textWrap: 'nowrap'
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    handleClickCTA(i, subtask.attribute)
                                  }}
                                >
                                  {subtask.buttonTitle}
                                </Button>
                              )}
                              {subtask.isCompleted && (
                                <Typography
                                  fontWeight={600}
                                  fontSize={14}
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    py: { xs: '2%', md: '0.2%' },
                                    px: '1%',
                                    backgroundColor: '#DAEFDF',
                                    color: '#00AA38',
                                    borderRadius: '5px',
                                    width: { xs: '100%', md: '10%' }
                                  }}
                                >
                                  Completed
                                </Typography>
                              )}
                            </Stack>
                          )
                        }
                      })}
                    </AccordionDetails>
                  </Accordion>
                )
              })}
            </Stack>
          </Grid>
          <Grid item xs={12} xl={3.5}>
            <Paper elevation={0} sx={{ p: '5%', border: '1px solid #D7D9E6' }}>
              <Stack direction='column' spacing={2}>
                <Stack direction='column' display={{ xs: 'none', md: 'contents' }}>
                  <Paper elevation={3} sx={{ px: '2%', py: '4%' }}>
                    <Stack
                      direction='row'
                      spacing={2}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Stack direction='row' sx={{ display: 'flex', alignItems: 'center' }}>
                        {OnboardingHub.icon(true, '#8181B0', 20)}
                        <Typography fontWeight={600} fontSize={14} mb={1} noWrap>
                          Your Onboarding progress
                        </Typography>
                      </Stack>
                      <Typography
                        color={theme.palette.grey[500]}
                        fontWeight={400}
                        fontSize={14}
                        mb={1}
                        noWrap
                      >
                        {completionPercentage}% complete
                      </Typography>
                    </Stack>
                    <BorderLinearProgress variant='determinate' value={completionPercentage} />
                  </Paper>
                </Stack>
                <Container
                  sx={{
                    overflow: 'hidden',
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '10px'
                  }}
                >
                  {/* <img src='/assets/OnboardingHub-VideoThumbnail.jpeg' width='100%' style={{ scale: '1.2', objectFit: 'fill' }} /> */}
                </Container>
                <Stack spacing={1.2}>
                  <Stack direction='row' spacing={1} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      fontWeight={600}
                      fontSize={14}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        py: '1%',
                        px: '5%',
                        backgroundColor: '#E7F0FF',
                        color: '#3F4FF8',
                        borderRadius: '5px'
                      }}
                    >
                      Recommended
                    </Typography>
                    <Stack
                      spacing={0.5}
                      direction='row'
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <ClockIcon />
                      <Typography>15 mins</Typography>
                    </Stack>
                  </Stack>
                  <Typography fontSize={24} lineHeight={1.2} fontWeight={700}>
                    Getting Started: Master The Platform in Minutes!
                  </Typography>
                  <Typography color={theme.palette.grey[500]}>
                    This guide covers all you need to master the tool and work with confidence.
                  </Typography>
                </Stack>
              </Stack>
            </Paper>
            <Paper
              elevation={0}
              sx={{
                // display: { xs: "none", md: "block" },
                p: '5%',
                border: '2px solid #3F4FF8',
                mt: 3,
                boxShadow: '0px 4px 10px #3F4FF880'
              }}
            >
              <Stack direction='column' spacing={2}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    position: 'relative'
                  }}
                >
                  <Typography
                    alignItems='center'
                    lineHeight={1.3}
                    align='center'
                    fontWeight={600}
                    fontSize={20}
                  >
                    Want to supercharge your experience?
                  </Typography>
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: -12,
                      left: { xs: '31%', sm: '37%', md: '39%', lg: '41%', xl: '30%' }
                    }}
                  >
                    <BlueUnderline />
                  </Box>
                </Box>
                <Button
                  href={configs?.calendlyURL ? configs?.calendlyURL : 'https://calendly.com/d/cmh7-chj-pcz/success-ai-demo-session'}
                  target='_blank'
                  sx={{
                    '&:hover': {
                      backgroundColor: 'inherit',
                      boxShadow: 'none'
                    }
                  }}
                  disableRipple
                  onClick={() => {
                    handleClickCTA(4, onBoardingTasksDefault[4].subtasks[0].attribute)
                  }}
                >
                  <Stack
                    direction='row'
                    justifyContent='center'
                    alignItems='center'
                    gap={2}
                    sx={{
                      background:
                        'linear-gradient(45deg,rgb(52, 192, 235), #3F5DF8,rgb(55, 62, 143))',
                      '&:hover': {
                        background:
                          'linear-gradient(45deg,rgb(14, 187, 240),rgb(34, 69, 243),rgb(32, 43, 163))'
                      },
                      '&:active': {
                        scale: 0.97
                      },
                      borderRadius: 3,
                      padding: '1.2rem 0px',
                      cursor: 'pointer',
                      width: '100%'
                    }}
                  >
                    <Stack direction='row'>
                      <FreeConsultantImage
                        key='freeConsult01'
                        imageLink='/assets/onboardingHub/OH-Consultation01.png'
                        rotate='-10deg'
                      />
                      <FreeConsultantImage
                        key='freeConsult02'
                        imageLink='/assets/onboardingHub/OH-Consultation02.png'
                        rotate='10deg'
                      />
                      <FreeConsultantImage
                        key='freeConsult03'
                        imageLink='/assets/onboardingHub/OH-Consultation03.png'
                        rotate='-6deg'
                      />
                    </Stack>

                    <Typography
                      sx={{
                        fontSize: { xs: '18px', xl: '14px', xxl: '18px' },
                        fontWeight: 500,
                        color: theme.palette.primary.contrastText
                      }}
                    >
                      Schedule A Free Consultation
                    </Typography>
                  </Stack>
                </Button>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12} display={{ xs: 'block', md: 'none' }}>
            <Typography fontSize={20} fontWeight={700} mb={-2}>{`Tasks(${tasks})`}</Typography>
          </Grid>
          <Grid item xs={12} display={{ xs: 'block', md: 'none' }}>
            <Stack direction='column'>
              <Paper elevation={0} sx={{ p: '4%', border: '1px solid #D7D9E6' }}>
                <Stack
                  direction='column'
                  // spacing={1}
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    mb: '10px'
                  }}
                >
                  <Stack direction='row' sx={{ display: 'flex', alignItems: 'center' }}>
                    {OnboardingHub.icon(true, '#8181B0', 20)}
                    <Typography fontWeight={600} fontSize={16} mb={1} noWrap>
                      Your Onboarding Progress
                    </Typography>
                  </Stack>
                  <Typography color={theme.palette.grey[500]} fontWeight={400} fontSize={14} noWrap>
                    {completionPercentage}% complete
                  </Typography>
                </Stack>
                <BorderLinearProgress variant='determinate' value={completionPercentage} />
              </Paper>
            </Stack>
          </Grid>
        </Grid>
        <Dialog
          open={open}
          fullScreen
          PaperProps={{
            style: {
              background: 'linear-gradient(to bottom right, #EEF2FF, #E6E9F5)'
            }
          }}
        >
          <DialogTitle
            sx={{
              textAlign: 'center',
              pt: 4,
              pb: 2
            }}
          >
            <Typography variant='h5' fontWeight='500' sx={{ color: '#1A73E8' }}>
              Create Your Account
            </Typography>
          </DialogTitle>

          <DialogContent>
            <Box
              sx={{
                maxWidth: 720,
                margin: '0 auto',
                px: 2
              }}
            >
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                sx={{
                  mb: 6,
                  '& .MuiStepLabel-root .Mui-active': {
                    color: '#1A73E8'
                  },
                  '& .MuiStepLabel-root .Mui-completed': {
                    color: '#1A73E8'
                  }
                }}
              >
                {['Welcome', 'User Details', 'Submit'].map((label, index) => (
                  <Step key={index}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>

              <StyledCard>
                <CardContent>
                  {activeStep === 0 && (
                    <Box sx={{ textAlign: 'center' }}>
                      <Typography
                        variant='h5'
                        gutterBottom
                        sx={{
                          color: '#1A73E8',
                          fontWeight: 500,
                          mb: 2
                        }}
                      >
                        Welcome!
                      </Typography>
                      <Typography
                        variant='body1'
                        sx={{
                          color: '#666',
                          mb: 4
                        }}
                      >
                        We're excited to have you on board. Let's get started with creating your
                        account.
                      </Typography>
                      <StyledButton
                        onClick={handleNext}
                        variant='contained'
                        endIcon={<ChevronRight size={20} />}
                      >
                        Continue
                      </StyledButton>
                    </Box>
                  )}

                  {activeStep === 1 && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                      <StyledTextField
                        autoFocus
                        label={
                          <>
                            First Name <span style={{ color: 'red' }}>*</span>
                          </>
                        }
                        required
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                          required: false,
                          sx: {
                            position: 'relative',
                            transform: 'none',
                            fontSize: '1rem',
                            fontWeight: 500
                          }
                        }}
                        error={isExceedingLength(firstName)}
                        helperText={isExceedingLength(firstName) ? 'Cannot exceed 15 characters' : ''}
                      />
                      <StyledTextField
                        label={
                          <>
                            Last Name <span style={{ fontSize: '0.8rem' }}>(optional)</span>
                          </>
                        }
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                          sx: {
                            position: 'relative',
                            transform: 'none',
                            fontSize: '1rem',
                            fontWeight: 500
                          }
                        }}
                        error={isExceedingLength(lastName)}
                        helperText={isExceedingLength(lastName) ? 'Cannot exceed 15 characters' : ''}
                      />
                      <StyledTextField
                        label={
                          <>
                            Company Name <span style={{ fontSize: '0.8rem' }}>(optional)</span>
                          </>
                        }
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                          sx: {
                            position: 'relative',
                            transform: 'none',
                            fontSize: '1rem',
                            fontWeight: 500
                          }
                        }}
                        error={isExceedingLength(companyName)}
                        helperText={isExceedingLength(companyName) ? 'Cannot exceed 15 characters' : ''}
                      />
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <StyledButton
                          onClick={handleNext}
                          variant='contained'
                          disabled={!firstName.trim() || isInvalid} // Disabled if empty or invalid
                          endIcon={<ChevronRight size={20} />}
                        >
                          Continue
                        </StyledButton>
                      </Box>
                    </Box>
                  )}

                  {activeStep === 2 && (
                    <Box sx={{ textAlign: 'center' }}>
                      <Typography variant='h5' gutterBottom color='primary'>
                        Almost there!
                      </Typography>
                      <Typography variant='body1' sx={{ mb: 4 }}>
                        Thank you,{' '}
                        <strong>
                          {firstName} {lastName}
                        </strong>
                        ! Click "Submit" to complete your registration.
                      </Typography>
                      <StyledButton onClick={handleSubmit} variant='contained'>
                        Submit
                      </StyledButton>
                    </Box>
                  )}
                </CardContent>
              </StyledCard>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
      <StepCompletitionConfirmation
        open={openTaskCompletedConfirmation}
        setOpen={setOpenTaskCompletedConfirmation}
        attribute={attributeToMark}
        attributeValue={attributeValue}
      />
    </>
  )
}

export default Page

const onBoardingTasksDefault = [
  {
    title: 'Start your free search',
    subtasks: [
      {
        title: 'Make a free search on lead finder',
        subtitle: 'Search through 700M leads that perfectly fits your goals.',
        isCompleted: false,
        buttonTitle: 'Make a free search',
        href: '/leadFinder',
        attribute: 'lead_finder_search',
        isAnimated: true
      }
    ]
  },
  {
    title: 'Schedule a Free Consultation',
    subtasks: [
      {
        title: 'Schedule a free call and set yourself ahead',
        subtitle:
          'Connect with our team for a personalized walkthrough of the platform. We’ll help you set up, answer your questions, and ensure you’re on the right track.',
        isCompleted: false,
        buttonTitle: 'Schedule Now',
        href: 'https://calendly.com/d/cmh7-chj-pcz/success-ai-demo-session',
        attribute: 'consultation_clicked'
      }
    ]
  },
  {
    title: 'Connect Mailboxes',
    subtasks: [
      {
        title: 'Link and existing email box to your account',
        subtitle: 'Securely integrate your email to start sending and tracking communications.',
        isCompleted: false,
        buttonTitle: 'Connect Mailboxes',
        href: '/accounts',
        attribute: 'email_account_linked'
      }
    ]
  },
  {
    title: 'Create Campaign',
    subtasks: [
      {
        title: 'Add your contacts',
        subtitle: 'Import or add your list of leads or clients to begin your outreach.',
        isCompleted: false,
        buttonTitle: 'Add Now',
        href: '/campaigns',
        attribute: 'contacts_added'
      },
      {
        title: 'Create a sequence',
        subtitle: 'Plan and build your automated email flow to engage your audience effectively.',
        isCompleted: false,
        buttonTitle: 'Create Now',
        href: '/campaigns',
        attribute: 'sequences_created'
      },
      {
        title: 'Set your sending schedule',
        subtitle: 'Choose when and how often your campaign emails should be sent.',
        isCompleted: false,
        buttonTitle: 'Schedule Now',
        href: '/campaigns',
        attribute: 'sending_schedule_created'
      },
      {
        title: 'Finalize your campaign options',
        subtitle: 'Review your setup and customize key details before launch.',
        isCompleted: false,
        buttonTitle: 'Check Now',
        href: '/campaigns',
        attribute: 'campaign_reviewed'
      },
      {
        title: 'Hit launch button!',
        subtitle: 'Go live and begin your outreach with your configured email sequence.',
        isCompleted: false,
        buttonTitle: 'Launch Now',
        href: '/campaigns',
        attribute: 'campaign_launched'
      }
    ]
  },
  {
    title: 'Invite Team Members (optional)',
    subtasks: [
      {
        title: 'Invite your team members to join your workspace',
        subtitle: 'Collaborate by adding team members to manage and execute campaigns together.',
        isCompleted: false,
        buttonTitle: 'Invite Now',
        href: '/settingsUpdated',
        attribute: 'team_members_invited'
      }
    ]
  }

]

const FreeConsultantImage = ({ imageLink, rotate }) => {
  return (
    <Box
      sx={{
        width: 25,
        height: 25,
        outline: '3px solid #FFFFFF',
        overflow: 'hidden',
        borderRadius: 1.5,
        transform: `rotate(${rotate})`
      }}
    >
      <img src={imageLink} style={{ width: '100%' }} />
    </Box>
  )
}

const AnimatedStack = styled(motion(Stack))({
  width: '100%',
  height: 'fit-content',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: 5,
  cursor: 'pointer',
  borderRadius: 8,
  background:
    'linear-gradient(-45deg,rgb(255, 255, 255),rgb(195, 228, 255),rgb(255, 255, 255),rgb(192, 224, 255))',
  backgroundSize: '400% 400%',
  animation: 'moveGradient 10s ease infinite',
  '@keyframes moveGradient': {
    '0%': { backgroundPosition: '0% 50%' },
    '50%': { backgroundPosition: '100% 50%' },
    '100%': { backgroundPosition: '0% 50%' }
  }
})

const MotionStack = ({ children, ...props }) => {
  return (
    <AnimatedStack
      direction={{
        xs: 'column',
        md: 'row'
      }}
      spacing={{ xs: 3, md: 0 }}
      whileHover={{
        boxShadow: '0px 5px 30px rgba(122, 175, 255, 0.42)', // Blue shadow
        scale: 1.001 // Slightly increase size
      }}
      transition={{ duration: 0.3 }} // Smooth transition
      {...props}
    >
      {children}
    </AnimatedStack>
  )
}
