import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { Layout as DashboardLayout } from './layouts/dashboard/layout'
import IconsPage from './pages/icons'
import NotFoundPage from './pages/404'
import OrdersPage from './pages/orders'
import SettingsPage from './pages/settings'
// import LeadsFinderPage from './pages/leadsFinder.js'
import OnboardingHub from './pages/onboardingHub'
import LeadsFinderPageNew from './pages/leadsFinderNew'

import EmailAccountsPage from './pages/emailAccounts.js'
import ConnectEmailPage from './components/emailAccounts/connect.js'
import AddNewCampaignPage from './components/campaigns/addNewCampaign.js'
import CampaignsPage from './pages/campaigns.js'
import OrderStatus from './pages/dfyDomains'
import SupportPage from './pages/support.js'
import TrialExpiredPage from './components/settings/TrialExpiredPage.js'
import IntegrationsPage from './pages/integrations.js'
import InboxHub from './pages/InboxHub.js'
import AnalyticsPage from './pages/analytics.js'
import CampaignDetailsPage from './pages/campaignDetails.js'
import ThemePage from './pages/theme'
import LoginPage from './pages/auth/login'
import CnamePage from './pages/tools/cname'
import DmarkCheckerPage from './pages/tools/dmarkChecker'
import SPFCheckerPage from './pages/tools/spfChecker'
import SSLCheckerPage from './pages/tools/sslChecker'
import DkimRecordPage from './pages/tools/dkimRecored'
import DkimRecoredGeneratorPage from './pages/tools/dkimRecoredGenerator'
import DomainIpBlockListPage from './pages/tools/domainIpBlockList'
import SPFRecordGeneratorPage from './pages/tools/spfRecordGenerator'
import DmarcRecordGeneratorPage from './pages/tools/dmarcRecordGeneratorPage'
import EmailVerificationPage from './pages/tools/emailVerifier'
import EmailOpenRateCalculatorPage from './pages/tools/emailOpenRateCalculator'
import EmailClickToOpenRateCalculator from './pages/tools/emailClickToOpenRateCalculator'
import SpamComplaintReductionRateCalculator from './pages/tools/spamComplaintReductionRateCalculator'
import EmailVerificationCalculator from './pages/tools/emailVerificationCalculator'
import EmailBounceRateCalculator from './pages/tools/emailBounceRateCalculator'
import GrammarChecker from './pages/tools/grammerChecker'
import EmailCopyWriter from './pages/tools/emailCopyWriter'
import EmailsubjectLineAnalyzer from './pages/tools/emailsubjectLineAnalyzer'
import SpamChecker from './pages/tools/spamChecker'
import EmailSubjectLineGenerator from './pages/tools/emailsubjectLineGenerator'
import TwoFaVerification from './pages/auth/twoFaVerification'
import RegisterPage from './pages/auth/register'
import ForgetPassword from './pages/auth/forgetPassword'
import VerifyPage from './pages/auth/verify.js'
import VerifyEmailAddressPage from './components/auth/verifyEmailAddress.js'
import VerifyReplyEmail from './components/auth/verifyReplyEmail'
import isLoggedIn from './utils/is-logged-in.js'
import MicrosoftRedirect from './pages/oauth/microsoftRedirect.js'
import GoogleRedirect from './pages/oauth/googleRedirect.js'
import ThankYou from './pages/thankyou'
import CrmPage from './pages/crmPage.js'
// import InboxCrm from './pages/inboxCrm.js'
import Unsubscribe from './pages/unsubscribe'
import AppsumoValidationPage from './pages/auth/validateAppSumo'
import { removeAuthToken } from './services/auth-service'
import AccountMessage from './pages/oauth/accountMessage'
import LoginProtection from './loginProtection'
import CreateWorkspace from './components/workSpaces/CreateWorkspace'
import EnterpriseAccess from './pages/enterpriseAccess'
import EnterpriseRoute from './utils/is-enterprise-user'
import EnterprisePlanRoute from './utils/is-enterprise-user-plan'
import Maintenance from './Maintenance'
import AddNew from './pages/AddNew'
import { paths } from './paths'
// import useWorkspaces from './utils/useWorkspaces.js'
import Invitation from './components/workSpaces/Invitation'
import ViewerRoute from './utils/is-viewer'
import PrivateRoute from './utils/PrivateRoute'
import { ResetPassword } from './pages/auth/resetPassword'
import ZapierInstructions from './pages/zapier-instructions'
import HubspotIntegrationPage from './pages/hubspotIntegrationPage'
import DealPipeline from './pages/deal-pipeline'
import SignupConfirmation from './utils/SignupConfirmation'
import PlanConfirmation from './utils/PlanConfirmation'
import FreeForeverConfirmation from './utils/FreeForeverConfirmation'
import TrialConfirmation from './utils/TrialConfirmation'
// import LeadsListView from "./pages/leadsListPage";

import LeadsListView from './pages/leadsListPage'
import ThemeSettings from './pages/userThemeSetting'
import PartnerRoute from './utils/is-partner-account'
import ManageAccount from './pages/manageAccount'
import SettingsUpdated from './pages/Settings_updated'
// import LeadsListView from "./pages/leadsListPage";
import WhiteLabelPlans from './pages/whiteLabelPlans'
import WhiteLabelLoginPage from './pages/auth/whiteLabelLogin'
import WhiteLabelRegisterPage from './pages/auth/whiteLabelRegister'
import SuccessAIRoute from './utils/is-successai-user'
import HubspotSettingsPage from './pages/hubspot/HubspotSettings'
import EmailValidation from './pages/emailValidation'
import SignupFlowBillingPage from './components/settings/SignupFlowBillingPage'
import LinkedinAuthMessage from './components/linkedinAuthMessage'

const DashboardWrapper = () => {
  // useWorkspaces()

  return (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  )
}

const isValidateRoute = window.location.pathname === '/validate'
if (isValidateRoute && isLoggedIn()) {
  removeAuthToken()
  window.location.reload()
}

// const PrivateRoute = () => (isLoggedIn() ? <Outlet /> : <Navigate to="/login" />);
const PublicRoute = () => (isLoggedIn() ? <Navigate to='/' /> : <Outlet />)

export const routes = (
  <Routes>
    <Route element={<PrivateRoute />}>
      <Route path='/' element={<DashboardWrapper />}>
        <Route path='/' element={<Navigate to='accounts' />} />
        <Route path='leadFinder' element={<ViewerRoute element={LeadsFinderPageNew} />} />
        <Route path='lists' element={<ViewerRoute element={LeadsListView} />} />
        {/* <Route path='leadFinderNew' element={<ViewerRoute element={LeadsFinderPageNew} />} /> */}
        <Route path='accounts' element={<EmailAccountsPage />} />
        <Route path='campaigns' element={<CampaignsPage />} />
        <Route
          path='campaigns/:id'
          element={<EnterprisePlanRoute element={CampaignDetailsPage} />}
        />
        <Route path='analytics' element={<AnalyticsPage />} />
        {/* <Route path='inboxhub' element={<UniboxPage />} /> */}
        <Route path='inboxhub' element={<InboxHub />} />
        <Route path='dealpipeline' element={<DealPipeline />} />
        <Route path='crmPage' element={<CrmPage />} />
        {/* <Route path='inboxCrm' element={<InboxCrm />} /> */}
        <Route path='orders' element={<OrdersPage />} />
        {/* <Route path='settings/billing' element={<SettingsPage tab='billing' />} /> */}
        <Route path='settings/profile' element={<SettingsPage tab='profile' />} />
        <Route path='settings/blocklist' element={<SettingsPage tab='blocklist' />} />
        <Route path='settingsUpdated' element={<SettingsUpdated />} />{' '}
        {/* not white-label user */}
        <Route path='support' element={<SuccessAIRoute element={SupportPage} />} />{' '}
        {/* not white-label user */}
        <Route path={paths.emailAccounts.orderStatus} element={<OrderStatus />} />
        <Route path={paths.emailAccounts.add} element={<AddNew />} />
        <Route path='integrations' element={<SuccessAIRoute element={IntegrationsPage} />} />{' '}
        {/* not white-label user */}
        <Route path='theme' element={<ThemePage />} />
        <Route path='icons' element={<IconsPage />} />{' '}
        <Route path='accounts/connect' element={<ConnectEmailPage />} />
        <Route path='new/workSpace/create' element={<CreateWorkspace />} />
        <Route path='enterprise' element={<EnterpriseRoute element={EnterpriseAccess} />} />
        <Route path='manage-users' element={<PartnerRoute element={ManageAccount} />} />
        <Route path='branding-management' element={<PartnerRoute element={ThemeSettings} />} />
        <Route path='plans' element={<PartnerRoute element={WhiteLabelPlans} />} />
        <Route path='onboardinghub' element={<OnboardingHub />} />
        <Route path='email-validation' element={<EmailValidation />} />
        <Route path='integrations/hubspot-settings' element={<HubspotSettingsPage element={IntegrationsPage} />} />
      </Route>

      {/* <Route path='workSpace/billing' element={<SettingsPage tab='billing' />} /> */}
      <Route path='workSpace/billing' element={<SettingsUpdated tab='billing' />} />
      <Route path='settingsUpdated/expired' element={<TrialExpiredPage />} />
      <Route path='settingsUpdated/signup/billing' element={<SignupFlowBillingPage />} />
      <Route path='campaigns/create' element={<AddNewCampaignPage />} />
      <Route path='loginProtection' element={<LoginProtection />} />
    </Route>
    <Route path='oauth/microsoft/redirect' element={<MicrosoftRedirect />} />
    <Route path='oauth/google/redirect' element={<GoogleRedirect />} />
    <Route element={<PublicRoute />}>
      <Route path='login' element={<LoginPage />} />
      <Route path='cname' element={<CnamePage />} />
      <Route path='spf-checker' element={<SPFCheckerPage />} />
      <Route path='dmark-checker' element={<DmarkCheckerPage />} />
      <Route path='ssl-checker' element={<SSLCheckerPage />} />
      <Route path='dkim-record-lookup' element={<DkimRecordPage />} />
      <Route path='dkim-record-generator' element={<DkimRecoredGeneratorPage />} />
      <Route path='spf-record-generator' element={<SPFRecordGeneratorPage />} />
      <Route path='domain-ip-blacklist-checker' element={<DomainIpBlockListPage />} />
      <Route path='dmarc-record-generator' element={<DmarcRecordGeneratorPage />} />
      <Route path='email-verificaton' element={<EmailVerificationPage />} />
      <Route path='blacklist-checker-tool' element={<DomainIpBlockListPage />} />
      <Route path='email-open-rate-calculator' element={<EmailOpenRateCalculatorPage />} />
      <Route path='click-to-open-rate-calculator' element={<EmailClickToOpenRateCalculator />} />
      <Route
        path='spam-complaint-rate-calculator'
        element={<SpamComplaintReductionRateCalculator />}
      />
      <Route path='email-verification-calculator' element={<EmailVerificationCalculator />} />
      <Route path='email-bounce-rate-calculator' element={<EmailBounceRateCalculator />} />
      <Route path='grammar-checker' element={<GrammarChecker />} />
      <Route path='email-copywriter' element={<EmailCopyWriter />} />
      <Route path='email-subject-line-generator' element={<EmailSubjectLineGenerator />} />
      <Route path='email-subject-line-analyser' element={<EmailsubjectLineAnalyzer />} />
      <Route path='spam-checker' element={<SpamChecker />} />
      <Route path='two-factor-authentication' element={<TwoFaVerification />} />
      <Route path='validate' element={<AppsumoValidationPage />} />
      <Route path='forget-password' element={<ForgetPassword />} />
      <Route path='w-login' element={<WhiteLabelLoginPage />} />
    </Route>
    <Route path='invitation' element={<Invitation />} />
    <Route path='register/:email' element={<RegisterPage />} />
    <Route path='register' element={<RegisterPage />} />
    <Route path='register/verify-email' element={<VerifyEmailAddressPage />} />
    <Route path='register/Maintenance' element={<Maintenance />} />
    <Route path='reset-password' element={<ResetPassword />} />

    <Route path='verifyReplyEmail' element={<VerifyReplyEmail />} />
    <Route path='verify' element={<VerifyPage />} />
    <Route path='404' element={<NotFoundPage />} />
    <Route path='*' element={<NotFoundPage />} />
    <Route path='thankyou' element={<ThankYou />} />
    <Route path='unsubscribe' element={<Unsubscribe />} />
    <Route path='accountMessage/:messageData' element={<AccountMessage />} />
    <Route path='integrations/zapier-instructions' element={<ZapierInstructions />} />
    <Route path='hubspot-integration' element={<HubspotIntegrationPage />} />
    <Route path=':planName-confirmation' element={<PlanConfirmation />} />
    <Route path='signup-confirmation' element={<SignupConfirmation />} />
    <Route path='free-forever-confirmation' element={<FreeForeverConfirmation />} />
    <Route path='trial-confirmation' element={<TrialConfirmation />} />
    <Route path='w-register' element={<WhiteLabelRegisterPage />} />
    <Route path='linkedinAuth-success-callkback' element={<LinkedinAuthMessage message="Account Connected Successfully" success={true} />} />
    <Route path='linkedinAuth-failure-callkback' element={<LinkedinAuthMessage message="Something went Wrong" success={false} />} />
  </Routes>
)
