import React, { useState } from 'react'
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Checkbox,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  useTheme
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import taost from 'react-hot-toast'
import { useLocation } from 'react-router-dom'

import { v4 as uuidv4 } from 'uuid' // Use UUID for unique batch ID
import { OffCheckboxCustomIcon } from 'src/assets/general/OffCheckboxCustomIcon'
import { OnCheckboxCustomIcon } from 'src/assets/general/OnCheckboxCustomIcon'
import {
  useCreateLeadsMutation,
  useDuplicateCheckMutation,
  useGetCampaignsQuery
} from 'src/services/campaign-service'
import { useAddLeadsToListMutation } from 'src/services/leads-service'
import usePlan from 'src/hooks/usePlan'

const AddNewDealModal = ({ isOpen, onClose, onCreateDeal, workspaceId, updateDealInPipelineData }) => {
  const paramsLocation = useLocation()
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [location, setLocation] = useState('')
  const [iceBreaker, setIceBreaker] = useState('')
  const [website, setWebsite] = useState('')
  const [dealValue, setDealValue] = useState('')
  const [customVariables, setCustomVariables] = useState([{ key: '', value: '' }])
  const [includeInList, setIncludeInList] = useState(false)
  const [includeInCampaign, setIncludeInCampaign] = useState(false)
  const [selectedList, setSelectedList] = useState('')
  const [selectedCampaign, setSelectedCampaign] = useState('')
  const theme = useTheme()

  const searchParams = new URLSearchParams(paramsLocation.search)
  const labelId = searchParams.get('labelId')
  const { planDetails } = usePlan()

  const { data: { updatedEmail: campaigns } = {} } = useGetCampaignsQuery({
    unibox: true,
    option: true,
    workspaceId
  })

  // const { data: { docs: lists } = {} } = useGetListsQuery({ workspaceId });
  const [createLeads] = useCreateLeadsMutation()
  const [duplicateCheck] = useDuplicateCheckMutation()
  const [addLeadsToList] = useAddLeadsToListMutation()

  const handleCreateDeal = async () => {
    if (!email.trim() || !/\S+@\S+\.\S+/.test(email)) {
      taost.error('A valid email address is required.')
      return
    }

    // Check if either a campaign or a list is selected
    if (!includeInCampaign && !includeInList) {
      taost.error('Please include the deal in at least one campaign or list.')
      return
    }

    if (includeInCampaign && !selectedCampaign) {
      taost.error('Please select a campaign.')
      return
    }

    if (includeInList && !selectedList) {
      taost.error('Please select a list.')
      return
    }

    const payload = {
      email,
      firstName,
      lastName,
      companyName,
      location,
      iceBreaker,
      website,
      valuePerDeal: dealValue,
      ...(labelId && { label: labelId, addedFromDealPipeline: true }),
      variables: customVariables
        .map(({ key, value }) => ({
          variableTitle: key.trim(),
          variableValue: value
        }))
        .filter((variable) => variable.variableTitle)
    }

    let createdLeads = []
    const leadId = uuidv4()

    try {
      if (includeInCampaign && selectedCampaign) {
        const duplicateCheckResponse = await duplicateCheck({
          id: selectedCampaign,
          data: {
            leads: [payload],
            checkDuplicates: true,
            stats: true,
            workspaceId
          }
        }).unwrap()

        createdLeads = duplicateCheckResponse?.createdLeads?.leads

        const { message, createdLeads: newLead } = await createLeads({
          id: selectedCampaign,
          data: {
            leads: createdLeads,
            verifyLeads: false,
            workspaceId
          }
        }).unwrap()

        taost.success(message)

        // optimistically update the UI
        if (labelId && newLead && newLead.length > 0) {
          updateDealInPipelineData({
            type: 'add',
            deal: { ...newLead[0], name: `${newLead[0].firstName} ${newLead[0].lastName}` },
            newColumnId: labelId
          })
        }
      }

      if (includeInList && selectedList) {
        const payloadForList = {
          email,
          first_name: firstName,
          last_name: lastName,
          organization: { name: companyName, wesbite: website },
          country: location,
          iceBreaker
        }

        const batchId = uuidv4()
        const newPayload = [{ id: leadId, ...payloadForList }]
        const leadsToSend = newPayload
        const leadsToSendIds = leadsToSend.map((lead) => lead.id)

        try {
          const { message } = await addLeadsToList({
            listId: selectedList,
            leads: leadsToSendIds,
            checkDuplicates: includeInCampaign,
            workspaceId,
            dataSelected: leadsToSend,
            batchId
          }).unwrap()

          taost.success(message)
        } catch (error) {
          console.error('Error processing chunk:', error)
          taost.error(error.message)
        }
      }
    } catch (error) {
      taost.error(error.message)
    }

    onCreateDeal(payload)

    // Reset fields after submission
    setEmail('')
    setFirstName('')
    setLastName('')
    setCompanyName('')
    setLocation('')
    setIceBreaker('')
    setWebsite('')
    setDealValue('')
    setCustomVariables([{ key: '', value: '' }])
    setIncludeInList(false)
    setIncludeInCampaign(false)
    setSelectedList('')
    setSelectedCampaign('')
  }

  const handleCustomVariableChange = (index, field, value) => {
    const updatedVariables = [...customVariables]
    updatedVariables[index][field] = value
    setCustomVariables(updatedVariables)
  }

  const handleDeleteCustomVariable = (index) => {
    if (customVariables.length === 1) {
      setCustomVariables([{ key: '', value: '' }])
    } else {
      setCustomVariables(customVariables.filter((_, i) => i !== index))
    }
  }

  const addCustomVariable = () => {
    const lastVariable = customVariables[customVariables.length - 1]
    if (!lastVariable.key.trim() && !lastVariable.value.trim()) {
      taost.error('Please fill in at least one field of the current custom variable before adding a new one.')
      return
    }
    setCustomVariables([...customVariables, { key: '', value: '' }])
  }

  // const handleListChange = (listId) => {
  //   setSelectedList(listId);
  // };

  const handleCampaignChange = (campaignId) => {
    setSelectedCampaign(campaignId)
  }

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          bgcolor: 'background.paper',
          borderRadius: '12px',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
          p: 3
        }}
      >
        {/* Header */}
        <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
          <Typography variant='h6' fontWeight='600' sx={{ color: '#111827' }}>
            Add new deal
          </Typography>
          <IconButton onClick={onClose} size='small'>
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Input Fields */}
        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            fullWidth
            variant='outlined'
            size='small'
            type='email'
            required
            placeholder="Person's Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#E4E4E5'
                },
                '&:hover fieldset': {
                  borderColor: theme.palette.primary.main
                }
              }
            }}
          />
          <TextField
            fullWidth
            variant='outlined'
            size='small'
            placeholder='First Name'
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#E4E4E5'
                },
                '&:hover fieldset': {
                  borderColor: theme.palette.primary.main
                }
              }
            }}
          />
        </Box>

        <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
          <TextField
            fullWidth
            variant='outlined'
            size='small'
            placeholder='Last Name'
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#E4E4E5'
                },
                '&:hover fieldset': {
                  borderColor: theme.palette.primary.main
                }
              }
            }}
          />
          <TextField
            fullWidth
            variant='outlined'
            size='small'
            placeholder='Company Name'
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#E4E4E5'
                },
                '&:hover fieldset': {
                  borderColor: theme.palette.primary.main
                }
              }
            }}
          />
        </Box>

        <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
          <TextField
            fullWidth
            variant='outlined'
            size='small'
            placeholder='Location'
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#E4E4E5'
                },
                '&:hover fieldset': {
                  borderColor: theme.palette.primary.main
                }
              }
            }}
          />
          <TextField
            fullWidth
            variant='outlined'
            size='small'
            placeholder='Ice Breaker'
            value={iceBreaker}
            onChange={(e) => setIceBreaker(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#E4E4E5'
                },
                '&:hover fieldset': {
                  borderColor: theme.palette.primary.main
                }
              }
            }}
          />
        </Box>

        <TextField
          fullWidth
          variant='outlined'
          size='small'
          placeholder='Website'
          value={website}
          onChange={(e) => setWebsite(e.target.value)}
          sx={{
            mt: 2,
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#E4E4E5'
              },
              '&:hover fieldset': {
                borderColor: theme.palette.primary.main
              }
            }
          }}
        />
        <TextField
          fullWidth
          variant='outlined'
          size='small'
          placeholder='Deal Value'
          value={dealValue}
          onChange={(e) => {
            const value = e.target.value
            const numValue = Number(value)
            if (value === '' || (numValue >= 0 && !isNaN(numValue))) {
              setDealValue(value)
            }
          }}
          InputProps={{
            startAdornment: (
              <Typography variant='body2' sx={{ mr: 1 }}>
                $
              </Typography>
            )
          }}
          sx={{
            mt: 2,
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#E4E4E5'
              },
              '&:hover fieldset': {
                borderColor: theme.palette.primary.main
              }
            }
          }}
        />

        {/* Custom Variables */}
        {planDetails?.isInifinityPlan && (
          <Box mt={3} mb={3}>
            <Typography variant='subtitle1' sx={{ mb: 1 }}>
              Custom Variables
            </Typography>
            {customVariables.map((variable, index) => (
              <Box display='flex' alignItems='center' gap={2} mb={1} key={index}>
                <TextField
                  fullWidth
                  variant='outlined'
                  size='small'
                  placeholder='Key'
                  value={variable.key}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#E4E4E5'
                      },
                      '&:hover fieldset': {
                        borderColor: theme.palette.primary.main
                      }
                    }
                  }}
                  onChange={(e) => handleCustomVariableChange(index, 'key', e.target.value)}
                />
                <TextField
                  fullWidth
                  variant='outlined'
                  size='small'
                  placeholder='Value'
                  value={variable.value}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#E4E4E5'
                      },
                      '&:hover fieldset': {
                        borderColor: theme.palette.primary.main
                      }
                    }
                  }}
                  onChange={(e) => handleCustomVariableChange(index, 'value', e.target.value)}
                />
                {customVariables.length > 1 && (
                  <IconButton onClick={() => handleDeleteCustomVariable(index)}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
            ))}
            <Button
              onClick={addCustomVariable}
              sx={{ textTransform: 'none', color: '#3F50F8', fontSize: '14px' }}
            >
              + Add Custom Variable
            </Button>
          </Box>
        )}

        {/* Include Options */}
        {/* TODO -Include list - commented - for next release */}
        {/* {lists && lists.length > 0 && (
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  size='small'
                  icon={<OffCheckboxCustomIcon />}
                  checkedIcon={<OnCheckboxCustomIcon />}
                  checked={includeInList}
                  onChange={(e) => setIncludeInList(e.target.checked)}
                  sx={{
                    color: '#3F50F8',
                    '&.Mui-checked': { color: '#3F50F8' }
                  }}
                />
              }
              label='Include in List'
            />
            {includeInList && (
              <FormControl fullWidth size='small' sx={{ mt: 1 }}>
                <Select
                  value={selectedList}
                  onChange={(e) => handleListChange(e.target.value)}
                  displayEmpty
                  sx={{
                    mb: 2,
                    '& .MuiOutlinedInput-root': { borderRadius: '8px' }
                  }}
                >
                  <MenuItem value='' disabled>
                    Select a list
                  </MenuItem>
                  {lists.map((list) => (
                    <MenuItem key={list._id} value={list._id}>
                      {list.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>
        )} */}

        {campaigns && campaigns.length > 0 && (
          <Box mb={3}>
            <FormControlLabel
              control={
                <Checkbox
                  size='small'
                  icon={<OffCheckboxCustomIcon />}
                  checkedIcon={<OnCheckboxCustomIcon />}
                  checked={includeInCampaign}
                  onChange={(e) => setIncludeInCampaign(e.target.checked)}
                  sx={{
                    color: '#3F50F8',
                    '&.Mui-checked': { color: '#3F50F8' }
                  }}
                />
              }
              label='Include in Campaign'
            />
            {includeInCampaign && (
              <FormControl fullWidth size='small' sx={{ mt: 1 }}>
                <Select
                  value={selectedCampaign}
                  onChange={(e) => handleCampaignChange(e.target.value)}
                  displayEmpty
                  sx={{
                    '& .MuiOutlinedInput-root': { borderRadius: '8px' }
                  }}
                >
                  <MenuItem value='' disabled>
                    Select a campaign
                  </MenuItem>
                  {campaigns.map((campaign) => (
                    <MenuItem key={campaign._id} value={campaign._id}>
                      {campaign.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>
        )}

        {/* Actions */}
        <Box display='flex' justifyContent='flex-end' gap={2}>
          <Button onClick={onClose} variant='outlined'>
            Cancel
          </Button>
          <Button onClick={handleCreateDeal} variant='contained'>
            Create deal
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default AddNewDealModal
