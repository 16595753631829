import React from 'react'
import { Box, Typography, Button, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import LockIcon from '@mui/icons-material/Lock'
import SvgRenderer from '../SvgRenderer'

import LinkClickedSvg from 'src/assets/dealPipeline/link-clicked.svg'
import EmailOpenedSvg from 'src/assets/dealPipeline/email-opened.svg'

const UpgradeAccessModal = () => {
  const activities = [
    {
      icon: <SvgRenderer src={LinkClickedSvg} width='40' height='40' alt='icon' />,
      title: 'Link Clicked',
      description: 'Welcome to 828 Bocket - Heigth eC',
      timestamp: '23 May 2025 • 1:50 PM'
    },
    {
      icon: <SvgRenderer src={EmailOpenedSvg} width='40' height='40' alt='icon' />,
      title: 'Email Opened',
      description: 'How to Develop Your Professional Network?',
      timestamp: '15 May 2025 • 11:41 AM'
    }
  ]

  const handleUpgrade = () => {
    window.location.href = '/settingsUpdated'
  }

  return (
    <Box
      sx={{
        width: '400px',
        margin: 'auto',
        padding: '24px',
        backgroundColor: '#FFFFFF',
        borderRadius: '12px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        border: '2px solid #3F4FF8'
      }}
    >
      {/* Lock Icon and Title */}
      <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
        <Box display='flex' justifyContent='center' alignItems='center' mb={3}>
          <LockIcon sx={{ color: '#3F50F8', fontSize: '32px' }} />
        </Box>
        <Typography variant='h6' fontWeight='600' sx={{ color: '#111827', mb: 2 }}>
          Upgrade to access activity
        </Typography>
      </Box>

      {/* Activity List */}
      <List
        sx={{
          padding: '0px 4px'
        }}
      >
        {activities.map((activity, index) => (
          <ListItem
            key={index}
            sx={{
              backgroundColor: '#F9FAFB',
              display: 'flex',
              borderRadius: '8px',
              padding: '8px 12px',
              mb: 2,
              alignItems: 'center'
            }}
          >
            <ListItemIcon>{activity.icon}</ListItemIcon>
            <ListItemText
              primary={
                <Typography variant='body1' fontWeight='600' sx={{ color: '#111827' }}>
                  {activity.title}
                </Typography>
              }
              secondary={
                <>
                  <Typography variant='body2' sx={{ color: '#6B7280' }}>
                    {activity.description}
                  </Typography>
                  <Typography variant='caption' sx={{ color: '#6B7280' }}>
                    {activity.timestamp}
                  </Typography>
                </>
              }
            />
          </ListItem>
        ))}
      </List>

      {/* Description */}
      <Typography variant='body2' sx={{ color: '#6B7280', mb: 3 }}>
        Activity view provides a comprehensive timeline of each lead's interactions over time.
      </Typography>

      {/* Upgrade Button */}
      <Button
        variant='contained'
        onClick={handleUpgrade}
        sx={{
          textTransform: 'none',
          backgroundColor: '#3F50F8',
          color: '#FFFFFF',
          fontWeight: '600',
          padding: '10px 16px',
          borderRadius: '8px',
          '&:hover': {
            backgroundColor: '#3B49E1'
          }
        }}
      >
        Upgrade your plan
      </Button>
    </Box>
  )
}

export default UpgradeAccessModal
