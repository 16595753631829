import { Chip, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useState } from 'react'
import DownloadIcon from '@mui/icons-material/Download'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, styled } from '@mui/system'
import VerifyListStatsDialog from './verifyListStatsDialog'
import { toast } from 'react-hot-toast'
import { useDeleteEmailBatchMutation, useLazyDownloadEmailBatchQuery, useLazyGetEmailBatchQuery } from 'src/services/email-validation-service'

// Styled components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'medium',
  color: theme.palette.text.secondary
}))

const VerifyListHistoryTable = ({ allEmailBatches }) => {
  const [selectedStats, setSelectedStats] = useState(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [deleteBatch] = useDeleteEmailBatchMutation() // delete data of single batch
  const [fetchBatchDetails] = useLazyGetEmailBatchQuery() // get data of single batch
  const [fetchBatchResult] = useLazyDownloadEmailBatchQuery() // Fetch batch download data

  const handleRowClick = async (row) => {
    try {
      const res = await fetchBatchDetails(row?.batchId)
      setSelectedStats({
        deliverable: res?.data?.data?.stats?.deliverable || 0,
        risky: res?.data?.data?.stats?.risky || 0,
        undeliverable: res?.data?.data?.stats?.undeliverable || 0,
        unknown: res?.data?.data?.stats?.unknown || 0
        // toxicityScore1: 5,
        // toxicityScore2: 7,
        // toxicityScore3: 10,
        // toxicityScore4: 0,
        // toxicityScore5: 0,
        // timestamp: row.created
      })
      setDialogOpen(true)
    } catch (error) {
      console.error(error)
    }
  }

  const handleDeleteBatch = async (batchId) => {
    try {
      await deleteBatch(batchId).unwrap()
      toast.success('Batch deleted successfully!')
    } catch (error) {
      toast.error(error?.data?.error?.message ?? 'Something went wrong while deleting the batch')
      throw error
    }
  }

  const handleDownloadBatch = async (batchId) => {
    try {
      const response = await fetchBatchResult(batchId).unwrap()

      if (!response || !response.data) {
        toast.error('No data received for batch result.')
        return
      }

      const csvData = convertToCSV(response.data)
      triggerCSVDownload(csvData, `batch-${batchId}.csv`)
    } catch (error) {
      toast.error(error?.data?.error?.message ?? 'Something went wrong while downloading')
      throw error
    }
  }

  // Convert JSON to CSV format
  const convertToCSV = (data) => {
    const headers = ['Email', 'status', 'reason', 'fullMailBox', 'role', 'domain name', 'DNS Record', 'accepetedAll', 'free', 'provider', 'score', 'toxicity']
    const rows = data.map(({ email, status, reason, account, domain, dns, provider, score, toxic }) => [
      email,
      status,
      reason || 'N/A',
      account.fullMailbox || 'N/A',
      account.role || 'N/A',
      domain.name || 'N/A',
      dns.record || 'N/A',
      domain.acceptAll || 'N/A',
      domain.free || 'N/A',
      provider || 'N/A',
      score || 'N/A',
      toxic || 'N/A'
    ])

    const csvContent = [headers, ...rows].map((row) => row.join(',')).join('\n')
    return csvContent
  }

  // Create and trigger CSV file download
  const triggerCSVDownload = (csvData, filename) => {
    const blob = new Blob([csvData], { type: 'text/csv' })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <>
      <TableContainer component={Paper} sx={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead sx={{ backgroundColor: '#F9FAFB' }}>
            <TableRow>
              <StyledTableCell>NAME</StyledTableCell>
              <StyledTableCell>EMAILS</StyledTableCell>
              <StyledTableCell>STATUS</StyledTableCell>
              <StyledTableCell>CREATED</StyledTableCell>
              <StyledTableCell>STARTED</StyledTableCell>
              <StyledTableCell>COMPLETED</StyledTableCell>
              <StyledTableCell>ACTIONS</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allEmailBatches.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  cursor: 'pointer',
                  '&:hover': { backgroundColor: '#F9FAFB' }
                }}
                onClick={() => handleRowClick(row)}
              >
                <TableCell component='th' scope='row'>
                  {row.batchName}
                </TableCell>
                <TableCell>{row.quantity}</TableCell>
                <TableCell>
                  <Chip
                    label={row.status}
                    sx={{
                      backgroundColor: row.status === 'completed' ? '#ECF8F2' : '#FEF2E6',
                      color: row.status === 'completed' ? '#48BF84' : '#F8810D',
                      fontWeight: 'medium',
                      textTransform: 'lowercase',
                      borderRadius: '20px'
                    }}
                  />
                </TableCell>
                <TableCell>{row.createdAt}</TableCell>
                <TableCell>{row.updatedAt || '-'}</TableCell>
                <TableCell>{row.completedAt || '-'}</TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <IconButton size='small' color='primary' disabled={row.status === 'queued'}>
                      <DownloadIcon
                        fontSize='small'
                        onClick={(e) => {
                          e.stopPropagation()
                          handleDownloadBatch(row.batchId)
                        }}
                      />
                    </IconButton>
                    <IconButton
                      size='small' color='error'
                      onClick={(e) => {
                        e.stopPropagation()
                        handleDeleteBatch(row.batchId)
                      }}
                    >
                      <DeleteIcon fontSize='small' />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <VerifyListStatsDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        stats={selectedStats}
      />
    </>
  )
}

export default VerifyListHistoryTable
