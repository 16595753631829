import checkSpamWords from '../campaigns/emailChecker/utils/checkSpamTree'

export function getSubjectCount (subject) {
  return subject.split(/\s+/).filter(Boolean).length
}

export function getWordCount (paragraph) {
  return paragraph.split(/\s+/).filter(Boolean).length
}

export function getReadingTime (paragraph, wordsPerMinute = 200) {
  const wordsArray = paragraph?.trim()?.split(/\s+/)
  const totalWords = wordsArray?.length
  const readingTimeMinutes = totalWords / wordsPerMinute
  const readingTime = Math.ceil(readingTimeMinutes * 60)
  return readingTime
}

export function getQuestions (paragraph) {
  const questionMarks = paragraph?.match(/\?+/g)
  return questionMarks ? questionMarks?.length : 0
}

export function getSpamCount (subject, paragraph) {
  const stringToCheck = paragraph + ' ' + subject
  const spamObj = checkSpamWords(stringToCheck)
  return spamObj.count
}

export function getUrlLength (content) {
  if (typeof window !== 'undefined') {
    const parser = new window.DOMParser()
    const doc = parser.parseFromString(content, 'text/html')
    const links = doc.querySelectorAll('a')
    const totalLinksCount = links.length
    return totalLinksCount
  }
}
