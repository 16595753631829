import React, { useState, useEffect } from 'react'
import { Box, Menu, MenuItem, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { useSelector } from 'react-redux'
import { format, parseISO } from 'date-fns'
import { toast } from 'react-hot-toast'

import PipelineHeaderActions from 'src/components/dealPipeline/PipelineHeaderActions'
import PipelineSelector from 'src/components/dealPipeline/PipelineSelector'
import ColumnManagementModal from 'src/components/dealPipeline/ColumnManagementModal'
import EditColumnModal from 'src/components/dealPipeline/EditColumnModal'
import SlidingModal from 'src/components/dealPipeline/user-detail/SlidingModal'
import {
  useGetAllLabelsQuery,
  useUpdateLabelWithDataMutation
} from 'src/services/campaign-service'
import { useGetCampaignEmailsMutation } from 'src/services/unibox-service'
import {
  useGetLeadCampaignsByLabelMutation,
  useGetleadsCampaignsMutation
} from 'src/services/leads-service'
import PipelineBoard from 'src/components/dealPipeline/PipelineBoard'
import { useUpdatePipelineMutation, useUpdatePipelineValueMutation } from 'src/services/dealpipeline-crm-service'
import AddNewDealModal from 'src/components/dealPipeline/AddNewDealModal'
import DancingDotsSpinner from 'src/components/dealPipeline/DancingDotSpinner'
import NoPipelineView from 'src/components/dealPipeline/NoPipelineView'

const DealPipeline = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [pipelineData, setPipelineData] = useState(null)
  const [dropdownAnchor, setDropdownAnchor] = useState(null)
  const [menuAnchor, setMenuAnchor] = useState({ anchor: null, columnId: null })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [workspaceId, setWorkspaceId] = useState()
  const [userId, setUserId] = useState()
  const [selectedPipeline, setSelectedPipeline] = useState(null)

  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [editColumnData, setEditColumnData] = useState(null)
  const [isUserSlideModalOpen, setIsUserSlideModalOpen] = useState(false)
  const [selectedUserDeal, setSelectedUserDeal] = useState(null)
  const [dragDropPosition] = useState(null)
  const [columnVisibilityId, setColumnVisibilityId] = useState([])
  const [isAddNewDealModalOpen, setIsAddNewDealModalOpen] = useState(false)
  const [isCreatePipelineModalOpen, setIsCreatePipelineModalOpen] = useState(false)
  const [selectedUserColumnId, setSelectedUserColumnId] = useState(null)
  const [mainFilter, setMainFilter] = useState({
    sortBy: 'Name',
    order: 'Ascending'
  })

  const {
    data: labelsResponse,
    isLoading: isAllLabelsLoading,
    refetch: refetchLabels
  } = useGetAllLabelsQuery(workspaceId, { skip: !workspaceId })

  const [getCampaignEmails] = useGetCampaignEmailsMutation()
  const [updateLabelsWithData] = useUpdateLabelWithDataMutation()
  const [getleadsCampaigns] = useGetleadsCampaignsMutation()

  const [updatePipeline] = useUpdatePipelineMutation()
  const [updatePipelineValue] = useUpdatePipelineValueMutation()
  const [getLeadCampaignsByLabel] = useGetLeadCampaignsByLabelMutation()

  const workspace = useSelector((state) => state.workspace)
  const user = useSelector((state) => state.user)

  useEffect(() => {
    if (workspace && workspace._id) {
      setWorkspaceId(workspace._id)
    }
    if (user && user._id) {
      setUserId(user._id)
    }
  }, [workspace, user])

  useEffect(() => {
    // Prevent vertical scrolling on the entire document
    document.documentElement.style.overflow = 'hidden'
    document.body.style.overflow = 'hidden'

    return () => {
      // Clean up when component unmounts
      document.documentElement.style.overflow = ''
      document.body.style.overflow = ''
    }
  }, [])

  useEffect(() => {
    if (!selectedPipeline) return
    if (isAllLabelsLoading) return

    setIsLoading(true)

    fetchPipelineDataCore()
      .then((data) => {
        if (dragDropPosition) {
          onDragEnd(dragDropPosition)
        }
        setIsLoading(false)
      })
      .catch((error) => {
        toast.error(`Failed to fetch pipeline data ${error.message}`)
        setIsLoading(false)
      })
  }, [selectedPipeline, labelsResponse])

  // Cleanup the state when the SlidingModal is unmounted
  useEffect(() => {
    if (!isUserSlideModalOpen) {
      setSelectedUserDeal(null)
    }
  }, [isUserSlideModalOpen])

  useEffect(() => {
    if (!isEditModalOpen) {
      setEditColumnData(null)
    }
  }, [isEditModalOpen])

  const fetchPipelineDataCore = async () => {
    // Make sure prerequisites are met
    if (!labelsResponse?.labels || !selectedPipeline) return

    const allLabelsMap = {}
    const labelUserMap = {}

    for (const label of labelsResponse.labels) {
      const isVisible = selectedPipeline.labels.some(
        (pipelineLabel) => pipelineLabel._id === label._id
      )

      allLabelsMap[label._id] = {
        id: label._id,
        title: label.name,
        visible: isVisible,
        valuePerDeal: label.valuePerDeal,
        defaultValuePerDeal: label.valuePerDeal,
        cards: [] // Initially empty, filled for visible labels
      }

      if (isVisible) {
        try {
          const response = await getCampaignEmails({
            label: label._id,
            workspaceId
          }).unwrap()

          const leadIds = response.docs.map((email) => email.lead_id)

          const leadCampaignsResponse = await getLeadCampaignsByLabel({
            labels: label._id
          }).unwrap()
          const leadCampaignIds = leadCampaignsResponse.map((lead) => lead._id)

          const combinedLeadIds = [...leadIds, ...leadCampaignIds]

          // Fetch user details for the leads
          const userDetailsResponse = await getleadsCampaigns({
            leadIds: combinedLeadIds
          }).unwrap()

          labelUserMap[label._id] = userDetailsResponse.map((user) => ({
            id: user._id,
            name: `${user.firstName} ${user.lastName}`,
            title: user.title,
            email: user.email,
            date: format(parseISO(user.createdAt), 'd MMMM yyyy'),
            time: format(parseISO(user.createdAt), 'h:mm a'),
            avatar: user.avatar || 'https://via.placeholder.com/40',
            additionalData: user,
            label: label._id,
            isDealPipelineUser: user.addedFromDealPipeline,
            valuePerDeal: user.valuePerDeal || label.valuePerDeal,
            isUserHasOwnDealValue: user.valuePerDeal !== null && user.valuePerDeal !== undefined
          }))

          // Set the cards for this label
          allLabelsMap[label._id].cards = labelUserMap[label._id]

          // Calculate the total valuePerDeal from the cards
          const totalValuePerDeal = labelUserMap[label._id].reduce((sum, card) => {
            return sum + (card.valuePerDeal || 0)
          }, 0)

          allLabelsMap[label._id] = {
            ...allLabelsMap[label._id],
            cards: labelUserMap[label._id],
            valuePerDeal: totalValuePerDeal
          }
        } catch (error) {
          toast.error(`Failed to fetch users for label ${label.name}`)
        }
      }
    }

    // Set state here and return data if needed
    setPipelineData({ columns: allLabelsMap })
    return { columns: allLabelsMap }
  }

  const updateDealInPipelineData = ({ type, deal, oldColumnId, newColumnId, updatedValue }) => {
    setPipelineData((prev) => {
      const updatedColumns = { ...prev.columns }

      // 1. DELETE or MOVE (remove from old column)
      if ((type === 'delete' || type === 'move') && oldColumnId) {
        setIsUserSlideModalOpen(false)

        const oldColumn = updatedColumns[oldColumnId]
        oldColumn.cards = oldColumn.cards.filter((card) => card.id !== deal.id)
        oldColumn.valuePerDeal = oldColumn.cards.reduce(
          (sum, card) => sum + (card.valuePerDeal || 0),
          0
        )
      }

      // 2. EDIT or VALUE CHANGE (update in place)
      if ((type === 'edit' || type === 'valueChange') && oldColumnId) {
        const column = updatedColumns[oldColumnId]
        const updatedCards = column.cards.map((card) =>
          card.id === deal.id
            ? {
                ...card,
                ...(type === 'edit' ? deal : {}), // full deal for edit
                ...(type === 'valueChange' ? { valuePerDeal: updatedValue } : {})
              }
            : card
        )

        column.cards = updatedCards
        column.valuePerDeal = updatedCards.reduce(
          (sum, card) => sum + (card.valuePerDeal || 0),
          0
        )
      }

      // 3. MOVE (add to new column)
      if (type === 'move' && newColumnId) {
        const newColumn = updatedColumns[newColumnId]

        // Update card value if user doesn't have custom value
        const updatedCards = newColumn.cards.map((card) => {
          if (!card.isUserHasOwnDealValue && newColumn.defaultValuePerDeal) {
            return {
              ...card,
              valuePerDeal: newColumn.defaultValuePerDeal
            }
          }
          return card
        })

        newColumn.cards = updatedCards

        // Recalculate the total column value
        newColumn.valuePerDeal = updatedCards.reduce(
          (sum, card) => sum + (card.valuePerDeal || 0),
          0
        )
      }

      // 4. ADD new deal to a column
      if (type === 'add' && newColumnId) {
        const newColumn = updatedColumns[newColumnId]
        newColumn.cards = [...newColumn.cards, deal]
        newColumn.valuePerDeal = newColumn.cards.reduce(
          (sum, card) => sum + (card.valuePerDeal || 0),
          0
        )
      }

      // 5. COLUMN VALUE CHANGE
      if (type === 'columnValueChange' && oldColumnId) {
        const column = updatedColumns[oldColumnId]
        column.defaultValuePerDeal = updatedValue

        column.cards = column.cards.map((card) => {
          return {
            ...card,
            valuePerDeal: !card.isUserHasOwnDealValue ? updatedValue : card.valuePerDeal
          }
        })

        column.valuePerDeal = column.cards.reduce(
          (sum, card) => sum + (card.valuePerDeal || column.defaultValuePerDeal || 0),
          0
        )
      }

      // 6. Recalculate total pipeline value
      const totalValue = Object.values(updatedColumns).reduce((sum, column) => {
        return (
          sum +
          column.cards.reduce(
            (cardSum, card) => cardSum + (card.valuePerDeal || column.valuePerDeal || 0),
            0
          )
        )
      }, 0)

      // optimistic update of the pipeline value
      updatePipelineValue({
        id: selectedPipeline.id,
        data: { totalValue, userId, workspaceId }
      })

      return {
        ...prev,
        columns: updatedColumns
      }
    })
  }

  const handleDropdownClick = (event) => {
    setDropdownAnchor(event.currentTarget)
  }

  const handleDropdownClose = (option) => {
    // if (option) setSelectedOption(option);
    setDropdownAnchor(null)
  }

  const handleMenuOpen = (event, columnId) => {
    setMenuAnchor({ anchor: event.currentTarget, columnId })
  }

  const handleMenuClose = () => {
    setMenuAnchor({ anchor: null, columnId: null })
  }

  const updatePipelineData = async (updatedLabels) => {
    if (selectedPipeline) {
      const {
        data: { pipeline }
      } = await updatePipeline({
        id: selectedPipeline.id,
        data: { labels: updatedLabels, userId, workspaceId }
      })

      return pipeline
    }
  }

  const handleUpatedLabelsForPipeline = async (updatedLabels) => {
    const pipeline = await updatePipelineData(updatedLabels)
    const updatedPipeline =
      pipeline && pipeline[0]
        ? {
            label: pipeline[0].title,
            value: pipeline[0].value,
            id: pipeline[0]._id,
            labels: pipeline[0].labels
          }
        : {}

    setSelectedPipeline(updatedPipeline)
    setEditColumnData(null)
    setIsEditModalOpen(false)
    toast.success('Pipeline updated successfully')
  }

  const handleColumnVisibilityToggle = (columnId) => {
    setColumnVisibilityId((prev) => {
      if (prev.includes(columnId)) {
        return prev.filter((id) => id !== columnId)
      } else {
        return [...prev, columnId]
      }
    })

    setPipelineData((prev) => {
      return {
        ...prev,
        columns: {
          ...prev.columns,
          [columnId]: {
            ...prev.columns[columnId],
            visible: !prev.columns[columnId].visible
          }
        }
      }
    })
  }

  const handleColumnManagementModalClose = () => {
    if (columnVisibilityId.length > 0) {
      setPipelineData((prev) => {
        const updatedColumns = { ...prev.columns }

        columnVisibilityId.forEach((columnId) => {
          if (updatedColumns[columnId]) {
            updatedColumns[columnId] = {
              ...updatedColumns[columnId],
              visible: !updatedColumns[columnId].visible
            }
          }
        })

        return {
          ...prev,
          columns: updatedColumns
        }
      })
    }

    setColumnVisibilityId([])

    setIsModalOpen(false)
  }

  const handleDeleteColumn = async () => {
    const columnId = menuAnchor.columnId

    setColumnVisibilityId((prev) => {
      if (prev.includes(columnId)) {
        return prev.filter((id) => id !== columnId)
      } else {
        return [...prev, columnId]
      }
    })

    const labelIds = Object.values(pipelineData.columns)
      .filter((column) => column.visible)
      .map((column) => column.id)

    const labelIdsAfterRemoval = labelIds.filter((id) => id !== columnId)

    setPipelineData((prev) => {
      return {
        ...prev,
        columns: {
          ...prev.columns,
          [columnId]: {
            ...prev.columns[columnId],
            visible: !prev.columns[columnId].visible
          }
        }
      }
    })

    const pipeline = await updatePipelineData(labelIdsAfterRemoval)
    const updatedPipeline =
      pipeline && pipeline[0]
        ? {
            label: pipeline[0].title,
            value: pipeline[0].value,
            id: pipeline[0]._id,
            labels: pipeline[0].labels
          }
        : {}
    setSelectedPipeline(updatedPipeline)
    setEditColumnData(null)
    setIsEditModalOpen(false)
    handleMenuClose()
    toast.success('Column deleted successfully')
  }

  const onDragEnd = (result) => {
    // Check if there's a valid source and destination
    if (!result || !result.source || !result.destination) return

    const { source, destination } = result

    // Exit if the source and destination are the same
    if (source.index === destination.index) return

    // Ensure pipelineData and columns are available
    if (!pipelineData || !pipelineData.columns) {
      toast.error('Failed to reorder columns')
      return
    }

    // Convert columns object to an array for reordering
    const columnsArray = Object.entries(pipelineData.columns)

    // Move the dragged column
    const [movedColumn] = columnsArray.splice(source.index, 1)
    columnsArray.splice(destination.index, 0, movedColumn)

    // Convert back to an object
    const reorderedColumns = Object.fromEntries(columnsArray)

    // Update the pipelineData state
    setPipelineData((prev) => ({
      ...prev,
      columns: reorderedColumns
    }))
  }

  const handleEditColumn = (columnId) => {
    setEditColumnData({
      columnId,
      ...pipelineData.columns[columnId]
    })

    setIsEditModalOpen(true)
  }

  const handleSaveColumnChanges = async (updatedColumn) => {
    const { columnId, title, valuePerDeal } = updatedColumn

    setEditColumnData(null)

    await updateLabelsWithData({
      labelId: columnId,
      data: {
        name: title,
        valuePerDeal
      }
    })

    const labelIds = Object.values(pipelineData.columns)
      .filter((column) => column.visible)
      .map((column) => column.id)

    setPipelineData((prev) => {
      return {
        ...prev,
        columns: {
          ...prev.columns,
          [columnId]: {
            ...prev.columns[columnId],
            title,
            valuePerDeal
          }
        }
      }
    })

    await updatePipelineData(labelIds)
    setIsEditModalOpen(false)
    handleMenuClose()

    toast.success('Default value per deal is set to $' + valuePerDeal)
    // optimistic update of the pipeline value
    updateDealInPipelineData({
      type: 'columnValueChange',
      oldColumnId: columnId,
      updatedValue: valuePerDeal
    })
  }

  const handlePipelineHeaderAction = (action) => {
    setMainFilter(action)
  }

  const handleLabelChange = (isLabelChanged, hideModal = false) => {
    if (isLabelChanged) {
      if (hideModal) {
        setIsUserSlideModalOpen(false)
      }

      refetchLabels()
        .unwrap()
        .then((newLabels) => {
          const updatedPipeline = {
            ...selectedPipeline
          }
          setSelectedPipeline(updatedPipeline) // Triggers the useEffect
        })
        .catch((error) => {
          toast.error('Failed to update labels. Please try again.', error)
        })
    }
  }

  const sortColumns = (columns, mainFilter) => {
    return Object.entries(columns)
      .filter(([, column]) => column.visible) // Filter out only visible columns
      .sort(([idA, colA], [idB, colB]) => {
        if (mainFilter.sortBy === 'Name') {
          const nameA = colA.title.toLowerCase()
          const nameB = colB.title.toLowerCase()
          return mainFilter.order === 'Ascending'
            ? nameA.localeCompare(nameB)
            : nameB.localeCompare(nameA)
        } else if (mainFilter.sortBy === 'Last Activity') {
          const lastActivityA = Math.max(
            ...colA.cards.map((card) => new Date(card.additionalData.updatedAt || 0).getTime())
          )
          const lastActivityB = Math.max(
            ...colB.cards.map((card) => new Date(card.additionalData.updatedAt || 0).getTime())
          )
          return mainFilter.order === 'Ascending'
            ? lastActivityA - lastActivityB
            : lastActivityB - lastActivityA
        } else if (mainFilter.sortBy === 'Date Created') {
          const dateCreatedA = Math.min(
            ...colA.cards.map((card) => new Date(card.additionalData.createdAt || 0).getTime())
          )
          const dateCreatedB = Math.min(
            ...colB.cards.map((card) => new Date(card.additionalData.createdAt || 0).getTime())
          )
          return mainFilter.order === 'Ascending'
            ? dateCreatedA - dateCreatedB
            : dateCreatedB - dateCreatedA
        }
        return 0 // Default to no sorting
      })
      .reduce((acc, [columnId, column]) => {
        acc[columnId] = column
        return acc
      }, {})
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          width: '100%',
          height: '100%'
        }}
      >
        <Box
          sx={{
            width: '90%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
            // alignItems: "center",
          }}
        >
          {/* <HeaderWithPopover
            title="Deal Pipeline"
            onWorkspaceChange={() => {}}
            selectedTimeline={data?.workspace}
          /> */}
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}
          >
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Typography
                sx={{
                  color: '#28287B',
                  fontSize: '32px',
                  fontWeight: 700,
                  lineHeight: '40px',
                  letterSpacing: '0px'
                }}
              >
                Deal Pipeline
              </Typography>

              <Box sx={{ display: 'flex' }}>
                {workspaceId && userId && (
                  <PipelineSelector
                    onDropdownClick={handleDropdownClick}
                    dropdownAnchor={dropdownAnchor}
                    onDropdownClose={handleDropdownClose}
                    setSelectedPipeline={setSelectedPipeline}
                    workspaceId={workspaceId}
                    userId={userId}
                    openCreatePipelineModal={isCreatePipelineModalOpen}
                    closeCreatePipelineModal={() => setIsCreatePipelineModalOpen(false)}
                  />
                )}
              </Box>
            </Box>
            <PipelineHeaderActions
              onDropdownClick={handleDropdownClick}
              onModalOpen={() => setIsModalOpen(true)}
              onAddNewDealModalOpen={() => setIsAddNewDealModalOpen(true)}
              onPipelineHeaderAction={handlePipelineHeaderAction}
            />
          </Box>
          <Box
            sx={{
              height: 'calc(100vh - 105px)',
              width: '100%',
              overflowY: 'auto',
              overflowX: 'hidden'
            }}
          >
            {(isAllLabelsLoading || isLoading) && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 10,
                  display: 'flex',
                  pointerEvents: 'none'
                }}
              >
                <DancingDotsSpinner />
              </Box>
            )}

            <>
              {/* Header */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: 2,
                  paddingY: '20px'
                }}
              >
                {/* <Box sx={{ display: "flex" }}>
                    {workspaceId && userId && (
                      <PipelineSelector
                        onDropdownClick={handleDropdownClick}
                        dropdownAnchor={dropdownAnchor}
                        onDropdownClose={handleDropdownClose}
                        setSelectedPipeline={setSelectedPipeline}
                        workspaceId={workspaceId}
                        userId={userId}
                      />
                    )}
                  </Box> */}
                {/* <PipelineHeaderActions
                    onDropdownClick={handleDropdownClick}
                    onModalOpen={() => setIsModalOpen(true)}
                    onAddNewDealModalOpen={() => setIsAddNewDealModalOpen(true)}
                    onPipelineHeaderAction={handlePipelineHeaderAction}
                  /> */}
              </Box>

              <>
                {pipelineData
                  ? (
                    <ColumnManagementModal
                      isOpen={isModalOpen}
                      onClose={handleColumnManagementModalClose}
                      pipelineData={pipelineData}
                      setPipelineData={setPipelineData}
                      labels={labelsResponse.labels}
                      onColumnVisibilityToggle={handleColumnVisibilityToggle}
                      onDeleteColumn={handleDeleteColumn}
                      onMenuOpen={handleMenuOpen}
                      onMenuClose={handleMenuClose}
                      onDragEnd={onDragEnd}
                      refetchLabels={refetchLabels}
                      updatedLabelsForPipeline={handleUpatedLabelsForPipeline}
                    />
                    )
                  : (
                    <>
                      {!selectedPipeline && (
                        <NoPipelineView onCreatePipeline={() => setIsCreatePipelineModalOpen(true)} />
                      )}
                    </>
                    )}

                {/* Deal Pipeline Grid */}
                {pipelineData && (
                  <PipelineBoard
                    columns={sortColumns(pipelineData.columns, mainFilter)} // Use the extracted function
                    onMenuOpen={handleMenuOpen}
                    onUserCardClick={(card, columnId) => {
                      setSelectedUserDeal(card)
                      setSelectedUserColumnId(columnId)
                      setIsUserSlideModalOpen(true)
                    }}
                    onAddDeal={() => setIsAddNewDealModalOpen(true)}
                    updateDealInPipelineData={updateDealInPipelineData}
                  />
                )}
              </>

              {/* Column Menu */}
              <Menu
                anchorEl={menuAnchor.anchor}
                open={Boolean(menuAnchor.anchor)}
                onClose={handleMenuClose}
              >
                <MenuItem
                  onClick={() => {
                    handleColumnVisibilityToggle(menuAnchor.columnId)
                    handleMenuClose()
                  }}
                >
                  Hide column
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleEditColumn(menuAnchor.columnId)
                  }}
                >
                  Edit column
                </MenuItem>
                {editColumnData && (
                  <EditColumnModal
                    isOpen={isEditModalOpen}
                    onClose={() => {
                      setIsEditModalOpen(false)
                      setEditColumnData(null)
                      handleMenuClose()
                    }}
                    columnData={editColumnData}
                    onSave={handleSaveColumnChanges}
                  />
                )}
                <MenuItem
                  onClick={handleDeleteColumn}
                  sx={{ color: 'red', display: 'flex', alignItems: 'center' }}
                >
                  <DeleteIcon sx={{ marginRight: '8px' }} />
                  Delete column
                </MenuItem>
              </Menu>
            </>
          </Box>
        </Box>
      </Box>

      {selectedUserDeal && (
        <SlidingModal
          isOpen={isUserSlideModalOpen}
          onClose={() => setIsUserSlideModalOpen(false)}
          user={selectedUserDeal}
          workspaceId={workspaceId}
          labels={labelsResponse.labels}
          loggedInUser={userId}
          onUpdateComplete={handleLabelChange}
          updateDealInPipelineData={updateDealInPipelineData}
          selectedUserColumnId={selectedUserColumnId}
        />
      )}

      {isAddNewDealModalOpen && (
        <AddNewDealModal
          isOpen={isAddNewDealModalOpen}
          onClose={() => setIsAddNewDealModalOpen(false)}
          onCreateDeal={(deal) => {
            setIsAddNewDealModalOpen(false)
          }}
          workspaceId={workspaceId}
          updateDealInPipelineData={updateDealInPipelineData}
        />
      )}
    </>
  )
}

export default DealPipeline
