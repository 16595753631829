import React from 'react'
import { Box, Typography, Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

const NoPipelineView = ({ onCreatePipeline }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'calc(100vh - 150px)', // Adjust this if you have a header height
        backgroundColor: '#F9F9F9',
        textAlign: 'center',
        p: 4
      }}
    >
      <Typography variant='h4' sx={{ mb: 2, color: '#3F50F8', fontWeight: 700 }}>
        No Pipeline Found
      </Typography>
      <Typography variant='body1' sx={{ mb: 4, color: '#555' }}>
        It looks like you haven’t created a pipeline yet. Please click the button below to create your first pipeline.
      </Typography>
      <Button
        variant='contained'
        startIcon={<AddIcon />}
        onClick={onCreatePipeline}
        sx={{
          backgroundColor: '#3F50F8',
          textTransform: 'none',
          fontWeight: 600,
          px: 3,
          py: 1.5,
          '&:hover': {
            backgroundColor: '#2C3CD0'
          }
        }}
      >
        Create New Pipeline
      </Button>
    </Box>
  )
}

export default NoPipelineView
