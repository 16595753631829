import { Button, Stack, styled, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { WhiteButton } from './CreateAnEmail'
import { InboxHubContext } from 'src/pages/InboxHub'

export const OrangeButton = styled(Button)(({ theme, padding }) => ({
  color: '#FFF', // Text color
  backgroundColor: '#FF4545', // Default background color
  borderRadius: '8px', // Rounded corners
  padding: padding || '8px 16px', // Padding
  transition: 'all 0.3s ease', // Smooth transitions
  fontSize: '16px',
  // Hover styles
  '&:hover': {
    backgroundColor: 'rgb(226, 65, 65)', // Background color on hover
    color: 'white', // Text color on hover
    borderColor: theme.palette.primary.dark // Border color on hover
  },

  // Disabled styles
  '&.Mui-disabled': {
    color: '#FFF',
    backgroundColor: 'rgb(107, 107, 107)' // Background for disabled state
  }
}))

export default function EditLeadKeepEditing ({ handleClosePopup }) {
  const { setCurrentPopUpOpened } = useContext(InboxHubContext)

  async function handleClickDiscard () {
    handleClosePopup()
  }
  async function handleClickNo () {
    setCurrentPopUpOpened('editlead')
  }
  return (
    <Stack gap={2}>
      <Stack width='100%' justifyContent='space-between'>
        <Typography fontWeight={700} fontSize='18px'>
          Discard unsaved changes?
        </Typography>
      </Stack>
      <Typography fontWeight={300} fontSize='18px'>
        You have unsaved changes. Do you want to discard them?
      </Typography>
      <Stack width='100%' direction={{ xs: 'column', mmd: 'row' }} justifyContent='space-between' gap={2}>

        <OrangeButton onClick={handleClickDiscard}>
          Yes, discard
        </OrangeButton>

        <WhiteButton onClick={handleClickNo}>No, keep editing</WhiteButton>
      </Stack>
    </Stack>
  )
}
