import { LogoSymbol } from './components/logoSymbol'
export const config = {
  GOOGLE_SHEET_KEY: process.env.REACT_APP_GOOGLE_SHEET_API_KEY,
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  INTERCOM_PROXY_BASE_URL: process.env.REACT_APP_INTERCOM_PROXY_BASE_URL,
  // Google OAuth
  GOOGLE_OAUTH_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  GOOGLE_OAUTH_AUTH_URL: 'https://accounts.google.com/o/oauth2/v2/auth',
  GOOGLE_OAUTH_REDIRECT_URL: process.env.REACT_APP_GOOGLE_REDIRECT_URL,
  GOOGLE_OAUTH_SCOPE: [
    'https://mail.google.com/',
    'https://www.googleapis.com/auth/userinfo.profile',
    'https://www.googleapis.com/auth/userinfo.email'
  ].join(' '),
  // Microsoft OAuth
  MSAUTH_CLIENT_ID: process.env.REACT_APP_MSAUTH_CLIENT_ID,
  MICROSOFT_OAUTH_CLIENT_ID: process.env.REACT_APP_MICROSOFT_OAUTH_CLIENT_ID,
  MICROSOFT_OAUTH_REDIRECT_URI: process.env.REACT_APP_MICROSOFT_OAUTH_REDIRECT_URI,
  MICROSOFT_OAUTH_AUTHORIZATION_URL:
    'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
  MICROSOFT_OAUTH_SCOPE: [
    'openid',
    'email',
    'profile',
    'offline_access',
    'https://outlook.office.com/IMAP.AccessAsUser.All',
    'https://outlook.office.com/SMTP.Send'
  ].join(' '),

  // Facebook OAuth
  FACEBOOK_OAUTH_APP_ID: process.env.REACT_APP_FB_APP_ID,

  // warmups
  SENDING_WARMUP_MONTHLY_GROWTH_PRICE_ID:
    process.env.REACT_APP_SENDING_WARMUP_MONTHLY_GROWTH_PRICE_ID,
  SENDING_WARMUP_MONTHLY_GROWTH_DISCOUNT_PRICE_ID:
    process.env.REACT_APP_SENDING_WARMUP_MONTHLY_GROWTH_DISCOUNT_PRICE_ID,
  SENDING_WARMUP_MONTHLY_SKYROCKET_PRICE_ID:
    process.env.REACT_APP_SENDING_WARMUP_MONTHLY_SKYROCKET_PRICE_ID,
  SENDING_WARMUP_MONTHLY_SCALE_PRICE_ID:
    process.env.REACT_APP_SENDING_WARMUP_MONTHLY_SCALE_PRICE_ID,
  SENDING_WARMUP_YEARLY_GROWTH_PRICE_ID:
    process.env.REACT_APP_SENDING_WARMUP_YEARLY_GROWTH_PRICE_ID,
  SENDING_WARMUP_YEARLY_SKYROCKET_PRICE_ID:
    process.env.REACT_APP_SENDING_WARMUP_YEARLY_SKYROCKET_PRICE_ID,
  SENDING_WARMUP_YEARLY_SCALE_PRICE_ID: process.env.REACT_APP_SENDING_WARMUP_YEARLY_SCALE_PRICE_ID,
  LEADS_MONTHLY_SKYROCKET_PRICE_ID: process.env.REACT_APP_LEADS_MONTHLY_SKYROCKET_PRICE_ID,
  LEADS_MONTHLY_GROWTH_PRICE_ID: process.env.REACT_APP_LEADS_MONTHLY_GROWTH_PRICE_ID,
  LEADS_MONTHLY_SCALE_PRICE_ID: process.env.REACT_APP_LEADS_MONTHLY_SCALE_PRICE_ID,
  LEADS_YEARLY_SKYROCKET_PRICE_ID: process.env.REACT_APP_LEADS_YEARLY_SKYROCKET_PRICE_ID,
  LEADS_YEARLY_GROWTH_PRICE_ID: process.env.REACT_APP_LEADS_YEARLY_GROWTH_PRICE_ID,
  LEADS_YEARLY_SCALE_PRICE_ID: process.env.REACT_APP_LEADS_YEARLY_SCALE_PRICE_ID,
  TINYMCE_EDITOR_API: process.env.REACT_APP_TINYMCE_API,
  INTERCOM_APP_ID: process.env.REACT_APP_INTERCOM_APP_ID,
  UNIPILE_DSN: process.env.REACT_APP_UNIPILE_DSN,
  UNIPILE_ACCESS_TOKEN: process.env.REACT_APP_UNIPILE_ACCESS_TOKEN,
  EMAIL_VERIFICATION_CREDIT_PRICE_ID: process.env.REACT_APP_EMAIL_VERIFICATION_CREDIT_PRICE_ID,
  EMAIL_ACCOUNT_PRICE_ID: process.env.REACT_APP_EMAIL_ACCOUNT_PRICE_ID,
  ACTIVE_CONTACTS_PRICE_ID: process.env.REACT_APP_ACTIVE_CONTACTS_PRICE_ID,
  AI_WRITER_PRICE_ID: process.env.REACT_APP_AI_WRITER_PRICE_ID,
  LINKEDIN_ACCOUNT_PRICE_ID: process.env.REACT_APP_LINKEDIN_ACCOUNT_PRICE_ID,
  // yearly add-ons
  YEARLY_EMAIL_VERIFICATION_CREDIT_PRICE_ID:
    process.env.REACT_APP_YEARLY_EMAIL_VERIFICATION_CREDIT_PRICE_ID,
  YEARLY_EMAIL_ACCOUNT_PRICE_ID: process.env.REACT_APP_YEARLY_EMAIL_ACCOUNT_PRICE_ID,
  YEARLY_ACTIVE_CONTACTS_PRICE_ID: process.env.REACT_APP_YEARLY_ACTIVE_CONTACTS_PRICE_ID,
  YEARLY_AI_WRITER_PRICE_ID: process.env.REACT_APP_YEARLY_AI_WRITER_PRICE_ID,
  YEARLY_LINKEDIN_ACCOUNT_PRICE_ID: process.env.REACT_APP_YEARLY_LINKEDIN_ACCOUNT_PRICE_ID,
  YEARLY_STARTER_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID:
    process.env.REACT_APP_YEARLY_STARTER_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID,
  YEARLY_GROWTH_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID:
    process.env.REACT_APP_YEARLY_GROWTH_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID,
  YEARLY_INFINITY_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID:
    process.env.REACT_APP_YEARLY_INFINITY_WARMUP_7_DAY_FREE_TRIAL_PRICE_ID,
  FREE_USER_PRICE: process.env.REACT_APP_FREE_USER_PRICE,
  HUBSPOT_LINK: process.env.REACT_APP_HUBSPOT_LINK,
  AWS_BUCKET_NAME: process.env.REACT_APP_AWS_BUCKET_NAME,
  AWS_REGION: process.env.REACT_APP_AWS_REGION,
}
export const planNames = {
  [config.SENDING_WARMUP_MONTHLY_GROWTH_PRICE_ID]: 'Monthly Starter',
  [config.SENDING_WARMUP_MONTHLY_SKYROCKET_PRICE_ID]: 'Monthly Growth',
  [config.SENDING_WARMUP_MONTHLY_SCALE_PRICE_ID]: 'Monthly Infinity',
  [config.SENDING_WARMUP_YEARLY_GROWTH_PRICE_ID]: 'Yearly Starter',
  [config.SENDING_WARMUP_YEARLY_SKYROCKET_PRICE_ID]: 'Yearly Growth',
  [config.SENDING_WARMUP_YEARLY_SCALE_PRICE_ID]: 'Yearly Infinity',
  [config.LEADS_MONTHLY_SKYROCKET_PRICE_ID]: 'Monthly Skyrocket Leads',
  [config.LEADS_MONTHLY_GROWTH_PRICE_ID]: 'Monthly Growth Leads',
  [config.LEADS_MONTHLY_SCALE_PRICE_ID]: 'Monthly 10X Scale Leads',
  [config.LEADS_YEARLY_SKYROCKET_PRICE_ID]: 'Yearly Skyrocket Leads',
  [config.LEADS_YEARLY_GROWTH_PRICE_ID]: 'Yearly Skyrocket Leads',
  [config.LEADS_YEARLY_SCALE_PRICE_ID]: 'Yearly 10X Scale Leads',
  [config.SENDING_WARMUP_MONTHLY_GROWTH_DISCOUNT_PRICE_ID]: 'Monthly Growth Discount',
}

export const s3ImageURL = `https://${config.AWS_BUCKET_NAME}.s3.${config.AWS_REGION}.amazonaws.com/`;

export const userTheme = {
  logo: <LogoSymbol color='white' />,
  companyName: '',
  colors: {
    light: '#EBEFFF',
    main: '#216fed',
    dark: '#004EEB',
    contrastText: '#FFFFFF',
    primaryText: '#28287B'
  }
}

export const allowedDomains = [
  'localhost',
  process.env.REACT_APP_STAGING_DOMAIN,
  process.env.REACT_APP_PRODUCTION_DOMAIN
]

export const allowedPrice = [
  config.SENDING_WARMUP_MONTHLY_GROWTH_PRICE_ID,
  config.SENDING_WARMUP_MONTHLY_SKYROCKET_PRICE_ID,
  config.SENDING_WARMUP_MONTHLY_SCALE_PRICE_ID,
  config.SENDING_WARMUP_YEARLY_GROWTH_PRICE_ID,
  config.SENDING_WARMUP_YEARLY_SKYROCKET_PRICE_ID,
  config.SENDING_WARMUP_YEARLY_SCALE_PRICE_ID,
  config.LEADS_MONTHLY_SKYROCKET_PRICE_ID,
  config.LEADS_MONTHLY_GROWTH_PRICE_ID,
  config.LEADS_MONTHLY_SCALE_PRICE_ID,
  config.LEADS_YEARLY_SKYROCKET_PRICE_ID,
  config.LEADS_YEARLY_GROWTH_PRICE_ID,
  config.LEADS_YEARLY_SCALE_PRICE_ID,
  config.FREE_USER_PRICE,
  config.SENDING_WARMUP_MONTHLY_GROWTH_DISCOUNT_PRICE_ID
]

export const yearlyPriceIds = [
  config.SENDING_WARMUP_YEARLY_GROWTH_PRICE_ID,
  config.SENDING_WARMUP_YEARLY_SKYROCKET_PRICE_ID,
  config.SENDING_WARMUP_YEARLY_SCALE_PRICE_ID,
  config.LEADS_YEARLY_SKYROCKET_PRICE_ID,
  config.LEADS_YEARLY_GROWTH_PRICE_ID,
  config.LEADS_YEARLY_SCALE_PRICE_ID
]

export const planSettingFormFields = [
  {
    title: 'Credits',
    textFields: [
      { label: 'Active Contacts', name: 'activeLeads' },
      { label: 'Emails Per Month', name: 'monthlyEmails' },
      { label: 'AI Writer Credits', name: 'aiWriterCredits' },
      { label: 'Lead Finder Credits', name: 'leadsCredits' },
      { label: 'Linkedin Credits', name: 'linkedinCredits' },
      { label: 'Email Export Credits (per day)', name: 'dailyLeadLimit' },
      { label: 'Connected Mailboxes', name: 'emailAccountsLimit' },
      { label: 'Email Verification Credits', name: 'bounceCredit' },
      { label: 'Merge Exports', name: 'dataEnrichmentCredits' },
      { label: 'Deal Pipeline', name: 'dealPipeline' },
      { label: 'Thresholds', name: 'buyerIntendDataTopiscs' }
    ]
  },
  {
    title: 'Lead Finder',
    switches: [
      { name: 'buyerIntendData', label: 'Buyer Intend Data' },
      { name: 'advancedFilters', label: 'Advanced Filters' }
    ]
  },
  {
    title: 'Campaigns',
    switches: [
      { name: 'abTesting', label: 'A/B Testing' },
      { name: 'aiFilters', label: 'AI Features' },
      { name: 'mailboxConnection', label: 'SMTP Mailbox Connection' },
      { name: 'customDomainTracking', label: 'Custom Domain Tracking' },
      { name: 'globalBlocklist', label: 'Global Blocklist' },
      { name: 'emailWarmup', label: 'Email Warmup' }
    ]
  },
  {
    title: 'Multichannel',
    switches: [{ name: 'multiChannelLinkedinOutreach', label: 'LinkedIn' }]
  },
  {
    title: 'Inbox & CRM',
    switches: [
      { name: 'unibox', label: 'Unibox' },
      { name: 'aiAutoReplies', label: 'AI Auto Replies' }
    ]
  },
  {
    title: 'Integrations',
    switches: [
      { name: 'zapierIntegration', label: 'Zapier' },
      { name: 'hubspotIntegration', label: 'Hubspot' },
      { name: 'salesforceIntegration', label: 'Salesforce' },
      { name: 'pipedriveIntegration', label: 'Pipedrive' },
      { name: 'webhookIntegration', label: 'Webhooks' },
      { name: 'advancedApis', label: 'APIs' }
    ]
  }
]
