import { Button, CircularProgress, Stack, styled, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { WhiteButton } from './CreateAnEmail'
import { useDeleteThreadMutation } from 'src/services/unibox-service'
import toast from 'react-hot-toast'
import { InboxHubContext } from 'src/pages/InboxHub'

export const OrangeButton = styled(Button)(({ theme, padding }) => ({
  color: '#FFF', // Text color
  backgroundColor: '#FF4545', // Default background color
  borderRadius: '8px', // Rounded corners
  padding: padding || '8px 16px', // Padding
  transition: 'all 0.3s ease', // Smooth transitions
  fontSize: '16px',
  // Hover styles
  '&:hover': {
    backgroundColor: 'rgb(226, 65, 65)', // Background color on hover
    color: 'white', // Text color on hover
    borderColor: theme.palette.primary.dark // Border color on hover
  },

  // Disabled styles
  '&.Mui-disabled': {
    color: '#FFF',
    backgroundColor: 'rgb(107, 107, 107)' // Background for disabled state
  }
}))

export default function DeleteEmailThread ({ handleClosePopup }) {
  const { currentlyOpenedEmail } = useContext(InboxHubContext)
  const [deleteThread, { isLoading }] = useDeleteThreadMutation()

  async function handleClickDelete () {
    try {
      const { message } = await deleteThread(currentlyOpenedEmail._id).unwrap()
      toast.success(message)
      handleClosePopup()
    } catch (e) {
      toast.error(e?.data?.error?.message ?? 'Something not right')
    }
  }
  return (
    <Stack gap={2}>
      <Stack width='100%' justifyContent='space-between'>
        <Typography fontWeight={700} fontSize='18px'>
          Are you sure you want to delete this email thread?
        </Typography>
      </Stack>
      <Typography fontWeight={300} fontSize='18px'>
        Deleting the email thread will permanently remove the exchanges from your inboxHub. They
        will still be accessible in your email account.
      </Typography>
      <Stack width='100%' direction={{ xs: 'column', mmd: 'row' }} justifyContent='space-between' gap={2}>
        {isLoading
          ? (
            <CircularProgress />
            )
          : (
            <OrangeButton disabled={isLoading} onClick={handleClickDelete}>
              Yes, delete email thread
            </OrangeButton>
            )}
        <WhiteButton onClick={handleClosePopup}>No, keep email thread</WhiteButton>
      </Stack>
    </Stack>
  )
}
