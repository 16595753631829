import React, { useContext, useState } from 'react'
import { Chip, Box, Stack, Typography, styled, CircularProgress } from '@mui/material'
import { BlueButton, WhiteButton } from './CreateAnEmail'
import { ChevronDownIcon, ForwardEmailIcon } from 'src/assets/inboxHub/InboxHubIcons'
import { useSendForwardMailMutation } from 'src/services/unibox-service'
import toast from 'react-hot-toast'
import { InboxHubContext } from 'src/pages/InboxHub'

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
export const StyledChip = styled(Chip)(() => ({
  border: '1px solid #3F4FF836',
  backgroundColor: '#E6F0FF',
  borderRadius: 30
}))

const ForwardEmail = ({ handleClosePopup }) => {
  const { currentlyOpenedEmail } = useContext(InboxHubContext)
  const leadName = `${currentlyOpenedEmail?.leads?.firstName ?? ''} ${currentlyOpenedEmail?.leads?.lastName ?? ''
    }`
  const [sendForward, { isLoading }] = useSendForwardMailMutation()
  const [emailAddress, setEmailAddress] = useState('')

  const [selectedOptionsEmail, setSelectedOptionsEmail] = useState([])

  const handleRemoveChipEmail = (indexToRemove) => {
    selectedOptionsEmail.splice(indexToRemove, 1)
    setSelectedOptionsEmail([...selectedOptionsEmail])
  }

  async function handleClickForward () {
    try {
      const { message } = await sendForward({
        body: {
          ...currentlyOpenedEmail,
          forwardEmail: selectedOptionsEmail[0]
        }
      }).unwrap()
      toast.success(message)
    } catch (e) {
      toast.error(e?.data?.error?.message ?? 'Something not right')
    }
  }

  function handleEnterKeyDown (e) {
    if (e.key === 'Enter' && emailAddress.length && emailRegex.test(emailAddress)) {
      setSelectedOptionsEmail([...selectedOptionsEmail, emailAddress])
      setEmailAddress('')
    }
  }

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <Stack width='100%' justifyContent='space-between' mb={3}>
        <Typography fontWeight={700} fontSize='18px'>
          Forward {leadName}'s email
        </Typography>
      </Stack>
      <Stack gap={3}>
        <Stack gap={1}>
          <Typography>Recipient Emails:</Typography>
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              border: '1px solid #ccc',
              borderRadius: 2,
              padding: '3px',
              cursor: 'pointer',
              minHeight: '3rem'
            }}
          >
            {selectedOptionsEmail.map((option, i) => (
              <StyledChip
                key={option}
                label={option}
                onDelete={() => handleRemoveChipEmail(i)}
                sx={{ margin: '2px' }}
              />
            ))}
            <input
              style={{
                height: '100%',
                outline: 'none',
                fontSize: '1rem',
                border: 'none',
                width: '12rem'
              }}
              placeholder='type email and press enter'
              value={emailAddress}
              onChange={(e) => {
                setEmailAddress(e.target.value)
              }}
              onKeyDown={handleEnterKeyDown}
            />
            <div
              style={{
                position: 'absolute',
                right: '2px',
                transform: 'rotate(180deg) translate(0, 15%)'
              }}
            >
              <ChevronDownIcon />
            </div>
          </Box>
        </Stack>
        <Stack direction={{ xs: 'column', mmd: 'row' }} justifyContent='flex-end' gap={2}>
          <WhiteButton onClick={handleClosePopup}>Cancel</WhiteButton>
          {isLoading
            ? (
              <CircularProgress />
              )
            : (
              <BlueButton
                disabled={isLoading}
                startIcon={<ForwardEmailIcon />}
                onClick={handleClickForward}
              >
                Forward {selectedOptionsEmail.length || ''} Email
              </BlueButton>
              )}
        </Stack>
      </Stack>
    </Box>
  )
}

export default ForwardEmail
