import React from 'react'
import { Box } from '@mui/material'

const TabNavigation = ({ activeTab, onTabChange }) => (
  <Box sx={{ padding: '15px 20px 0px 20px' }}>
    <Box
      sx={{
        display: 'flex',
        borderBottom: '1px solid #E5E7EB',
        backgroundColor: '#E1E3EC',
        borderRadius: '8px',
        overflow: 'hidden',
        marginBottom: '16px'
      }}
    >
      {['person', 'company', 'activity'].map((tab) => (
        <Box
          key={tab}
          onClick={() => onTabChange(tab)}
          sx={{
            flex: 1,
            textAlign: 'center',
            padding: '8px 0',
            fontWeight: activeTab === tab ? 'bold' : 'normal',
            color: activeTab === tab ? '#111827' : '#8181B0',
            backgroundColor: activeTab === tab ? '#FFFFFF' : 'transparent',
            cursor: 'pointer',
            transition: 'all 0.3s ease',
            margin: '6px',
            borderRadius: '8px',
            fontSize: '14px',
            boxShadow: activeTab === tab ? '0px 2px 4px rgba(0, 0, 0, 0.1)' : 'none'
          }}
        >
          {tab.charAt(0).toUpperCase() + tab.slice(1)}
        </Box>
      ))}
    </Box>
  </Box>
)

export default TabNavigation
