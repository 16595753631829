import React from 'react'
import { Dialog, DialogTitle, DialogContent, Box, Typography, IconButton } from '@mui/material'
import Chart from 'react-apexcharts'
import CloseIcon from '@mui/icons-material/Close'

const StatusPieChart = ({ data }) => {
  const chartData = {
    series: [
      data.deliverable || 0,
      data.risky || 0,
      data.undeliverable || 0,
      data.unknown || 0
    ],
    options: {
      chart: {
        type: 'donut'
      },
      labels: ['Deliverable', 'Risky', 'Undeliverable', 'Unknown'],
      colors: ['#10B981', '#F59E0B', '#EF4444', '#9CA3AF'],
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      plotOptions: {
        pie: {
          donut: {
            size: '65%'
          }
        }
      }
    }
  }

  return (
    <Chart
      options={chartData.options}
      series={chartData.series}
      type='donut'
      height={250}
    />
  )
}

// const ToxicityScoreChart = ({ stats }) => {
//   const totalEmails = stats?.deliverable + stats?.risky + stats?.undeliverable + stats?.unknown || 0

//   const chartData = {
//     series: [{
//       data: [1, 2, 3, 4, 5].map(score => {
//         const value = stats?.[`toxicityScore${score}`] || 0
//         return {
//           x: `Score ${score}`,
//           y: value,
//           percentage: totalEmails > 0 ? ((value / totalEmails) * 100).toFixed(2) : 0
//         }
//       })
//     }],
//     options: {
//       chart: {
//         type: 'bar',
//         toolbar: {
//           show: false
//         }
//       },
//       plotOptions: {
//         bar: {
//           horizontal: true,
//           borderRadius: 4,
//           dataLabels: {
//             position: 'top'
//           }
//         }
//       },
//       colors: ['#F59E0B'],
//       dataLabels: {
//         enabled: true,
//         formatter: function (val, opt) {
//           return `${val} (${opt.w.config.series[0].data[opt.dataPointIndex].percentage}%)`
//         },
//         style: {
//           fontSize: '12px'
//         },
//         offsetX: 30
//       },
//       xaxis: {
//         categories: [1, 2, 3, 4, 5].map(score => `Score ${score}`),
//         labels: {
//           show: true
//         },
//         axisBorder: {
//           show: false
//         }
//       },
//       yaxis: {
//         labels: {
//           style: {
//             fontSize: '12px'
//           }
//         }
//       },
//       grid: {
//         show: false
//       }
//     }
//   }

//   return (
//     <Chart
//       options={chartData.options}
//       series={chartData.series}
//       type='bar'
//       height={200}
//     />
//   )
// }

const VerifyListStatsDialog = ({ open, onClose, stats }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='sm'
      fullWidth
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pb: 1 }}>
        <Typography>Verification Statistics</Typography>
        <IconButton>
          <CloseIcon onClick={onClose} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 4, padding: 2, border: '1px solid #EDEDED', borderRadius: '8px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-around', mb: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Box component='span' sx={{ color: '#10B981', fontWeight: 'bold' }}>
                {stats?.deliverable || 0}
              </Box>
              <Box sx={{ color: 'text.secondary' }}>deliverable</Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Box component='span' sx={{ color: '#F59E0B', fontWeight: 'bold' }}>
                {stats?.risky || 0}
              </Box>
              <Box sx={{ color: 'text.secondary' }}>risky</Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Box component='span' sx={{ color: '#EF4444', fontWeight: 'bold' }}>
                {stats?.undeliverable || 0}
              </Box>
              <Box sx={{ color: 'text.secondary' }}>undeliverable</Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Box component='span' sx={{ color: '#9CA3AF', fontWeight: 'bold' }}>
                {stats?.unknown || 0}
              </Box>
              <Box sx={{ color: 'text.secondary' }}>unknown</Box>
            </Box>
          </Box>
          <StatusPieChart data={stats || {}} />
        </Box>

        {/* <Box sx={{ mt: 4, border: '1px solid #EDEDED', borderRadius: '8px' }}>
          <Box sx={{ m: 1 }}>Toxicity Check</Box>
          <ToxicityScoreChart stats={stats} />
        </Box> */}
      </DialogContent>
    </Dialog>
  )
}

export default VerifyListStatsDialog
