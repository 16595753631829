import React, { useState, useEffect, useRef } from 'react'
import { Box, IconButton } from '@mui/material'
import { DragDropContext } from 'react-beautiful-dnd'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import PipelineColumn from './PipelineColumn'
import toast from 'react-hot-toast'
import { useChangeLabelMutation } from 'src/services/leads-service'

const PipelineBoard = ({ columns, onUserCardClick, onMenuOpen, onAddDeal, updateDealInPipelineData }) => {
  const [availableHeight, setAvailableHeight] = useState(window.innerHeight)
  const scrollContainerRef = useRef(null)
  const [showLeft, setShowLeft] = useState(false)
  const [showRight, setShowRight] = useState(false)

  const [changeLabel] = useChangeLabelMutation()

  // Update available height based on window and header height
  useEffect(() => {
    const updateHeight = () => {
      const header = document.querySelector('.header') // Adjust class if needed
      const headerHeight = header ? header.offsetHeight : 150
      setAvailableHeight(window.innerHeight - headerHeight)
    }

    updateHeight()
    window.addEventListener('resize', updateHeight)
    return () => window.removeEventListener('resize', updateHeight)
  }, [])

  // Update arrow visibility based on scroll position
  const updateArrows = () => {
    const container = scrollContainerRef.current
    if (container) {
      const { scrollLeft, scrollWidth, clientWidth } = container
      setShowLeft(scrollLeft > 0)
      setShowRight(scrollLeft < scrollWidth - clientWidth - 1)
    }
  }

  useEffect(() => {
    updateArrows()
  }, [availableHeight, columns])

  function findUserByDraggableId (columns, draggableId) {
    for (const columnKey in columns) {
      const column = columns[columnKey]
      for (const card of column.cards) {
        if (card.id === draggableId) {
          return card
        }
      }
    }
    return null // If no user is found with the given draggableId
  }

  // Handle card drag end (for moving cards between columns)
  const handleCardDragEnd = async (result) => {
    const { source, destination, draggableId } = result
    // if no destination, exit
    if (!destination) return
    // if dropped in the same column at the same index, exit
    if (source.droppableId === destination.droppableId && source.index === destination.index) { return }

    const newColumns = { ...columns }

    // Copy source and destination cards arrays to avoid mutation
    const sourceCards = Array.from(newColumns[source.droppableId].cards)
    const destinationCards = Array.from(newColumns[destination.droppableId].cards)

    // Remove the card from source column
    const [movedCard] = sourceCards.splice(source.index, 1)
    // Insert it into destination column at the destination index
    destinationCards.splice(destination.index, 0, movedCard)

    // Update the columns object
    newColumns[source.droppableId].cards = sourceCards
    newColumns[destination.droppableId].cards = destinationCards

    await handleLabelChange(source.droppableId, destination.droppableId, draggableId)
  }

  const handleLabelChange = async (oldlabel, newlabel, userId) => {
    const user = findUserByDraggableId(columns, userId)

    try {
      const data = {
        leadCampaignId: user?.id,
        newLabel: newlabel,
        oldLabel: oldlabel,
        isDealPipelineUser: user?.isDealPipelineUser || false
      }
      const response = await changeLabel(data).unwrap()
      if (response) {
        // Optimistically update the UI
        updateDealInPipelineData({
          type: 'move',
          deal: user,
          oldColumnId: oldlabel,
          newColumnId: newlabel
        })
      } else {
        toast.error('Issue in updating label. Please try again')
      }
    } catch (error) {
      toast.error('Issue while moving card. Please try again')
    }
  }

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -300, behavior: 'smooth' })
    }
  }

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 300, behavior: 'smooth' })
    }
  }

  return (
    <Box sx={{ position: 'relative', width: '100%', overflow: 'visible' }}>
      {/* Left Arrow */}
      {showLeft && (
        <IconButton
          onClick={scrollLeft}
          sx={{
            position: 'absolute',
            top: '50%',
            left: 0,
            transform: 'translate(0, -50%)',
            zIndex: 1000,
            backgroundColor: 'rgba(0,0,0,0.5)',
            color: '#fff',
            opacity: 0.3,
            transition: 'opacity 0.3s ease',
            '&:hover': { opacity: 1, backgroundColor: 'rgba(0,0,0,0.7)' }
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
      )}
      {/* Right Arrow */}
      {showRight && (
        <IconButton
          onClick={scrollRight}
          sx={{
            position: 'absolute',
            top: '50%',
            right: 0,
            transform: 'translate(0, -50%)',
            zIndex: 1000,
            backgroundColor: 'rgba(0,0,0,0.5)',
            color: '#fff',
            opacity: 0.3,
            transition: 'opacity 0.3s ease',
            '&:hover': { opacity: 1, backgroundColor: 'rgba(0,0,0,0.7)' }
          }}
        >
          <ChevronRightIcon />
        </IconButton>
      )}

      {/* DragDropContext for card-level drag and drop */}
      <DragDropContext onDragEnd={handleCardDragEnd}>
        <Box
          ref={scrollContainerRef}
          onScroll={updateArrows}
          sx={{
            display: 'flex',
            flexGrow: 1,
            overflowX: 'auto',
            overflowY: 'hidden',
            gap: '8px',
            height: `${availableHeight}px`,
            '&::-webkit-scrollbar': { display: 'none' },
            scrollbarWidth: 'none',
            '-ms-overflow-style': 'none'
          }}
        >
          {Object.entries(columns).map(([columnId, column]) => (
            <PipelineColumn
              key={columnId}
              column={column}
              columnId={columnId}
              onUserCardClick={onUserCardClick}
              onMenuOpen={onMenuOpen}
              availableHeight={availableHeight}
              onAddDeal={onAddDeal}
            />
          ))}
        </Box>
      </DragDropContext>
    </Box>
  )
}

export default PipelineBoard
