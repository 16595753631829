import React from 'react'
import { Box, Typography, Divider } from '@mui/material'
import { PhoneBlueIcon } from 'src/icons/phone'
import { ExportBlueIcon } from 'src/icons/export'
import { Enrich } from 'src/icons/enrich'
import { InfoIcon } from 'src/icons/infoIcon'

const CreditBalance = ({ usage }) => {
  // Helper function to format numbers with commas
  const formatNumber = (num) => {
    return num?.toLocaleString() || '0'
  }

  const stats = [
    { icon: <Enrich />, label: 'Enrich', value: formatNumber(usage?.activeLeads) },
    { icon: <ExportBlueIcon />, label: 'Export', value: formatNumber(usage?.leadsCredits) },
    { icon: <PhoneBlueIcon />, label: 'Phone', value: formatNumber(usage?.leadsPhoneNumberExportsPerMonth) }
  ]

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      {/* Commented out Schedule Consultation Button */}
      {/* <Box
    //     component='a'
  //     href='https://calendly.com/d/cmh7-chj-pcz/success-ai-demo-session'
  //     target='_blank'
  //     rel='noreferrer'
  //     sx={{
  //       display: 'flex',
  //       alignItems: 'center',
  //       gap: 1,
  //       background: 'linear-gradient(45deg, rgb(52, 192, 235), #3F5DF8, rgb(55, 62, 143))',
  //       '&:hover': {
  //         background: 'linear-gradient(45deg, rgb(14, 187, 240), rgb(34, 69, 243), rgb(32, 43, 163))'
  //       },
  //       '&:active': {
  //         scale: 0.97
  //       },
  //       borderRadius: '6px',
  //       padding: '11px 16px',
  //       textDecoration: 'none',
  //       cursor: 'pointer',
  //       height: '100%'
  //     }}
  //   >
  //     <svg
  //       width='16'
  //       height='16'
  //       viewBox='0 0 24 24'
  //       fill='none'
  //       stroke='#FFFFFF'
  //       strokeWidth='2'
  //       strokeLinecap='round'
  //       strokeLinejoin='round'
  //       style={{ minWidth: '16px' }}
  //     >
  //       <rect x='3' y='4' width='18' height='18' rx='2' ry='2' />
  //       <line x1='16' y1='2' x2='16' y2='6' />
  //       <line x1='8' y1='2' x2='8' y2='6' />
  //       <line x1='3' y1='10' x2='21' y2='10' />
  //     </svg>
  //     <Typography sx={{ color: '#FFFFFF', fontSize: '13px', fontWeight: 500 }}>
  //       Schedule A Free Consultation
  //     </Typography>

      </Box> */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
          border: '1px solid #D7D9E6',
          borderRadius: '6px',
          padding: { xs: '12px 16px', sm: '10px 16px' },
          minWidth: { xs: '100%', sm: '35%' },
          gap: { xs: 1, sm: 0 }
        }}
      >
        {/* Header with title and info icon */}
        <Box
          sx={{
            display: { xs: 'flex', sm: 'none' },
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: { xs: '4px', sm: '0' },
            width: '100%'
          }}
        >
          <Typography
            sx={{
              fontSize: '13px',
              fontWeight: 700,
              color: '#333333'
            }}
          >
            Credits balance:
          </Typography>
          <InfoIcon
            sx={{
              fontSize: '16px',
              color: '#9DA4B7'
            }}
          />
        </Box>

        {/* Stats container - different layout for mobile and desktop */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'row', sm: 'row' },
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            marginTop: { xs: '6px', sm: '0' }
          }}
        >
          {stats.map((stat, index) => (
            <React.Fragment key={stat.label}>
              {/* Mobile view: stacked layout */}
              <Box
                sx={{
                  display: { xs: 'flex', sm: 'none' },
                  flexDirection: 'column',
                  alignItems: 'space-between',
                  width: 'calc(33.33% - 2px)',
                  padding: '0px',
                  borderRadius: '4px'
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {stat.icon}
                  <Typography
                    sx={{
                      fontSize: '13px',
                      fontWeight: 600,
                      color: '#555555',
                      ml: 0.75
                    }}
                  >
                    {stat.label}:
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    fontSize: '13px',
                    fontWeight: 400,
                    color: '#101010',
                    ml: 3.5 // Align with label
                  }}
                >
                  {stat.value}
                </Typography>
              </Box>

              {/* Desktop view: inline layout */}
              <Box
                sx={{
                  display: { xs: 'none', sm: 'flex' },
                  alignItems: 'center',
                  gap: 0 // Change from gap: 2 to gap: 0
                }}
              >
                {stat.icon}
                <Typography
                  sx={{
                    fontSize: '13px',
                    fontWeight: 700,
                    color: '#101010',
                    ml: 1
                  }}
                >
                  {stat.label}: <Box component='span' sx={{ fontWeight: 400 }}>{stat.value}</Box>
                </Typography>
              </Box>

              {/* Divider - adjust for mobile/desktop */}
              {index < stats.length - 1 && (
                <Divider
                  orientation='vertical'
                  flexItem
                  sx={{
                    height: { xs: '40px', sm: '22px' },
                    borderColor: '#D7D9E6',
                    mx: { xs: '4px', sm: '8px' }
                  }}
                />
              )}
            </React.Fragment>
          ))}
        </Box>
        <InfoIcon sx={{
          fontSize: '16px',
          marginLeft: '5px',
          color: '#9DA4B7',
          display: { xs: 'none', sm: 'block' }
        }}
        />
      </Box>
    </Box>
  )
}

export default CreditBalance
