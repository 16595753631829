import React, { useState, useEffect } from 'react'
import {
  Box,
  Typography,
  Menu,
  MenuItem,
  Button,
  Modal,
  TextField,
  CircularProgress
} from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {
  useCreatePipelineMutation,
  useGetPipelinesForUserQuery
} from 'src/services/dealpipeline-crm-service'

import { toast } from 'react-hot-toast'
import usePlan from 'src/hooks/usePlan'

const PipelineSelector = ({
  onDropdownClick,
  dropdownAnchor,
  onDropdownClose,
  setSelectedPipeline,
  workspaceId,
  userId,
  openCreatePipelineModal,
  closeCreatePipelineModal
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [newPipelineName, setNewPipelineName] = useState('')
  const [selectedDealPipeline, setSelectedDealPipeline] = useState(null)
  const [createPipeline] = useCreatePipelineMutation()
  const { planDetails } = usePlan()

  // Fetch deal pipelines from the backend
  const { data: dealPipelines, isLoading: isLoadingPipelines } = useGetPipelinesForUserQuery({
    workspaceId,
    userId
  })

  // Map dealPipelines to dropdown options
  const dropdownOptions = dealPipelines
    ? dealPipelines.map((pipeline) => ({
      label: pipeline.title,
      totalValue: pipeline.totalValue || 0,
      id: pipeline._id,
      labels: pipeline.labels,
      createdAt: pipeline.createdAt,
      updatedAt: pipeline.updatedAt
    }))
    : []

  // Set the default selected pipeline when pipelines are loaded
  useEffect(() => {
    if (dealPipelines && dealPipelines.length > 0 && !selectedDealPipeline) {
      setSelectedPipeline(dropdownOptions[0])
      setSelectedDealPipeline(dropdownOptions[0])
    }
  }, [dealPipelines, dropdownOptions, setSelectedPipeline, selectedDealPipeline])

  useEffect(() => {
    setIsModalOpen(openCreatePipelineModal)
  }, [openCreatePipelineModal])

  const handleCreatePipeline = async () => {
    if (!newPipelineName.trim()) return

    try {
      const newPipeline = {
        title: newPipelineName,
        workspaceId,
        description: 'My new pipeline', // Add additional fields as required
        labels: [],
        createdBy: userId
      }

      await createPipeline(newPipeline).unwrap()
      setIsModalOpen(false)
      setNewPipelineName('')
      toast.success('Pipeline created successfully')
    } catch (error) {
      toast.error('Failed to create pipeline')
    }
  }

  const handlePipelineChange = (option) => {
    setSelectedPipeline(option) // Update the selected pipeline
    setSelectedDealPipeline(option) // Update the selected pipeline ID
    onDropdownClose(option) // Close the menu
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Button
        onClick={onDropdownClick}
        endIcon={<ArrowDropDownIcon />}
        sx={{
          backgroundColor: 'white',
          borderRadius: '10px',
          padding: '5px 15px',
          color: '#111927',
          fontWeight: '500',
          textTransform: 'none',
          border: '2px solid #3F50F8'
        }}
      >
        {(dropdownOptions.length > 0 &&
          dropdownOptions.find((option) => option.id === selectedDealPipeline?.id)?.label) ||
          'Select Pipeline'}
        <Typography
          sx={{
            fontWeight: '700',
            marginLeft: '5px',
            color: '#4caf50'
          }}
        >
          <Typography
            sx={{
              fontWeight: '700',
              backgroundColor: '#DAEFDF',
              color: '#4caf50',
              padding: '4px',
              borderRadius: '6px'
            }}
          >
            ${' '}
            {(dropdownOptions.length > 0 &&
              dropdownOptions
                .find((option) => option.id === selectedDealPipeline?.id)
                ?.totalValue?.toLocaleString()) ||
              0}
          </Typography>
        </Typography>
      </Button>
      <Menu
        anchorEl={dropdownAnchor}
        open={Boolean(dropdownAnchor)}
        onClose={() => onDropdownClose(null)}
        sx={{
          width: '100%'
        }}
      >
        {isLoadingPipelines
          ? (
            <MenuItem>
              <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <CircularProgress size={20} sx={{ color: '#8181B0' }} />
              </Box>
            </MenuItem>
            )
          : (
              dropdownOptions.map((option) => (
                <MenuItem key={option.id} onClick={() => handlePipelineChange(option)}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', gap: 2 }}>
                    <Typography>{option.label}</Typography>
                    {option.totalValue !== undefined && (
                      <Typography
                        sx={{
                          fontWeight: '700',
                          backgroundColor: '#DAEFDF',
                          color: '#4caf50',
                          padding: '4px',
                          borderRadius: '6px'
                        }}
                      >
                        $ {option.totalValue}
                      </Typography>
                    )}
                  </Box>
                </MenuItem>
              ))
            )}

        <Button
          variant='contained'
          sx={{
            backgroundColor:
              dealPipelines &&
              ((planDetails?.isFreeUser && dealPipelines.length >= 1) ||
                (planDetails?.isStarterPlan && dealPipelines.length >= 5) ||
                (planDetails?.isGrowthPlan && dealPipelines.length >= 10))
                ? '#bdbdbd' // Greyed-out for disabled state
                : '#3F50F8', // Default button color
            color: '#fff',
            borderRadius: '8px',
            fontWeight: '600',
            margin: '10px',
            width: '90%',
            '&:hover': {
              backgroundColor:
                dealPipelines &&
                ((planDetails?.isFreeUser && dealPipelines.length >= 1) ||
                  (planDetails?.isStarterPlan && dealPipelines.length >= 5) ||
                  (planDetails?.isGrowthPlan && dealPipelines.length >= 10))
                  ? '#bdbdbd' // Prevent hover effect when disabled
                  : '#3B49E1' // Default hover color
            }
          }}
          disabled={
            dealPipelines &&
            ((planDetails?.isFreeUser && dealPipelines.length >= 1) ||
              (planDetails?.isStarterPlan && dealPipelines.length >= 5) ||
              (planDetails?.isGrowthPlan && dealPipelines.length >= 10))
          }
          onClick={() => setIsModalOpen(true)}
        >
          Create New Pipeline
        </Button>
      </Menu>

      {/* Modal for Creating New Pipeline */}
      <Modal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false)
          closeCreatePipelineModal()
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '10px'
          }}
        >
          <Typography variant='h6' sx={{ fontWeight: '700', marginBottom: '20px' }}>
            Create a new pipeline
          </Typography>
          <TextField
            fullWidth
            variant='outlined'
            label='Pipeline name'
            value={newPipelineName}
            onChange={(e) => setNewPipelineName(e.target.value)}
            sx={{ marginBottom: '20px' }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button
              onClick={() => setIsModalOpen(false)}
              sx={{
                backgroundColor: '#f5f5f5',
                color: '#111927',
                textTransform: 'none'
              }}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              disabled={!newPipelineName.trim()}
              onClick={handleCreatePipeline}
              sx={{
                backgroundColor: newPipelineName.trim() ? '#3F50F8' : '#bdbdbd',
                color: '#fff',
                textTransform: 'none'
              }}
            >
              Create a pipeline
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default PipelineSelector
