import React, { useState, createContext } from 'react'
import { Box, Stack, Typography, useMediaQuery } from '@mui/material'

import PropTypes from 'prop-types'
import 'react-quill/dist/quill.snow.css'
import { useGetMeAndWorkspaceQuery } from 'src/services/user-service.js'
// import HeaderWithPopoverUpdated from "src/components/HeaderWithPopoverUpdated.js";
import MainFilters from 'src/components/inboxHubV2/MainFilters.js'
import CreateAnEmail from 'src/components/inboxHubV2/CreateAnEmail.js'
import EmailList from 'src/components/inboxHubV2/EmailList'
import { useSelector } from 'react-redux'
import EmailContent from 'src/components/inboxHubV2/EmailContent'
import HeaderWithPopoverUpdated from 'src/components/HeaderWithPopoverUpdated'

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pl: { xs: 0, md: 3 } }}>
          <Typography sx={{ border: '1px solid #E4E4E5', borderRadius: '12px' }}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

function CustomTabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ width: '100%' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

export const InboxHubContext = createContext()
export const EmailListingContext = createContext()

const Page = () => {
  const { data } = useGetMeAndWorkspaceQuery()
  const isMobile = useMediaQuery('(max-width:1024px)')

  const workspace = useSelector((state) => state.workspace)

  const [selectedMainFilter, setSelectedMainFilter] = useState('inbox')
  const [selectedCampaignId, setSelectedCampaignId] = useState(null)
  const [selectedStatusLabelId, setSelectedStatusLabelId] = useState(null)
  const [allChecked, setAllChecked] = useState(false)
  const [markedEmailIds, setmarkedEmailIds] = useState([])
  const [currentlyOpenedEmail, setCurrentlyOpenedEmail] = useState(null)
  const [totalInbox, setTotalInbox] = useState(null)
  const [totalUnread, setTotalUnread] = useState(null)
  const [totalSent, setTotalSent] = useState(null)
  const [createAnEmailOpen, setCreateAnEmailOpen] = useState(false)
  const [currentPopUpOpened, setCurrentPopUpOpened] = useState('')
  const [currentBulkDialogOpened, setCurrentBulkDialogOpened] = useState('')
  const [getCampaignEmailPayload, setGetCampaignEmailPayload] = useState({
    offset: 0,
    perPage: 20,
    workspaceId: workspace?._id,
    campaignId: null,
    label: null,
    unread: false
  })
  const [searchEmailValue, setSearchEmailValue] = useState('')
  return (
    <InboxHubContext.Provider
      value={{
        selectedMainFilter,
        setSelectedMainFilter,
        selectedCampaignId,
        setSelectedCampaignId,
        selectedStatusLabelId,
        setSelectedStatusLabelId,
        allChecked,
        setAllChecked,
        markedEmailIds,
        setmarkedEmailIds,
        currentlyOpenedEmail,
        setCurrentlyOpenedEmail,
        totalInbox,
        setTotalInbox,
        totalUnread,
        setTotalUnread,
        totalSent,
        setTotalSent,
        createAnEmailOpen,
        setCreateAnEmailOpen,
        currentPopUpOpened,
        setCurrentPopUpOpened,
        currentBulkDialogOpened,
        setCurrentBulkDialogOpened,
        isMobile
      }}
    >
      <EmailListingContext.Provider
        value={{
          getCampaignEmailPayload,
          setGetCampaignEmailPayload,
          searchEmailValue,
          setSearchEmailValue
        }}
      >
        <Box
          sx={{
            width: '100%',
            // height: "calc(100vh - 134px)",
            height: { xs: '93vh', mmd: '100vh' },
            pl: isMobile ? 2 : 4,
            pr: 2,
            my: -4,
            display: 'flex',
            flexDirection: 'column'
            // justifyContent: "flex-start",
            // alignItems: "center",
          }}
        >
          <Box sx={{ height: isMobile ? '5%' : '10%', display: 'flex', alignItems: 'center' }}>
            {
              currentlyOpenedEmail
                ? (
                  <Stack direction='row' onClick={() => { setCurrentlyOpenedEmail() }}>
                    <Typography>
                      Back To Login
                    </Typography>
                  </Stack>
                  )
                : (
                  <HeaderWithPopoverUpdated
                    title='InboxHub'
                    onWorkspaceChange={() => { }}
                    selectedTimeline={data?.workspace}
                  />
                  )
            }
          </Box>
          {workspace?.enterprise && !workspace?.analytics?.uiForUnibox && (
            <Typography
              sx={{
                fontSize: '15px',
                fontWeight: 700,
                lineHeight: '16.38px',
                color: '#0071f6',
                mt: 3
              }}
            >
              Purchase the InboxHub UI Plan to manage your inboxHub process effectively.
            </Typography>
          )}
          {workspace._id &&
            ((workspace?.analytics?.uiForUnibox && workspace?.enterprise) ||
              !workspace?.enterprise) && (
                <Box sx={{ display: 'flex', gap: 1, width: '100%', height: { xs: '95%', mmd: '90%' } }}>
                  {
                  isMobile
                    ? (
                      <Box
                        sx={{
                          display: currentlyOpenedEmail ? 'none' : 'flex',
                          gap: 1,
                          flexDirection: 'column',
                          height: '100%',
                          width: isMobile ? '100%' : '30%'
                        }}
                      >
                        <MainFilters />
                        <EmailList />
                      </Box>
                      )
                    : (
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 1,
                          flexDirection: 'column',
                          height: '100%',
                          width: isMobile ? '100%' : '30%'
                        }}
                      >
                        <MainFilters />
                        <EmailList />
                      </Box>
                      )
                }
                  {
                  isMobile
                    ? (
                        currentlyOpenedEmail &&
                          <Box
                            sx={{
                              height: '100%',
                              width: '100%'
                            }}
                          >
                            <EmailContent email={currentlyOpenedEmail} />
                          </Box>
                      )
                    : (
                        (
                          <Box
                            sx={{
                              height: '100%',
                              width: '70%'
                            }}
                          >
                            <EmailContent email={currentlyOpenedEmail} />
                          </Box>
                        )
                      )
                }
                </Box>
          )}
          {createAnEmailOpen && <CreateAnEmail setCreateAnEmailOpen={setCreateAnEmailOpen} />}
        </Box>
      </EmailListingContext.Provider>
    </InboxHubContext.Provider>
  )
}

export default Page
