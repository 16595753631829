import { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { InboxHubContext } from 'src/pages/InboxHub'

import { useGetCampaignsQuery } from 'src/services/campaign-service'
export function useMenu () {
  const [anchorEl, setAnchorEl] = useState(null)
  const [currentFilter, setCurrentFilter] = useState(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-menu' : undefined

  const handleClick = (event, filter) => {
    setCurrentFilter(filter)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return { anchorEl, currentFilter, open, id, handleClick, handleClose }
}
export function useTextAdjustments () {
  const [alignment, setAlignment] = useState('left')
  const [fontSize, setFontSize] = useState('Medium')
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClickTextAdjustments = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleAlignment = (newAlignment) => {
    if (newAlignment) {
      setAlignment(newAlignment)
    }
  }

  const handleFontSizeChange = (fontSize) => {
    setFontSize(fontSize)
  }
  const handleCloseTextAdjustments = () => {
    setAnchorEl(null)
  }

  return {
    anchorEl,
    open,
    alignment,
    fontSize,
    handleAlignment,
    handleFontSizeChange,
    handleClickTextAdjustments,
    handleCloseTextAdjustments
  }
}

export function usePopups () {
  const [openPopUp, setOpenPopUp] = useState(false)
  const { setCurrentPopUpOpened, setCurrentBulkDialogOpened } = useContext(InboxHubContext)
  const handleOpenPopup = (popUpName) => {
    setOpenPopUp(true)
    setCurrentPopUpOpened(popUpName)
  }
  const handleClosePopup = () => {
    setOpenPopUp(false)
    setCurrentPopUpOpened('')
    setCurrentBulkDialogOpened('')
  }

  return { openPopUp, handleOpenPopup, handleClosePopup }
}

export function useCampaignsList () {
  const [campaignList, setCampaignList] = useState([])
  const workspace = useSelector((state) => state.workspace)
  const [offset, setOffset] = useState(0)
  const { data: { docs, total } = { docs: [], total: 0 }, isLoading } = useGetCampaignsQuery({
    offset,
    limit: 10,
    unibox: true,
    workspaceId: workspace._id
  })

  const handleClickMore = () => {
    if (offset < total) {
      setOffset(offset + 10)
    }
  }

  useEffect(() => {
    setCampaignList([...campaignList, ...docs])
  }, [docs])
  return { offset, campaignList, total, isLoading, handleClickMore }
}
