import React, { useContext, useState } from 'react'
import {
  Box,
  Typography,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  IconButton,
  Select,
  MenuItem,
  Stack,
  styled,
  CircularProgress,
  Menu,
  Chip
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import { WhiteButton } from './CreateAnEmail'
import { useUpdateLeadMutation } from 'src/services/leads-service'
import toast from 'react-hot-toast'
import { InboxHubContext } from 'src/pages/InboxHub'
import { StyledChip } from './AddToCampaignForm'
import {
  AdditionalInfoIcon,
  BasicInfoIcon,
  EditLeadInvalid,
  EditLeadNoStatus,
  EditLeadQuestionable,
  EditLeadVerified
} from 'src/assets/inboxHub/InboxHubIcons'
import { useMenu } from 'src/hooks/InboxHubHooks'

const StyledSelect = styled(Select)(({ theme }) => ({
  minWidth: '100%',
  backgroundColor: '#FFF',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  '& .MuiSelect-select': {
    padding: theme.spacing(1)
  },
  '&:hover': {
    borderColor: 'rgb(138, 138, 138)'
  },
  '&.Mui-focused': {
    borderColor: 'rgb(138, 138, 138)'
  }
}))

const EditContactForm = ({ handleClosePopup }) => {
  const { setCurrentPopUpOpened } = useContext(InboxHubContext)
  const [phoneNumbers, setPhoneNumbers] = useState([{ number: '', type: 'other', status: '4' }])
  const [updateLead, { isLoading: isLeadUpdating }] = useUpdateLeadMutation()
  const [selectedAccounts, setSelectedAccounts] = useState([])
  const {
    currentlyOpenedEmail: {
      leads: { _id = '', firstName = '', lastName = '', email = '' } = {}
    } = {}
  } = useContext(InboxHubContext)

  const [accountPhone, setAccountPhone] = useState({ phone: '', status: '4' })

  const {
    anchorEl: accountOptionsAnchorEl,
    open: accountOptionsOpen,
    handleClick: handleOpenAccountsMenu,
    handleClose: handleAccountOptionsClose
  } = useMenu()

  const [payload, setPayload] = useState({ firstName, lastName, email, jobTitle: '' })

  const handleAddPhoneNumber = () => {
    setPhoneNumbers([...phoneNumbers, { number: '', type: 'other', status: '4' }])
  }

  const handleRemovePhoneNumber = (index) => {
    const updatedPhoneNumbers = phoneNumbers.filter((_, i) => i !== index)
    setPhoneNumbers(updatedPhoneNumbers)
  }

  const handlePhoneNumberChange = (index, field, value) => {
    const updatedPhoneNumbers = phoneNumbers.map((phone, i) =>
      i === index ? { ...phone, [field]: value } : phone
    )
    setPhoneNumbers(updatedPhoneNumbers)
  }

  function handleChangeTextFields (e) {
    setPayload((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  async function handleLeadEdit () {
    try {
      const { message } = await updateLead({
        id: _id,
        data: payload
      }).unwrap()
      toast.success(message)
      handleClosePopup()
    } catch (err) {
      toast.error(err.data.error.message)
    }
  }

  function handleClickAccountsOption (option) {
    handleAccountOptionsClose()
    if (!selectedAccounts.includes(option)) {
      setSelectedAccounts((prev) => [...prev, option])
    }
  }

  function handleDeleteAccountOption (option) {
    const filtered = selectedAccounts.filter((acc) => acc !== option)
    setSelectedAccounts([...filtered])
  }

  return (
    <Box
      sx={{
        width: '100%',
        margin: 'auto',
        backgroundColor: '#fff',
        borderRadius: 2,
        position: 'relative'
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', mmd: 'row' }, justifyContent: 'space-between', marginY: 1 }}>
        <Typography variant='h6' gutterBottom>
          Edit Contact
        </Typography>
        <Stack direction={{ xs: 'column', mmd: 'row' }} gap={{ xs: 1, mmd: 2 }}>
          <Button
            variant='outlined'
            onClick={() => {
              setCurrentPopUpOpened('editleadkeepediting')
            }}
          >
            Cancel
          </Button>
          {isLeadUpdating
            ? (
              <CircularProgress />
              )
            : (
              <Button onClick={handleLeadEdit} variant='contained' color='primary'>
                Save Edit
              </Button>
              )}
        </Stack>
      </Box>

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Stack direction='row' alignItems='center' gap={1}>
            <BasicInfoIcon />
            <Typography>Basic Information</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction='row' gap={2}>
            <TextField
              label='First Name'
              name='firstName'
              fullWidth
              margin='normal'
              value={payload.firstName}
              onChange={handleChangeTextFields}
            />
            <TextField
              label='Last Name'
              name='lastName'
              fullWidth
              margin='normal'
              value={payload.lastName}
              onChange={handleChangeTextFields}
            />
          </Stack>
          <TextField
            label='Email'
            name='email'
            fullWidth
            margin='normal'
            value={payload.email}
            onChange={handleChangeTextFields}
          />
          <TextField
            label='Job Title'
            name='jobTitle'
            fullWidth
            margin='normal'
            value={payload.jobTitle}
            onChange={handleChangeTextFields}
          />

          <Typography fontSize='14px' fontWeight={500} mt={2}>
            Account
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              border: '1px solid #ccc',
              borderRadius: 1,
              padding: '3px',
              cursor: 'pointer',
              minHeight: '2.5rem'
            }}
            onClick={(e) => {
              handleOpenAccountsMenu(e)
            }}
          >
            {selectedAccounts.map((option, i) => (
              <StyledChip
                key={i}
                label={option ?? ''}
                onDelete={() => {
                  handleDeleteAccountOption(option)
                }}
                sx={{ margin: '2px' }}
              />
            ))}
          </Box>
          <Typography fontSize='14px' fontWeight={500} mt={3}>
            Contact Stage
          </Typography>
          <StyledSelect defaultValue='Forbes' fullWidth margin='normal'>
            <MenuItem value='Forbes'>Interested</MenuItem>
          </StyledSelect>
        </AccordionDetails>
        <AccountsMenu
          open={accountOptionsOpen}
          moreOptionsAnchorEl={accountOptionsAnchorEl}
          handleClose={handleAccountOptionsClose}
          handleOptionClick={handleClickAccountsOption}
        />
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Stack direction='row' alignItems='center' gap={1}>
            <AdditionalInfoIcon />
            <Typography>Additional Information</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack gap={2}>
            <Stack direction={{ xs: 'column', mmd: 'row' }} alignItems='flex-end' gap={1}>
              <Stack width={{ xs: '100%', mmd: '70%' }}>
                <Typography sx={{ fontSize: '14px', fontWeight: 500 }} gutterBottom>
                  Account Phone
                </Typography>
                <TextField
                  value={accountPhone.phone}
                  onChange={(e) => {
                    setAccountPhone((prev) => ({ ...prev, phone: e.target.value }))
                  }}
                  width='100%'
                />
              </Stack>
              <StyledSelect
                value={accountPhone.status}
                onChange={(e) => {
                  setAccountPhone((prev) => ({ ...prev, status: e.target.value }))
                }}
                // displayEmpty
                sx={{ minWidth: { xs: '100%', mmd: '30%' }, mb: '-2px', height: '2.5rem' }}
              >
                <MenuItem value='1'>
                  <Chip
                    sx={{
                      scale: 1,
                      borderRadius: 4,
                      paddingLeft: 1,
                      backgroundColor: '#00AA3833',
                      border: '1px solid #00AA3866',
                      color: '#00AA38',
                      fontWeight: 700
                    }}
                    icon={<EditLeadVerified />}
                    label='Verified'
                  />
                </MenuItem>
                <MenuItem value='2'>
                  <Chip
                    sx={{
                      scale: 1,
                      borderRadius: 4,
                      paddingLeft: 1,
                      backgroundColor: '#FFA94533',
                      border: '1px solid #FFA94566',
                      color: '#FFA945',
                      fontWeight: 700
                    }}
                    icon={<EditLeadQuestionable />}
                    label='Questionable'
                  />
                </MenuItem>
                <MenuItem value='3'>
                  <Chip
                    sx={{
                      scale: 1,
                      borderRadius: 4,
                      paddingLeft: 1,
                      backgroundColor: '#FF454533',
                      border: '1px solid #FF454566',
                      color: '#FF4545',
                      fontWeight: 700
                    }}
                    icon={<EditLeadInvalid />}
                    label='Invalid'
                  />
                </MenuItem>
                <MenuItem value='4'>
                  <Chip
                    sx={{
                      scale: 1,
                      borderRadius: 4,
                      paddingLeft: 1,
                      backgroundColor: '#61616133',
                      border: '1px solid #61616133',
                      color: '#616161',
                      fontWeight: 700
                    }}
                    icon={<EditLeadNoStatus />}
                    label='No Status'
                  />
                </MenuItem>
              </StyledSelect>
            </Stack>
            <Stack>
              <Typography sx={{ fontSize: '14px', fontWeight: 500 }} gutterBottom>
                Contact Phones
              </Typography>
              <Stack gap={1}>
                {phoneNumbers.map((phone, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: { xs: 'column', mmd: 'row' },
                      gap: 1,
                      backgroundColor: 'rgb(241, 241, 241)',
                      p: 1,
                      borderRadius: 2,
                      border: '1px solid rgb(233, 233, 233)'
                    }}
                  >
                    <TextField
                      value={phone.number}
                      onChange={(e) => handlePhoneNumberChange(index, 'number', e.target.value)}
                      fullWidth
                    />
                    <StyledSelect
                      value={phone.type}
                      onChange={(e) => handlePhoneNumberChange(index, 'type', e.target.value)}
                      // displayEmpty
                      sx={{ minWidth: { xs: '100%', mmd: 120 } }}
                    >
                      <MenuItem value='mobile'>Mobile</MenuItem>
                      <MenuItem value='work'>Work - Direct</MenuItem>
                      <MenuItem value='corporate'>Corporate Phone</MenuItem>
                      <MenuItem value='home'>Home</MenuItem>
                      <MenuItem value='other'>Other</MenuItem>
                    </StyledSelect>
                    <StyledSelect
                      value={phone.status}
                      onChange={(e) => {
                        handlePhoneNumberChange(index, 'status', e.target.value)
                      }}
                      displayEmpty
                      sx={{ minWidth: { xs: '100%', mmd: '30%' }, mb: '-2px', height: '2.6rem' }}
                    >
                      <MenuItem value='1'>
                        <Chip
                          sx={{
                            scale: 1,
                            borderRadius: 4,
                            paddingLeft: 1,
                            backgroundColor: '#00AA3833',
                            border: '1px solid #00AA3866',
                            color: '#00AA38',
                            fontWeight: 700
                          }}
                          icon={<EditLeadVerified />}
                          label='Verified'
                        />
                      </MenuItem>
                      <MenuItem value='2'>
                        <Chip
                          sx={{
                            scale: 1,
                            borderRadius: 4,
                            paddingLeft: 1,
                            backgroundColor: '#FFA94533',
                            border: '1px solid #FFA94566',
                            color: '#FFA945',
                            fontWeight: 700
                          }}
                          icon={<EditLeadQuestionable />}
                          label='Questionable'
                        />
                      </MenuItem>
                      <MenuItem value='3'>
                        <Chip
                          sx={{
                            scale: 1,
                            borderRadius: 4,
                            paddingLeft: 1,
                            backgroundColor: '#FF454533',
                            border: '1px solid #FF454566',
                            color: '#FF4545',
                            fontWeight: 700
                          }}
                          icon={<EditLeadInvalid />}
                          label='Invalid'
                        />
                      </MenuItem>
                      <MenuItem value='4'>
                        <Chip
                          sx={{
                            scale: 1,
                            borderRadius: 4,
                            paddingLeft: 1,
                            backgroundColor: '#61616133',
                            border: '1px solid #61616133',
                            color: '#616161',
                            fontWeight: 700
                          }}
                          icon={<EditLeadNoStatus />}
                          label='No Status'
                        />
                      </MenuItem>
                    </StyledSelect>
                    <IconButton onClick={() => handleRemovePhoneNumber(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))}
              </Stack>
            </Stack>
            <WhiteButton
              sx={{ width: '100%' }}
              startIcon={<AddIcon />}
              onClick={handleAddPhoneNumber}
            >
              Add a Phone Number
            </WhiteButton>
            <Stack width='100%'>
              <Typography sx={{ fontSize: '14px', fontWeight: 500 }} gutterBottom>
                Phone Notes
              </Typography>
              <TextField
                multiline
                placeholder="Use this box to enter instructions on how to enter through the phone tree. (E.g. Dial 123# to reach the Contact's direct line)."
                onChange={() => { }}
                width='100%'
              />
            </Stack>
            <Stack width='100%'>
              <Typography sx={{ fontSize: '14px', fontWeight: 500 }} gutterBottom>
                LinkedIn URL
              </Typography>
              <TextField onChange={() => { }} width='100%' />
            </Stack>
            <Stack direction={{ xs: 'column', mmd: 'row' }} gap={1}>
              <Stack width={{ xs: '100%', mmd: '50%' }}>
                <Typography sx={{ fontSize: '14px', fontWeight: 500 }} gutterBottom>
                  Location
                </Typography>
                <TextField onChange={() => { }} width='100%' />
              </Stack>
              <Stack width={{ xs: '100%', mmd: '50%' }}>
                <Typography sx={{ fontSize: '14px', fontWeight: 500 }} gutterBottom>
                  TimeZone
                </Typography>
                <StyledSelect defaultValue='1' fullWidth>
                  <MenuItem value='1'>Europe/KIEV</MenuItem>
                  <MenuItem value='2'>Karachi/Islamabad</MenuItem>
                  <MenuItem value='3'>Mumbai/Pune</MenuItem>
                </StyledSelect>
              </Stack>
            </Stack>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default EditContactForm

const accountsList = ['Forbes', 'Main', 'Sales']

const AccountsMenu = ({
  moreOptionsAnchorEl,
  handleClose,
  open,
  handleOptionClick,
  selectedField
}) => {
  return (
    <Menu
      id='accounts-list'
      anchorEl={moreOptionsAnchorEl}
      open={open}
      onClose={handleClose}
      sx={{ width: '100%', border: '1px solid black' }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      MenuListProps={{
        'aria-labelledby': 'basic-button'
      }}
      PaperProps={{
        style: {
          maxHeight: 400, // Set the maximum height
          overflow: 'auto' // Enable scrolling
        }
      }}
    >
      <Stack sx={{ width: '100%' }}>
        {accountsList.map((account, key) => (
          <MenuItem
            key={key}
            onClick={() => {
              handleOptionClick(account)
            }}
            sx={{ width: '100%' }}
          >
            <Typography textOverflow='ellipsis' overflow='hidden' width='100%'>
              {account}
            </Typography>
          </MenuItem>
        ))}
      </Stack>
    </Menu>
  )
}
