import { Box, Typography, Button, SvgIcon, useTheme } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import BilingPage from './BillingPage.js'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Logo } from '../logo.js'
import { StarIcon } from './settingsImages/SvgPlanIcons'

const Page = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const theme = useTheme()
  const workspaceID = searchParams.get('workspaceID')
  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        p: { xs: 1, sm: 2 },
        overflowX: 'hidden'
      }}
    >

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          width: '75%',
          flexDirection: 'column',
          mb: { xs: 2, sm: 3 },
          position: 'relative'
        }}
      />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          flexDirection: 'column',
          position: 'relative'
        }}
      >
        <Box>
          <Button
            variant='outlined'
            onClick={() => navigate('/settingsUpdated')}
            sx={{
              display: { xs: 'none', sm: 'flex' },
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
              fontSize: '14px',
              fontWeight: 'bold',
              borderRadius: '12px',
              padding: '8px 16px',
              textTransform: 'none',
              borderColor: 'rgba(0, 0, 0, 0.2)',
              color: 'black',
              position: 'absolute',
              top: 0,
              right: 100,
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
                borderColor: 'rgba(0, 0, 0, 0.4)'
              }
            }}
          >
            I will do it later
            <ArrowForwardIcon fontSize='small' sx={{ color: 'rgba(0, 0, 0, 0.6)' }} />
          </Button>
          <Box sx={{ position: 'relative', width: '100%' }}>
            <Box
              sx={{
                position: 'absolute',
                top: { xs: '-11px', sm: '-11px', md: '-9px', lg: '-9px', xl: '-9px' },
                right: { xs: '104%', sm: '104%', md: '117%', lg: '115%', xl: '124%' },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <SvgIcon
                component={StarIcon}
                stroke={theme.palette.primary.main}
                width='50'
                height='60'
              />
            </Box>
            <Box
              sx={{
                position: 'absolute',
                top: { xs: '36px', sm: '36px', md: '103px', lg: '109px', xl: '102px' },
                left: { xs: '103%', sm: '103', md: '121%', lg: '124%', xl: '139%' },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <SvgIcon
                component={StarIcon}
                stroke={theme.palette.primary.main}
                width='36'
                height='40'
              />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
              <Typography
                sx={{
                  color: (theme) => theme.palette.primary.primaryText,
                  fontSize: { xs: '20px', sm: '24px' },
                  fontWeight: 900,
                  lineHeight: { xs: '28px', sm: '32px' },
                  letterSpacing: '0px',
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'center',
                  flexWrap: { xs: 'wrap', sm: 'nowrap' },
                  justifyContent: 'center',
                  gap: 1
                }}
              >
                Welcome to&nbsp;
                <Box component='span' sx={{ display: 'inline-flex', alignItems: 'center' }}>
                  <Logo sx={{ color: '#3F4FF8' }} logoTextColor='#3F4FF8' />
                </Box>
              </Typography>
            </Box>

            <Typography
              sx={{
                color: '#8181B0',
                fontSize: { xs: '14px', sm: '16px' },
                fontWeight: 400,
                lineHeight: { xs: '20px', sm: '24px' },
                letterSpacing: '0px',
                mt: { xs: 1, sm: '5px', md: '5px', lg: '5px' },
                textAlign: 'center'
              }}
            >
              Please continue with plan selections.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: '100%', sm: '90%' },
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}
        >
          <BilingPage workspaceID={workspaceID} />
        </Box>
      </Box>
    </Box>
  )
}

export default Page
