import ReactDOM from 'react-dom'
import React, { useState } from 'react'
import { Box, Typography, Modal, Button, TextField } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import DraggableColumn from './DraggableColumn'
import { useCreateLabelMutation } from 'src/services/campaign-service'
import { toast } from 'react-hot-toast'
import usePlan from 'src/hooks/usePlan'

const ColumnManagementModal = ({
  isOpen,
  onClose,
  pipelineData,
  setPipelineData,
  onColumnVisibilityToggle,
  onDeleteColumn,
  onDragEnd,
  onMenuOpen,
  onMenuClose,
  refetchLabels,
  labels,
  updatedLabelsForPipeline
}) => {
  const [addColumnModalOpen, setAddColumnModalOpen] = useState(false)
  const [newColumnName, setNewColumnName] = useState('')
  const [newColumnValue, setNewColumnValue] = useState('')
  const [createLabel] = useCreateLabelMutation()
  const { planDetails } = usePlan()

  const handleAddNewColumn = async () => {
    try {
      const data = { name: newColumnName, type: 'neutral', valuePerDeal: newColumnValue }
      const { message } = await createLabel(data).unwrap()
      toast.success(message)
    } catch (error) {
      toast.error(error.message)
    }
    refetchLabels()

    setAddColumnModalOpen(false) // Close modal
    setNewColumnName('') // Reset fields
    setNewColumnValue('')
  }

  const handleSaveChanges = () => {
    const labelsForPipeline = Object.values(pipelineData.columns)
      .filter((column) => column.visible)
      .map((column) => column.id)

    updatedLabelsForPipeline(labelsForPipeline)
    onClose()
  }

  const handleOnCancel = () => {
    onClose()
  }

  const PortalAwareDraggable = ({ children }) => {
    return ReactDOM.createPortal(children, document.body)
  }

  return (
    <>
      {/* Main Column Management Modal */}
      <Modal open={isOpen} onClose={onClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: '10px',
            p: 4,
            // position: "relative",
            overflow: 'visible'
          }}
        >
          {planDetails?.isInifinityPlan && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography variant='h6' sx={{ fontWeight: '700' }}>
                Manage Columns ({Object.keys(pipelineData.columns).length})
              </Typography>
              <Button
                startIcon={<AddIcon />}
                sx={{
                  backgroundColor: '#3F50F8',
                  color: '#fff',
                  borderRadius: '10px',
                  padding: '5px 15px',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: '#3F50F8'
                  }
                }}
                onClick={() => setAddColumnModalOpen(true)}
              >
                Add New Column
              </Button>
            </Box>
          )}

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='columns'>
              {(provided) => (
                <Box
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  sx={{
                    maxHeight: '400px',
                    overflowY: 'auto',
                    position: 'relative'
                  }}
                >
                  {Object.entries(pipelineData.columns).map(([columnId, column], index) => (
                    <DraggableColumn
                      key={columnId}
                      columnId={columnId}
                      column={column}
                      index={index}
                      onColumnVisibilityToggle={onColumnVisibilityToggle}
                      onDeleteColumn={onDeleteColumn}
                      onMenuOpen={onMenuOpen}
                      onMenuClose={onMenuClose}
                      PortalAwareDraggable={PortalAwareDraggable}
                    />
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
            <Button onClick={handleOnCancel}>Cancel</Button>
            <Button
              variant='contained'
              sx={{ backgroundColor: '#3F50F8', color: '#fff' }}
              onClick={handleSaveChanges} // Save changes
            >
              Save changes
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Add New Column Modal */}
      <Modal open={addColumnModalOpen} onClose={() => setAddColumnModalOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: '10px',
            p: 4
          }}
        >
          <Typography variant='h6' sx={{ fontWeight: '700', mb: 3 }}>
            Add a New Column
          </Typography>
          <Box sx={{ mb: 3 }}>
            <Typography sx={{ fontSize: '14px', mb: 1 }}>Name of Column</Typography>
            <TextField
              fullWidth
              value={newColumnName}
              onChange={(e) => setNewColumnName(e.target.value)}
              placeholder='Enter column name'
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <Typography sx={{ fontSize: '14px', mb: 1 }}>Value per Deal</Typography>
            <TextField
              fullWidth
              type='number'
              value={newColumnValue}
              onChange={(e) => {
                const value = e.target.value
                if (value === '' || (Number(value) > 0 && !isNaN(value))) {
                  setNewColumnValue(value)
                }
              }}
              placeholder='Enter value per lead'
              inputProps={{ min: 1 }}
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button onClick={() => setAddColumnModalOpen(false)}>Cancel</Button>
            <Button
              variant='contained'
              sx={{ backgroundColor: '#3F50F8', color: '#fff' }}
              onClick={handleAddNewColumn} // Handle Add New Column
              disabled={!newColumnName || !newColumnValue}
            >
              Add Column
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default ColumnManagementModal
