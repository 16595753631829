import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { config } from 'src/config.js'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import { DialogTitle } from '@mui/material'
import { useTheme } from '@emotion/react'
import CommonUpgradeModal from 'src/components/leadsFinder/filters/commonUpgradeModal'
import useUserVerification from 'src/hooks/useUserVerification'
import { useGetCurrentPlanQuery } from 'src/services/billing-service'

const StyledDialog = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    borderRadius: '10px',
    maxWidth: 850,
    width: '100%',
    margin: 1,
    backgroundImage: 'none'
  }
}))

const ActionButton = styled('button')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  border: '1px solid #E1E1E1',
  borderRadius: '6px',
  padding: 0,
  overflow: 'hidden',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    borderColor: theme.palette.primary.main,
    '& .icon-wrapper': {
      transform: 'scale(1.1)'
    }
  }
}))

const IconWrapper = styled(Box)(({ theme }) => ({
  width: 30,
  height: 30,
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.light,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.primary.main,
  transition: 'transform 0.3s ease-in-out'
}))

const planNames = {
  [config.SENDING_WARMUP_MONTHLY_GROWTH_PRICE_ID]: 'starter',
  [config.SENDING_WARMUP_YEARLY_GROWTH_PRICE_ID]: 'starter-yearly',
  [config.SENDING_WARMUP_MONTHLY_SKYROCKET_PRICE_ID]: 'growth',
  [config.SENDING_WARMUP_YEARLY_SKYROCKET_PRICE_ID]: 'growth-yearly',
  [config.SENDING_WARMUP_MONTHLY_SCALE_PRICE_ID]: 'infinity',
  [config.SENDING_WARMUP_YEARLY_SCALE_PRICE_ID]: 'infinity-yearly',
  [config.LEADS_MONTHLY_GROWTH_PRICE_ID]: 'starter',
  [config.LEADS_YEARLY_GROWTH_PRICE_ID]: 'starter-yearly',
  [config.LEADS_MONTHLY_SKYROCKET_PRICE_ID]: 'growth',
  [config.LEADS_YEARLY_SKYROCKET_PRICE_ID]: 'growth-yearly',
  [config.LEADS_MONTHLY_SCALE_PRICE_ID]: 'infinity',
  [config.LEADS_YEARLY_SCALE_PRICE_ID]: 'infinity-yearly'
}

export const AddStepDialog = ({
  open,
  onClose,
  handleAddStepClick,
  handleCustomIconClick,
  setReactQuillPlaceholder,
  setStartAnimation,
  setSendInviteLinkedInModal,
  setSendMessageLinkedInModal,
  getAllLinkedInAccounts,
  sequenceId
}) => {
  const theme = useTheme()
  const [isPlanCommonUpgradeModalOpen, setIsPlanCommonUpgradeModalOpen] = useState(false)
  const currentPlan = useGetCurrentPlanQuery()
  const planId = currentPlan?.currentData?.subscription?.sendingWarmup?.planId ?? currentPlan?.currentData?.subscription?.leads?.planId
  const { isEarlyBirdAccessUser: isEarlyBirdUser, isWhiteLabelUser } = useUserVerification()
  const planName = isEarlyBirdUser ? 'earlyBird' : (planNames[planId] ?? 'free')

  const closeModal = (event) => {
    event.stopPropagation()
    setIsPlanCommonUpgradeModalOpen(false)
  }

  const emailActions = [
    {
      icon: <AddCircleOutlineIcon sx={{ fontSize: 16 }} />,
      title: 'From Scratch',
      desc: 'Create a custom email from scratch',
      handleClick: handleAddStepClick
    },
    {
      icon: <DescriptionOutlinedIcon sx={{ fontSize: 16 }} />,
      title: 'Use a template',
      desc: 'Start with a pre-built template',
      handleClick: () => {
        handleAddStepClick()
        handleCustomIconClick()
      }
    },
    {
      icon: <AutoAwesomeIcon sx={{ fontSize: 16 }} />,
      title: 'Start with AI',
      desc: 'Let AI draft your email',
      handleClick: () => {
        handleAddStepClick()
        setReactQuillPlaceholder('Enter Prompt for email here...')
        setStartAnimation(true)
      }
    }
  ]

  const linkedInActions = [
    {
      icon: <PersonAddOutlinedIcon sx={{ fontSize: 16 }} />,
      title: 'Send Connection Request',
      desc: 'Connect with new prospects',
      handleClick: () => {
        if (planName === 'free' && !isWhiteLabelUser) {
          setIsPlanCommonUpgradeModalOpen(true)
          return
        }
        setSendInviteLinkedInModal(true)
        getAllLinkedInAccounts()
        onClose()
      }
    },
    {
      icon: <ChatOutlinedIcon sx={{ fontSize: 16 }} />,
      title: 'Send Message',
      desc: 'Start a conversation',
      handleClick: () => {
        if (planName === 'free' && !isWhiteLabelUser) {
          setIsPlanCommonUpgradeModalOpen(true)
          return
        }
        setSendMessageLinkedInModal(true)
        getAllLinkedInAccounts()
        onClose()
      }
    }
  ]

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth={false}>
      <Box sx={{ p: '0px 6px', borderBottom: '1px solid #E1E1E1', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <DialogTitle id='customized-dialog-title' sx={{ pl: 1.5 }}>
          Add Step
        </DialogTitle>
        <IconButton onClick={onClose} sx={{ borderRadius: '100%', height: '35px', width: '35px' }}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          {/* Email Actions */}
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
              <EmailOutlinedIcon sx={{ fontSize: 22, color: theme.palette.primary.main }} />
              <Typography sx={{ fontWeight: '700' }}>Email Actions</Typography>
            </Box>
            <Grid container spacing={1.5}>
              {
                emailActions.map((action, i) => (
                  <Grid item xs={12} md={4} key={i}>
                    <ActionButton onClick={action.handleClick}>
                      <Box
                        className='gradient-overlay' sx={{
                          position: 'absolute',
                          inset: 0
                        }}
                      />
                      <Box sx={{ p: 1.5, display: 'flex', flexDirection: 'column', gap: 1, position: 'relative' }}>
                        <IconWrapper className='icon-wrapper'>{action.icon}</IconWrapper>
                        <Box sx={{ textAlign: 'left' }}>
                          <Typography variant='subtitle1' sx={{ fontSize: '14px', fontWeight: 500 }}>
                            {action.title}
                          </Typography>
                          <Typography variant='body2' color='text.secondary'>
                            {action.desc}
                          </Typography>
                        </Box>
                      </Box>
                    </ActionButton>
                  </Grid>
                ))
              }
            </Grid>
          </Box>

          {/* LinkedIn Actions */}
          {
            !sequenceId &&
            (
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                  <LinkedInIcon sx={{ fontSize: 22, color: theme.palette.primary.main }} />
                  <Typography sx={{ fontWeight: '700' }}>LinkedIn Actions</Typography>
                </Box>
                <Grid container spacing={1.5}> {/* Reduced from spacing: 2 */}
                  {linkedInActions.map((action, i) => (
                    <Grid item xs={12} md={6} key={i}>
                      <ActionButton onClick={action.handleClick}>
                        <Box
                          className='gradient-overlay' sx={{
                            position: 'absolute',
                            inset: 0
                          }}
                        />
                        <Box sx={{ p: 1.5, display: 'flex', flexDirection: 'column', gap: 1, position: 'relative' }}>
                          <IconWrapper className='icon-wrapper'>{action.icon}</IconWrapper>
                          <Box sx={{ textAlign: 'left' }}>
                            <Typography variant='subtitle1' sx={{ fontSize: '14px', fontWeight: 500 }}>
                              {action.title}
                            </Typography>
                            <Typography variant='body2' color='text.secondary'>
                              {action.desc}
                            </Typography>
                          </Box>
                        </Box>
                      </ActionButton>
                    </Grid>
                  ))}
                </Grid>
                <Dialog
                  open={isPlanCommonUpgradeModalOpen}
                  onClose={closeModal}
                  maxWidth='lg'
                  fullWidth
                >
                  <CommonUpgradeModal currentPlan={planName} closeModal={closeModal} />
                </Dialog>
              </Box>
            )
          }
        </Box>
      </DialogContent>
    </StyledDialog>
  )
}
