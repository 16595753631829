import { Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import { Box, styled } from '@mui/system'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import InfoIcon from '@mui/icons-material/Info'

const UploadBox = styled(Box)(({ theme }) => ({
  border: '2px dashed #E5E7EB',
  borderRadius: '8px',
  padding: theme.spacing(6),
  textAlign: 'center',
  backgroundColor: '#FFFFFF',
  cursor: 'pointer',
  '&:hover': {
    borderColor: theme.palette.primary.main
  }
}))

const VerlifyListDialog = ({
  openModal,
  setOpenModal,
  handleDrag,
  handleDrop,
  dragActive,
  handleFileInput,
  selectedFile,
  handleStartBulkValidation,
  isValidatingBulkEmail,
  handleRemoveFile
}) => {
  return (
    <>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pb: 1 }}>
          <Box>
            <Typography variant='h6' component='h2'>
              Add new list
            </Typography>
          </Box>
          <IconButton onClick={() => setOpenModal(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <UploadBox
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
            sx={{
              backgroundColor: dragActive ? '#F9FAFB' : '#FFFFFF'
            }}
          >
            <Typography variant='body1' sx={{ mb: 2 }}>
              Drag and drop file here
            </Typography>
            <Typography variant='body1' sx={{ mb: 2 }}>
              or
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
              <Button
                variant='contained'
                component='label'
                sx={{ backgroundColor: '#4F46E5' }}
                disabled={!!selectedFile} // Disable if file is already uploaded
              >
                UPLOAD FILE
                <input
                  type='file'
                  hidden
                  accept='.csv,.txt'
                  onChange={handleFileInput}
                />
              </Button>
            </Box>
            {selectedFile && (
              <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                <Typography>Selected: {selectedFile.name}</Typography>
                <IconButton size='small' onClick={handleRemoveFile} color='error'>
                  <CloseIcon fontSize='small' />
                </IconButton>
              </Box>
            )}
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
              <InfoIcon sx={{ fontSize: 16, mr: 1, color: '#6B7280' }} />
              <Typography variant='body2' color='text.secondary'>
                We support .csv, .txt up to 1M emails/100 MB
              </Typography>
            </Box>
          </UploadBox>
          {
            selectedFile && (
              <Box sx={{ mt: 2, width: '100%', display: 'flex', justifyContent: 'right' }}>
                <Button
                  variant='contained'
                  sx={{ mt: 2, backgroundColor: '#4F46E5' }}
                  onClick={handleStartBulkValidation}
                  disabled={isValidatingBulkEmail}
                >
                  Start Validation
                </Button>
              </Box>
            )
          }
        </DialogContent>
      </Dialog>
    </>
  )
}

export default VerlifyListDialog
