import { Avatar, Box, Checkbox, Typography } from '@mui/material'
import moment from 'moment'
import { React, useEffect, useState, useContext, useRef } from 'react'
import { useSelector } from 'react-redux'
import {
  useGetSentEmailsMutation,
  useOpenedEmailMutation,
  useFetchCampaignEmailsQuery
} from 'src/services/unibox-service'
import NoDataAndWaitingWrapper from './NoDataAndWaitingWrapper'
import { InboxHubContext, EmailListingContext } from 'src/pages/InboxHub'

const EmailListing = () => {
  const workspace = useSelector((state) => state.workspace)
  const {
    selectedCampaignId,
    selectedStatusLabelId,
    selectedMainFilter,
    allChecked,
    setAllChecked,
    markedEmailIds,
    setmarkedEmailIds,
    currentlyOpenedEmail,
    setCurrentlyOpenedEmail,
    isMobile
    // setTotalInbox,
    // setTotalUnread,
    // setTotalSent,
  } = useContext(InboxHubContext)
  const [openEmail] = useOpenedEmailMutation()
  const { getCampaignEmailPayload, setGetCampaignEmailPayload, searchEmailValue } =
    useContext(EmailListingContext)

  const {
    data: { docs: fetchedEmails = [] } = {},
    refetch: refetchCampaignEmails,
    isError: isCampaignsEmailError,
    isLoading: isCampaignsEmailLoading
  } = useFetchCampaignEmailsQuery(getCampaignEmailPayload)

  const [getSentEmails, { isLoading: isSentEmailsLoading, isError: isSentEmailsError }] =
    useGetSentEmailsMutation()

  const [emails, setEmails] = useState([])
  const [searchEmailsInHere, setSearchEmailsInHere] = useState([])
  const [isMainFilterSent, setIsMainFilterSent] = useState(false)
  const [offset, setOffset] = useState(0)

  // to keep track of the current values so that when filters change from outside this component we can call specific emails
  const currentValues = useRef({
    selectedCampaignId: null,
    selectedStatusLabelId: null,
    selectedMainFilter: null,
    offset: 0
  })

  useEffect(() => {
    setIsMainFilterSent(false)
    setAllChecked(false)
    if (workspace?._id) {
      // when user changes the filter id offset must be 0
      if (currentValues.current.selectedMainFilter !== selectedMainFilter) {
        setOffset(0)
        if (selectedMainFilter === 'inbox') {
          // get unread emails
          setGetCampaignEmailPayload((prev) => ({
            ...prev,
            offset: 0,
            unread: false,
            workspaceId: workspace?._id,
            time: new Date().getTime()
          }))
        }
        if (selectedMainFilter === 'unread') {
          // get unread emails
          setGetCampaignEmailPayload((prev) => ({
            ...prev,
            offset: 0,
            unread: true,
            workspaceId: workspace?._id,
            time: new Date().getTime()
          }))
        }
        if (selectedMainFilter === 'sent') {
          // get sent emails
          getSentEmails({ offset: 0, perPage: 20, workspaceId: workspace?._id })
            .unwrap()
            .then((res) => {
              setEmails(res?.docs)
            })
          setIsMainFilterSent(true)
        }
        currentValues.current.selectedMainFilter = selectedMainFilter
        setEmails([])
        return
      }

      // when user changes the campaign or status id offset must be 0
      if (currentValues.current.selectedCampaignId !== selectedCampaignId) {
        setOffset(0)
        setGetCampaignEmailPayload((prev) => ({
          ...prev,
          offset: 0,
          campaignId: selectedCampaignId,
          label: null
        }))
        currentValues.current.selectedCampaignId = selectedCampaignId
        setEmails([])
        return
      }
      if (currentValues.current.selectedStatusLabelId !== selectedStatusLabelId) {
        setOffset(0)
        setGetCampaignEmailPayload((prev) => ({
          ...prev,
          offset: 0,
          campaignId: null,
          label: selectedStatusLabelId
        }))
        currentValues.current.selectedStatusLabelId = selectedStatusLabelId
        setEmails([])
        return
      }

      // if above 3 coditions didn't meet then the offset was changed
      if (isMainFilterSent) {
        // get sent emails
        getSentEmails({ offset, perPage: 20, workspaceId: workspace?._id })
          .unwrap()
          .then((res) => {
            setSearchEmailsInHere([...emails, ...fetchedEmails])
            setEmails((prev) => [...prev, ...res?.docs])
          })
        setIsMainFilterSent(true)
      } else {
        setGetCampaignEmailPayload((prev) => ({ ...prev, offset, workspaceId: workspace?._id }))
      }
    }
  }, [selectedCampaignId, selectedStatusLabelId, selectedMainFilter, offset, workspace])
  // if main filters change or campaign or status label change we set the offset back to 0
  // and if only only offset change we check whether we have any filter or status label already set so we call only those apis with the updated offset

  useEffect(() => {
    if (selectedMainFilter === 'inbox' || selectedMainFilter === 'unread') {
      if (currentValues.current.offset === offset) {
        setSearchEmailsInHere([...emails.slice(0, emails.length - 20), ...fetchedEmails])
        // if offset is same it means the email is clicked so replace with the fetched Emails
        setEmails([...emails.slice(0, emails.length - 20), ...fetchedEmails])
      } else {
        setSearchEmailsInHere([...emails, ...fetchedEmails])
        // if offset changes then we add the api results
        setEmails((prev) => [...prev, ...fetchedEmails])
        currentValues.current.offset = offset
      }
    }
  }, [fetchedEmails])

  useEffect(() => {
    if (allChecked) {
      const emailIds = emails.map((email) => email._id)
      setmarkedEmailIds([...emailIds])
    } else {
      setmarkedEmailIds([])
    }
  }, [allChecked])

  useEffect(() => {
    const filteredEmails = searchEmailsInHere.filter((email) =>
      email.subject.toLowerCase().includes(searchEmailValue.toLowerCase())
    )
    setEmails([...filteredEmails])
  }, [searchEmailValue])

  const handleMarkEmail = (id) => {
    if (!markedEmailIds.includes(id)) {
      setmarkedEmailIds([...markedEmailIds, id])
    } else {
      const filtered = markedEmailIds.filter((emailId) => emailId !== id)
      setmarkedEmailIds([...filtered])
    }
  }

  const handleClickEmail = async (email) => {
    setCurrentlyOpenedEmail(email)
    await openEmail({ id: email._id, body: { value: true } })
    if (!isMainFilterSent) {
      if (!getCampaignEmailPayload.unread) {
        refetchCampaignEmails()
      }
    }
  }

  function handleClickMoreEmails () {
    setOffset((prev) => prev + 20)
  }

  return (
    <NoDataAndWaitingWrapper
      data={emails.length}
      isLoading={isCampaignsEmailLoading || isSentEmailsLoading}
      loadingText='Please Wait'
      isError={isCampaignsEmailError || isSentEmailsError}
      errorText='There some problem getting inboxes, please reload the page'
      noDataText='No Inboxes at the moment'
    >
      <Box
        sx={{
          // height: { xs: '100vh', sm: 'calc(100vh - 29vh)' },
          display: 'flex',
          flexDirection: 'column',
          height: '90%',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }}
      >
        {[
          emails?.map((email, key) => {
            const lastReply = !isMainFilterSent
              ? email?.mailReplies?.[email?.mailReplies?.length - 1] ?? {}
              : { bodyTextHtml: email?.campaign_email_id?.body ?? '' }
            const replyHtml = parseBodyContent({ body: lastReply?.bodyTextHtml ?? '' })
            const previewText = extractTextBeforeSeparator(replyHtml)

            const {
              email: leadEmailAddress = '',
              firstName = '',
              lastName = ''
            } = email?.leads ?? {}
            const subject = isMainFilterSent
              ? email?.campaign_email_id?.subject ?? ''
              : email?.subject ?? ''
            return (
              <Box
                key={key}
                sx={{
                  position: 'relative',
                  width: { xs: '100%', sm: '100%' },
                  height: '10rem',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  // backgroundColor: email?.portal_email_opened === false ? '#F0FFFF' : '',
                  backgroundColor:
                    email.portal_email_opened || isMainFilterSent ? '#FFF' : '#F2F4F6',
                  borderBottom: '1px solid rgba(228, 228, 229, 1)',
                  cursor: 'pointer',
                  py: 2,
                  color: '#28287B',
                  '&:hover': {
                    backgroundColor: '#F2F4F6',
                    color: '#3F4FF8'
                  },
                  zIndex: email._id === currentlyOpenedEmail?._id ? 1 : 0,
                  boxShadow:
                    email._id === currentlyOpenedEmail?._id
                      ? '0px 0px 10px rgba(0, 0, 0, 0.3)'
                      : ''
                }}
                onClick={() => handleClickEmail(email)}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'row' },
                    // justifyContent: { xs: "space-between" },
                    alignItems: { xs: 'center' }
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'row' },
                      alignItems: 'center',
                      width: '97%'
                    }}
                  >
                    {/* {!sent && ( */}
                    <Checkbox
                      checked={markedEmailIds.includes(email._id)}
                      size={isMobile ? 'medium' : 'small'}
                      onClick={(e) => {
                        e.stopPropagation()
                        handleMarkEmail(email._id)
                      }}
                    />

                    <Avatar
                      alt='Remy Sharp'
                      src={email?.lead?.photoUrl}
                      sx={{
                        height: { xs: '25px', sm: '25px', md: '30px', lg: '30px' },
                        width: { xs: '25px', sm: '25px', md: '30px', lg: '30px' },
                        mr: 1
                      }}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '75%'
                      }}
                    >
                      <Typography
                        color='text.secondary'
                        noWrap
                        sx={{
                          fontSize: '14px',
                          lineHeight: '20px'
                        }}
                      >
                        {`${firstName ?? ''} ${lastName ?? ''}`} &bull; {leadEmailAddress}
                      </Typography>
                      <Typography
                        noWrap
                        sx={{
                          fontSize: '14px',
                          fontWeight: { xs: 600, md: 700 },
                          lineHeight: '20px',
                          textAlign: 'left',
                          width: { xs: '50%', sm: '70%', md: '100%', lg: '100%' },
                          color: '#000000'
                        }}
                      >
                        {subject}
                      </Typography>
                    </Box>
                  </Box>
                  {email.portal_email_opened === false && (
                    <Avatar
                      sx={{
                        width: 5,
                        height: 5,
                        bgcolor: '#3F4FF8',
                        borderRadius: '50%'
                      }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: { xs: 'flex-end', sm: 'flex-end' },
                    justifyContent: 'space-between',
                    width: '98%'
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 300,
                      lineHeight: '20px',
                      color: '#000',
                      textAlign: 'left',
                      width: '75%',
                      // border: "1px solid black",
                      ml: 4.5,
                      height: 40,
                      textOverflow: 'ellipsis',
                      overflow: 'hidden'
                    }}
                  >
                    {previewText}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: '12px', md: '13px', lg: '11px', xl: '13px' },
                      fontWeight: { xs: 300, md: 400 },
                      lineHeight: '20px',
                      color: 'rgba(40, 40, 123, 0.5)'
                    }}
                  >
                    {formatDate(email?.createdAt, workspace?.timezone?.split(' ')[0])}
                  </Typography>
                </Box>
                {email._id === currentlyOpenedEmail?._id && (
                  <div
                    style={{
                      width: '2px',
                      height: '100%',
                      backgroundColor: '#3F4FF8',
                      position: 'absolute',
                      right: '0',
                      top: '0'
                    }}
                  />
                )}
              </Box>
            )
          }),
          <Box
            key={emails.length + 1}
            onClick={handleClickMoreEmails}
            sx={{
              border: 'none',
              outline: 'none',
              paddingY: 1,
              color: 'rgba(0, 0, 0, 0.2)',
              '&:hover': { color: 'rgb(56, 56, 56)' },
              display: 'flex',
              justifyContent: 'center',
              cursor: 'pointer'
            }}
          >
            Get More Emails
          </Box>
        ]}
      </Box>
    </NoDataAndWaitingWrapper>
  )
}

export default EmailListing

function parseBodyContent ({ body, trim = true }) {
  // Create a temporary DOM element to decode HTML entities
  const tempElement = document.createElement('div')
  tempElement.innerHTML = body
  const decodedString = tempElement.textContent || tempElement.innerText

  // Remove HTML tags
  const plainString = decodedString
    .replace(/<[^>]+>/g, '')
    .replace(/p\.\w+\s*{[^}]*}|\s*span\.\w+\s*{[^}]*}/g, '')

  if (!trim) {
    return plainString
  }

  return plainString.length <= 110 ? plainString : plainString.substring(0, 110 - 3) + '...'
}

function extractTextBeforeSeparator (bodyText) {
  const cleanedText = bodyText.replace(/P {margin-top:0;margin-bottom:0;}/g, '').trim()

  const onIndex = cleanedText.indexOf('On')
  const fromIndex = cleanedText.indexOf('From:')

  const separatorIndex = Math.min(
    onIndex !== -1 ? onIndex : cleanedText.length,
    fromIndex !== -1 ? fromIndex : cleanedText.length
  )

  return separatorIndex !== -1 ? cleanedText.substring(0, separatorIndex).trim() : cleanedText
}

const formatDate = (timestamp, timezone) => {
  const date = moment(timestamp)
  const oneMonthAgo = moment().subtract(25, 'days')
  if (date.isBefore(oneMonthAgo)) {
    return moment(date).tz(timezone).format('DD/MM/YYYY')
  } else {
    return date.fromNow()
  }
}
