import React, { useState } from 'react'
import { Box, TextField } from '@mui/material'

import PersonHeader from './PersonHeader'
import ActionButtons from './ActionButtons'
import CollapsibleSection from './CollapsibleSection'

import ServerSvg from 'src/assets/dealPipeline/server.svg'
import EmailIconSvg from 'src/assets/dealPipeline/email.svg'
import InfoIconSvg from 'src/assets/dealPipeline/info.svg'
import LinkIconSvg from 'src/assets/dealPipeline/link.svg'

import SvgRenderer from '../SvgRenderer'
import HighlightedText from './HighlightedText'
import HighlightedTextPills from './HighlightedTextPills'
// import DropdownWithUserLabel from './DropdownWithUserLabel'

export const PersonTab = ({ user, labels, onUpdateComplete, updateDealInPipelineData, selectedUserColumnId }) => {
  const [isContactInfoOpen, setIsContactInfoOpen] = useState(true)
  const [isPrincipalInfoOpen, setIsPrincipalInfoOpen] = useState(false)
  const [isAdditionalInformationOpen, setIsAdditionalInformationOpen] = useState(false)

  const additionalData = user?.additionalData?.additionalData || {}
  let {
    country = '',
    state = '',
    email = '',
    photo_url: photoUrl = '',
    city = '',
    departments = [],
    email_status: emailStatus = '',
    linkedin_url: linkedInUrl = '',
    phone_numbers: phoneNumbers = [],
    subdepartments = [],
    employment_history: employmentHistory = [],
    headline = '',
    facebook_url: facebookUrl = '',
    seniority = '',
    organization = {}
  } = additionalData

  //   let campaign = user?.additionalData?.campaign || null;
  const valuePerDeal = user?.valuePerDeal || null

  phoneNumbers = phoneNumbers && phoneNumbers.length > 0 ? phoneNumbers[0].sanitized_number : ''

  employmentHistory =
    employmentHistory && employmentHistory.length > 0
      ? employmentHistory.map((e) => `${e.title} - ${e.organization_name}`)
      : null

  const location = `${state || ''}${city ? `, ${city}` : ''}${country ? `, ${country}` : ''}`

  return (
    <>
      {/* TODO - hidden until next step */}
      {/* <DropdownWithUserLabel
        user={user}
        labels={labels}
        onUpdateComplete={(selectedValue) => handleLabelChange(selectedValue)}
      /> */}

      <Box mt={2}>
        <Box sx={{ padding: '0px 20px' }}>
          <PersonHeader
            name={user.name}
            title={user.title}
            email={user.email}
            emailStatus={emailStatus}
            logo={photoUrl}
          />
          <ActionButtons
            email={email}
            call={phoneNumbers}
            leadCampaignId={user.id}
            valuePerDeal={valuePerDeal}
            labelId={user.label}
            onUpdateComplete={onUpdateComplete}
            user={user}
            selectedUserColumnId={selectedUserColumnId}
            updateDealInPipelineData={updateDealInPipelineData}
          />

          <Box mb={2}>
            <TextField
              fullWidth
              placeholder='Add a note about this contact...'
              multiline
              rows={3}
              variant='outlined'
            />
          </Box>
        </Box>

        <CollapsibleSection
          title='Contact Information'
          icon={<SvgRenderer src={EmailIconSvg} width='22' height='22' alt='icon' />}
          isOpen={isContactInfoOpen}
          onToggle={() => setIsContactInfoOpen(!isContactInfoOpen)}
        >
          <Box mt={1} pl={2}>
            {user.email && <HighlightedText label='Email' value={user.email} />}

            {phoneNumbers && <HighlightedText label='Phone' value={phoneNumbers} />}

            {linkedInUrl && (
              <HighlightedText
                label='LinkedIn'
                value={
                  <a sx={{ color: '#3F50F8' }} href={linkedInUrl}>
                    {linkedInUrl}
                  </a>
                }
              >
                <a sx={{ color: '#3F50F8' }} href={linkedInUrl}>
                  <SvgRenderer src={LinkIconSvg} width='16' height='16' alt='icon' />
                </a>
              </HighlightedText>
            )}
          </Box>
        </CollapsibleSection>

        <CollapsibleSection
          title='Principal Information'
          icon={<SvgRenderer src={InfoIconSvg} width='22' height='22' alt='icon' />}
          isOpen={isPrincipalInfoOpen}
          onToggle={() => setIsPrincipalInfoOpen(!isPrincipalInfoOpen)}
        >
          <Box mt={1} pl={2}>
            {location !== '' && <HighlightedText label='Location' value={location} />}
            <HighlightedText label='Title' value={user.title} />

            {headline && <HighlightedText label='Headline' value={headline} />}

            {organization && organization.keywords && organization.keywords.length > 0 && (
              <HighlightedTextPills label='Keywords' values={organization.keywords} />
            )}

            {departments && departments.length > 0 && (
              <HighlightedTextPills label='Departments' values={departments} />
            )}
          </Box>
        </CollapsibleSection>

        <CollapsibleSection
          title='Additional Information'
          icon={<SvgRenderer src={ServerSvg} width='22' height='22' alt='icon' />}
          isOpen={isAdditionalInformationOpen}
          onToggle={() => setIsAdditionalInformationOpen(!isAdditionalInformationOpen)}
        >
          <Box mt={1} pl={2}>
            {facebookUrl && (
              <HighlightedText
                label='Facebook'
                value={
                  <a sx={{ color: '#3F50F8' }} href={facebookUrl}>
                    {facebookUrl}
                  </a>
                }
              >
                <a sx={{ color: '#3F50F8' }} href={facebookUrl}>
                  <SvgRenderer src={LinkIconSvg} width='16' height='16' alt='icon' />
                </a>
              </HighlightedText>
            )}

            {seniority && <HighlightedText label='Seniority' value={seniority} />}

            {subdepartments && subdepartments.length > 0 && (
              <HighlightedTextPills label='Subdepartments' values={subdepartments} />
            )}

            {employmentHistory && employmentHistory.length > 0 && (
              <HighlightedTextPills label='Employment History' values={employmentHistory} />
            )}
          </Box>
        </CollapsibleSection>
      </Box>
    </>
  )
}
