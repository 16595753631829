import React from 'react'
import { Box, Typography, Avatar } from '@mui/material'
import AccessTimeIcon from '@mui/icons-material/AccessTime'

const UserCard = ({ name, email, date, time, avatar, onClick }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        backgroundColor: '#FFFFFF',
        border: '1px solid #D7D9E6', // Updated border color
        borderRadius: '8px',
        padding: '16px',
        gap: '8px',
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
        minWidth: '240px',
        marginBottom: '8px',
        cursor: 'pointer'
      }}
      onClick={onClick}
    >
      {/* Header with Avatar and Name */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
        <Avatar
          src={avatar}
          alt={name}
          sx={{
            width: 40,
            height: 40,
            backgroundColor: '#F3F4F6'
          }}
        />
        <Box>
          <Typography
            sx={{
              fontWeight: '700',
              fontSize: '16px',
              lineHeight: '1.5',
              color: '#111827'
            }}
          >
            {name}
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              lineHeight: '1.5',
              color: '#6B7280'
            }}
          >
            {email}
          </Typography>
        </Box>
      </Box>

      {/* Date and Time */}
      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '12px',
          lineHeight: '1.5',
          color: '#8181B0', // Updated date color
          gap: '4px'
        }}
      >
        <AccessTimeIcon sx={{ fontSize: '16px' }} />
        {date || '28 August 2025'} · {time || '11:00 AM'} {/* Added dummy date and time */}
      </Typography>
    </Box>
  )
}

export default UserCard
