import React, { useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  Menu,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SvgRenderer from '../SvgRenderer'

import EmailRepliedSvg from 'src/assets/dealPipeline/email-replied.svg'
import LinkClickedSvg from 'src/assets/dealPipeline/link-clicked.svg'
import EmailOpenedSvg from 'src/assets/dealPipeline/email-opened.svg'
import EmailSentSvg from 'src/assets/dealPipeline/email-sent.svg'
import { OffCheckboxCustomIcon } from 'src/assets/general/OffCheckboxCustomIcon'
import { OnCheckboxCustomIcon } from 'src/assets/general/OnCheckboxCustomIcon'

const TypeDropdown = ({ onFilter }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedTypes, setSelectedTypes] = useState([])
  const typeOptions = [
    {
      label: 'Email Sent',
      count: 92,
      icon: <SvgRenderer src={EmailSentSvg} width='40' height='40' alt='icon' />
    },
    {
      label: 'Email Opened',
      count: 92,
      icon: <SvgRenderer src={EmailOpenedSvg} width='40' height='40' alt='icon' />
    },
    {
      label: 'Email Replied',
      count: 18,
      icon: <SvgRenderer src={EmailRepliedSvg} width='40' height='40' alt='icon' />
    },
    {
      label: 'Link Clicked',
      count: 7,
      icon: <SvgRenderer src={LinkClickedSvg} width='40' height='40' alt='icon' />
    }
  ]

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    onFilter({
      selectedTypes
    })

    setAnchorEl(null)
  }

  const handleToggle = (type) => {
    if (selectedTypes.includes(type)) {
      setSelectedTypes(selectedTypes.filter((t) => t !== type))
    } else {
      setSelectedTypes([...selectedTypes, type])
    }
  }

  const handleClearAll = () => {
    setSelectedTypes([])
  }

  return (
    <Box position='relative'>
      {/* Dropdown Button */}
      <Button
        onClick={handleOpen}
        endIcon={<ExpandMoreIcon />}
        sx={{
          textTransform: 'none',
          padding: '6px 8px',
          border: '1px solid #E5E7EB',
          borderRadius: '8px',
          color: '#111827',
          backgroundColor: selectedTypes.length > 0 ? '#EEF2FF' : '',
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
          '&:hover': {
            borderColor: '#3F50F8',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)'
          }
        }}
      >
        <Typography sx={{ fontWeight: '500', color: selectedTypes.length > 0 ? '"#3F50F8"' : '' }}>
          Type
        </Typography>
        {selectedTypes.length > 0 && (
          <Box
            sx={{
              backgroundColor: '#3F50F8',
              color: '#FFFFFF',
              fontSize: '12px',
              fontWeight: '600',
              padding: '2px 6px',
              borderRadius: '12px',
              display: 'inline-block'
            }}
          >
            {selectedTypes.length}
          </Box>
        )}
      </Button>

      {/* Dropdown Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: '350px',
            borderRadius: '8px',
            padding: '16px',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
          }
        }}
      >
        <Box>
          {/* Header with Title and Clear All */}
          <Box display='flex' justifyContent='space-between' alignItems='center' mb={1}>
            <Typography variant='subtitle1' fontWeight='bold'>
              Type
            </Typography>
            <Button
              onClick={handleClearAll}
              sx={{
                textTransform: 'none',
                color: '#3F50F8',
                fontWeight: 'bold',
                fontSize: '14px'
              }}
            >
              Clear all
            </Button>
          </Box>

          {/* List of Types */}
          <List>
            {typeOptions.map((type) => (
              <ListItem
                key={type.label}
                sx={{
                  padding: '8px 0',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}
              >
                <ListItemIcon sx={{ minWidth: 'unset' }}>
                  <Checkbox
                    size='small'
                    icon={<OffCheckboxCustomIcon />}
                    checkedIcon={<OnCheckboxCustomIcon />}
                    checked={selectedTypes.includes(type.label)}
                    onChange={() => handleToggle(type.label)}
                  />
                </ListItemIcon>
                {type.icon}
                <ListItemText
                  primary={<Typography>{type.label}</Typography>}
                  sx={{ marginLeft: '8px' }}
                />
              </ListItem>
            ))}
          </List>

          {/* Apply Button */}
          <Button
            variant='contained'
            fullWidth
            onClick={handleClose}
            sx={{
              textTransform: 'none',
              backgroundColor: '#3F50F8',
              color: '#FFFFFF',
              fontWeight: '600',
              padding: '10px 0',
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: '#3F50F8'
              }
            }}
          >
            Apply
          </Button>
        </Box>
      </Menu>
    </Box>
  )
}

export default TypeDropdown
