import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  MenuItem,
  Typography,
  Avatar,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  Menu,
  IconButton,
  Checkbox,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Snackbar
} from '@mui/material'
import toast from 'react-hot-toast'
import Pagination from '../Pagination'
import PropTypes from 'prop-types'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import DownloadIcon from '@mui/icons-material/Download'
import _ from 'lodash'
import { useDeleteBlocklistMutation, useGetBlocklistMutation } from 'src/services/account-service'
import { useSelector } from 'react-redux'
import { EACloseIcon } from 'src/assets/emailAccounts/EACloseIcon'
import { BulkUploadIcon } from 'src/assets/campaignDetailsLeads/BulkUploadIcon'
import { ManualEmailIcon } from 'src/assets/campaignDetailsLeads/ManualEmailIcon'
import { Google } from 'src/assets/Google'
import { ArrowLeftIconBlue } from 'src/assets/emailAccounts/connect/ArrowLeftIconBlue'
import { ArrowRight } from 'src/assets/general/ArrowRight'
import { downloadCsv } from 'src/utils/util'
import CsvImport from './CsvImport'
import ManualImport from './ManualImport'
import GoogleSheetImport from './GoogleSheetImport'
import { useGetWorkSpacesMutation } from 'src/services/workSpace-service'
import SearchIcon from '@mui/icons-material/Search'
import UploadIcon from '@mui/icons-material/Upload'

function CustomTabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ width: '100%' }}
    >
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

const BlocklistPage = ({ campaign, canEdit }) => {
  const theme = useTheme()
  const [leads, setLeads] = useState([])
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [isLoadingBlocklist, setIsLoadingBlocklist] = useState(false)
  const [search, setSearch] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)
  const [listType, setListType] = useState('email')
  const [getWorkSpaces] = useGetWorkSpacesMutation()
  const [, setAllWorkSpaces] = useState([])
  const [getBlocklist] = useGetBlocklistMutation()
  const [selectType, setSelectType] = useState(null)
  const [total, setTotal] = useState(0)
  const [checkedAll, setCheckedAll] = useState(false)
  const [selectedLeads, setSelectedLeads] = useState([])
  const [deleteBlocklist] = useDeleteBlocklistMutation()
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMsg, setSnackbarMsg] = useState('')
  const [page, setPage] = useState(1)
  const [isImportLeadsDialogOpen, setIsImportLeadsDialogOpen] = useState(false)
  const [blockListCreate, setBlockListCreate] = useState(false)
  const [limit, setLimit] = useState(10)
  const [selectedTab, setSelectedTab] = useState(listType === 'email' ? 0 : 1)
  const [activeStep, setActiveStep] = useState(0)
  const [leadCreated, setLeadCreated] = useState(false)
  const offset = leads?.length
  const workspace = useSelector((state) => state.workspace)
  // const [, setOrganization] = useState(workspace?.name)
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(workspace?._id)
  const fetchWorkspaces = async () => {
    const docs = await getWorkSpaces().unwrap()
    setAllWorkSpaces(docs.uniqueWorkspaces)
    setSelectedWorkspaceId(docs.currentWorkspace._id)
  }
  useEffect(() => {
    fetchWorkspaces()
  }, [workspace])
  const [value] = useState(0)
  const [anchorElSelected, setAnchorElSelected] = React.useState(null)

  const handleMenuClick = (event) => {
    setAnchorElSelected(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorElSelected(null)
  }

  useEffect(() => {
    setBlockListCreate(false)

    const fetchBlocklist = async (offset) => {
      setIsLoadingBlocklist(true)
      const { docs, total } = await getBlocklist({
        params: _.pickBy({ search, offset, limit, listType, workspaceId: selectedWorkspaceId })
      }).unwrap()
      setTotal(total)
      setLeads(docs)
      setIsLoadingBlocklist(false)
    }

    const timer = setTimeout(() => {
      if (page === 1 && selectedWorkspaceId) {
        fetchBlocklist(0)
      } else if (offset < total && page > 1 && selectedWorkspaceId) {
        fetchBlocklist(limit * (page - 1))
      }
    }, 500)

    return () => clearTimeout(timer)
  }, [
    search,
    blockListCreate,
    getBlocklist,
    page,
    limit,
    leadCreated,
    listType,
    selectedWorkspaceId
  ])

  useEffect(() => {
    const fetchLeads = async () => {
      try {
        if (leadCreated && selectedWorkspaceId) {
          const params = _.pickBy({
            search,
            offset: 0,
            limit,
            workspaceId: selectedWorkspaceId
          })
          const { docs } = await getBlocklist({ id: campaign._id, params }).unwrap()
          setLeads(docs)
          setPage(1)
          setLeadCreated(false)
        }
      } catch (error) {
        toast.error('Error fetching leads')
      }
    }

    fetchLeads()
  }, [leadCreated])

  const handleLimitChange = (event) => {
    setLimit(event.target.value)
    setPage(1)
  }

  const getHighlightedText = (text, highlight) => {
    // Split text on highlight term, include term itself into parts, ignore case
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'))
    return (
      <span>
        {parts.map((part, i) => (
          <span
            key={i}
            style={
              part.toLowerCase() === highlight.toLowerCase()
                ? { fontWeight: 'bold', backgroundColor: '#FFD700' }
                : {}
            }
          >
            {part}
          </span>
        ))}
      </span>
    )
  }

  const handleClickOpenImportLeadsDialog = () => {
    setIsImportLeadsDialogOpen(true)
  }

  const handleCloseOpenImportLeadsDialog = () => {
    setIsImportLeadsDialogOpen(false)
    setActiveStep(0)
  }

  const getButtonText = () => {
    if (selectedTab === 0) return selectedLeads.length > 1 ? 'emails' : 'email'
    if (selectedTab === 1) return selectedLeads.length > 1 ? 'domains' : 'domain'
    return ''
  }

  const handleSelectLeadChange = (lead, checked) => {
    let updatedSelectedLeads = [...selectedLeads]
    if (checked) {
      updatedSelectedLeads.push(lead)
    } else {
      if (checkedAll) {
        setCheckedAll(false)
        setSelectType(null)
      }
      updatedSelectedLeads = selectedLeads.filter((e) => e._id !== lead._id)
    }
    setSelectedLeads(updatedSelectedLeads)
  }

  const onLeadsCreate = (createdLeads) => {
    if (createdLeads) setLeadCreated(true)
    setActiveStep(0)
    setIsImportLeadsDialogOpen(false)
  }

  const handleTabChange = (event, newValue) => {
    setPage(1)
    setSelectedLeads([])
    setCheckedAll(false)
    setSelectType(null)

    setSelectedTab(newValue)
    setListType(newValue === 0 ? 'email' : 'domain')
  }

  const handleSelectAllLeadsChange = async (checked, type) => {
    if (checked) {
      setSelectType(type)
      setCheckedAll(true)
      if (type === 'all' && selectedWorkspaceId) {
        const { docs } = await getBlocklist({
          params: {
            search,
            offset: 0,
            limit: total,
            listType,
            workspaceId: selectedWorkspaceId
          }
        }).unwrap()
        setSelectedLeads(docs)
      } else {
        setSelectedLeads(leads)
      }
    } else {
      setSelectedLeads([])
      setCheckedAll(false)
      setSelectType(null)
    }
  }
  const handleDownloadLeadsClick = () => {
    if (listType === 'email') {
      const data = selectedLeads.map((lead) => {
        return { email: lead.email }
      })
      downloadCsv('Blocklist', data)
    } else {
      const data = selectedLeads.map((lead) => {
        return { domain: lead.domain }
      })
      downloadCsv('Blocklist', data)
    }
  }
  const handleDeleteClick = async (id) => {
    try {
      const data = selectedLeads.map((lead) => {
        return lead._id
      })
      if (id) {
        data.push(id)
      }
      const { message } = await deleteBlocklist({
        lists: data,
        workspaceId: selectedWorkspaceId
      }).unwrap()
      // setSelectedLeads([]);
      toast.success(message)
      //
      if (selectType === 'all') {
        setLeads([])
        setSelectedLeads([])
        setCheckedAll(false)
        setSelectType(null)
        setTotal(0)
      } else {
        let offset = 0
        let pageNum = page
        const pageCount = Math.ceil((total - selectedLeads.length) / limit)
        if (selectType === 'page') {
          offset = limit * (page - 1)
          pageNum = page > 1 ? (page > pageCount ? page - 1 : page) : 1
        } else {
          offset = limit * (page - 1)
          pageNum =
            selectedLeads.length === leads?.length
              ? page > 1
                ? page > pageCount
                  ? page - 1
                  : page
                : 1
              : page
        }
        if (page === pageNum && selectedWorkspaceId) {
          const { docs, total } = await getBlocklist({
            params: _.pickBy({ search, offset, limit, listType, workspaceId: selectedWorkspaceId })
          }).unwrap()

          setLeads(docs)
          setSelectedLeads([])
          setCheckedAll(false)
          setSelectType(null)
          setTotal(total)
        } else {
          setPage(pageNum)
          setSelectedLeads([])
          setCheckedAll(false)
          setSelectType(null)
        }
      }
    } catch (err) {
      toast.error(err.data.error.message)
    }
  }

  const handleRemove = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Box
        sx={{
          padding: 4,
          backgroundColor: '#fff',
          minHeight: '100vh',
          border: '1px solid #D7D9E6',
          borderRadius: '8px'
        }}
      >
        <Box sx={{ alignItems: 'center', gap: '8px' }}>
          <Typography variant='h6' sx={{}}>
            Blocklist
          </Typography>
          <Typography sx={{ mb: 2, fontWeight: 400, fontSize: '13px' }}>
            Blocklist helps you avoid sending emails to invalid or spammy addresses, keeping your
            reputation clean.
          </Typography>
        </Box>

        {/* Header Section  */}

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '16px',
            mt: 2,
            flexWrap: 'wrap',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 2
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            sx={{
              marginBottom: '16px',
              background: '#E1E3EC',
              border: '1ps solid #D7D9E6',
              borderRadius: '10px',
              width: { xs: '100%', md: 'auto' },
              '.MuiTabs-flexContainer': {
                borderBottom: '1px solid #ddd',
                flexDirection: { xs: 'row', sm: 'row' }
              },
              '.MuiTab-root': {
                textTransform: 'none',
                minWidth: { xs: '5%', sm: '200px', md: '400px' },
                color: 'black'
              },
              '& .Mui-selected': {
                backgroundColor: theme.palette.primary.contrastText,
                color: `${theme.palette.primary.main}`,
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                borderRadius: '10px'
              },

              '& .MuiTabs-indicator': {
                display: 'none'
              }
            }}
          >
            <Tab
              sx={{
                my: { xs: '4px', sm: '5px' },
                mx: { xs: '4px', sm: '5px' },
                px: { xs: 5.2, sm: 6 },
                py: { xs: '8px', sm: '4px' }
              }}
              label={selectedTab === 0 ? `Emails (${total})` : 'Emails'}
            />
            <Tab
              sx={{
                my: { xs: '4px', sm: '5px' },
                mx: { xs: '4px', sm: '5px' },
                px: { xs: 5.2, sm: 6 },
                py: { xs: '8px', sm: '4px' }
              }}
              label={selectedTab === 1 ? `Domains (${total})` : 'Domains'}
            />
          </Tabs>
          <Box
            sx={{
              width: { xs: '100%', md: 'auto' },
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              gap: 2
            }}
          >
            <TextField
              variant='outlined'
              size='small'
              placeholder='Search email...'
              InputProps={{
                startAdornment: <SearchIcon sx={{ color: '#8181B0', mr: 1 }} />
              }}
              sx={{
                backgroundColor: '#fff',
                borderRadius: '20px',
                width: { xs: '100%', sm: '400px' },
                mr: { xs: 0, sm: 2 }
              }}
              value={search}
              onChange={(event) => {
                setSearch(event.target.value)
                setPage(1)
              }}
            />
            <Button
              variant='contained'
              startIcon={<UploadIcon />}
              sx={{
                bgcolor: `${theme.palette.primary.main}`,
                color: `${theme.palette.primary.contrastText}`,
                '&:hover': {
                  bgcolor: `${theme.palette.primary.main}`
                },
                width: { xs: '100%', sm: 'auto' }
              }}
              disabled={!canEdit}
              onClick={handleClickOpenImportLeadsDialog}
            >
              import
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            padding: '8px',
            mb: 2,
            flexDirection: { xs: 'column', sm: 'row' },
            width: '100%'
          }}
        >
          {/* Checkbox and Dropdown */}
          {selectedLeads.length > 0 && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                bgcolor: '#F2F4F6',
                width: { xs: '100%', sm: 'auto' }
              }}
            >
              <Checkbox
                sx={{ padding: '0px', ml: 1 }}
                checked={selectedLeads.length > 0 && selectedLeads.length === total}
                onChange={(event, checked) => handleSelectAllLeadsChange(checked, 'all')}
              />
              <Button
                endIcon={<KeyboardArrowDownIcon />}
                onClick={handleMenuClick}
                sx={{
                  textTransform: 'none',
                  fontSize: '13px',
                  fontWeight: 600,
                  backgroundColor: '#F2F4F6',
                  color: '#333',
                  padding: '8px 16px',
                  borderRadius: '6px'
                }}
              >
                {selectedLeads.length > 0 ? `${selectedLeads.length} selected` : 'Select'}
              </Button>
              <Menu
                anchorEl={anchorElSelected}
                open={Boolean(anchorElSelected)}
                onClose={handleMenuClose}
                sx={{
                  '& .MuiPaper-root': {
                    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                    borderRadius: '8px'
                  }
                }}
              >
                {!checkedAll && (
                  <MenuItem
                    onClick={() => {
                      handleSelectAllLeadsChange(true, 'all')
                      handleMenuClose()
                    }}
                  >
                    Select All
                  </MenuItem>
                )}
                {selectedLeads.length > 0 && (
                  <MenuItem
                    onClick={() => {
                      handleSelectAllLeadsChange(false, 'all')
                      handleMenuClose()
                    }}
                  >
                    Deselect All
                  </MenuItem>
                )}
              </Menu>
            </Box>
          )}

          {selectedLeads.length > 0 && (
            <Button
              startIcon={<DownloadIcon />}
              sx={{
                textTransform: 'none',
                fontSize: '14px',
                fontWeight: 600,
                color: '#333',
                border: '1px solid #E4E4E5',
                padding: '8px 16px',
                borderRadius: '8px',
                backgroundColor: '#fff',
                '&:hover': { backgroundColor: '#f5f5f5' }
              }}
              onClick={canEdit ? handleDownloadLeadsClick : null}
              disabled={!canEdit}
            >
              Export {selectedLeads.length} {getButtonText()}
            </Button>
          )}

          {/* Remove Emails Button */}
          {selectedLeads.length > 0 && (
            <Button
              sx={{
                textTransform: 'none',
                fontSize: '14px',
                fontWeight: 600,
                color: '#FF4545',
                padding: '8px 16px',
                border: '1px solid #E4E4E5',
                borderRadius: '6px',
                '&:hover': {
                  backgroundColor: 'rgba(255, 69, 69, 0.1)'
                }
              }}
              onClick={canEdit && (() => handleDeleteClick())}
              disabled={!canEdit}
            >
              Remove {selectedLeads.length} {getButtonText()} from blocklist
            </Button>
          )}
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Table
            sx={{
              backgroundColor: theme.palette.primary.contrastText,
              border: 'none',
              borderCollapse: 'collapse',
              display: { xs: 'block', sm: 'table' },
              overflowX: { xs: 'auto', sm: 'visible' }
            }}
          >
            {isLoadingBlocklist
              ? (
                <CircularProgress />
                )
              : leads?.length === 0
                ? (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={3} align='center' sx={{ border: 'none', padding: '16px' }}>
                        <Typography sx={{ color: '#8181B0', fontWeight: 400, fontSize: '14px' }}>
                          No {listType === 'email' ? 'leads' : 'domains'} available to display.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  )
                : (
                  <TableBody>
                    {leads.map((lead, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          bgcolor: theme.palette.primary.contrastText,
                          display: { xs: 'flex', sm: 'table-row' },
                          flexDirection: { xs: 'column', sm: 'row' }
                        }}
                      >
                        <TableCell
                          sx={{
                            border: 'none',
                            width: { xs: '100%', sm: 'auto' },
                            '&:last-child td, &:last-child th': { border: 'none' }
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '16px',
                              flexWrap: { xs: 'wrap', sm: 'nowrap' }
                            }}
                          >
                            {/* <Checkbox  /> */}
                            <Checkbox
                              sx={{ padding: '0px', ml: 1 }}
                              checked={selectedLeads.some((selected) => selected._id === lead._id)}
                              onChange={(event, checked) => handleSelectLeadChange(lead, checked)}
                            />
                            <Avatar>{String.fromCharCode(65 + index)}</Avatar>

                            <Box>
                              <Typography sx={{ fontWeight: 700, fontSize: '13px', color: '#101010' }}>
                                {lead.email
                                  ? getHighlightedText(lead.email, search)
                                  : getHighlightedText(lead.domain, search)}
                              </Typography>

                              <Typography sx={{ color: '#8181B0', fontWeight: 400, fontSize: '13px' }}>
                                {new Date(lead.createdAt).toLocaleString()}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>

                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: { xs: 'flex-start', sm: 'end' },
                            bgcolor: 'white',
                            width: { xs: '100%', sm: 'auto' },
                            pl: { xs: 3, sm: 0 }
                          }}
                        >
                          <Box sx={{ display: 'flex', gap: '8px', justifyContent: 'flex-end' }}>
                            <Button
                              variant='outlined'
                              size='small'
                              color='error'
                              sx={{
                                textTransform: 'none',
                                border: '1px solid #E4E4E5',
                                color: '#FF4545'
                              }}
                              onClick={() => handleDeleteClick(lead._id)}
                            >
                              Remove
                            </Button>

                            <Menu
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={() => setAnchorEl(null)}
                              sx={{
                                '& .MuiPaper-root': {
                                  border: '1px solid #D7D9E6',
                                  borderRadius: '8px',
                                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                                }
                              }}
                            >
                              <MenuItem onClick={handleRemove} sx={{ padding: '8px 16px' }}>
                                <Typography color='error'>Remove team member</Typography>
                              </MenuItem>
                              <MenuItem onClick={handleRemove} sx={{ padding: '8px 16px' }}>
                                <Typography>View / VA Access</Typography>
                              </MenuItem>
                            </Menu>
                          </Box>
                        </Box>
                      </TableRow>
                    ))}
                  </TableBody>
                  )}
          </Table>
          <Box sx={{ mt: 2 }}>
            <Pagination
              page={page}
              setPage={setPage}
              total={total}
              length={leads?.length}
              limit={limit}
              handleLimitChange={handleLimitChange}
            />
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "16px",
              background: "#F2F4F6",
              border: "1px solid #D7D9E6",
              px: "10px",
              py: "4px",
              borderRadius: "8px",
            }}
          >
            <Typography variant="body2">1–100 of 188</Typography>
            <Box sx={{ display: "flex " }}>
              <Typography sx={{ mr: 2, mt: 1, fontWeight: 500, fontSize: "13px" }}>
                Rows per page
              </Typography>
              <Select defaultValue="100" size="small" sx={{ width: "80px", borderRadius: "8px" }}>
                <MenuItem value="10">10</MenuItem>
                <MenuItem value="25">25</MenuItem>
                <MenuItem value="50">50</MenuItem>
                <MenuItem value="100">100</MenuItem>
              </Select>
            </Box>
          </Box> */}
        </CustomTabPanel>
        <Dialog
          open={isImportLeadsDialogOpen}
          onClose={handleCloseOpenImportLeadsDialog}
          fullWidth
          maxWidth='md'
          sx={{ backgroundColor: 'rgba(4, 4, 30, 0.5)' }}
          fullScreen={isMobile}
        >
          <DialogTitle>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                width: '100%'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  flexDirection: 'column'
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Typography
                    sx={{
                      fontSize: '20px',
                      fontWeight: 700,
                      lineHeight: '28px',
                      color: `${theme.palette.primary.primaryText}`
                    }}
                  >
                    {listType === 'email' ? 'Import Leads' : 'Import Domains'}
                  </Typography>
                  {activeStep !== 0 && (
                    <>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          mr: 0.5
                        }}
                      >
                        <ArrowRight />
                      </Box>
                    </>
                  )}
                  {activeStep === 1
                    ? (
                      <>
                        <Typography
                          sx={{
                            fontSize: '16px',
                            fontWeight: 700,
                            lineHeight: '28px',
                            color: '#8181B0'
                          }}
                        >
                          Import CSV File
                        </Typography>
                      </>
                      )
                    : activeStep === 2
                      ? (
                        <>
                          <Typography
                            sx={{
                              fontSize: '16px',
                              fontWeight: 700,
                              lineHeight: '28px',
                              color: '#8181B0'
                            }}
                          >
                            {listType === 'email' ? 'Input Emails Manually' : 'Input Domains Manually'}
                          </Typography>
                        </>
                        )
                      : activeStep === 3
                        ? (
                          <>
                            <Typography
                              sx={{
                                fontSize: '16px',
                                fontWeight: 700,
                                lineHeight: '28px',
                                color: '#8181B0'
                              }}
                            >
                              Utilize Google Sheets
                            </Typography>
                          </>
                          )
                        : null}
                </Box>

                {activeStep === 0
                  ? (
                    <>
                      <Typography
                        sx={{
                          fontSize: '13px',
                          fontWeight: 400,
                          lineHeight: '20px',
                          color: '#8181B0',
                          mt: 1
                        }}
                      >
                        {listType === 'email'
                          ? 'Choose one of the methods listed below to effortlessly import emails.'
                          : 'Choose one of the methods listed below to effortlessly import domains.'}
                      </Typography>
                    </>
                    )
                  : (
                    <>
                      <Typography
                        sx={{
                          fontSize: '13px',
                          fontWeight: 400,
                          lineHeight: '20px',
                          color: '#8181B0',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          mt: 1,
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          setActiveStep(0)
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mr: 1
                          }}
                        >
                          <ArrowLeftIconBlue color='#8181B0' />
                        </Box>
                        Select a Different Method
                      </Typography>
                    </>
                    )}
              </Box>
              <IconButton
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                onClick={handleCloseOpenImportLeadsDialog}
              >
                <EACloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            {activeStep === 0
              ? (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', sm: 'row' },
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      rowGap: 2
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        cursor: 'pointer',
                        width: '100%',
                        p: 3,
                        border: '1px solid #00AA38',
                        flexDirection: 'column',
                        height: '100%',
                        backgroundColor: '#fff',
                        borderRadius: '12px',
                        boxShadow: '0px 12px 15px 0px #4B71970D'
                      }}
                      onClick={() => {
                        setActiveStep(1)
                      }}
                    >
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <BulkUploadIcon />
                      </Box>

                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start'
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: '14px',
                            fontWeight: 700,
                            lineHeight: '26px',
                            color: `${theme.palette.primary.primaryText}`,
                            mt: 1.5
                          }}
                        >
                          Upload CSV
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        cursor: 'pointer',
                        width: '100%',
                        p: 3,
                        border: '1px solid #CECECE',
                        flexDirection: 'column',
                        height: '100%',
                        backgroundColor: '#fff',
                        borderRadius: '12px',
                        boxShadow: '0px 12px 15px 0px #4B71970D',
                        mx: 2
                      }}
                      onClick={() => {
                        setActiveStep(2)
                      }}
                    >
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <ManualEmailIcon />
                      </Box>

                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start'
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: '14px',
                            fontWeight: 700,
                            lineHeight: '26px',
                            color: `${theme.palette.primary.primaryText}`,
                            mt: 1.5
                          }}
                        >
                          {listType === 'email' ? 'Enter Emails Manually' : 'Enter Domains Manually'}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        cursor: 'pointer',
                        width: '100%',
                        p: 3,
                        border: `1px solid ${theme.palette.primary.main}`,
                        flexDirection: 'column',
                        height: '100%',
                        backgroundColor: '#fff',
                        borderRadius: '12px',
                        boxShadow: '0px 12px 15px 0px #4B71970D'
                      }}
                      onClick={() => {
                        setActiveStep(3)
                      }}
                    >
                      <Google />
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start'
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: '14px',
                            fontWeight: 700,
                            lineHeight: '26px',
                            color: `${theme.palette.primary.primaryText}`,
                            mt: 1.5
                          }}
                        >
                          Use Google Sheets
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </>
                )
              : activeStep === 1
                ? (
                  <CsvImport
                    listType={listType}
                    setSnackbarOpen={setSnackbarOpen}
                    setSnackbarMsg={setSnackbarMsg}
                    onLeadsCreate={onLeadsCreate}
                    workspaceId={selectedWorkspaceId}
                  />
                  )
                : activeStep === 2
                  ? (
                    <ManualImport
                      listType={listType}
                      setSnackbarOpen={setSnackbarOpen}
                      setSnackbarMsg={setSnackbarMsg}
                      onLeadsCreate={onLeadsCreate}
                      workspaceId={selectedWorkspaceId}
                    />
                    )
                  : activeStep === 3
                    ? (
                      <GoogleSheetImport
                        listType={listType}
                        setSnackbarOpen={setSnackbarOpen}
                        setSnackbarMsg={setSnackbarMsg}
                        onLeadsCreate={onLeadsCreate}
                        workspaceId={selectedWorkspaceId}
                      />
                      )
                    : null}
          </DialogContent>
        </Dialog>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        message={snackbarMsg}
        key='csv-import'
        ContentProps={{
          sx: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
          }
        }}
      />
    </>
  )
}

export default BlocklistPage
