import React, { useState } from 'react'
import { Box, TextField } from '@mui/material'
import { format, parseISO } from 'date-fns'

import ActionButtons from './ActionButtons'
import CollapsibleSection from './CollapsibleSection'

import InfoIconSvg from 'src/assets/dealPipeline/info.svg'
import ServerSvg from 'src/assets/dealPipeline/server.svg'
import LinkIconSvg from 'src/assets/dealPipeline/link.svg'

import SvgRenderer from '../SvgRenderer'
import HighlightedText from './HighlightedText'
import CompanyHeader from './CompanyHeader'
import HighlightedTextPills from './HighlightedTextPills'

export const CompanyTab = ({ user }) => {
  const [isPrincipalInfoOpen, setIsPrincipalInfoOpen] = useState(true)
  const [isAdditionalInfoOpen, setIsAdditionalInfoOpen] = useState(false)

  const additionalData = user?.additionalData?.additionalData || {}
  const { email_status: emailStatus = '', organization = {} } = additionalData

  let {
    country: orgCountry = '',
    state: orgState = '',
    city: orgCity = '',
    postal_code: orgPostalCode = '',
    raw_address: orgRawAddress = '',
    sanitized_phone: orgPhone = '',
    primary_domain: orgDomain = '',
    website_url: orgWebsite = '',
    current_technologies: orgCurrentTechnologies = [],
    name: orgName = '',
    linkedin_url: orgLinkedin = '',
    street_address: orgStreet = '',
    technology_names: orgTechNames = [],
    industry: orgIndustry = '',
    estimated_num_employees: orgEmployees = 0,
    founded_year: orgFoundedYear = 0,
    email: orgEmail = '',
    seo_description: orgSeoDescription = '',
    logo_url: orgLogoUrl = '',
    facebook_url: orgFacebook = '',
    latest_funding_round_date: orgLatestFundingRoundDate = ''
  } = organization

  orgName = orgName || user?.additionalData?.companyName || 'N/A'
  const location = `${orgState || ''}${orgCity ? `, ${orgCity}` : ''}${
    orgCountry ? `, ${orgCountry}` : ''
  }`

  orgCurrentTechnologies =
    orgCurrentTechnologies && orgCurrentTechnologies.length > 0
      ? orgCurrentTechnologies.map((t) => t.name)
      : null

  return (
    <Box mt={2}>
      <Box sx={{ padding: '0px 20px' }}>
        <CompanyHeader
          name={orgName}
          email={orgEmail || ''}
          isVerified={emailStatus === 'verified' && orgName !== 'N/A'}
          icon={<SvgRenderer src={LinkIconSvg} width='16' height='16' alt='icon' />}
          logo={orgLogoUrl}
        />

        {/* Action Buttons */}
        <ActionButtons email={orgEmail} call={orgPhone} />

        {/* Notes Section */}
        <Box mb={2}>
          <TextField
            fullWidth
            placeholder='Add a note about this company...'
            multiline
            rows={3}
            variant='outlined'
          />
        </Box>
      </Box>

      {/* Principal Information */}
      <CollapsibleSection
        title='Principal Information'
        icon={<SvgRenderer src={InfoIconSvg} width='22' height='22' alt='icon' />}
        isOpen={isPrincipalInfoOpen}
        onToggle={() => setIsPrincipalInfoOpen(!isPrincipalInfoOpen)}
      >
        <Box mt={1} pl={2}>
          {orgName && <HighlightedText label='Company Name' value={orgName || ''} />}

          {(orgWebsite || orgDomain) && (
            <HighlightedText
              label='Company Domain'
              value={
                <a href={orgWebsite || orgDomain} target='_blank' rel='noopener noreferrer'>
                  {orgWebsite || orgDomain}
                </a>
              }
            />
          )}

          {location !== '' && <HighlightedText label='Location' value={location} />}

          {orgIndustry && <HighlightedText label='Industry' value={orgIndustry} />}
          {orgTechNames && orgTechNames.length > 0 && (
            <HighlightedTextPills label='Technologies' values={orgTechNames} />
          )}

          {orgLinkedin && (
            <HighlightedText
              label='LinkedIn'
              value={
                <a sx={{ color: '#3F50F8' }} href={orgLinkedin}>
                  {orgLinkedin}
                </a>
              }
            >
              <a sx={{ color: '#3F50F8' }} href={orgLinkedin}>
                <SvgRenderer src={LinkIconSvg} width='16' height='16' alt='icon' />
              </a>
            </HighlightedText>
          )}

          {orgFacebook && (
            <HighlightedText
              label='Facebook'
              value={
                <a sx={{ color: '#3F50F8' }} href={orgFacebook}>
                  {orgFacebook}
                </a>
              }
            >
              <a sx={{ color: '#3F50F8' }} href={orgFacebook}>
                <SvgRenderer src={LinkIconSvg} width='16' height='16' alt='icon' />
              </a>
            </HighlightedText>
          )}
        </Box>
      </CollapsibleSection>

      {/* Additional Information */}
      <CollapsibleSection
        title='Additional Information'
        icon={<SvgRenderer src={ServerSvg} width='22' height='22' alt='icon' />}
        isOpen={isAdditionalInfoOpen}
        onToggle={() => setIsAdditionalInfoOpen(!isAdditionalInfoOpen)}
      >
        <Box mt={1} pl={2}>
          {orgSeoDescription && (
            <HighlightedText label='SEO Description' value={null}>
              {orgSeoDescription}
            </HighlightedText>
          )}

          {orgCurrentTechnologies && orgCurrentTechnologies.length > 0 && (
            <HighlightedTextPills label='Current Technologies' values={orgCurrentTechnologies} />
          )}
          {orgEmployees !== 0 && <HighlightedText label='Estimated Employees' value={orgEmployees} />}

          {orgFoundedYear !== 0 && <HighlightedText label='Founded Year' value={orgFoundedYear} />}

          {orgLatestFundingRoundDate && (
            <HighlightedText
              label='Latest Funding Round Date'
              value={format(parseISO(orgLatestFundingRoundDate), 'd MMMM yyyy h:mm a')}
            />
          )}

          {orgRawAddress && <HighlightedText label='Raw Address' value={orgRawAddress} />}

          {orgStreet && <HighlightedText label='Street Address' value={orgStreet} />}

          {orgPostalCode && <HighlightedText label='Postal Code' value={orgPostalCode} />}
        </Box>
      </CollapsibleSection>
    </Box>
  )
}

export default CompanyTab
