import { ArrowDropDown, MoreHoriz } from '@mui/icons-material'
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography
} from '@mui/material'
import { React, useState, useEffect, useContext } from 'react'
import { FilterIcon } from 'src/assets/general/FilterIcon'
import { IHCampaignsIcon } from 'src/assets/inboxHub/InboxHubIcons'
import { SBEmail } from 'src/assets/sidebar/SBEmail'
import { useGetAllLabelsQuery } from 'src/services/campaign-service'
import { InboxHubContext } from 'src/pages/InboxHub'
import { useCampaignsList, useMenu } from 'src/hooks/InboxHubHooks'

const EmailListControls = () => {
  const {
    setSelectedCampaignId,
    setSelectedStatusLabelId,
    allChecked,
    setAllChecked,
    markedEmailIds,
    setCurrentBulkDialogOpened,
    isMobile
  } = useContext(InboxHubContext)
  const { anchorEl, currentFilter, open, handleClick, handleClose } = useMenu()
  const [statuses, setStatuses] = useState([])

  const {
    offset,
    campaignList,
    total: totalCampaigns,
    isLoading: isCampaignsLoading,
    handleClickMore
  } = useCampaignsList()

  const { data: { labels: statusLabels } = { labels: [] }, isFetching: isLabelsLoading } =
    useGetAllLabelsQuery()

  useEffect(() => {
    setStatuses([...statuses, ...statusLabels])
  }, [statusLabels])

  const handleSelectCampaign = (campaignId) => {
    setSelectedCampaignId(campaignId)
    handleClose()
  }
  const handleSelectStatus = (statusId) => {
    setSelectedStatusLabelId(statusId)
    handleClose()
  }

  const handleClickAllChecked = () => {
    setAllChecked(!allChecked)
  }

  function handleClickDeleteSelected () {
    if (markedEmailIds.length) {
      setCurrentBulkDialogOpened('deleteSelectedEmails')
    }
  }
  function handleClickMarkAsReadSelected () {
    if (markedEmailIds.length) {
      setCurrentBulkDialogOpened('markAsReadSelectedEmails')
    }
  }

  const styles = {
    display: 'flex',
    py: 1,
    boxShadow: '0 2px 3px rgba(0, 0, 0, 0.1)',
    position: 'relative',
    zIndex: 2
  }

  return (
    <Box
      sx={
        isMobile
          ? {
              ...styles,
              alignItems: 'start',
              justifyContent: 'space-between',
              flexDirection: 'column-reverse',
              flexWrap: 'wrap',
              height: 'fit-content',
              paddingX: 1
            }
          : {
              ...styles,
              alignItems: 'center',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              height: '7%'
            }
      }
    >
      <Stack key={0} direction='row' gap={0.2} width={isMobile ? 'fit-content' : '24%'}>
        <Checkbox
          disableRipple
          size='small'
          sx={{}}
          checked={allChecked}
          onChange={handleClickAllChecked}
        />

        <Typography
          variant='body2'
          color='#000'
          sx={{
            fontSize: '14px',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          {/* {`${selectedEmails.length} selection`} */}
          {`${markedEmailIds.length} selected`}
        </Typography>
      </Stack>
      {/* </ListItemIcon> */}
      <Stack direction='row' width={isMobile ? '100%' : '76%'} justifyContent='space-between'>
        <Stack key={1} direction='row' spacing={0.5}>
          <FilterButton
            key={0}
            buttonLabel='Campaigns'
            popoverContent='campaign'
            icon={<IHCampaignsIcon />}
            handleClick={handleClick}
          />
          <FilterButton
            key={1}
            buttonLabel='Status'
            popoverContent='status'
            icon={<SBEmail />}
            handleClick={handleClick}
          />
          <FilterButton
            key={2}
            buttonLabel={null}
            popoverContent='more'
            icon={<MoreHoriz />}
            handleClick={handleClick}
          />

          <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
            PaperProps={{
              style: {
                maxHeight: 400, // Set the maximum height
                overflow: 'auto' // Enable scrolling
              }
            }}
          >
            {currentFilter === 'campaign'
              ? (
                  isCampaignsLoading
                    ? (
                      <Box>loading...</Box>
                      )
                    : (
                        [
                          campaignList.map((campaign, i) => (
                            <MenuItem
                              key={i}
                              onClick={() => {
                                handleSelectCampaign(campaign._id)
                              }}
                            >
                              <Typography textOverflow='ellipsis' overflow='hidden' width='20rem'>
                                {campaign?.name ?? ''}
                              </Typography>
                            </MenuItem>
                          )),
                          offset < totalCampaigns && <MenuItem onClick={handleClickMore}>more</MenuItem>
                        ]
                      )
                )
              : currentFilter === 'status'
                ? (
                    isLabelsLoading
                      ? (
                        <Box>loading...</Box>
                        )
                      : (
                          statuses.map((status, i) => (
                            <MenuItem
                              key={i}
                              onClick={() => {
                                handleSelectStatus(status._id)
                              }}
                            >
                              <Typography textOverflow='ellipsis' overflow='hidden' width='15rem'>
                                {status?.name ?? ''}
                              </Typography>
                            </MenuItem>
                          ))
                        )
                  )
                : (
                    [
                      <MenuItem key={0} onClick={handleClickMarkAsReadSelected}>
                        Mark as Read
                      </MenuItem>,
                      // <MenuItem key={1} onClick={handleClose}>
                      //   Move
                      // </MenuItem>,
                      <MenuItem key={2} onClick={handleClickDeleteSelected}>
                        Delete
                      </MenuItem>
                    ]
                  )}
          </Menu>
        </Stack>
        <Button
          key={2}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '13px',
            fontWeight: 700,
            lineHeight: '16.38px',
            color: '#28287B',
            backgroundColor: 'white',
            '&:hover': {
              backgroundColor: 'white'
            },
            px: 2,
            py: 0,
            padding: 0,
            height: '34px',
            // width: "42px",
            minWidth: 'auto'
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <FilterIcon />
            <ArrowDropDown />
          </Box>
        </Button>
      </Stack>
    </Box>
  )
}

export default EmailListControls

const FilterButton = ({ popoverContent, buttonLabel, icon, handleClick }) => {
  if (popoverContent !== 'more') {
    return (
      <Button
        onClick={(e) => {
          handleClick(e, popoverContent)
        }}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: { xs: '12px', sm: '16px' },
          fontWeight: 500,
          lineHeight: '16.38px',
          color: '#000',
          backgroundColor: 'white',
          '&:hover': {
            backgroundColor: 'white'
          },
          border: '1px solid #E4E4E5',
          height: '34px',
          minWidth: { xs: '135px', sm: '120px' },
          px: 2,
          flexShrink: 0,
          gap: 1
        }}
      >
        {icon}
        {buttonLabel}
      </Button>
    )
  }
  return (
    <IconButton
      onClick={(e) => {
        handleClick(e, popoverContent)
      }}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#28287B',
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: 'white'
        },
        border: '1px solid #E4E4E5',
        borderRadius: 1,
        height: '34px',
        width: '34px',
        minWidth: { xs: '40px', sm: '34px' },
        mr: 1
      }}
    >
      {icon}
    </IconButton>
  )
}
