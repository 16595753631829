import React, { useContext, useState } from 'react'
import {
  Chip,
  MenuItem,
  Box,
  Menu,
  Stack,
  Typography,
  styled,
  Checkbox,
  CircularProgress
} from '@mui/material'
import Alert from '@mui/material/Alert'
import { BlueButton, WhiteButton } from './CreateAnEmail'
import { AddLeadsToCampaigns, ChevronDownIcon } from 'src/assets/inboxHub/InboxHubIcons'
import { useMoveToCampaignMutation } from 'src/services/leads-service'
import toast from 'react-hot-toast'
import { InboxHubContext } from 'src/pages/InboxHub'
import { useCampaignsList, useMenu } from 'src/hooks/InboxHubHooks'

export const StyledChip = styled(Chip)(() => ({
  border: '1px solid #3F4FF836',
  backgroundColor: '#E6F0FF',
  borderRadius: 30
}))

const AddToCampaignForm = ({ handleClosePopup }) => {
  const { currentlyOpenedEmail } = useContext(InboxHubContext)
  const leadName = `${currentlyOpenedEmail?.leads?.firstName ?? ''} ${currentlyOpenedEmail?.leads?.lastName
    }`
  const {
    anchorEl: campaignsOptionsAnchorEl,
    currentFilter: selectedField,
    open: fieldOptionsOpen,
    handleClick: fieldHandleClick,
    handleClose: campaignsOptionsHandleClose
  } = useMenu()

  const [selectedOptionsCampaign, setSelectedOptionsCampaign] = useState([])
  const [selectedOptionsEmail, setSelectedOptionsEmail] = useState([])

  const handleOptionClickCampaign = (option) => {
    if (!selectedOptionsCampaign.includes(option)) {
      setSelectedOptionsCampaign([...selectedOptionsCampaign, option])
    }
    campaignsOptionsHandleClose()
  }
  const handleOptionClickEmail = (option) => {
    if (!selectedOptionsEmail.includes(option)) {
      setSelectedOptionsEmail([...selectedOptionsEmail, option])
    }

    campaignsOptionsHandleClose()
  }

  const handleRemoveChipCampaign = (optionToRemove) => {
    setSelectedOptionsCampaign(
      selectedOptionsCampaign.filter((option) => option !== optionToRemove)
    )
  }
  const handleRemoveChipEmail = (optionToRemove) => {
    setSelectedOptionsEmail(selectedOptionsEmail.filter((option) => option !== optionToRemove))
  }
  const [moveToCampaign, { isLoading }] = useMoveToCampaignMutation()

  async function handleClickAddToCampaign () {
    try {
      const { message } = await moveToCampaign({
        id: selectedOptionsCampaign?.[0]?._id,
        data: { leads: [currentlyOpenedEmail?.leads?._id], fromCampaign: selectedOptionsEmail?.[0]?._id }
      }).unwrap()
      handleClosePopup()
      toast.success(message)
    } catch (e) {
      toast.error(e?.data?.error?.message ?? 'Something not right')
    }
  }

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <Stack width='100%' justifyContent='space-between' mb={3}>
        <Typography fontWeight={700} fontSize='18px'>
          Add {leadName} to a Campaign
        </Typography>
      </Stack>
      <Stack gap={3}>
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            border: '1px solid #ccc',
            borderRadius: 2,
            padding: '3px',
            cursor: 'pointer',
            minHeight: '3rem'
          }}
          onClick={(e) => {
            fieldHandleClick(e, 'campaign')
          }}
        >
          {selectedOptionsCampaign.map((option, i) => (
            <StyledChip
              key={i}
              label={option?.name ?? ''}
              onDelete={() => handleRemoveChipCampaign(option)}
              sx={{ margin: '2px' }}
            />
          ))}
          <div
            style={{
              position: 'absolute',
              right: '2px',
              transform: 'rotate(180deg) translate(0, 15%)'
            }}
          >
            <ChevronDownIcon />
          </div>
        </Box>
        <Stack gap={1}>
          <Typography>Send Email From:</Typography>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              border: '1px solid #ccc',
              borderRadius: 2,
              padding: '3px',
              cursor: 'pointer',
              minHeight: '3rem'
            }}
            onClick={(e) => {
              fieldHandleClick(e, 'email')
            }}
          >
            {selectedOptionsEmail.map((option, i) => (
              <StyledChip
                key={i}
                label={option?.name ?? ''}
                onDelete={() => handleRemoveChipEmail(option)}
                sx={{ margin: '2px' }}
              />
            ))}
          </Box>

          <Stack direction='row' alignItems='center'>
            <Checkbox defaultChecked />
            <Typography>Skip Contacts Validation</Typography>
          </Stack>
          <Alert severity='warning'>
            Adding unverified contacts speeds things up but may affect deliverability. Final totals
            may differ if permission isn’t granted.
          </Alert>
        </Stack>

        <Stack direction={{ xs: 'column', mmd: 'row' }} justifyContent='flex-end' gap={2}>
          <WhiteButton onClick={handleClosePopup}>Cancel</WhiteButton>
          {isLoading
            ? (
              <CircularProgress />
              )
            : (
              <BlueButton endIcon={<AddLeadsToCampaigns />} onClick={handleClickAddToCampaign}>
                Add this lead to {selectedOptionsCampaign.length || ''} campaigns
              </BlueButton>
              )}
        </Stack>
      </Stack>

      <MoreOptionsMenu
        open={fieldOptionsOpen}
        selectedField={selectedField}
        moreOptionsAnchorEl={campaignsOptionsAnchorEl}
        handleClose={campaignsOptionsHandleClose}
        handleOptionClick={
          selectedField === 'campaign' ? handleOptionClickCampaign : handleOptionClickEmail
        }
      />
    </Box>
  )
}

export default AddToCampaignForm

// const campaignsOptions = [
//     { name: 'Social Media Marketing Campaign' },
//     { name: 'New Discounts Launching Campaign' },
//     { name: 'Products Campaign' },
// ]
// const emailsOptions = [
//     { name: 'mycompany@email.com' },
//     { name: 'successai@email.com' },
//     { name: 'watsongroup@gmail.com' },
// ]

const MoreOptionsMenu = ({
  moreOptionsAnchorEl,
  handleClose,
  open,
  handleOptionClick,
  selectedField
}) => {
  // const options = selectedField === 'campaign' ? campaignsOptions : emailsOptions
  const {
    offset,
    campaignList,
    total: totalCampaigns,
    isLoading: campaignsListLoading,
    handleClickMore
  } = useCampaignsList()
  return (
    <Menu
      id='campaigns-list'
      anchorEl={moreOptionsAnchorEl}
      open={open}
      onClose={handleClose}
      sx={{ width: '100%' }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      MenuListProps={{
        'aria-labelledby': 'basic-button'
      }}
      PaperProps={{
        style: {
          maxHeight: 400, // Set the maximum height
          overflow: 'auto' // Enable scrolling
        }
      }}
    >
      <Stack>
        {campaignsListLoading
          ? (
            <Box>loading...</Box>
            )
          : (
              [
                campaignList.map((campaign, key) => (
                  <MenuItem key={key} onClick={() => handleOptionClick(campaign)}>
                    <Typography textOverflow='ellipsis' overflow='hidden' width='20rem'>
                      {campaign?.name ?? ''}
                    </Typography>
                  </MenuItem>
                )),
                offset < totalCampaigns && <MenuItem onClick={handleClickMore}>more</MenuItem>
              ]
            )}
      </Stack>
    </Menu>
  )
}
