import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  LinearProgress,
  linearProgressClasses,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  Stack,
  styled,
  Typography,
  useTheme,
  CircularProgress
} from '@mui/material'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import {
  BulletsIcon,
  CheckEmailIcon,
  EmailSettingsAttachmentIcon,
  EmailSettingsCodeIcon,
  EmailSettingsPictureIcon,
  EmailSettingsSendReplyIcon,
  EmailSettingsTextIcon,
  FontBoldIcon,
  FontItalicIcon,
  FontSizeIcon,
  FontUnderlineIcon,
  FormatCenterIcon,
  FormatLeftIcon,
  FormatRightIcon,
  HashtagIcon,
  OptimizeEmailIcon,
  SendAReplyCrossIcon,
  SendAReplyEmailIcon,
  StrikeThroughIcon,
  SubscriptIcon,
  SuperScriptIcon,
  TestEmailIcon,
  TextColorIcon,
  TextPaintBucketIcon,
  VariablesMenuCompanyNameIcon,
  VariablesMenuEmailIcon,
  VariablesMenuFirstNameIcon,
  VariablesMenuLinkedInIcon,
  VariablesMenuSenderIcon
} from 'src/assets/inboxHub/InboxHubIcons'
import { useMenu, useTextAdjustments } from 'src/hooks/InboxHubHooks'
import { useOptimizeEmailMutation } from 'src/services/campaign-service'
import toast from 'react-hot-toast'
import {
  getQuestions,
  getReadingTime,
  getSpamCount,
  getSubjectCount,
  getUrlLength,
  getWordCount
} from './CheckEmailFunctions'
import {
  BlockButton,
  Element,
  HOTKEYS,
  initialValue,
  Leaf,
  MarkButton,
  toggleMark
} from './RichTextEditor'

import { Transforms, createEditor, Node } from 'slate'
import { Editable, withReact, Slate } from 'slate-react'
import isHotkey from 'is-hotkey'
import { withHistory } from 'slate-history'
import { deserialize, serialize } from 'src/utils/inboxHubUtils'
import { useSendReplyEmailMutation } from 'src/services/unibox-service'
import { InboxHubContext } from 'src/pages/InboxHub'
import { emailRegex, StyledChip } from './ForwardEmail'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[300],
    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.grey[800]
    })
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary,
    ...theme.applyStyles('dark', {
      backgroundColor: '#308fe8'
    })
  }
}))
export const BlueButton = styled(Button)(({ theme, padding }) => ({
  color: '#FFF', // Text color
  backgroundColor: '#3F4FF8', // Default background color
  borderRadius: '8px', // Rounded corners
  padding: padding || '8px 16px', // Padding
  transition: 'all 0.3s ease', // Smooth transitions
  fontSize: '16px',
  // Hover styles
  '&:hover': {
    backgroundColor: theme.palette.primary.main, // Background color on hover
    color: 'white', // Text color on hover
    borderColor: theme.palette.primary.dark // Border color on hover
  },

  // Active styles
  '&:active': {
    backgroundColor: theme.palette.primary.dark, // Background color when pressed
    borderColor: theme.palette.primary.dark, // Border color when pressed
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' // Add shadow on click
  },

  // Disabled styles
  '&.Mui-disabled': {
    color: '#FFF',
    backgroundColor: 'rgb(107, 107, 107)' // Background for disabled state
  }
}))
export const WhiteButton = styled(Button)(({ theme }) => ({
  color: '#000', // Text color
  backgroundColor: '#FFF', // Default background color
  borderRadius: '8px', // Rounded corners
  padding: '8px 16px', // Padding
  transition: 'all 0.3s ease', // Smooth transitions
  fontSize: '16px',
  border: '1px solid rgb(223, 223, 223)',
  // Hover styles
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.11)', // Background color on hover
    color: '000' // Text color on hover
  }
}))
const OptimizeEmailButton = styled(Button)(({ theme }) => ({
  color: '#000', // Text color
  backgroundColor: '#FFF', // Default background color
  borderRadius: '8px', // Rounded corners
  width: '12em',
  transition: 'all 0.3s ease', // Smooth transitions
  fontSize: '16px',
  border: '1px solid rgb(223, 223, 223)',
  // Hover styles
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.11)', // Background color on hover
    color: '000' // Text color on hover
  },
  '&:disabled': {
    color: 'rgb(90, 90, 90)'
  }
}))

export default function CreateAnEmail ({ setCreateAnEmailOpen }) {
  const renderElement = useCallback((props) => <Element {...props} />, [])
  const renderLeaf = useCallback((props) => <Leaf {...props} />, [])
  const editor = useMemo(() => withHistory(withReact(createEditor())), [])
  const [expanded, setExpanded] = useState(true)
  const {
    currentlyOpenedEmail: { subject = '', mailReplies = [], messageId = '' }
  } = useContext(InboxHubContext)

  const lastReply = mailReplies?.[0] ?? {}
  const theme = useTheme()
  const { anchorEl, open, handleClick, handleClose } = useMenu()
  const {
    anchorEl: textAdjAnchorEl,
    open: textAdjOpen,
    alignment,
    fontSize,
    handleAlignment,
    handleFontSizeChange,
    handleClickTextAdjustments,
    handleCloseTextAdjustments
  } = useTextAdjustments()
  const [emailPlainText, setEmailPlainText] = useState('')
  const [emailMetrics, setEmailMetrics] = useState([...emailMetricsDefault])
  const [sendReply, { isLoading: isSendingReply }] = useSendReplyEmailMutation()

  const [optimizeEmail, { isLoading: isOptimizingEmail }] = useOptimizeEmailMutation()
  async function handleClickOptimizeEmail () {
    if (!emailPlainText) {
      return toast.error("Email Text can't be empty.")
    }
    try {
      const optimize = await optimizeEmail({
        email: emailPlainText
      }).unwrap()
      const document = new window.DOMParser().parseFromString(optimize, 'text/html')
      Transforms.delete(editor)
      editor.insertFragment(deserialize(document.body))
    } catch (e) {
      return toast.error(e.data.error.message)
    }
  }

  function handleClickCheckEmail () {
    const metricValues = [
      getSubjectCount(subject),
      getWordCount(emailPlainText),
      getReadingTime(emailPlainText),
      getUrlLength(emailPlainText),
      getQuestions(emailPlainText),
      getSpamCount(subject, emailPlainText)
    ]
    emailMetrics.forEach((emailMetric, i) => {
      emailMetric.value = metricValues[i]
    })
    setEmailMetrics([...emailMetrics])
  }

  function handleClickVariableEmail (variable) {
    editor.insertText(`${variable}`)
  }

  const [editorState, setEditorState] = useState({})

  const [emailAddressTempCC, setEmailAddressTempCC] = useState('')
  const [emailAddressTempBCC, setEmailAddressTempBCC] = useState('')

  const [ccList, setCcList] = useState([])
  const [bccList, setBccList] = useState([])

  async function handleClickSendEmail () {
    try {
      const { message } = await sendReply({
        body: {
          subject,
          body: serialize({ children: editorState }),
          messageId,
          cc: ccList,
          bcc: bccList,
          ...lastReply
        }
      }).unwrap()
      toast.success(message)
    } catch (err) {
      toast.error(err.data.error.message)
    }
    setCreateAnEmailOpen(false)
  }

  function handleEnterCCKeyDown (e) {
    if (
      e.key === 'Enter' &&
      emailAddressTempCC.length &&
      emailRegex.test(emailAddressTempCC) &&
      !ccList.includes(emailAddressTempCC)
    ) {
      setCcList([...ccList, emailAddressTempCC])
      setEmailAddressTempCC('')
    }
  }
  const handleRemoveCCEmail = (indexToRemove) => {
    ccList.splice(indexToRemove, 1)
    setCcList([...ccList])
  }

  function handleEnterBCCKeyDown (e) {
    if (
      e.key === 'Enter' &&
      emailAddressTempBCC.length &&
      emailRegex.test(emailAddressTempBCC) &&
      !bccList.includes(emailAddressTempBCC)
    ) {
      setBccList([...bccList, emailAddressTempBCC])
      setEmailAddressTempBCC('')
    }
  }

  const handleRemoveBCCEmail = (indexToRemove) => {
    bccList.splice(indexToRemove, 1)
    setBccList([...bccList])
  }

  return (
    <Stack
      sx={{
        width: expanded ? { xs: '100vw', lg: '70vw' } : '30vw',
        height: 'fit-content',
        position: 'absolute',
        bottom: 0,
        right: 0,
        borderRadius: '8px 8px 0px 0px',
        backgroundColor: '#FFF',
        // overflow: 'hidden',
        zIndex: 2
      }}
    >
      {/* <RichText /> */}
      <Box
        onClick={() => {
          if (!expanded) {
            setExpanded(true)
          }
        }}
        sx={{
          height: '4.5vh',
          backgroundColor: '#3F4FF8',
          display: 'flex',
          justifyContent: 'space-between',
          px: 4,
          cursor: !expanded && 'pointer',
          '&:hover': { backgroundColor: !expanded && 'rgb(53, 40, 231)' }
        }}
      >
        <Stack direction='row' alignItems='center' gap={1}>
          <SendAReplyEmailIcon />
          <Typography sx={{ fontWeight: 500, color: '#FFF' }}>Send A Reply Email</Typography>
        </Stack>
        <Stack direction='row' alignItems='center' gap={3}>
          {/* {expanded && (
            <IconButton
              onClick={() => {
                setExpanded(!expanded);
              }}
            >
              <SendAReplyDownArrowIcon />
            </IconButton>
          )} */}
          <IconButton
            onClick={() => {
              setCreateAnEmailOpen(false)
            }}
          >
            <SendAReplyCrossIcon />
          </IconButton>
        </Stack>
      </Box>
      {expanded && (
        <Box sx={{ display: 'flex', width: '100%', height: { xs: '88vh', lg: '70vh' } }}>
          <Box sx={{ width: { xs: '100%', lg: '70%' }, height: 'full' }}>
            <Stack
              height='8%'
              borderBottom='1px solid rgb(231, 231, 231)'
              direction='row'
              alignItems='center'
              gap={2}
              paddingX={2}
            >
              <Typography sx={{ color: '#8181B0' }}>From:</Typography>
              <EmailChip email={lastReply?.from ?? ''} />
            </Stack>
            <Stack
              height='8%'
              borderBottom='1px solid rgb(231, 231, 231)'
              direction='row'
              alignItems='center'
              gap={2}
              paddingX={2}
            >
              <Typography sx={{ color: '#8181B0' }}>To:</Typography>
              <EmailChip email={lastReply?.to ?? ''} />
            </Stack>
            <Stack
              height='fit-content'
              borderBottom='1px solid rgb(231, 231, 231)'
              direction='row'
              alignItems='center'
              gap={2}
              paddingX={2}
            >
              <Typography sx={{ color: '#8181B0' }}>CC:</Typography>
              <Box
                sx={{
                  position: 'relative',
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  borderRadius: 2,
                  padding: '3px',
                  cursor: 'pointer',
                  minHeight: '3rem',
                  width: '100%'
                }}
              >
                {ccList.map((option, i) => (
                  <StyledChip
                    key={option}
                    label={option}
                    onDelete={() => handleRemoveCCEmail(i)}
                    sx={{ margin: '2px' }}
                  />
                ))}
                <input
                  style={{
                    height: '100%',
                    outline: 'none',
                    fontSize: '1rem',
                    border: 'none',
                    width: '12rem'
                  }}
                  placeholder=''
                  value={emailAddressTempCC}
                  onChange={(e) => {
                    setEmailAddressTempCC(e.target.value)
                  }}
                  onKeyDown={handleEnterCCKeyDown}
                />
              </Box>
            </Stack>
            <Stack
              height='fit-content'
              borderBottom='1px solid rgb(231, 231, 231)'
              direction='row'
              alignItems='center'
              gap={2}
              paddingX={2}
            >
              <Typography sx={{ color: '#8181B0' }}>BCC:</Typography>
              <Box
                sx={{
                  position: 'relative',
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  borderRadius: 2,
                  padding: '3px',
                  cursor: 'pointer',
                  minHeight: '3rem',
                  width: '100%'
                }}
              >
                {bccList.map((option, i) => (
                  <StyledChip
                    key={option}
                    label={option}
                    onDelete={() => handleRemoveBCCEmail(i)}
                    sx={{ margin: '2px' }}
                  />
                ))}
                <input
                  style={{
                    height: '100%',
                    outline: 'none',
                    fontSize: '1rem',
                    border: 'none',
                    width: '12rem'
                  }}
                  placeholder=''
                  value={emailAddressTempBCC}
                  onChange={(e) => {
                    setEmailAddressTempBCC(e.target.value)
                  }}
                  onKeyDown={handleEnterBCCKeyDown}
                />
              </Box>
            </Stack>
            <Stack
              height='8%'
              borderBottom='1px solid rgb(231, 231, 231)'
              direction={{ xs: 'column', lg: 'row' }}
              justifyContent={{ xs: 'center', lg: 'space-between' }}
              alignItems={{ xs: 'left', lg: 'center' }}
              gap={{ xs: 0, lg: 2 }}
              paddingX={2}
            >
              <Stack direction='row' gap={2}>
                <Typography sx={{ color: '#8181B0' }}>Subject:</Typography>
                <Typography fontWeight={500}>Re: {lastReply.subject}</Typography>
              </Stack>
              <Typography
                onClick={handleClick}
                sx={{ cursor: 'pointer' }}
                color='#3F4FF8'
                fontWeight={{ xs: 500, lg: 700 }}
              >
                {'Add {{Variable}}'}
              </Typography>
              <VariablesMenu anchorEl={anchorEl} open={open} handleClose={handleClose} />
            </Stack>

            <Slate
              editor={editor}
              initialValue={initialValue}
              onChange={(value) => {
                setEditorState(value)
                setEmailPlainText(value.map((n) => Node.string(n)).join('\n'))
              }}
            >
              {/* <Box sx={{ width: "full", height: "66%", border: "1px solid black" }}> */}
              <Box sx={{ overflowY: 'auto', overflowX: 'hidden', height: { xs: '50.5%', lg: '51.5%' } }}>
                <Editable
                  renderElement={renderElement}
                  renderLeaf={renderLeaf}
                  placeholder='Start typing your reply…'
                  spellCheck
                  autoFocus
                  style={{ border: 'none', outline: 'none', padding: '0 1rem', height: '100%' }}
                  onKeyDown={(event) => {
                    for (const hotkey in HOTKEYS) {
                      if (isHotkey(hotkey, event)) {
                        event.preventDefault()
                        const mark = HOTKEYS[hotkey]
                        toggleMark(editor, mark)
                      }
                    }
                  }}
                />
              </Box>
              <Box
                sx={{
                  width: 'full',
                  height: { xs: 'fit-content', lg: '10%' },
                  display: 'flex',
                  flexDirection: { xs: 'column', lg: 'row' },
                  alignItems: { xs: 'center', lg: 'center' },
                  justifyContent: { xs: 'flex-start', lg: 'space-between' },
                  px: { xs: 1, lg: 3 },
                  py: { xs: 1, lg: 0 },
                  gap: { xs: 0, lg: 2 },
                  borderTop: '1px solid rgb(223, 223, 223)'
                }}
              >
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  height={{ xs: 'fit-content', lg: '100%' }}
                  gap={{ xs: 0, lg: 4 }}
                  sx={{ width: { xs: '100%', lg: 'fit-content' } }}
                >
                  <OptimizeEmailButton
                    disabled={isOptimizingEmail}
                    onClick={handleClickOptimizeEmail}
                    startIcon={
                      !isOptimizingEmail ? <OptimizeEmailIcon /> : <CircularProgress size={16} />
                    }
                  >
                    {!isOptimizingEmail ? 'Optimize Email' : 'Optimizing...'}
                  </OptimizeEmailButton>
                  <Stack direction='row' gap={{ xs: 0, lg: 4 }} sx={{ flex: 1, display: 'flex', justifyContent: 'space-around' }}>
                    <IconButton aria-label='text-formatting' onClick={handleClickTextAdjustments}>
                      <EmailSettingsTextIcon />
                    </IconButton>
                    <IconButton aria-label='hyperlink'>
                      <EmailSettingsAttachmentIcon />
                    </IconButton>
                    <IconButton aria-label='code'>
                      <MarkButton format='code' icon={<EmailSettingsCodeIcon />} />
                    </IconButton>
                    <IconButton aria-label='image-upload'>
                      <EmailSettingsPictureIcon />
                    </IconButton>
                  </Stack>
                  <TextAdjustments
                    fontSize={fontSize}
                    handleFontSizeChange={handleFontSizeChange}
                    alignment={alignment}
                    handleAlignment={handleAlignment}
                    handleClose={handleCloseTextAdjustments}
                    anchorEl={textAdjAnchorEl}
                    open={textAdjOpen}
                  />
                </Stack>
                <Divider sx={{ display: { xs: 'none', lg: 'block' } }} orientation='vertical' />
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='space-around'
                  flexGrow={{ xs: 0, lg: 2 }}
                  sx={{ width: { xs: '100%', lg: 'fit-content' } }}
                >
                  <Typography
                    onClick={handleClick}
                    sx={{ cursor: 'pointer' }}
                    color='#3F4FF8'
                    fontWeight={700}
                  >
                    {'Add {{Variable}}'}
                  </Typography>
                  <VariablesMenu
                    handleClickVariable={handleClickVariableEmail}
                    anchorEl={anchorEl}
                    open={open}
                    handleClose={handleClose}
                  />
                  {isSendingReply
                    ? (
                      <CircularProgress size={25} />
                      )
                    : (
                      <BlueButton
                        onClick={handleClickSendEmail}
                        padding='8px 24px'
                        endIcon={<EmailSettingsSendReplyIcon />}
                      >
                        Send Reply
                      </BlueButton>
                      )}
                </Stack>
              </Box>
            </Slate>
          </Box>
          <Box
            sx={{
              px: 4,
              py: 2,
              width: '30%',
              display: { xs: 'none', lg: 'flex' },
              flexDirection: 'column',
              justifyContent: 'space-between',
              backgroundColor: 'rgb(240, 240, 240)'
            }}
          >
            <Box>
              <Typography fontSize={16} fontWeight={700}>
                Email Metrics Snapshop
              </Typography>
              <Typography fontSize={14} color='#8181B0'>
                See metrics to quickly assess your email's performace.
              </Typography>
            </Box>
            <Stack sx={{ height: '60%', display: 'flex', justifyContent: 'space-between' }}>
              {emailMetrics.map((metric, i) => {
                const percentage = (metric.value / metric.maxValue) * 100
                return (
                  <Box key={i}>
                    <Stack
                      direction='column'
                      sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        mb: '2%'
                      }}
                    >
                      <Stack
                        direction='row'
                        sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}
                      >
                        <Typography fontWeight={700} fontSize={16} noWrap>
                          {metric.subjectName}
                        </Typography>
                        <Typography
                          color={theme.palette.grey[500]}
                          fontWeight={400}
                          fontSize={16}
                          noWrap
                        >
                          {metric.value}
                        </Typography>
                      </Stack>
                    </Stack>
                    <BorderLinearProgress variant='determinate' value={percentage} />
                  </Box>
                )
              })}
              <BlueButton onClick={handleClickCheckEmail} startIcon={<CheckEmailIcon />}>
                Recheck Email
              </BlueButton>
            </Stack>
            <Divider />
            <Stack sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
              <Box>
                <Typography fontSize={16} fontWeight={700}>
                  Test Preview Email
                </Typography>
                <Typography fontSize={14} color='#8181B0'>
                  It will send an email with the preview on the left to the email address below.
                </Typography>
              </Box>
              <WhiteButton startIcon={<TestEmailIcon />}>Send Email Test</WhiteButton>
            </Stack>
          </Box>
        </Box>
      )}
    </Stack>
  )
}

const emailMetricsDefault = [
  {
    subjectName: 'Subject Count',
    value: 0,
    maxValue: 15
  },
  {
    subjectName: 'Word Count',
    value: 0,
    maxValue: 500
  },
  {
    subjectName: 'Reading Time',
    value: 0,
    maxValue: 210
  },
  {
    subjectName: 'URL Count',
    value: 0,
    maxValue: 3
  },
  {
    subjectName: 'Question Count',
    value: 0,
    maxValue: 4
  },
  {
    subjectName: 'Spam Word Count',
    value: 0,
    maxValue: 7
  }
]

const EmailChip = ({ email, photoUrl }) => {
  return (
    <Stack
      direction='row'
      px={0.8}
      py={0.5}
      gap={1}
      alignItems='center'
      sx={{ backgroundColor: '#E6F0FF', border: '1px solid #3F4FF836' }}
      borderRadius={4}
    >
      <Avatar
        alt='Natacha'
        src={photoUrl || 'https://randomuser.me/api/portraits/women/75.jpg'}
        sx={{ width: { xs: 20, lg: 28 }, height: { xs: 20, lg: 28 } }}
      />
      <Typography fontWeight={500} sx={{ fontSize: { xs: '12px', lg: '16px' }, textWrap: 'nowrap', maxWidth: '15rem', overflow: 'hidden', textOverflow: 'ellipsis' }}>{email || 'someemail@gmail.com'}</Typography>
      {/* <RecipientCrossIcon /> */}
    </Stack>
  )
}

const variableMenuItems = [
  {
    name: 'Email',
    variable: '{{emailaddress}}',
    icon: <VariablesMenuEmailIcon />
  },
  {
    name: 'First Name',
    variable: '{{firstname}}',
    icon: <VariablesMenuFirstNameIcon />
  },
  {
    name: 'Last Name',
    variable: '{{lastname}}',
    icon: <VariablesMenuFirstNameIcon />
  },
  {
    name: 'LinkedIn URL',
    variable: '{{linkedinurl}}',
    icon: <VariablesMenuLinkedInIcon />
  },
  {
    name: 'Company Name',
    variable: '{{companyname}}',
    icon: <VariablesMenuCompanyNameIcon />
  },
  {
    name: 'Sender Name',
    variable: '{{sendername}}',
    icon: <VariablesMenuSenderIcon />
  }
]

const VariablesMenu = ({ anchorEl, open, handleClose, handleClickVariable }) => {
  return (
    <Menu
      id='basic-menu'
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button'
      }}
      PaperProps={{
        style: {
          maxHeight: 400, // Set the maximum height
          overflow: 'auto' // Enable scrolling
        }
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
    >
      {variableMenuItems.map((menuItem, i) => (
        <MenuItem
          onClick={() => {
            handleClickVariable(menuItem.variable)
          }}
          key={i}
          sx={{ display: 'flex', justifyContent: 'space-between', gap: 4 }}
        >
          <Stack direction='row' alignItems='center'>
            <ListItemIcon>{menuItem.icon}</ListItemIcon>
            <ListItemText>{menuItem.name}</ListItemText>
          </Stack>
          <Typography variant='body2' sx={{ color: '#3F4FF8', fontSize: '16px' }}>
            {menuItem.variable}
          </Typography>
        </MenuItem>
      ))}
    </Menu>
  )
}

const TextAdjustments = ({
  handleFontSizeChange,
  alignment,
  handleAlignment,
  open,
  anchorEl,
  handleClose
}) => {
  const {
    anchorEl: internalMenuAnchorEl,
    currentFilter: currentAdjustment,
    open: internalMenuOpen,
    id: internalMenuId,
    handleClick,
    handleClose: handleInternalMenuClose
  } = useMenu()
  return (
    <Popover
      id='text-adjustments-popover'
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          padding: 1,
          border: '1px solid #ccc',
          borderRadius: '8px',
          backgroundColor: '#FFF',
          gap: 2
        }}
      >
        <IconButton
          onClick={(e) => {
            handleClick(e, 'font-size')
          }}
        >
          <FontSizeIcon />
        </IconButton>
        <IconButton>
          <MarkButton format='bold' icon={<FontBoldIcon />} />
        </IconButton>

        <IconButton>
          <MarkButton format='italic' icon={<FontItalicIcon />} />
        </IconButton>
        <IconButton>
          <MarkButton format='underline' icon={<FontUnderlineIcon />} />
        </IconButton>
        <IconButton>
          <MarkButton format='strikethrough' icon={<StrikeThroughIcon />} />
        </IconButton>
        <Divider orientation='vertical' flexItem sx={{ marginX: 1 }} />
        <IconButton>
          <MarkButton format='textcolor' icon={<TextColorIcon />} />
        </IconButton>
        <IconButton>
          <MarkButton format='highlight' icon={<TextPaintBucketIcon />} />
        </IconButton>
        <IconButton
          onClick={(e) => {
            handleClick(e, 'text-alignment')
          }}
        >
          {alignment === 'left' && <FormatLeftIcon />}
          {alignment === 'right' && <FormatRightIcon />}
          {alignment === 'center' && <FormatCenterIcon />}
        </IconButton>
        <IconButton>
          <BlockButton format='bulleted-list' icon={<BulletsIcon />} />
        </IconButton>
        <Divider orientation='vertical' flexItem sx={{ marginX: 1 }} />
        <IconButton>
          <MarkButton format='subscript' icon={<SubscriptIcon />} />
        </IconButton>
        <IconButton>
          <MarkButton format='superscript' icon={<SuperScriptIcon />} />
        </IconButton>
        <IconButton>
          <MarkButton format='heading' icon={<HashtagIcon />} />
        </IconButton>
      </Box>
      <Menu
        id={internalMenuId}
        anchorEl={internalMenuAnchorEl}
        open={internalMenuOpen}
        onClose={handleInternalMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
      >
        {currentAdjustment === 'font-size'
          ? (
            <div>
              <MenuItem
                sx={{ fontSize: '10px' }}
                onClick={() => {
                  handleFontSizeChange('extra-small')
                  handleInternalMenuClose()
                }}
              >
                <BlockButton format='size-10' icon='Extra Small' />
              </MenuItem>
              <MenuItem
                sx={{ fontSize: '12px' }}
                onClick={() => {
                  handleFontSizeChange('small')
                  handleInternalMenuClose()
                }}
              >
                <BlockButton format='size-12' icon='Small' />
              </MenuItem>
              <MenuItem
                sx={{ fontSize: '14px' }}
                onClick={() => {
                  handleFontSizeChange('medium')
                  handleInternalMenuClose()
                }}
              >
                <BlockButton format='size-14' icon='Medium' />
              </MenuItem>
              <MenuItem
                sx={{ fontSize: '18px' }}
                onClick={() => {
                  handleFontSizeChange('huge')
                  handleInternalMenuClose()
                }}
              >
                <BlockButton format='size-16' icon='Huge' />
              </MenuItem>
            </div>
            )
          : (
            <Stack>
              <MenuItem
                onClick={() => {
                  handleAlignment('left')
                  handleInternalMenuClose()
                }}
              >
                <BlockButton format='left' icon={<FormatLeftIcon />} />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleAlignment('center')
                  handleInternalMenuClose()
                }}
              >
                <BlockButton format='center' icon={<FormatCenterIcon />} />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleAlignment('right')
                  handleInternalMenuClose()
                }}
              >
                <BlockButton format='right' icon={<FormatRightIcon />} />
              </MenuItem>
            </Stack>
            )}
      </Menu>
    </Popover>
  )
}
