import { Draggable } from 'react-beautiful-dnd'
import {
  Box,
  Typography,
  IconButton
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { renderLabelWithIcon } from 'src/pages/statusLabelSelect'

const DraggableColumn = ({ columnId, column, index, onColumnVisibilityToggle, onDeleteColumn, PortalAwareDraggable, onMenuOpen, onMenuClose }) => (
  <Draggable draggableId={columnId} index={index}>
    {(provided, snapshot) => {
      const dragContent = (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px 0',
            borderBottom: '1px solid #ddd',
            backgroundColor: snapshot.isDragging ? '#f4f5f7' : 'white',
            zIndex: snapshot.isDragging ? 9999 : 'auto'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <DragIndicatorIcon sx={{ color: '#8181B0' }} />
            <IconButton onClick={() => onColumnVisibilityToggle(columnId)}>
              <VisibilityIcon sx={{ color: column.visible ? '#3F50F8' : '#8181B0' }} />
            </IconButton>
            <Typography sx={{ fontWeight: column.visible ? '700' : '400', color: column.visible ? '#000' : '#8181B0' }}>
              {renderLabelWithIcon({ name: column.title })}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography>Total: ${column.valuePerDeal || 0}</Typography>
            <IconButton onClick={(event) => onMenuOpen(event, columnId)}>
              <MoreVertIcon />
            </IconButton>
          </Box>
        </Box>
      )

      return snapshot.isDragging
        ? (
          <PortalAwareDraggable>{dragContent}</PortalAwareDraggable>
          )
        : (
            dragContent
          )
    }}
  </Draggable>
)

export default DraggableColumn
