import React, { useState } from 'react'
import { Box } from '@mui/material'
import CollapsibleSection from './CollapsibleSection'
import ActivityCard from './ActivityCard'

const ActivityEmailsGroup = ({ date, activities }) => {
  const [isOpen, setIsOpen] = useState(true)

  return (
    <CollapsibleSection
      title={date}
      icon={null} // Add any icon if needed
      isOpen={isOpen}
      onToggle={() => setIsOpen(!isOpen)}
    >
      <Box>
        {activities.map((activity, index) => (
          <ActivityCard key={index} {...activity} />
        ))}
      </Box>
    </CollapsibleSection>
  )
}

export default ActivityEmailsGroup
