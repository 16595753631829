import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Card,
  CardContent,
  Typography,
  Divider,
  Paper
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const TerminologyDialog = ({ openTerminologyDialog, setOpenTerminologyDialog }) => {
  const handleClose = () => setOpenTerminologyDialog(false)

  const statusData = [
    {
      status: 'Deliverable',
      description: 'The recipient\'s email provider has confirmed that the email address exists, it is safe to send.',
      color: '#48BF84',
      bgcolor: 'rgba(46, 204, 113, 0.1)'
    },
    {
      status: 'Risky',
      description: 'The email address may result in a bounce or low engagement. Risky addresses: Accept All, Full Mailbox or Disposable.',
      color: '#F8810D',
      bgcolor: 'rgba(255, 152, 0, 0.1)'
    },
    {
      status: 'Undeliverable',
      description: 'The address is either syntactically incorrect or does not exist.',
      color: '#F04438',
      bgcolor: 'rgba(244, 67, 54, 0.1)'
    },
    {
      status: 'Unknown',
      description: 'We were unable to receive a response from the email provider. Such verification will not be deducted from your credits balance. We will never charge you for the emails with this status.',
      color: '#667285',
      bgcolor: 'rgba(158, 158, 158, 0.1)'
    }
  ]

  const reasonData = {
    description: 'Additionally to the status of your results, Success.ai will provide you with a reason for the results:',
    items: [
      {
        status: 'Deliverable',
        color: '#48BF84',
        reasons: [
          {
            code: 'ACCEPTED EMAIL',
            description: 'Email address was accepted.',
            bgcolor: 'rgba(46, 204, 113, 0.1)'
          }
        ]
      },
      {
        status: 'Risky',
        color: '#F8810D',
        reasons: [
          {
            code: 'LOW DELIVERABILITY',
            description: 'Email address appears to be deliverable, but deliverability cannot be guaranteed (in most cases it is caused by catch_all / accept_all configuration of recipient\'s server or full mailbox).',
            bgcolor: 'rgba(255, 152, 0, 0.1)'
          },
          {
            code: 'LOW QUALITY',
            description: 'Email address has quality issues that may make it a risky or low-value address (in most cases it is caused by the fact that it is a disposable/temporary email).',
            bgcolor: 'rgba(255, 152, 0, 0.1)'
          }
        ]
      },
      {
        status: 'Undeliverable',
        color: '#667285',
        reasons: [
          {
            code: 'INVALID EMAIL',
            description: 'Specified email doesn\'t have a valid email address syntax.',
            bgcolor: 'rgba(244, 67, 54, 0.1)'
          },
          {
            code: 'INVALID DOMAIN',
            description: 'Domain for email does not exist or has no valid DNS records.',
            bgcolor: 'rgba(244, 67, 54, 0.1)'
          },
          {
            code: 'REJECTED EMAIL',
            description: 'Email address was rejected by the SMTP server, email address does not exist.',
            bgcolor: 'rgba(244, 67, 54, 0.1)'
          },
          {
            code: 'DNS ERROR',
            description: 'We were unable to resolve DNS records or domain is misconfigured.',
            bgcolor: 'rgba(244, 67, 54, 0.1)'
          },
          {
            code: 'TIMEOUT',
            description: 'Verification required more time than was available.',
            bgcolor: 'rgba(244, 67, 54, 0.1)'
          }
        ]
      },
      {
        status: 'Unknown',
        color: 'text.secondary',
        reasons: [
          {
            code: 'UNSUPPORTED',
            description: 'Email hosted by an unsupported email service provider.',
            bgcolor: 'rgba(158, 158, 158, 0.1)'
          },
          {
            code: 'UNAVAILABLE SMTP',
            description: 'SMTP server was unavailable to process our request or we were unable to connect to it.',
            bgcolor: 'rgba(158, 158, 158, 0.1)'
          },
          {
            code: 'UNKNOWN',
            description: 'An unexpected error has occurred.',
            bgcolor: 'rgba(158, 158, 158, 0.1)'
          }
        ]
      }
    ]
  }

  const domainDetails = [
    {
      term: 'Accept all',
      description: 'Domain set up to accept all emails. While the server will reply that the email is valid, it can still bounce.'
    },
    {
      term: 'Disposable',
      description: 'Temporary email, that is usually valid for up to 72hrs.'
    },
    {
      term: 'Free',
      description: 'Email that comes from a free domain, like hotmail.com or gmail.com'
    }
  ]

  const accountDetails = [
    {
      term: 'Role',
      description: 'Role-based email address, that is not assigned to a specific person, but to a position or a group of people, like info@ or support@'
    },
    {
      term: 'Disabled',
      description: 'Account exists but is disabled and won\'t be able to receive email.'
    },
    {
      term: 'Full mailbox',
      description: 'The inbox of this recipient is full, it might generate a soft bounce.'
    }
  ]

  const otherDetails = [
    {
      term: 'Provider',
      description: 'Name of infrastructure provider which handles SMTP communication for this domain. It can be either an email service provider or a name of an anti-spam filter provider which protects underlying infrastructure.'
    },
    {
      term: 'Did You Mean',
      description: 'If the email address you have provided seems to be undeliverable or of low quality - Bouncer may find a suggestion of a correct one and return it in the didYouMean field.\n\nPlease note it was not fully verified, so you might want to run a verification of the suggested email address.'
    },
    {
      term: 'Toxicity',
      description: 'If toxicity has been checked, it will have value of range 0 to 5 Meaning of which are as follows:\n0    - it has not been found on any of our email toxicity lists\n1-5 - higher value means higher toxicity and risk related with sending to this email\n      email or its domain has been found in one of our lists which indicate that it was involved in spamming, abusing, or is a spam trap'
    },
    {
      term: 'Score',
      description: 'Score represents likelihood that it email won\'t bounce.\nScore 98 means 98% chance that email will be delivered given that sender has good reputation.'
    }
  ]

  return (
    <Dialog
      open={openTerminologyDialog}
      onClose={handleClose}
      maxWidth='md'
      fullWidth
      scroll='paper'
    >
      <DialogTitle>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='h6'>Terminology</Typography>
          <IconButton aria-label='close' onClick={handleClose} size='small'>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>

          {/* Status Section */}
          <Box>
            <Typography variant='h6' gutterBottom sx={{ mb: 2 }}>
              Status
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {statusData.map((item) => (
                <Card key={item.status} variant='outlined'>
                  <CardContent>
                    <Typography color={item.color} gutterBottom sx={{ fontSize: '14px', fontWeight: 600 }}>
                      {item.status}
                    </Typography>
                    <Typography variant='body2' color='text.secondary'>
                      {item.description}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </Box>
          </Box>

          <Divider />

          {/* Reason Section */}
          <Box>
            <Typography variant='h6' gutterBottom sx={{ mb: 2 }}>Reason</Typography>
            <Typography variant='body2' color='text.secondary' sx={{ mb: 2 }}>
              {reasonData.description}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {reasonData.items.map((statusGroup) => (
                <Card key={statusGroup.status} variant='outlined'>
                  <CardContent>
                    <Typography
                      variant='subtitle1'
                      color={statusGroup.color}
                      sx={{ mb: 1, fontSize: '14px', fontWeight: 600 }}
                    >
                      {statusGroup.status}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                      {statusGroup.reasons.map((reason) => (
                        <Paper
                          key={reason.code}
                          elevation={0}
                          sx={{
                            display: 'grid',
                            gridTemplateColumns: '200px 1fr',
                            bgcolor: reason.bgcolor,
                            // overflowX: 'scroll',
                            borderRadius: 1
                          }}
                        >
                          <Box sx={{ p: 2, borderRight: '1px solid rgba(0, 0, 0, 0.05)' }}>
                            <Typography variant='subtitle2' sx={{ fontSize: '12px', fontWeight: 500 }}>
                              {reason.code}
                            </Typography>
                          </Box>
                          <Box sx={{ p: 2 }}>
                            <Typography variant='body2' color='text.secondary'>
                              {reason.description}
                            </Typography>
                          </Box>
                        </Paper>
                      ))}
                    </Box>
                  </CardContent>
                </Card>
              ))}
            </Box>
          </Box>

          <Divider />

          {/* Domain Details Section */}
          <Box>
            <Typography variant='h6' gutterBottom sx={{ mb: 2 }}>
              Domain Details
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {domainDetails.map((item) => (
                <Card key={item.term} variant='outlined'>
                  <CardContent>
                    <Typography variant='subtitle1' gutterBottom sx={{ fontSize: '14px', fontWeight: 600 }}>
                      {item.term}
                    </Typography>
                    <Typography variant='body2' color='text.secondary'>
                      {item.description}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </Box>
          </Box>

          <Divider />

          {/* Account Details Section */}
          <Box>
            <Typography variant='h6' gutterBottom sx={{ mb: 2 }}>
              Account Details
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {accountDetails.map((item) => (
                <Card key={item.term} variant='outlined'>
                  <CardContent>
                    <Typography variant='subtitle1' gutterBottom sx={{ fontSize: '14px', fontWeight: 600 }}>
                      {item.term}
                    </Typography>
                    <Typography variant='body2' color='text.secondary'>
                      {item.description}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </Box>
          </Box>

          <Divider />

          {/* Other Details Section */}
          <Box>
            <Typography variant='h6' gutterBottom sx={{ mb: 2 }}>
              Other Details
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {otherDetails.map((item) => (
                <Card key={item.term} variant='outlined'>
                  <CardContent>
                    <Typography variant='subtitle1' gutterBottom sx={{ fontSize: '14px', fontWeight: 600 }}>
                      {item.term}
                    </Typography>
                    <Typography variant='body2' color='text.secondary' sx={{ whiteSpace: 'pre-line' }}>
                      {item.description}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </Box>
          </Box>

        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default TerminologyDialog
