import React, { useContext } from 'react'
import { Box } from '@mui/material'
import EmailSearchBox from 'src/components/inboxHubV2/EmailSearchBox'
import EmailListControls from 'src/components/inboxHubV2/EmailListControls'
import EmailListing from 'src/components/inboxHubV2/EmailListing'
import DialogDeleteSelectedEmails from './DialogDeleteSelectedEmails'
import DialogMarkAsReadSelectedEmails from './DialogMarkAsReadSelectedEmails'
import ScrollablePopup from './ScrollablePopup'
import { InboxHubContext } from 'src/pages/InboxHub'
import { usePopups } from 'src/hooks/InboxHubHooks'

const EmailList = React.memo(() => {
  const { currentBulkDialogOpened } = useContext(InboxHubContext)
  const { handleClosePopup } = usePopups()
  return (
    <>
      <Box
        sx={{
          flexShrink: 1,
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 1,
          overflow: 'hidden',
          bgcolor: 'background.paper',
          border: '1px solid rgba(228, 228, 229, 1)'
        }}
      >
        <EmailSearchBox />
        <EmailListControls />
        <EmailListing />
      </Box>
      {currentBulkDialogOpened === 'deleteSelectedEmails' && (
        <ScrollablePopup
          open={currentBulkDialogOpened.length}
          onClose={handleClosePopup}
          title='Scrollable'
        >
          <DialogDeleteSelectedEmails handleClosePopup={handleClosePopup} />
        </ScrollablePopup>
      )}
      {currentBulkDialogOpened === 'markAsReadSelectedEmails' && (
        <ScrollablePopup
          open={currentBulkDialogOpened.length}
          onClose={handleClosePopup}
          title='Scrollable'
        >
          <DialogMarkAsReadSelectedEmails handleClosePopup={handleClosePopup} />
        </ScrollablePopup>
      )}
    </>
  )
})

export default EmailList
