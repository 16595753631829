import React from "react";
import {
  Box,
  Typography,
  Button,
  Divider,
  Card,
  CardContent,
  Grid,
  Checkbox,
  Dialog,
  IconButton,
  Stack,
  List,
  ListItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Logo } from "src/components/logo";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import FeedbackPopup from "./FeedbackPopup";
import { useState } from "react";
import { useUpdatePaymentMutation, useUpdatePlanMutation, useGetCurrentPlanQuery } from "src/services/billing-service";
import { toast } from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { config, planNames } from 'src/config'
const CancellationPlans = ({
  cancellationPlansOpen,
  handleClosecancellationPlans,
  handleOpenFeedbackPopup,
}) => {
  const [selectedBox, setSelectedBox] = useState(null);
  const handleBoxClick = (boxIndex) => {
    setSelectedBox(boxIndex);
  };
  const [updatePlan] = useUpdatePlanMutation()

  const [UpdatePayment] = useUpdatePaymentMutation()
  const handelBillingCheckout = async () => {
    const { data: url } = await UpdatePayment()
    window.location.href = url
  }
  const checkIconStyles = {
    backgroundColor: "#DAEFDF",
    color: "#00AA38",
    fontSize: "20px",
    marginRight: "8px",
    borderRadius: "50%",
  };
  const fireIconStyles = {
    color: "#FF5722",
    fontSize: "20px",
    marginRight: "8px",
  };

  const { data: fetchedCurrentPlan, refetch: refetchCurrentPlan } = useGetCurrentPlanQuery()

  const disableButton =
    (fetchedCurrentPlan?.subscription?.sendingWarmup == null ||
      fetchedCurrentPlan?.subscription?.sendingWarmup?.planId === config.SENDING_WARMUP_MONTHLY_GROWTH_DISCOUNT_PRICE_ID)
      ? "true"
      : "false";

  const workspace = useSelector((state) => state.workspace)
  const handleConfirmPlanUpdate = async () => {
    const priceId = config.SENDING_WARMUP_MONTHLY_GROWTH_DISCOUNT_PRICE_ID
    
    let planName = planNames
    const matchedPlanName = planNames[priceId]
    
    if (matchedPlanName) {
      planName = matchedPlanName.toLowerCase().split(' ')[1]
    }
    const toastId = toast.loading('Updating...', { duration: Infinity })
    // return
    try {
      const session = await updatePlan({
        priceId,
        workspaceId: workspace._id,
        planName
      }).unwrap()
      if (session.url) {
        window.location.href = session.url
      } else if (session.errorMsg) {
        toast.error(session.errorMsg, { id: toastId, duration: 5000 })
        if (session.errorMsg.includes('No attached default payment method')) {
          setTab(1)
        }
      } else {
        toast.success('Plan Updated', { id: toastId, duration: 2000 })
        await refetchCurrentPlan()
      }
    } catch (error) {
      toast.error('An unexpected error occurr during update', { id: toastId, duration: 5000 })
    }
  }

  return (
    <Dialog
      open={cancellationPlansOpen}
      onClose={handleClosecancellationPlans}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "12px",
          overflow: "hidden",
          border: "1px solid #e3e3e3",
        },
      }}
      sx={{ height: "calc(100%-50px)" }}
    >
      <Box
        sx={{
          paddingX: 6,
          backgroundColor: "#fff",
        }}
      >
        {/* Header */}
        <Box sx={{ position: "relative", p: 3, textAlign: "center" }}>
          <Typography variant="h6" sx={{ fontWeight: "bold", color: "#333" }}>
            <Logo color="#3E50F8" />
          </Typography>
          <IconButton
            onClick={handleClosecancellationPlans}
            sx={{
              position: "absolute",
              top: 16,
              right: 16,
              border: "1px solid #ccc", // Adds a border around the IconButton
              borderRadius: "20%", // Makes the border circular
              padding: "4px", // Adjusts the spacing inside the button
              backgroundColor: "white", // Optional: Background for contrast
              "&:hover": {
                backgroundColor: "#f0f0f0", // Hover effect
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            textAlign: "center",
            padding: "8px",
            backgroundColor: "#DAEFDF",
            border: "1px solid #00AA384D",
            borderRadius: "6px",
            marginBottom: "20px",
            color: "#101010",
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
            <CheckCircleIcon sx={checkIconStyles} />
            <Typography sx={{ fontSize: "14px" }}>
              Your subscription has been canceled successfully. Access remains active until [end
              date].
            </Typography>
          </Stack>
        </Box>

        {/* Timer Section */}
        <Box
          sx={{
            textAlign: "center",
            marginBottom: "12px",
            color: "#666",
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 600,
              fontSize: "13px",
              color: "#3F4FF8",
              textAlign: "center",
              background: "#E7F0FF",
              py: 1,
              borderRadius: "6px",
              mx: 38,
            }}
          >
            Limited: 4:59:59 remaining
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "22px",
              fontWeight: 700,
              fontWeight: "bold",
              color: "#222",
              margin: "10px 0",
              mb: 2,
            }}
          >
            <span style={{ color: "red" }}>Only today</span>, your last chance to grab an exclusive
            offer!
          </Typography>
        </Box>

        {/* Plans Section */}
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Box
              sx={{
                // border: "1px solid #e3e3e3",
                border: selectedBox === 1 ? "2px solid #0070f3" : "1px solid #e0e0e0",
                boxShadow:
                  selectedBox === 1
                    ? "0 4px 15px rgba(0, 112, 243, 0.5)" // Blue shadow
                    : "0 2px 10px rgba(0,0,0,0.1)", // Default shadow
                transition: "box-shadow 0.3s, border 0.3s",

                borderRadius: "8px",
                padding: "12px",
                mb: 2,
                display: "flex",
                alignItems: "center", // Align items vertically
                gap: "16px", // Space between image and text
              }}
            >
              {/* Image Container */}
              <Box
                sx={{
                  flex: "0 0 200px", // Fixed width for the image box
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/frame-1.png`}
                  alt="frame-1"
                  style={{
                    width: "100%", // Full width of the container
                    height: "auto", // Maintain aspect ratio
                    borderRadius: "4px", // Optional rounded corners
                  }}
                />
              </Box>

              {/* Text Content */}
              <Box sx={{ flex: "1", ml: 2, mr: 6 }}>
                <Typography sx={{ fontSize: "13px", fontWeight: 500, color: "#8181B0", mt: 2 }}>
                  Keep your monthly plan for just $9, the price of a cup of coffee.
                </Typography>

                <Box sx={{ mt: 1 }}>
                  <List sx={{ padding: 0, color: "#555", fontSize: "14px", fontWeight: 600 }}>
                    {/* First Row */}
                    <ListItem
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: "4px 0",
                        flexWrap: "wrap", // Ensures items wrap on smaller screens
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "50%", // Occupies half the width
                        }}
                      >
                        <CheckCircleIcon sx={checkIconStyles} />
                        1,200 active contacts
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "50%", // Occupies half the width
                        }}
                      >
                        <CheckCircleIcon sx={checkIconStyles} />
                        2,500 Al writer Credits
                      </Box>
                    </ListItem>

                    {/* Second Row */}
                    <ListItem
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: "4px 0",
                        flexWrap: "wrap",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "50%", // Occupies half the width
                        }}
                      >
                        <CheckCircleIcon sx={checkIconStyles} />
                        Unlimited emails
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "50%", // Occupies half the width
                        }}
                      >
                        <CheckCircleIcon sx={checkIconStyles} />
                        600 lead exports/month forever
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "50%",
                          // Occupies half the width
                          padding: "4px 0",
                          mt: "4px",
                        }}
                      >
                        <CheckCircleIcon sx={checkIconStyles} />5 connected Mailboxes
                      </Box>
                    </ListItem>
                  </List>
                </Box>

                <Button
                  onClick={handleConfirmPlanUpdate}
                  variant="contained"
                  fullWidth
                  disabled={!fetchedCurrentPlan?.subscription?.sendingWarmup ||
                    fetchedCurrentPlan?.subscription?.sendingWarmup?.planId === config.SENDING_WARMUP_MONTHLY_GROWTH_DISCOUNT_PRICE_ID }
                  sx={{
                    marginTop: "10px",
                    backgroundColor: "#3F4FF8",
                    borderRadius: "14px",
                    color: "#fff",
                    py: "12px",
                    "&:hover": { backgroundColor: "#1550b5" },
                  }}
                >
                  Subscribe to Saver Plan ($9/Monthly)
                </Button>
              </Box>
            </Box>
          </Grid>
          <Divider
            sx={{
              my: 2,
              // marginTop: "50px",
              borderColor: "#e0e0e0",
              borderWidth: "1px",
              width: "90%",
              margin: "0 auto",
              position: "relative",
              "::before, ::after": {
                borderColor: "#e0e0e0",
              },
              color: "#555",
              fontWeight: 500,
              fontSize: "13px",
            }}
          >
            or
          </Divider>

          {/* Lifetime Plan */}
          <Grid item xs={12}>
            <Box
              sx={{
                // border: "1px solid #e3e3e3",
                border: selectedBox === 2 ? "2px solid #0070f3" : "1px solid #e0e0e0",
                boxShadow:
                  selectedBox === 2
                    ? "0 4px 15px rgba(0, 112, 243, 0.5)" // Blue shadow
                    : "0 2px 10px rgba(0,0,0,0.1)", // Default shadow
                transition: "box-shadow 0.3s, border 0.3s",
                borderRadius: "8px",
                padding: "12px",
                mb: 2,
                display: "flex",
                alignItems: "center", // Align items vertically
                gap: "16px", // Space between image and text
              }}
            >
              {/* Image Container */}
              <Box
                sx={{
                  flex: "0 0 200px", // Fixed width for the image box
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/frame-2.png`}
                  alt="frame-1"
                  style={{
                    width: "100%", // Full width of the container
                    height: "auto", // Maintain aspect ratio
                    borderRadius: "4px", // Optional rounded corners
                  }}
                />
              </Box>

              {/* Text Content */}
              <Box sx={{ flex: "1", ml: 2, mr: 6 }}>
                <Typography sx={{ fontSize: "13px", fontWeight: 500, color: "#8181B0", mt: 2 }}>
                  Pay $199 today and get access to success.ai forever, never pay again.
                </Typography>

                <Box sx={{ mt: 1 }}>
                  <List sx={{ padding: 0, color: "#555", fontSize: "14px", fontWeight: 600 }}>
                    {/* First Row */}
                    <ListItem
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: "4px 0",
                        flexWrap: "wrap", // Ensures items wrap on smaller screens
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "50%", // Occupies half the width
                        }}
                      >
                        <LocalFireDepartmentIcon sx={fireIconStyles} />
                        6,000 active contacts
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "50%", // Occupies half the width
                        }}
                      >
                        <LocalFireDepartmentIcon sx={fireIconStyles} />
                        10,000 Al writer Credits
                      </Box>
                    </ListItem>

                    {/* Second Row */}
                    <ListItem
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: "4px 0",
                        flexWrap: "wrap",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "50%", // Occupies half the width
                        }}
                      >
                        <CheckCircleIcon sx={checkIconStyles} />
                        Unlimited emails
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "50%", // Occupies half the width
                        }}
                      >
                        <LocalFireDepartmentIcon sx={fireIconStyles} />
                        1,200 lead exports/month forever
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "50%",
                          // Occupies half the width
                          padding: "4px 0",
                          mt: "4px",
                        }}
                      >
                        <CheckCircleIcon sx={checkIconStyles} />5 connected Mailboxes
                      </Box>
                    </ListItem>
                  </List>
                </Box>

                <Button
                  onClick={() => handleBoxClick(2)}
                  variant="contained"
                  fullWidth
                  disabled = 'true'
                  sx={{
                    marginTop: "10px",
                    backgroundColor: "#3F4FF8",
                    borderRadius: "14px",
                    color: "#fff",
                    py: "12px",
                    "&:hover": { backgroundColor: "#1550b5" },
                  }}
                >
                  Subscribe to Lifetime Deal (Pay once $199)
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Decline Button */}
        <Divider
          sx={{
            my: 2,
            // marginTop: "50px",
            borderColor: "#e0e0e0",
            borderWidth: "1px",
            width: "90%",
            margin: "0 auto",
            position: "relative",
            "::before, ::after": {
              borderColor: "#e0e0e0",
            },
            color: "#555",
            fontWeight: 500,
            fontSize: "13px",
          }}
        >
          or
        </Divider>
        <Box
          sx={{
            textAlign: "center",
            // mt: 3,
            // borderTop: "1px solid #e0e0e0",
            pt: 2,
          }}
        >
          <Button
            onClick={handelBillingCheckout}
            variant="text"
            sx={{
              textTransform: "none",
              color: "#101010",
              fontWeight: "bold",
              border: "1px solid #E4E4E5",
              borderRadius: "8px",
              px: 14,
              py: "10px",
              ":hover": {
                backgroundColor: "#f9f9f9", // Optional: Light hover background
                borderColor: "#0070f3", // Optional: Change border color on hover
              },
              fontWeight: 600,
              fontSize: "14px",

              mb: 5,
            }}
          >
            I’m not interested in exclusive offers
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CancellationPlans;
