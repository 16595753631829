import { MoreHoriz, AddOutlined } from '@mui/icons-material'
import {
  Avatar,
  Box,
  IconButton,
  ListItemAvatar,
  Tooltip,
  Typography,
  Button,
  useTheme,
  alpha,
  Select,
  ListSubheader,
  TextField,
  InputAdornment,
  MenuItem,
  Stack,
  Menu
} from '@mui/material'
import React, { useContext, useMemo, useState } from 'react'
import {
  IHCampaignsIcon, IHForward, IHReply,
  MoreOptionsDelete,
  MoreOptionsEditLead,
  MoreOptionsMarkUnread,
  MoreOptionsRemoveLead
} from 'src/assets/inboxHub/InboxHubIcons'
import { SBEmail } from 'src/assets/sidebar/SBEmail'
import { SBSearch } from 'src/assets/sidebar/SBSearch'
import { useGetAllLabelsQuery, useUpdateLabelMutation } from 'src/services/campaign-service'
import NoDataAndWaitingWrapper from './NoDataAndWaitingWrapper'
import { useMenu, usePopups } from 'src/hooks/InboxHubHooks'
import { useFetchCampaignEmailsQuery, useOpenedEmailMutation } from 'src/services/unibox-service'
import toast from 'react-hot-toast'
import { EmailListingContext, InboxHubContext } from 'src/pages/InboxHub'
import { renderLabelWithIcon } from './RenderWithLabelIcon'

const RecipientInfo = ({ recipientInfo }) => {
  const theme = useTheme()
  const { setCreateAnEmailOpen, setCurrentPopUpOpened } = useContext(InboxHubContext)
  const { leads: { email, firstName, lastName } = {}, label, emailSubject = '' } = recipientInfo
  const {
    anchorEl: moreOptionsAnchorEl,
    open: moreOptionMenuOpen,
    id: moreOptionsId,
    handleClick: handleMoreOptionsClick,
    handleClose: moreOptionsHandleClose
  } = useMenu()
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: { xs: 'center', mmd: 'space-between' },
        alignItems: { xs: 'flex-start' },
        border: '1px solid #E4E4E5',
        borderRadius: '8px',
        // my: 1,
        // p: 2,
        backgroundColor: 'white'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: { xs: 'center', md: 'space-between' },
          alignItems: 'flex-start',
          gap: 1,
          my: 1,
          p: 1,
          width: '100%',
          borderBottom: '#000000'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: { xs: '100%', md: '60%' },
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <ListItemAvatar>
            <Avatar alt='name' src='https://randomuser.me/api/portraits/women/75.jpg' />
          </ListItemAvatar>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              flexDirection: { xs: 'column', sm: 'column', lg: 'column', md: 'column' }
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                flexDirection: { xs: 'column', md: 'row', lg: 'row', sm: 'column' }
              }}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '18px',
                  color: '#000000'
                }}
              >
                {`${firstName ?? ''} ${lastName ?? ''}`}
                <Typography
                  variant='span'
                  color='#000'
                  sx={{
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '18px'
                  }}
                >
                  {' '}
                  &bull; {email ?? ''}
                </Typography>
              </Typography>
            </Box>
            <Typography
              color='#8181B0'
              sx={{
                fontSize: '13px',
                lineHeight: '18px'
              }}
            >
              {emailSubject.substring(0, 30)}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: { xs: '100%', mmd: '50%' },
            alignItems: 'center'
          }}
        >
          <StatusLabels label={label} />
          <Button
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              // fontSize: '14px',
              // fontWeight: 700,
              lineHeight: '18px',
              py: 1,
              color: theme.palette.primary.contrastText,
              backgroundColor: '#3F4FF8',
              '&:hover': {
                color: theme.palette.primary.contrastText,
                backgroundColor: alpha(theme.palette.primary.main, 0.8)
              },
              minWidth: { xs: '3rem', sm: '8rem' }
            }}
            // onClick={handleEditClick}
            onClick={() => {
              setCreateAnEmailOpen(true)
            }}
          >
            <IHReply />
            <Typography
              sx={{
                display: { xs: 'none', sm: 'block' },
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '18px',
                color: '#ffffff',
                ml: '5px'
              }}
            >
              Reply
            </Typography>
          </Button>

          <Button
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '18px',
              py: 1,
              color: theme.palette.primary.primaryText,
              border: '1px solid #E4E4E5',
              backgroundColor: 'white',
              '&:hover': {
                backgroundColor: 'white'
              },
              minWidth: { xs: '3rem', sm: '8rem' }
            }}
            // onClick={handleForwardClick}
            onClick={() => {
              setCurrentPopUpOpened('forward')
            }}
          >
            <IHForward />
            <Typography
              sx={{
                display: { xs: 'none', sm: 'block' },
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '18px',
                color: '#000000',
                ml: '5px'
              }}
            >
              Forward
            </Typography>
          </Button>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer'
            }}
          >
            <Tooltip title='More' placement='top' arrow>
              <IconButton
                // onClick={handleClick}
                onClick={handleMoreOptionsClick}
                size='small'
                aria-controls='account-menu'
                aria-haspopup='true'
                aria-expanded='true'
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#000',
                  backgroundColor: 'white',
                  '&:hover': {
                    backgroundColor: 'white'
                  },
                  border: '1px solid #E4E4E5',
                  borderRadius: 1
                }}
              >
                <MoreHoriz />
              </IconButton>
            </Tooltip>
            <MoreOptionsMenu
              moreOptionsAnchorEl={moreOptionsAnchorEl}
              moreOptionMenuOpen={moreOptionMenuOpen}
              handleClose={moreOptionsHandleClose}
              id={moreOptionsId}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexDirection: { xs: 'column', lg: 'row', md: 'row' },
          justifyContent: { xs: 'flex-start', lg: 'space-between', md: 'space-between' },
          alignItems: { xs: 'flex-start', lg: 'center', md: 'center' },
          borderTop: '1px solid #E4E4E5',
          // my: 1,
          p: 1
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: { xs: 'column', lg: 'row', md: 'row' }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              paddingX: 2
            }}
          >
            <IHCampaignsIcon />
            <Typography
              variant='body1'
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '13px',
                fontWeight: '700',
                p: 1
              }}
            >
              Campaign: Unlock Your Data Potential: Join us! {'>'}
            </Typography>
          </Box>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            paddingX: 2,
            paddingY: { xs: 1, md: 0 },
            backgroundColor: '#E7F0FF',
            color: '#3F4FF8',
            borderRadius: '10px'
          }}
          >
            <SBEmail />
            <Typography
              variant='body2'
              sx={{
                fontSize: '13px',
                fontWeight: '500'
              }}
            >
              (Step 2) Share Your Favorite Moments with Us!
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default RecipientInfo

const StatusLabels = ({ label }) => {
  const theme = useTheme()
  const [, setSearchText] = useState('')
  const { handleOpenPopup } = usePopups()
  const { getCampaignEmailPayload } = useContext(EmailListingContext)
  const { refetch: refetchCampaignEmails } = useFetchCampaignEmailsQuery(getCampaignEmailPayload)
  const [currentLabel, setCurrentLabel] = useState(label)
  const [updateLabel] = useUpdateLabelMutation()
  const { currentlyOpenedEmail } = useContext(InboxHubContext)
  const {
    data: { labels: statusLabels } = { labels: [] },
    isFetching: isLabelsLoading,
    isError: isLabelsFetchingError
  } = useGetAllLabelsQuery()

  const containsText = (text, searchText) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1
  const displayedOptions = useMemo(
    () => statusLabels?.filter((option) => containsText(option.name, '')),
    [statusLabels?.labels]
  )

  const handleStatusChange = async (newLabel) => {
    try {
      if (currentLabel._id === newLabel._id) return
      const { message } = await updateLabel({
        campaignEmailId: currentlyOpenedEmail._id,
        labelId: newLabel._id
      }).unwrap()
      toast.success(message)
      refetchCampaignEmails()
      setCurrentLabel(newLabel)
    } catch (error) {
      toast.success('Label not updated, something went wrong')
    }
  }

  return (
    <NoDataAndWaitingWrapper
      data={statusLabels.length}
      isError={isLabelsFetchingError}
      errorText='Something went wrong while fetching labels'
      isLoading={isLabelsLoading}
      loadingText='Loading Labels'
      noDataText='No Labels'
    >
      <Select
        size='small'
        sx={{
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '18px',
          color: theme.palette.primary.primaryText,
          '& .MuiOutlinedInput-input': {
            py: 0.5
          }
        }}
        MenuProps={{
          autoFocus: false,
          PaperProps: {
            sx: {
              display: 'flex',
              flexDirection: 'column',
              height: '400px',
              overflowY: 'auto',

              '&::-webkit-scrollbar': {
                width: '14px'
              },

              '&::-webkit-scrollbar-track': {
                borderRadius: '60px'
              },

              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#E4E4E5',
                borderRadius: '10px',
                border: '4px solid rgba(0, 0, 0, 0)',
                backgroundClip: 'padding-box'
              },

              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#d5d5d5'
              }
            }
          }
        }}
        labelId='search-select-label'
        id='search-select'
        value={currentLabel?._id ?? ''}
        // onChange={canEdit && handleStatusChange}
        onChange={() => { }}
        // onClose={() => setSearchText('')}
        onClose={() => { }}
      >
        <ListSubheader sx={{ px: 0 }}>
          <TextField
            placeholder='Search by status'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <IconButton sx={{ width: 32, height: 32 }}>
                    <SBSearch color='rgba(40, 40, 123, 0.5)' />
                  </IconButton>
                </InputAdornment>
              )
            }}
            variant='outlined'
            sx={{
              width: '100%',
              height: 40,
              backgroundColor: 'white',
              '& div': { pl: 0.3 },
              '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
              '& div input': {
                py: 1.3,
                fontSize: '13px',
                fontWeight: 400,
                lineHeight: '16px',
                letterSpacing: '0em',
                '&::placeholder': {
                  color: 'rgba(40, 40, 123, 0.5)'
                }
              }
            }}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key !== 'Escape') {
                // Prevents autoselecting item while typing (default Select behaviour)
                e.stopPropagation()
              }
            }}
          />

          <Button
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              textAlign: 'left',
              px: 1.5,
              py: 1,
              mb: 1,
              // mx: 2,
              width: '100%',
              borderRadius: '8px',
              fontSize: '14px',
              fontWeight: 700,
              lineHeight: '18px',
              color: theme.palette.primary.primaryText,
              '&:hover': {
                backgroundColor: '#F2F4F6',
                color: '#3F4FF8'
              },
              border: '1px solid #fff'
            }}
            // onClick={handleClickOpenAddStatusDialog}
            onClick={() => {
              handleOpenPopup('createNewLabel')
            }}
          >
            <Typography
              sx={{
                width: 'calc(100% - 20px)',
                textAlign: 'left',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: theme.palette.primary.primaryText,
                fontSize: '14px',
                fontWeight: 700
              }}
            >
              Create New Label
            </Typography>
            <AddOutlined />
          </Button>
        </ListSubheader>
        {!isLabelsLoading &&
          displayedOptions.map((label, index) => {
            return (
              <MenuItem
                key={label._id}
                value={label._id}
                sx={{ mx: 1, borderRadius: '8px' }}
                onClick={() => {
                  handleStatusChange(label)
                }}
              >
                {renderLabelWithIcon(label)}
              </MenuItem>
            )
          })}
      </Select>
    </NoDataAndWaitingWrapper>
  )
}

const moreOptions = [
  {
    name: 'Add To Campaign',
    id: 'addtocampaign',
    icon: <IHCampaignsIcon />
  },
  {
    name: 'Mark As Unread',
    id: 'markasunread',
    icon: <MoreOptionsMarkUnread />
  },
  {
    name: 'Edit Lead',
    id: 'editlead',
    icon: <MoreOptionsEditLead />
  },
  {
    name: 'Remove Lead',
    id: 'removelead',
    icon: <MoreOptionsRemoveLead />
  },
  {
    name: 'Delete',
    id: 'delete',
    icon: <MoreOptionsDelete />
  }
]

const MoreOptionsMenu = ({ moreOptionsAnchorEl, moreOptionMenuOpen, id, handleClose }) => {
  const { handleOpenPopup } = usePopups()
  const { getCampaignEmailPayload } = useContext(EmailListingContext)
  const { currentlyOpenedEmail } = useContext(InboxHubContext)
  const { refetch: refetchCampaignEmails } = useFetchCampaignEmailsQuery(getCampaignEmailPayload)
  const [openEmail] = useOpenedEmailMutation()
  async function handleClickUnread (id) {
    await openEmail({ id: currentlyOpenedEmail._id, body: { value: false } })
    refetchCampaignEmails()
    handleClose()
  }
  return (
    <Menu
      id={id}
      anchorEl={moreOptionsAnchorEl}
      open={moreOptionMenuOpen}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <Stack>
        {moreOptions.map((option, i) => (
          <MenuItem
            key={i}
            sx={{ display: 'flex', gap: 1 }}
            onClick={() => {
              if (option.id !== 'markasunread') {
                handleOpenPopup(option.id)
              } else {
                handleClickUnread()
              }
            }}
          >
            {option.icon}
            <Typography>{option.name}</Typography>
          </MenuItem>
        ))}
      </Stack>
    </Menu>
  )
}
