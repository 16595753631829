import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  IconButton,
  Alert,
  Avatar,
  Checkbox,
  FormControlLabel,
  useTheme
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import AddIcon from '@mui/icons-material/Add'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Close } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { toast } from 'react-hot-toast'
import {
  useCreateListMutation,
  useGetListsQuery,
  useGetListLeadsQuery,
  useDeleteListMutation
} from '../services/lists-service'
import { useGetCampaignsQuery } from '../services/campaign-service'
import { useAddLeadsToCampaignMutation } from '../services/leads-service'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'

const LeadsListView = () => {
  const [selectedList, setSelectedList] = useState(null)
  const [newListDialogOpen, setNewListDialogOpen] = useState(false)
  const [newListName, setNewListName] = useState('')
  const [selectedRows, setSelectedRows] = useState([])
  const [addToCampaignDialogOpen, setAddToCampaignDialogOpen] = useState(false)
  const [selectedCampaign, setSelectedCampaign] = useState('')
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25
  })
  const [rowClickedId, setRowClickedId] = useState(null)
  const [isLeadDetailDrawerOpen, setIsLeadDetailDrawerOpen] = useState(false)
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false)
  const [verifyLeads, setVerifyLeads] = useState(false)
  const [leadDuplicate, setLeadDuplicate] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const storedWorkspace = JSON.parse(window.localStorage.getItem('workspace'))
  const [workspaceId, setWorkspaceId] = useState(storedWorkspace?._id || '')
  const theme = useTheme()

  // Get current workspace
  const currentWorkspace = useSelector((state) => state.workspace)
  useEffect(() => {
    if (currentWorkspace?._id) {
      setWorkspaceId(currentWorkspace._id)
    }
  }, [currentWorkspace])

  // Query parameters
  const buildQueryParams = (params) => {
    return Object.fromEntries(
      Object.entries(params).filter(
        ([_, value]) => value !== '' && value !== null && value !== undefined
      )
    )
  }

  const listsQueryParams = searchTerm
    ? buildQueryParams({
      search: searchTerm,
      offset: 0,
      limit: paginationModel.pageSize,
      workspaceId
    })
    : { workspaceId }

  const leadsQueryParams = selectedList
    ? {
        listId: selectedList,
        params: buildQueryParams({
          ...(searchTerm && { search: searchTerm }),
          offset: paginationModel.page * paginationModel.pageSize,
          limit: paginationModel.pageSize
        })
      }
    : null
  const {
    data: listsData,
    isLoading: listsLoading,
    refetch: refetchLists
  } = useGetListsQuery(listsQueryParams)

  const selectedListData = listsData?.docs?.find((list) => list._id === selectedList)
  const exactTotalCount = selectedListData?.leadCount || 0

  const {
    data: selectedListLeads = { docs: [] },
    isLoading: leadsLoading,
    refetch: refetchLeads
  } = useGetListLeadsQuery(leadsQueryParams, {
    skip: !selectedList
  })

  const { data: campaigns } = useGetCampaignsQuery({
    unibox: true,
    option: true,
    workspaceId
  })

  // Mutations
  const [createList, { isLoading: isCreating }] = useCreateListMutation()
  const [deleteList] = useDeleteListMutation()
  // const [addLeadsToList] = useAddLeadsToListMutation()
  const [addLeadsToCampaign] = useAddLeadsToCampaignMutation()

  const lists = listsData?.docs || []

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
      valueGetter: (params) => `${params.row.firstName || ''} ${params.row.lastName || ''}`
    },
    {
      field: 'company',
      headerName: 'Company',
      width: 200,
      valueGetter: (params) => params.row.organization?.name || 'N/A'
    },
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'title', headerName: 'Title', width: 200 },
    { field: 'country', headerName: 'Country', width: 150 },
    { field: 'state', headerName: 'State', width: 150 },
    { field: 'city', headerName: 'City', width: 150 }
  ]

  const handleSearch = (event) => {
    setSearchTerm(event.target.value)
    setPaginationModel({ ...paginationModel, page: 0 })
  }

  const handleNewList = async () => {
    try {
      const newListNameStr = String(newListName || '')
      if (newListNameStr.length > 45) {
        toast.error('List name cannot exceed 45 characters')
        return
      }
      await createList({
        name: newListName,
        workspaceId
      }).unwrap()
      toast.success('The new list has been created successfully.')
      setNewListDialogOpen(false)
      setNewListName('')
      refetchLists()
    } catch (error) {
      toast.error(error.data?.error?.message || 'Failed to create list')
    }
  }

  const handleDeleteConfirmation = () => {
    setDeleteConfirmationOpen(true)
  }

  const handleConfirmedDelete = async () => {
    try {
      await deleteList(selectedList).unwrap()
      toast.success('The list has been deleted successfully.')
      setSelectedList(null)
      setDeleteConfirmationOpen(false)
      refetchLists()
    } catch (error) {
      toast.error(error.data?.error?.message || 'Failed to delete list')
    }
  }

  const handleAddToCampaign = async () => {
    const toastId = toast.loading(`${selectedRows.length} Leads Adding to campaign`)

    try {
      const batchId = uuidv4()
      const result = await addLeadsToCampaign({
        campaignId: selectedCampaign,
        leads: selectedRows,
        checkDuplicates: leadDuplicate,
        useBounce: verifyLeads,
        workspaceId,
        batchId
      }).unwrap()

      toast.success(result.message, { id: toastId })
      setAddToCampaignDialogOpen(false)
      setSelectedCampaign('')
      refetchLeads()
      refetchLists()
    } catch (error) {
      toast.error(error.data?.error?.message || 'Failed to add leads to campaign', { id: toastId })
    }
  }

  const handleLeadDetailDrawerClose = () => {
    setIsLeadDetailDrawerOpen(false)
    setRowClickedId(null)
  }

  const LeadDetailDialog = ({ open, onClose, lead }) => (
    <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth>
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant='h6'>Lead Details</Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
          <Avatar src={lead?.photoUrl} sx={{ width: 60, height: 60, mr: 2 }}>
            {!lead?.photoUrl && `${lead?.firstName?.[0]}${lead?.lastName?.[0]}`}
          </Avatar>
          <Box>
            <Typography variant='h6'>{`${lead?.firstName} ${lead?.lastName}`}</Typography>
            <Typography variant='body2' color='textSecondary'>
              {lead?.title}
            </Typography>
          </Box>
        </Box>

        <Typography variant='subtitle1' sx={{ mb: 2 }}>
          Contact Information
        </Typography>
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2, mb: 3 }}>
          <Typography>
            <strong>Email:</strong> {lead?.email}
          </Typography>
          <Typography>
            <strong>Country:</strong> {lead?.country}
          </Typography>
          <Typography>
            <strong>Company:</strong> {lead?.organization?.name}
          </Typography>
          <Typography>
            <strong>Industry:</strong> {lead?.organization?.industry}
          </Typography>
        </Box>

        <Typography variant='subtitle1' sx={{ mb: 2 }}>
          Employment History
        </Typography>
        {lead?.employmentHistory?.map((history, index) => (
          <Box key={index} sx={{ mb: 2 }}>
            <Typography>
              <strong>{history.title}</strong> at {history.organizationName}
            </Typography>
            <Typography variant='body2' color='textSecondary'>
              {moment(history.startDate).format('MMM YYYY')} -
              {history.current ? 'Present' : moment(history.endDate).format('MMM YYYY')}
            </Typography>
          </Box>
        ))}
      </DialogContent>
    </Dialog>
  )

  const dataGridProps = {
    rows: selectedListLeads?.docs || [],
    columns,
    checkboxSelection: true,
    onRowSelectionModelChange: setSelectedRows,
    rowSelectionModel: selectedRows,
    loading: leadsLoading,
    pagination: true,
    paginationMode: 'server',
    pageSizeOptions: [15, 25, 50],
    paginationModel: {
      page: paginationModel.page,
      pageSize: paginationModel.pageSize
    },
    rowCount: exactTotalCount,
    onPaginationModelChange: (newModel) => {
      setPaginationModel(newModel)
    },
    getRowId: (row) => row._id,
    onRowClick: (params) => {
      setRowClickedId(params.row._id)
      setIsLeadDetailDrawerOpen(true)
    },
    disableRowSelectionOnClick: true,
    slots: {
      pagination: (props) => {
        const currentPage = paginationModel.page
        const pageSize = paginationModel.pageSize
        const from = exactTotalCount === 0 ? 0 : currentPage * pageSize + 1
        const to = Math.min((currentPage + 1) * pageSize, exactTotalCount)

        return (
          <Box
            sx={{
              p: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderTop: '1px solid #E4E4E5'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Typography variant='body2' color='text.secondary'>
                Rows per page:
              </Typography>
              <Select
                value={pageSize}
                onChange={(e) => {
                  setPaginationModel((prev) => ({
                    ...prev,
                    pageSize: Number(e.target.value),
                    page: 0
                  }))
                }}
                size='small'
                sx={{ minWidth: 50, mr: 1 }}
              >
                {[15, 25, 50].map((size) => (
                  <MenuItem key={size} value={size}>
                    {size}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant='body2' color='text.secondary'>
                {`${from}-${to} of ${exactTotalCount}`}
              </Typography>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <IconButton
                  onClick={() => setPaginationModel((prev) => ({ ...prev, page: prev.page - 1 }))}
                  disabled={currentPage === 0}
                >
                  <ChevronLeftIcon />
                </IconButton>
                <IconButton
                  onClick={() => setPaginationModel((prev) => ({ ...prev, page: prev.page + 1 }))}
                  disabled={to >= exactTotalCount}
                >
                  <ChevronRightIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
        )
      },
      noRowsOverlay: () => (
        <Box sx={{ p: 2, textAlign: 'center' }}>
          {leadsLoading
            ? (
              <CircularProgress size={24} />
              )
            : selectedList
              ? (
                  'No leads found'
                )
              : (
                  'Select a list to view leads'
                )}
        </Box>
      )
    }
    // hideFooterSelectedRowCount: true,
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        width: '100%',
        backgroundColor: '#F8FAFC'
      }}
    >
      {/* Left Sidebar */}
      <Box
        sx={{
          width: 280,
          bgcolor: 'white',
          borderRight: '1px solid #E4E4E5',
          p: 2,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 700,
              color: theme.palette.primary.primaryText
            }}
          >
            Lists
          </Typography>
          <IconButton
            onClick={() => setNewListDialogOpen(true)}
            size='small'
            sx={{ color: theme.palette.primary.main }}
          >
            <AddIcon />
          </IconButton>
        </Box>

        <TextField
          placeholder='Search lists...'
          value={searchTerm}
          onChange={handleSearch}
          variant='outlined'
          size='small'
          sx={{ mb: 2 }}
        />

        {listsLoading
          ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
              <CircularProgress size={24} />
            </Box>
            )
          : lists?.length === 0
            ? (
              <Alert severity='info' sx={{ mt: 2 }}>
                No lists found
              </Alert>
              )
            : (
              <List sx={{ pt: 0, flex: 1, overflowY: 'auto' }}>
                {lists?.map((list) => (
                  <ListItem
                    key={list._id}
                    onClick={() => setSelectedList(list._id)}
                    sx={{
                      borderRadius: 1,
                      mb: 1,
                      cursor: 'pointer',
                      bgcolor: selectedList === list._id ? '#F2F4F6' : 'transparent',
                      '&:hover': {
                        bgcolor: '#F2F4F6'
                      }
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography
                          sx={{
                            fontSize: '14px',
                            fontWeight: 500,
                            color: theme.palette.primary.primaryText
                          }}
                        >
                          {list.name}
                        </Typography>
                  }
                      secondary={
                        <Typography
                          sx={{
                            fontSize: '13px',
                            color: '#8181B0'
                          }}
                        >
                          {`${list.leadCount || 0} leads`}
                        </Typography>
                  }
                    />
                    <ChevronRightIcon sx={{ color: '#8181B0' }} />
                  </ListItem>
                ))}
              </List>
              )}
      </Box>

      {/* Main Content */}
      <Box sx={{ flexGrow: 1, p: 3, height: '100%', overflowY: 'auto' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 3
          }}
        >
          <Typography
            sx={{
              fontSize: '20px',
              fontWeight: 700,
              color: theme.palette.primary.primaryText
            }}
          >
            {selectedList ? lists?.find((l) => l._id === selectedList)?.name : 'Select a List'}
          </Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
            {selectedList && (
              <Button variant='outlined' color='error' onClick={handleDeleteConfirmation}>
                Delete List
              </Button>
            )}
            <Button
              variant='contained'
              onClick={() => setAddToCampaignDialogOpen(true)}
              disabled={selectedRows.length === 0}
              sx={{
                bgcolor: theme.palette.primary.main,
                '&:hover': {
                  bgcolor: '#1b5cc8'
                }
              }}
            >
              Add to Campaign
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            bgcolor: 'white',
            borderRadius: 2,
            boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
            height: 'calc(100% - 60px)'
          }}
        >
          <DataGrid
            {...dataGridProps}
            sx={{
              border: 'none',
              '& .MuiDataGrid-cell': {
                borderBottom: '1px solid #E4E4E5'
              },
              '& .MuiDataGrid-columnHeaders': {
                bgcolor: '#F2F4F6',
                borderBottom: '1px solid #E4E4E5'
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 700,
                color: theme.palette.primary.primaryText
              }
            }}
          />
        </Box>
      </Box>

      {/* New List Dialog */}
      <Dialog
        open={newListDialogOpen}
        onClose={() => setNewListDialogOpen(false)}
        PaperProps={{
          sx: {
            borderRadius: 2,
            width: '100%',
            maxWidth: 480
          }
        }}
      >
        <DialogTitle
          sx={{
            fontSize: '20px',
            fontWeight: 700,
            color: theme.palette.primary.primaryText
          }}
        >
          Create New List
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin='dense'
            label='List Name'
            fullWidth
            value={newListName}
            onChange={(e) => setNewListName(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#E4E4E5'
                },
                '&:hover fieldset': {
                  borderColor: theme.palette.primary.main
                }
              }
            }}
          />
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            onClick={() => setNewListDialogOpen(false)}
            variant='outlined'
            sx={{
              borderColor: '#E4E4E5',
              color: theme.palette.primary.primaryText,
              '&:hover': {
                borderColor: theme.palette.primary.main
              }
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleNewList}
            disabled={!newListName.trim() || isCreating}
            variant='contained'
            sx={{
              bgcolor: theme.palette.primary.main,
              '&:hover': {
                bgcolor: '#1b5cc8'
              }
            }}
          >
            {isCreating ? <CircularProgress size={24} /> : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteConfirmationOpen} onClose={() => setDeleteConfirmationOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this list?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmationOpen(false)}>Cancel</Button>
          <Button onClick={handleConfirmedDelete} color='error'>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add to Campaign Dialog */}
      <Dialog
        open={addToCampaignDialogOpen}
        onClose={() => setAddToCampaignDialogOpen(false)}
        PaperProps={{
          sx: {
            borderRadius: 2,
            width: '100%',
            maxWidth: 480
          }
        }}
      >
        <DialogTitle
          sx={{
            fontSize: '20px',
            fontWeight: 700,
            color: theme.palette.primary.primaryText
          }}
        >
          Add to Campaign
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Select Campaign</InputLabel>
            <Select
              value={selectedCampaign}
              onChange={(e) => setSelectedCampaign(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#E4E4E5'
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.primary.main
                }
              }}
            >
              {campaigns?.updatedEmail?.map((campaign) => (
                <MenuItem key={campaign._id} value={campaign._id}>
                  {campaign.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox checked={verifyLeads} onChange={(e) => setVerifyLeads(e.target.checked)} />
            }
            label='Validate Emails'
            sx={{ mt: 2, display: 'block' }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={leadDuplicate}
                onChange={(e) => setLeadDuplicate(e.target.checked)}
              />
            }
            label='Check Duplicates'
            sx={{ mt: 1, display: 'block' }}
          />
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            onClick={() => setAddToCampaignDialogOpen(false)}
            variant='outlined'
            sx={{
              borderColor: '#E4E4E5',
              color: theme.palette.primary.primaryText,
              '&:hover': {
                borderColor: theme.palette.primary.main
              }
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleAddToCampaign}
            disabled={!selectedCampaign}
            variant='contained'
            sx={{
              bgcolor: theme.palette.primary.main,
              '&:hover': {
                bgcolor: '#1b5cc8'
              }
            }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <LeadDetailDialog
        open={isLeadDetailDrawerOpen}
        onClose={handleLeadDetailDrawerClose}
        lead={selectedListLeads?.docs?.find((lead) => lead.id === rowClickedId)}
      />
    </Box>
  )
}

export default LeadsListView
