import React from 'react'
import { Box, Typography, Collapse } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

const CollapsibleSection = ({ title, icon, isOpen, onToggle, children }) => (
  <Box>
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      onClick={onToggle}
      sx={{
        cursor: 'pointer',
        background: '#F2F4F6',
        overflow: 'hidden',
        padding: '5px 20px'
      }}
    >
      <Box display='flex' alignItems='center' sx={{ gap: 2 }}>
        {icon}
        <Typography variant='subtitle1' fontWeight='bold'>
          {title}
        </Typography>
      </Box>
      {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
    </Box>
    <Collapse in={isOpen}>{children}</Collapse>
  </Box>
)

export default CollapsibleSection
