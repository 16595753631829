import React, { useState } from 'react'
import { Box, Button, Menu, TextField, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

const DateDropdown = ({ onFilter }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    onFilter({ selectedDates: { fromDate, toDate } })
    setAnchorEl(null)
  }

  const handleClearAll = () => {
    setFromDate(null)
    setToDate(null)
  }

  return (
    <Box position='relative'>
      {/* Date Button */}
      <Button
        onClick={handleOpen}
        endIcon={<ExpandMoreIcon />}
        sx={{
          textTransform: 'none',
          padding: '8px 16px',
          border: '1px solid #E5E7EB',
          borderRadius: '8px',
          color: '#111827',
          '&:hover': {
            borderColor: '#3F50F8',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)'
          }
        }}
      >
        Date
      </Button>

      {/* Dropdown Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: '350px',
            borderRadius: '8px',
            padding: '16px',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
          }
        }}
      >
        <Box>
          {/* Header with Title and Clear All */}
          <Box display='flex' justifyContent='space-between' alignItems='center' mb={2}>
            <Typography variant='subtitle1' fontWeight='bold'>
              Date
            </Typography>
            <Button
              onClick={handleClearAll}
              sx={{
                textTransform: 'none',
                color: '#3F50F8',
                fontWeight: 'bold',
                fontSize: '14px'
              }}
            >
              Clear all
            </Button>
          </Box>

          {/* From and To Date Fields */}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box display='flex' justifyContent='space-between' gap={2} mb={2}>
              <DatePicker
                label='From'
                value={fromDate}
                onChange={(newValue) => setFromDate(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant='outlined'
                    size='small'
                    InputProps={{
                      endAdornment: <CalendarMonthIcon color='action' />
                    }}
                    sx={{
                      borderRadius: '8px'
                    }}
                  />
                )}
              />
              <DatePicker
                label='To'
                value={toDate}
                onChange={(newValue) => setToDate(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant='outlined'
                    size='small'
                    InputProps={{
                      endAdornment: <CalendarMonthIcon color='action' />
                    }}
                    sx={{
                      borderRadius: '8px'
                    }}
                  />
                )}
              />
            </Box>
          </LocalizationProvider>

          {/* Apply Button */}
          <Button
            variant='contained'
            fullWidth
            onClick={handleClose}
            sx={{
              textTransform: 'none',
              backgroundColor: '#3F50F8',
              color: '#FFFFFF',
              fontWeight: '600',
              padding: '10px 0',
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: '#3F50F8'
              }
            }}
          >
            Apply
          </Button>
        </Box>
      </Menu>
    </Box>
  )
}

export default DateDropdown
