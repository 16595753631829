import React, { useEffect, useState, useRef } from 'react'
import { config } from 'src/config.js'
import { useDispatch, useSelector } from 'react-redux'
import {
  useGetAccountsMutation,
  accountsAdded,
  setAccounts
} from 'src/services/account-service.js'
import LinkedIn from '@mui/icons-material/LinkedIn'
// import MessageIcon from '@mui/icons-material/Message'
// import SupportRoundedIcon from '@mui/icons-material/SupportRounded'
import {
  useGetLeadsMutation,
  useUpdateTestAccountMutation,
  useCampaignLaunchMutation,
  useWriteEmailMutation,
  useOptimizeEmailMutation,
  useCreateSequenceMutation,
  useUpdateSequenceMutation,
  useDeleteSequenceMutation,
  useUpdateSequenceOrderMutation,
  useGetCampaignVariablesQuery,
  useGetEmailTemplatesQuery,
  useSpinTaxEmailMutation,
  useCreateVariantMutation,
  useUpdateVariantMutation,
  useDeleteVariantMutation,
  useCopyVariantMutation
} from 'src/services/campaign-service.js'

import {
  CircularProgress,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Step,
  TextField,
  Tooltip,
  Typography,
  Stack,
  Popover,
  useTheme,
  useMediaQuery,
  DialogActions,
  InputLabel,
  Autocomplete,
  Checkbox,
  Divider,
  MenuItem,
  Select,
  Switch
} from '@mui/material'
import * as Yup from 'yup'
// import styled from '@emotion/styled'
import { useFormik } from 'formik'
import { OffCheckboxCustomIcon } from 'src/assets/general/OffCheckboxCustomIcon'
import { OnCheckboxCustomIcon } from 'src/assets/general/OnCheckboxCustomIcon'
// import AddNewSequenceStepBlock from './addNewSequenceStep'
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic'
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe'
import { CloseOutlined, Send, Email } from '@mui/icons-material'
import { toast } from 'react-hot-toast'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { HeroIcon } from 'src/assets/campaignSequence/HeroIcon'
import { BoltIcon } from 'src/assets/general/BoltIcon'
import { DeleteIconBlack } from 'src/assets/general/DeleteIcon'
import { EditIcon } from 'src/assets/general/EditIcon'
import { CopyIcon } from 'src/assets/general/CopyIcon'
import { OpenAiIcon } from 'src/assets/general/OpenAiIcon'
import AddIcon from '@mui/icons-material/Add'
// import { Editor } from '@tinymce/tinymce-react'
import CustomCounterProgress from './emailChecker/CustomCounterProgress'
import checkSpamWords from './emailChecker/utils/checkSpamTree'
// import templateEmails, { newTemplates } from "../templateEmails";
// import { renderToString } from 'react-dom/server'

import PropTypes from 'prop-types'
import TemplateSection from './templateSection'
import ReactQuill from 'react-quill'
// import ReactQuill, { Quill } from 'react-quill';
import QuillToolbar, { modules, formats } from './QuillToolbar'
import 'react-quill/dist/quill.snow.css'
// import CodeIcon from '@mui/icons-material/Code';
// const icons = Quill.import('ui/icons');
// icons['AutoAwesomeMosaicIcon'] = ReactDOMServer.renderToString(<AutoAwesomeMosaicIcon  style={{ width: '18px', height: '18px' }} />);
import CloseIcon from '@mui/icons-material/Close'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import Slide from '@mui/material/Slide'
// import { getAuthEmail } from "src/services/auth-service";
import axios from 'axios'
// import LinkedIn from "@mui/icons-material/LinkedIn";
import { useGetMeQuery } from 'src/services/user-service'
import { useGetCustomAttributesQuery } from 'src/services/intercom-service'
import DeleteVariantDialog from './DeleteVariantDialog'
import DeleteStepDialog from './DeleteStepDialog'
import { Link } from 'react-router-dom'
import { AddStepDialog } from './addStepDialog'

// const linkedInUrlRegex = /^https:\/\/(www\.)?linkedin\.com\/in\/[a-zA-Z0-9-]+(\/)?(\?.*)?$/

// function extractLinkedInIdentifier(url) {
//   // Regular expression to match the identifier part of the LinkedIn URL
//   const regex = /^https:\/\/(www\.)?linkedin\.com\/in\/([a-zA-Z0-9-]+)(\/)?/;

//   // Use regex match to extract the identifier
//   const match = url.match(regex);

//   // If a match is found, return the identifier (second group in regex)
//   return match ? match[2] : null;
// }

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pl: { xs: 0, md: 3 } }}>
          <Typography sx={{ border: '1px solid #E4E4E5', borderRadius: '12px' }}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  )
}
const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

const [maxSubjectCount, maxWordCount, maxReadingTime, maxLinks, maxQuestions, maxSpams] = [
  15, 500, 210, 3, 4, 7
]

const DraggableSequence = ({
  sequence,
  index,
  showWaitDays,
  showDelete,
  onEditClick,
  campaignId,
  setEditingLinkedInBody,
  setEditingLinkedInMessage,
  canEdit,
  handleOpenSequenceStepModal,
  setSequenceId,
  updateVariant,
  selectedVariant,
  onVariantClick,
  campaignStatus,
  theme
}) => {
  const [waitDays, setWaitDays] = useState(sequence?.waitDays)
  const [error, setError] = useState(false)
  const [showSaveButton, setShowSaveButton] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isStepModalOpen, setIsStepModalOpen] = useState(false)
  const [variantToDelete, setVariantToDelete] = useState(null)

  const [deleteSequence, { isLoading: isDeleteSequenceLoading }] = useDeleteSequenceMutation()
  const [updateSequence, { isLoading: isUpdateSequenceLoading }] = useUpdateSequenceMutation()
  const [deleteVariant, { isLoading: isDeleteVariantLoading }] = useDeleteVariantMutation()
  const [copyVariant, { isLoading: isCopyVariantLoading }] = useCopyVariantMutation()
  const { variants } = sequence
  useEffect(() => {
    if (waitDays && parseInt(waitDays) !== sequence.waitDays) {
      setShowSaveButton(true)
    } else {
      setShowSaveButton(false)
    }
  }, [sequence.waitDays, waitDays])

  const handleDelete = async () => {
    const { message } = await deleteSequence(sequence._id).unwrap()
    toast.success(message)
  }

  const handleEdit = (id, body, subject) => {
    if (subject.includes('LINKEDIN')) {
      setEditingLinkedInBody(true)
    } else if (subject.includes('LINKEDIN MESSAGE')) {
      setEditingLinkedInMessage(true)
    }
    onEditClick(id, sequence?.Step, body, subject)
  }

  const handleSaveWaitDays = async () => {
    if (waitDays > 0) {
      const { message } = await updateSequence({ id: sequence?._id, data: { waitDays } }).unwrap()
      toast.success(message)
      setError(false)
    } else {
      setError(true)
    }
  }

  const handleAddVariant = async () => {
    if (variants?.length >= 26) {
      toast.error(
        "You've hit the limits of AZ testing because those are all the letters in the English alphabet. ¯\\_(ツ)_/¯"
      )
      return
    }
    setSequenceId(sequence?._id)
    handleOpenSequenceStepModal()
  }

  const handleCopyVariant = async (variantId) => {
    if (variants?.length >= 26) {
      toast.error(
        "You've hit the limits of AZ testing because those are all the letters in the English alphabet. ¯\\_(ツ)_/¯"
      )
      return
    }
    const { message, sequence } = await copyVariant(variantId).unwrap()
    if (sequence) {
      toast.success(message)
    } else {
      toast.error(message)
    }
  }

  const handleToggleVariant = async (variant) => {
    if (variant?.isActive) {
      const enabledVariants = variants.filter((v) => v?.isActive)
      if (enabledVariants.length <= 1) {
        toast.error('You must have at least one active variant in a step.')
        return
      }
    }
    const data = { isActive: !variant?.isActive }
    await updateVariant({ id: variant?._id, data }).unwrap()
    const message = data?.isActive
      ? 'Variant successfully enabled.'
      : 'Variant successfully disabled.'
    toast.success(message)
  }

  const handleDeleteVariant = async () => {
    const { message } = await deleteVariant(variantToDelete?._id).unwrap()
    toast.success(message)
    handleClose()
  }

  const handleOpen = (variant) => {
    const enabledVariants = variants.filter((v) => v?.isActive)
    if (enabledVariants.length <= 1 && variant?.isActive) {
      toast.error('You must have at least one active variant in a step.')
      return
    }
    setVariantToDelete(variant)
    setIsModalOpen(true)
  }

  const handleClose = () => {
    setVariantToDelete(null)
    setIsModalOpen(false)
  }

  const handleOpenStep = () => {
    setIsStepModalOpen(true)
  }

  const handleCloseStep = () => {
    setIsStepModalOpen(false)
  }

  return (
    <>
      <Draggable draggableId={sequence?._id} index={index}>
        {(provided) => (
          <Box
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            sx={{
              userSelect: 'none',
              margin: '0 0 8px 0',
              borderRadius: 4,
              mt: 1,
              ...provided.draggableProps.style
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>
                  Step {sequence?.step}
                </Typography>
                {!variants[0]?.subject.includes('LINKEDIN') && (
                  <Button
                    onClick={handleAddVariant}
                    size='medium'
                    sx={{
                      fontSize: '14px',
                      color: `${theme.palette.primary.primaryText}`,
                      padding: '6px 16px',
                      textTransform: 'none',
                      borderRadius: '4px',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px'
                    }}
                  >
                    <AddIcon sx={{ fontSize: 18 }} />
                    Add Variant
                  </Button>
                )}
              </Box>
              {canEdit && (
                <IconButton
                  onClick={handleOpenStep}
                  title='Delete Step'
                  disabled={isDeleteSequenceLoading}
                  sx={{
                    borderRadius: '4px',
                    width: '40px',
                    height: '40px',
                    '&:disabled': {
                      backgroundColor: '#BDBDBD',
                      color: '#757575'
                    }
                  }}
                >
                  <DeleteIconBlack />
                </IconButton>
              )}
            </Box>
            {showWaitDays && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mt: 1
                }}
              >
                <Typography
                  sx={{
                    fontSize: '13px',
                    fontWeight: 500,
                    lineHeight: '15px',
                    letterSpacing: '0px',
                    color: `${theme.palette.primary.primaryText}`,
                    ml: 2
                  }}
                >
                  After
                </Typography>
                <TextField
                  type='number'
                  inputProps={{ min: 0 }}
                  variant='outlined'
                  InputProps={{ inputProps: { min: 0 } }}
                  sx={{
                    width: 75,
                    '& div': { pl: 0.3 },
                    '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                    '& div input': {
                      backgroundColor: 'white',
                      fontSize: '13px',
                      fontWeight: 400,
                      lineHeight: '16px',
                      letterSpacing: '0em',
                      '&::placeholder': {
                        color: 'rgba(40, 40, 123, 0.5)'
                      }
                    },
                    ml: 1
                  }}
                  size='small'
                  value={waitDays}
                  onChange={(e) => setWaitDays(e.target.value)}
                  error={error}
                  helperText={error ? 'Invalid' : ''}
                />
                <Typography
                  sx={{
                    fontSize: '13px',
                    fontWeight: 500,
                    lineHeight: '15px',
                    letterSpacing: '0px',
                    color: `${theme.palette.primary.primaryText}`,
                    ml: 1
                  }}
                >
                  Days
                </Typography>
                {isUpdateSequenceLoading
                  ? (
                    <CircularProgress size={20} sx={{ ml: 2 }} />
                    )
                  : (
                    <Button
                      onClick={handleSaveWaitDays}
                      variant='outlined'
                      sx={{ ml: 2, fontSize: '12px', display: !showSaveButton && 'none' }}
                    >
                      Save
                    </Button>
                    )}
              </Box>
            )}
            <Box
              sx={{
                maxHeight: 400,
                overflowY: 'auto',
                border: '1px solid #E0E0E0',
                borderRadius: 4,
                p: 2,
                backgroundColor: '#F9F9F9',
                mt: 1
              }}
            >
              {variants?.slice(0, 26).map((variant) => (
                <Box
                  key={variant?._id}
                  onClick={() => onVariantClick(variant?._id)}
                  sx={(theme) => ({
                    display: 'flex',
                    flexWrap: 'wrap',
                    backgroundColor: theme.palette.primary.contrastText,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: 2,
                    p: 2,
                    borderRadius: 4,
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                    cursor: 'pointer',
                    transition: 'background-color 0.2s',
                    mt: 1,
                    gap: 2,
                    position: 'relative',
                    [theme.breakpoints.down('md')]: {
                      flexDirection: 'column',
                      alignItems: 'flex-start'
                    },
                    '&:hover .action-icons': {
                      opacity: 1,
                      [theme.breakpoints.down('md')]: {
                        opacity: 1
                      }
                    }
                  })}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      minWidth: 30,
                      height: 30,
                      borderRadius: '50%',
                      fontSize: '16px',
                      fontWeight: 700,
                      color: `${theme.palette.primary.primaryText}`,
                      mr: 2,
                      backgroundColor:
                        selectedVariant === variant?._id
                          ? `${theme.palette.info.light}`
                          : '#f9f9f9'
                    }}
                  >
                    {variant?.name}
                  </Box>

                  <Typography
                    sx={{
                      flex: 1,
                      fontSize: '14px',
                      fontWeight: 600,
                      color: '#333',
                      wordBreak: 'break-word'
                    }}
                  >
                    {variant?.subject
                      ? (
                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                          {variant?.subject.includes('LINKEDIN') && <LinkedIn />}
                          <span>{variant?.subject}</span>
                        </div>
                        )
                      : sequence?.step > 1
                        ? (
                            "<Previous email's subject>"
                          )
                        : (
                            '<Empty subject>'
                          )}
                  </Typography>
                  {canEdit && (
                    <Box
                      className='action-icons'
                      sx={(theme) => ({
                        display: 'flex',
                        gap: 1,
                        opacity: 0,
                        transition: 'opacity 0.2s',
                        position: 'absolute',
                        right: '10px',
                        [theme.breakpoints.down('md')]: {
                          opacity: 1,
                          position: 'relative',
                          right: '0'
                        }
                      })}
                    >
                      {variants?.length > 1 && (
                        <Switch
                          checked={variant?.isActive}
                          onChange={() => handleToggleVariant(variant)}
                        />
                      )}
                      <IconButton
                        title='Edit'
                        onClick={() => handleEdit(variant?._id, variant?.body, variant?.subject)}
                      >
                        <EditIcon />
                      </IconButton>
                      {!variant?.subject?.includes('LINKEDIN') &&
                        (isCopyVariantLoading
                          ? (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <CircularProgress size={20} sx={{ color: '#28287b' }} />
                            </Box>
                            )
                          : (
                            <IconButton title='Copy' onClick={() => handleCopyVariant(variant?._id)}>
                              <CopyIcon />
                            </IconButton>
                            ))}
                      {variants?.length > 1 && (
                        <IconButton
                          title='Delete'
                          disabled={isDeleteVariantLoading}
                          onClick={() => handleOpen(variant)}
                        >
                          <DeleteIconBlack />
                        </IconButton>
                      )}
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Draggable>
      <DeleteVariantDialog
        open={isModalOpen}
        onClose={handleClose}
        onConfirm={handleDeleteVariant}
        isLoading={isDeleteVariantLoading}
        status={campaignStatus}
      />
      <DeleteStepDialog
        open={isStepModalOpen}
        onClose={handleCloseStep}
        onConfirm={handleDelete}
        isLoading={isDeleteSequenceLoading}
      />
    </>
  )
}
// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//   '& .MuiDialogContent-root': {
//     padding: theme.spacing(2)
//   },
//   '& .MuiDialogActions-root': {
//     padding: theme.spacing(1)
//   }
// }))

const CampaignSequences = ({ campaign, canEdit }) => {
  const theme = useTheme()
  const { data: user } = useGetMeQuery()
  const [sequenceId, setSequenceId] = useState(null)
  const [variantId, setVariantId] = useState(null)
  const [isEditorDialogOpen, setIsEditorDialogOpen] = useState(false)
  const [isUnsubscribeOpen, setIsUnsubscribeOpen] = useState(false)
  const [unsubscribeText, setUnsubscribeText] = useState('Click here to unsubscribe')
  const [wordCount, setWordCount] = useState(0)
  const [subjectCount, setSubjectCount] = useState(0)
  const [spamCount, setSpamCount] = useState(0)
  const [questionCount, setQuestionCount] = useState(0)
  const [readingTime, setReadingTime] = useState(0)
  const [urlCount, setUrlCount] = useState(0)
  const [editingLinkedInBody, setEditingLinkedInBody] = useState(false)
  const [editingLinkedInMessage, setEditingLinkedInMessage] = useState(false)
  const [showWhatsAppAccountSelectModal, setShowWhatsAppAccountSelectModal] = useState(false)
  // const [isLinkedInConnecting, setIsLinkedInConnecting] = useState(false)
  const [editorSubject, setEditorSubject] = useState('')
  const [reactQuillPlaceholder, setReactQuillPlaceholder] = useState('')
  // const [openConnectLinkedIn, setOpenConnectLinkedIn] = React.useState(false);
  const [editorContent, setEditorContent] = useState('')
  const [, setContentLength] = useState(false)
  const [sendInviteLinkedInModal, setSendInviteLinkedInModal] = useState(false)
  const [sendMessageLinkedInModal, setSendMessageLinkedInModal] = useState(false)
  // const [linkedInUrl, setLinkedInUrl] = useState('')
  const [cursorLocation, setCursorLoaction] = useState(1)
  const [error, setError] = useState(false)
  // const [isLinkedInConnected, setIsLinkedInConnected] = useState(false)
  // const [linkedInEmail, setLinkedInEmail] = useState('')
  // const [linkedInPassword, setLinkedInPassword] = useState('')
  const [allLinkedInAccounts, setAllLinkedInAccounts] = useState([])
  const [allWhatsAppAccounts] = useState(null)
  // const editorRef = useRef(null);
  const [animationCount, setAnimationCount] = useState(0)
  const [startAnimation, setStartAnimation] = useState(false)
  const [borderColor, setBorderColor] = useState(theme.palette.primary.primaryText) // Initial border color
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const [writeEmail, { isLoading: isWriteEmailLoading }] = useWriteEmailMutation()
  const [spinTaxEmail, { isLoading: isSpinTaxEmailLoading }] = useSpinTaxEmailMutation()
  const [optimizeEmail, { isLoading: isOptimizeEmailLoading }] = useOptimizeEmailMutation()
  const [createSequence, { isLoading: isCreateSequenceLoading }] = useCreateSequenceMutation()
  const [updateSequence, { isLoading: isUpdateSequenceLoading }] = useUpdateSequenceMutation()
  const [createVariant, { isLoading: isCreateVariantLoading }] = useCreateVariantMutation()
  const [updateVariant, { isLoading: isUpdateVariantLoading }] = useUpdateVariantMutation()
  const [linkedInAccountId, setLinkedInAccountId] = useState(null)
  const [whatsAppAccountId, setWhatsAppAccountId] = useState(null)
  const [updateSequenceOrder] = useUpdateSequenceOrderMutation()
  const [sequenceStepCount, setSequenceStepCount] = useState(0)
  const [openModal, setOpenModal] = useState(false)
  const [tempOpenModal, setTempOpenModal] = useState(false)
  // const [selectedTemplate, setSelectedTemplate] = useState(1)
  // const [newTemplatess, setNewTemplatess] = useState([])
  // const defaultTemplate = newTemplatess[0]?.category[0]?.templates[0];
  const [selectedTemplateObject, setSelectedTemplateObject] = useState({})
  // const [istemplateOpen, setIsTemplateOpen] = useState(false)
  // const [currentInput, setCurrentInput] = useState('')
  const [aiButtonAnimation, setAiButtonAnimation] = useState(false)
  const quillEditorRef = useRef(null)
  const [openSequenceStepModal, setOpenSequenceStepModal] = React.useState(false)
  const [gettingAllLinkedInAccounts, setGettingAllLinkedInAccounts] = useState(false)
  const [selectedVariant, setSelectedVariant] = useState(null)
  const { refetch: getCustomAttributeRefetch } = useGetCustomAttributesQuery(undefined, {
    skip: user?.isWhiteLabelUser
  })

  const editingLinkedinSequence = editingLinkedInBody || editingLinkedInMessage

  const handleCloseSequenceEditor = () => {
    setIsEditorDialogOpen(false)
    setEditingLinkedInBody(false)
    setEditingLinkedInMessage(false)
    setReactQuillPlaceholder('')
    setSequenceId(null)
  }

  const handleOpenSequenceStepModal = () => {
    setOpenSequenceStepModal(true)
  }
  const handleCloseSequenceStepModal = () => {
    setOpenSequenceStepModal(false)
  }
  const FILE_SIZE_LIMIT = 4.5 * 1024 * 1024

  const handlePaste = (e) => {
    const clipboardData = e.clipboardData
    const items = clipboardData.items

    let imageFound = false

    for (let i = 0; i < items.length; i++) {
      const item = items[i]

      if (item.type.indexOf('image') !== -1) {
        const blob = item.getAsFile()
        imageFound = true

        if (blob.size > FILE_SIZE_LIMIT) {
          toast.error('Pasted image exceeds 4.5 MB. Please use a smaller image.')
          e.preventDefault()
          return
        }
        if (typeof window !== 'undefined' && typeof FileReader !== 'undefined') {
          const reader = new window.FileReader()
          reader.onload = (event) => {
            const range = quillEditorRef.current.getEditor().getSelection()
            quillEditorRef.current
              .getEditor()
              .insertEmbed(range.index, 'image', event.target.result)
          }
          reader.readAsDataURL(blob)
        }
        e.preventDefault()
      }
    }

    if (!imageFound) {
      const html = clipboardData.getData('text/html')
      const tempDiv = document.createElement('div')
      tempDiv.innerHTML = html

      const images = tempDiv.getElementsByTagName('img')
      for (const img of images) {
        const src = img.src
        if (src.startsWith('data:image/')) {
          const base64Data = src.split(',')[1]
          const byteLength =
            base64Data.length * (3 / 4) -
            (base64Data.endsWith('==') ? 2 : base64Data.endsWith('=') ? 1 : 0)

          if (byteLength > 4.5 * 1024 * 1024) {
            toast.error('Pasted image exceeds 4.5 MB. Please use a smaller image.')
            e.preventDefault()
            return
          }
        }
      }
    }
  }

  const getAllLinkedInAccounts = async () => {
    setGettingAllLinkedInAccounts(true)
    try {
      const response = await axios.post(`${config.API_BASE_URL}/multichannel/getLinkedAccounts`, {
        email: user.email,
        workspace_id: workspace?._id,
        filter: 'LINKEDIN'
      })
      console.log('response', response)
      if (response?.data?.success) {
        // console.log(`response.data.accounts`, response.data.accounts);
        setAllLinkedInAccounts(response.data.accounts)
        if (response.data.accounts.length > 0) {
          setLinkedInAccountId(response.data.accounts[0].account_id)
        }
      } else {
        toast.error(response?.data?.error || 'Something went wrong')
      }
    } catch (error) {
      console.log('error', error)
      toast.error('Something went wrong')
    } finally {
      setGettingAllLinkedInAccounts(false)
    }
  }
  // const getAllWhatsAppAccounts = async () => {
  //   try {
  //     const response = await axios.post(`${config.API_BASE_URL}/multichannel/getLinkedAccounts`, {
  //       email: user.email,
  //       filter: 'WHATSAPP'
  //     })
  //     console.log('response', response)
  //     if (response?.data?.success) {
  //       // console.log(`response.data.accounts`, response.data.accounts);
  //       setAllWhatsAppAccounts(response.data.accounts)
  //       if (response.data.accounts.length > 0) {
  //         setWhatsAppAccountId(response.data.accounts[0].account_id)
  //       }
  //     } else {
  //       toast.error(response?.data?.error || 'Something went wrong')
  //     }
  //   } catch (error) {
  //     console.log('error', error)
  //     toast.error('Something went wrong')
  //   }
  // }

  const handleDrop = (e) => {
    e.preventDefault()
    const items = e.dataTransfer.items

    for (let i = 0; i < items.length; i++) {
      const item = items[i]

      if (item.kind === 'file' && item.type.indexOf('image') !== -1) {
        const file = item.getAsFile()
        if (file && file.size > FILE_SIZE_LIMIT) {
          toast.error('Dropped image exceeds 4.5 MB. Please use a smaller image.')
          return
        }
        if (typeof window !== 'undefined' && typeof FileReader !== 'undefined') {
          const reader = new window.FileReader()
          reader.onload = (event) => {
            const range = quillEditorRef.current.getEditor().getSelection()
            quillEditorRef.current
              .getEditor()
              .insertEmbed(range.index, 'image', event.target.result)
          }
          reader.readAsDataURL(file)
        }
        return
      }
    }
  }

  // const handleSearchLinkedInUser = async () => {
  //   if (linkedInUrl === "") {
  //     toast.error("Please enter LinkedIn URL");
  //     return;
  //   }
  //   if (!linkedInUrlRegex.test(linkedInUrl)) {
  //     toast.error("Please enter a valid LinkedIn URL");
  //     return;
  //   }
  //   console.log('account id', linkedInAccountId);
  //   const linkedInIdentifier = extractLinkedInIdentifier(linkedInUrl);
  //   console.log(`linkedInIdentifier`, linkedInIdentifier);
  //   const options = {
  //     method: 'GET',
  //     headers: {
  //       accept: 'application/json',
  //       'X-API-KEY': config.UNIPILE_ACCESS_TOKEN
  //     }
  //   };
  //   setSearchinglinkedInUser(true);

  //   const response = await fetch(`${config.UNIPILE_DSN}/api/v1/users/${linkedInIdentifier}?account_id=${linkedInAccountId}`, options)
  //   const data = await response.json();
  //   console.log(`data`, data);
  //   if (data?.provider_id)
  //     setLinkedInSearchedUserData(data);
  //   else {
  //     toast.error(data.title)
  //     setLinkedInSearchedUserData(null);
  //   }
  //   setSearchinglinkedInUser(false);
  // }
  // const handleSendInvitationLinkedIn = async () => {
  //   const options = {
  //     method: 'POST',
  //     headers: {
  //       accept: 'application/json',
  //       'content-type': 'application/json',
  //       'X-API-KEY': config.UNIPILE_ACCESS_TOKEN
  //     },
  //     body: JSON.stringify({
  //       provider_id: linkedInSearchedUserData.provider_id,
  //       message: "Hi, I would like to connect with you on LinkedIn.",
  //       account_id: linkedInAccountId
  //     })
  //   };
  //   setSendingInvitationRequest(true);
  //   const response = await fetch(`${config.UNIPILE_DSN}/api/v1/users/invite`, options)
  //   const data = await response.json();
  //   console.log(`data`, data);
  //   if (data?.object === "UserInvitationSent")
  //     toast.success("Invitation sent successfully");
  //   else
  //     toast.error(data.title);
  //   setSendInviteLinkedInModal(false);
  //   setSendingInvitationRequest(false);
  // }
  // const userAlreadyExists = async (email) => {
  //   const options = {
  //     method: 'GET',
  //     headers: {
  //       accept: 'application/json',
  //       'X-API-KEY': config.UNIPILE_ACCESS_TOKEN
  //     }
  //   };

  //   const response = await fetch(`${config.UNIPILE_DSN}/api/v1/accounts`, options)
  //   const data = await response.json();
  //   const linkedInAccounts = data?.items?.filter(account => account.type === 'LINKEDIN');
  //   const allResponses = await Promise.all(linkedInAccounts.map(async account => {
  //     const response = await fetch(`${config.UNIPILE_DSN}/api/v1/users/me?account_id=${account.id}`, options);
  //     const data = await response.json();
  //     return { account_id: account.id, ...data };
  //   }))
  //   const foundUser = allResponses.find(user => user.email === email);
  //   return foundUser;
  // }

  // const handleConnectLinkedInAccount = async () => {
  //   setIsLinkedInConnecting(true);
  //   const linkedInEmailExists = await userAlreadyExists(linkedInEmail);
  //   if (linkedInEmailExists.account_id) {
  //     toast.success("LinkedIn account connected successfully");
  //     console.log(`linkedInEmailExists`, linkedInEmailExists);
  //     setLinkedInAccountId(linkedInEmailExists.account_id);
  //     setIsLinkedInConnecting(false);
  //     setOpenConnectLinkedIn(false);
  //     setIsLinkedInConnected(true);
  //     return;
  //   }
  //   const options = {
  //     method: 'POST',
  //     headers: {
  //       accept: 'application/json',
  //       'content-type': 'application/json',
  //       'X-API-KEY': config.UNIPILE_ACCESS_TOKEN
  //     },
  //     body: JSON.stringify({
  //       provider: 'LINKEDIN',
  //       username: linkedInEmail,
  //       password: linkedInPassword
  //     })
  //   };

  //   const response = await fetch(`${config.UNIPILE_DSN}/api/v1/accounts`, options)
  //   const data = await response.json();

  //   if (data.account_id && data.object !== "Checkpoint") {
  //     toast.success("LinkedIn account connected successfully");
  //     console.log(`data`, data);
  //     setIsLinkedInConnected(true);
  //     setLinkedInAccountId(data.account_id);
  //     setOpenConnectLinkedIn(false);
  //   } else {
  //     toast.error(data.title || "captcha not solved");
  //   }
  //   setIsLinkedInConnecting(false);
  // }

  useEffect(() => {
    const quill = quillEditorRef?.current?.getEditor()
    const editorContainer = quill?.root

    const handleDocumentDrop = (e) => {
      e.preventDefault()
      e.stopPropagation()
    }

    editorContainer?.addEventListener('paste', handlePaste)
    editorContainer?.addEventListener('drop', handleDrop)
    document.addEventListener('drop', handleDocumentDrop)
    document.addEventListener('dragover', (e) => e.preventDefault())

    return () => {
      editorContainer?.removeEventListener('paste', handlePaste)
      editorContainer?.removeEventListener('drop', handleDrop)
      document.removeEventListener('drop', handleDocumentDrop)
    }
  }, [handleDrop, handlePaste])

  const [showData] = useState(false)
  const { data: templates, isLoading, isError } = useGetEmailTemplatesQuery(campaign._id)
  useEffect(() => {
    if (!isLoading && !isError && templates) {
      const defaultTemplate = templates[0]?.category[0]?.templates[0]
      setSelectedTemplateObject(defaultTemplate)
    }
  }, [isLoading, isError, templates])

  const { test } = campaign
  const dispatch = useDispatch()

  const testEmailAccounts = []
  const accounts = useSelector((state) => state.accounts)
  accounts?.forEach((element) => {
    testEmailAccounts.push(element.email)
  })

  const handleFieldSelectionAndValidation = (newValue) => {
    const value = newValue?.length === 0 ? '' : newValue[newValue.length - 1]
    const foundObject = accounts?.find((obj) => obj.email === value)
    if (
      (value === '' && !foundObject?.accountError) ||
      (foundObject?.status === 'connected' && !foundObject?.accountError)
    ) {
      formik.setFieldValue('testEmailAccounts', newValue)
    } else {
      if (foundObject?.accountError) {
        toast.error('Resolve your email account error first')
      } else if (foundObject?.status === 'disconnected' || foundObject?.status === 'paused') {
        toast.error('Resume your email account first')
      } else {
        toast.error('Reconnect your email account first')
      }
    }
  }

  const [workspaceId, setWorkspaceId] = useState()
  const workspace = useSelector((state) => state.workspace)
  useEffect(() => {
    setWorkspaceId(workspace?._id)
  }, [workspace])

  const [getAccounts] = useGetAccountsMutation()

  const [isLoadingMoreAccounts, setIsLoadingMoreAccounts] = useState(false)
  const [search] = useState('')
  const [filter] = useState(null)
  const [total, setTotal] = useState(0)
  const [, setLaunch] = useState(false)
  const offset = accounts.length
  const limit = 15

  useEffect(() => {
    const timer = setTimeout(async () => {
      const { docs, total } = await getAccounts({
        search,
        filter: filter?.value,
        unibox: true,
        option: true,
        workspaceId
      }).unwrap()
      dispatch(setAccounts(docs))
      setTotal(total)
    }, 500)
    return () => clearTimeout(timer)
  }, [search, filter, limit, getAccounts, dispatch, workspaceId])

  useEffect(() => {
    const handler = async () => {
      if (isLoadingMoreAccounts) return
      const { scrollHeight, scrollTop, clientHeight } = document.documentElement

      if (scrollHeight - scrollTop === clientHeight && offset < total) {
        setIsLoadingMoreAccounts(true)
        const { docs, total } = await getAccounts({
          search,
          filter: filter?.value,
          offset,
          limit,
          workspaceId
        }).unwrap()
        dispatch(accountsAdded(docs))
        setTotal(total)
        setIsLoadingMoreAccounts(false)
      }
    }

    window.addEventListener('scroll', handler)
    return () => window.removeEventListener('scroll', handler)
  }, [
    isLoadingMoreAccounts,
    search,
    filter,
    total,
    offset,
    limit,
    getAccounts,
    dispatch,
    workspaceId
  ])

  const [updateTestAccount] = useUpdateTestAccountMutation()

  const formik = useFormik({
    initialValues: {
      testEmailAccounts: test?.testEmailAccounts
    },
    validationSchema: Yup.object({
      testEmailAccounts: Yup.array()
        .min(1, 'At least one email account is required')
        .of(Yup.string().required('Email account is required'))
    }),
    onSubmit: async (values) => {
      try {
        await updateTestAccount({
          campaignID: campaign._id,
          test: values
        }).unwrap()
      } catch (err) {
        toast.error(err.data.error.message)
      }
    }
  })

  const [campaignLaunch, { isLoading: isLaunchingCampaign }] = useCampaignLaunchMutation()

  const launchCampaign = async (formik, data = null) => {
    try {
      setLaunch(true)
      if (formik?.values?.testEmailAccounts?.length < 1) {
        toast.error('Please Add email account and save campaign first')
        return
      }
      await formik.handleSubmit()
      const { launch } = await campaignLaunch({
        id: campaign._id,
        params: { step: sequenceStepCount, reciepient: manualImportText, data }
      }).unwrap()
      if (launch?.includes('Email Sent')) {
        toast.success('Email Sent')
        setManualImportText('')
        setOpenModal(false)
      } else {
        toast.error(launch)
        setOpenModal(false)
      }
      setButtonDisabled(false)
    } catch (err) {
      setButtonDisabled(false)
      console.log('errr', err)
      toast.error(err.data.error.message)
    }
  }
  const [manualImportText, setManualImportText] = useState('')

  const { data: variables, refetch } = useGetCampaignVariablesQuery(campaign._id)
  const handleAddStepClick = () => {
    setSequenceStepCount(campaign?.sequences.length + 1)
    setVariantId(null)
    setEditorSubject('')
    setEditorContent('')
    setIsEditorDialogOpen(true)
    refetch()
    setSubjectCount(0)
    setWordCount(0)
    setReadingTime(0)
    setUrlCount(0)
    setQuestionCount(0)
    setSpamCount(0)
  }

  const handleEditClick = (id, step, body, subject) => {
    setSequenceStepCount(step)
    setVariantId(id)
    setEditorSubject(subject)
    setEditorContent(body)
    setIsEditorDialogOpen(true)
    setSubjectCount(0)
    setWordCount(0)
    setReadingTime(0)
    setUrlCount(0)
    setQuestionCount(0)
    setSpamCount(0)
  }

  const removeLeadingBrTags = (emailContent) => {
    return emailContent.replace(/^(<br\s*\/?>\s*)+/, '')
  }

  const getEmailBodyFromPrompt = async (
    prompt,
    defaultValue = " Use my variables defined inside 'variables' array after the 'message' instead of your variables, also use {{ }} instead of [] , do not use any variables from outside and only use 'senderVariables' for regards",
    senderVariables = "['senderName', 'signature']"
  ) => {
    try {
      if (prompt === '') {
        toast.error('Template body cannot be empty.1')
      } else {
        const myData = variables
        const dataList = myData.map((item) => item.value)
        const customVariables = dataList.join('\n')
        let body = await writeEmail({
          prompt:
            defaultValue +
            ' ' +
            'message: ' +
            prompt +
            'variables: [' +
            customVariables +
            ']' +
            senderVariables
        }).unwrap()
        const subjectPattern = /Subject:(.*?)<br>/
        const subjectMatch = body?.match(subjectPattern)
        const subject = subjectMatch ? subjectMatch[1].trim() : 'Subject not found'
        const dearPattern = /dear/i
        const dearMatch = body.match(dearPattern)
        const startIndex = dearMatch ? dearMatch.index : 0
        if (!dearMatch) {
          body = body.replace(/Subject:.*?<br>/i, '').replace(/Email:.*?<br>/i, '')
        }
        body = body.substring(startIndex)
        setEditorContent(removeLeadingBrTags(body))
        setEditorSubject(subject)
      }
    } catch (err) {
      toast.error(err.data.error.message)
    }
  }

  const handleFocus = () => {
    setError(false)
  }

  // const startAiButtonAnimation = () => {
  //   setAnimationCount(0);
  //   let interval = setInterval(() => {
  //     setAiButtonAnimation(!aiButtonAnimation);
  //     setAnimationCount(count => count + 1);
  //     if (animationCount === 6) {
  //       clearInterval(interval);
  //     }
  //   }, 1000);
  // }
  useEffect(() => {
    if (startAnimation && animationCount < 8) {
      const interval = setInterval(() => {
        setAiButtonAnimation((prev) => !prev)
        setBorderColor((prevColor) =>
          prevColor === `${theme.palette.primary.primaryText}`
            ? 'red'
            : `${theme.palette.primary.primaryText}`
        )
        setAnimationCount((prevCount) => prevCount + 1)
      }, 200) // Change color every second

      // Cleanup interval after 5 seconds (5 times)
      return () => clearInterval(interval)
    }
  }, [startAnimation, animationCount])

  // Reset animationCount when animation ends
  useEffect(() => {
    if (animationCount === 8) {
      setStartAnimation(false) // Stop animation after 5 cycles
      setAnimationCount(0) // Reset count for future animations
    }
  }, [animationCount])

  const handleSaveSequenceClickLinkedIn = async (type) => {
    if (type === 'invite') setSendInviteLinkedInModal(false)
    else setSendMessageLinkedInModal(false)
    const tempContent = document.createElement('div')
    tempContent.innerHTML = editorContent

    const yellowSpans = tempContent.querySelectorAll("span[style*='background-color: yellow']")

    yellowSpans.forEach((span) => {
      const parent = span.parentNode
      while (span.firstChild) {
        parent.insertBefore(span.firstChild, span)
      }
      parent.removeChild(span)
    })

    tempContent.innerHTML = tempContent.innerHTML.replace(/<([^>]+)>\s*<\/\1>/g, '')
    const cleanedContent = tempContent.innerHTML
    const data = {
      subject: type === 'invite' ? 'LINKEDIN INVITE' : 'LINKEDIN MESSAGE',
      body: cleanedContent || 'Hey there! I would like to connect with you on LinkedIn.'
    }
    try {
      if (sequenceId) {
        const { message } = await updateSequence({ id: sequenceId, data }).unwrap()
        toast.success(message)
        setSequenceId(null)
        if (!user?.isWhiteLabelUser) getCustomAttributeRefetch()
      } else {
        const { message } = await createSequence({ id: campaign._id, data }).unwrap()
        window.Intercom('trackEvent', 'Campaign step added')
        toast.success(message)
        setSequenceId(null)
        if (!user?.isWhiteLabelUser) getCustomAttributeRefetch()
      }
    } catch (error) {
      toast.error(error?.data?.error?.message)
    } finally {
      setLinkedInAccountId(null)
      setAllLinkedInAccounts([])
      setSendInviteLinkedInModal(false)
      setSendMessageLinkedInModal(false)
      setSequenceId(null)
    }
  }

  const handleSaveSequenceClick = async (formik, Test) => {
    if (editorContent === '') {
      toast.error('Please enter body to continue')
      setButtonDisabled(false)
      return
    }

    const tempContent = document.createElement('div')
    tempContent.innerHTML = editorContent
    /**
     * Remove spam-specific highlights but retain Quill editor's styles and attributes.
     * @param {Node} node - The current DOM node to process.
     */
    const cleanNode = (node) => {
      // eslint-disable-next-line
      if (typeof window !== "undefined" && node.nodeType === window.Node?.ELEMENT_NODE) {
        // Remove spam-specific classes
        const spamClasses = ['highlight', 'yellow-bg', 'sw']
        spamClasses.forEach((cls) => node.classList.remove(cls))

        // Preserve Quill-specific classes
        const quillClasses = [
          'ql-size',
          'ql-size-large',
          'ql-size-small',
          'ql-font',
          'ql-align',
          'ql-color',
          'ql-background',
          'ql-bold',
          'ql-italic',
          'ql-underline'
        ]

        const hasQuillClass = quillClasses.some((cls) => node.classList.contains(cls))

        if (node.style.backgroundColor === 'yellow') {
          node.style.backgroundColor = '' // Remove spam highlights
        }

        // Remove spans only if they do not have Quill classes or inline styles
        if (node.tagName.toLowerCase() === 'span' && !hasQuillClass && node.style.length === 0) {
          const textContent = node.textContent
          node.parentNode.replaceChild(document.createTextNode(textContent), node)
        } else {
          // Recursively clean child nodes
          Array.from(node.childNodes).forEach(cleanNode)
        }
      }
    }

    // Clean the content
    cleanNode(tempContent)

    tempContent.innerHTML = tempContent.innerHTML.replace(/<([^>]+)>\s*<\/\1>/g, '')
    const cleanedContent = tempContent.innerHTML
    if (Test) {
      setButtonDisabled(true)
      const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/

      if (manualImportText.trim() === '') {
        setError(true)
        setButtonDisabled(false)
        return
      }

      if (
        formik?.values?.testEmailAccounts === undefined ||
        !formik?.values?.testEmailAccounts?.length
      ) {
        toast.error('Select at least one account from dropdown')
        setButtonDisabled(false)
        return
      }

      if (!emailRegex.test(manualImportText)) {
        toast.error('Please enter a valid email address')
        setButtonDisabled(false)
        return
      }
    }
    const emailContentUpdated = processQuillContent(updatedContent)
    const emailContentCleaned = processQuillContent(cleanedContent)
    const data = { subject: editorSubject, body: emailContentCleaned }
    const testData = { subject: updatedEditorSubject, body: emailContentUpdated }
    try {
      if (variantId) {
        const { message } = await updateVariant({ id: variantId, data }).unwrap()
        toast.success(message)
      } else {
        if (sequenceId) {
          const { message } = await createVariant({ id: sequenceId, data }).unwrap()
          window.Intercom('trackEvent', 'Campaign variant added')
          toast.success(message)
        } else {
          const { message } = await createSequence({ id: campaign._id, data }).unwrap()
          window.Intercom('trackEvent', 'Campaign step added')
          toast.success(message)
        }
        handleCloseSequenceStepModal()
      }
      if (Test) {
        await launchCampaign(formik, testData)
        setOpenHtml(false)
        setIsEditorDialogOpen(false)
      }
    } catch (error) {
      setButtonDisabled(false)
      toast.error(error?.data?.error?.message)
    } finally {
      if (!Test) {
        setIsEditorDialogOpen(false)
      }
    }
  }

  const processQuillContent = (html) => {
    const parser = new window.DOMParser()
    const doc = parser.parseFromString(html, 'text/html')

    doc.querySelectorAll('img').forEach((img) => {
      const naturalWidth = img.width
      if (naturalWidth > 400) {
        img.style.width = '400px'
      }

      if (!naturalWidth) {
        img.style.width = '300px'
      }

      img.style.height = 'auto'
    })

    return doc.body.innerHTML
  }

  function hasMoreThanFiveWords (str) {
    // const specialCharsRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/g
    const specialCharsRegex = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/g
    str = str.replace(specialCharsRegex, '').replace('  ', ' ')
    const words = str.split(/\s+/) // Split the string by whitespace characters
    if (words.length >= 5) setContentLength(true) // Check if the number of words is greater than 5
    else setContentLength(false)
  }

  const handleOptimizeClick = async () => {
    if (!editorContent) return toast.error('Template body cannot be empty. 2')
    try {
      const optimized = await optimizeEmail({
        email: editorContent + 'Do not use [] use {{ }}'
      }).unwrap()
      setEditorContent(removeLeadingBrTags(optimized))
      // update count
      // const subjectCountBar = editorSubject;
      // const wordCountBar = optimized;
      // setSubjectCount(subjectCountBar.split(/\s+/).filter(Boolean).length);
      // setWordCount(wordCountBar.split(/\s+/).filter(Boolean).length);
      // setReadingTime(() => handleReadingTime(wordCountBar));
      // setUrlCount(() => handleUrlCount(wordCountBar));
      // setQuestionCount(() => handleQuestions(wordCountBar));
      // setSpamCount(() => handleSpamCount(subjectCountBar, wordCountBar));
    } catch (err) {
      toast.error(err.data.error.message)
    }
  }

  const handleDragEnd = async ({ source, destination }) => {
    try {
      const fromStep = source.index + 1
      const toStep = destination.index + 1

      if (fromStep === toStep) return

      await updateSequenceOrder({ id: campaign._id, data: { fromStep, toStep } }).unwrap()
    } catch (err) {
      toast.error(err.data.error.message)
    }
  }

  function handleReadingTime (paragraph, wordsPerMinute = 200) {
    const wordsArray = paragraph?.trim()?.split(/\s+/)
    const totalWords = wordsArray?.length
    const readingTimeMinutes = totalWords / wordsPerMinute
    const readingTime = Math.ceil(readingTimeMinutes * 60)
    return readingTime
  }

  function handleQuestions (paragraph) {
    const questionMarks = paragraph?.match(/\?+/g)
    return questionMarks ? questionMarks?.length : 0
  }

  // function highlightSpam(spamArray) {
  //   console.log('spamArray',spamArray);

  //   const iframe = document.getElementsByClassName("tox-edit-area__iframe")[0];
  //   console.log('iframe',iframe);

  //   var box = iframe.contentWindow.document.getElementById("tinymce");
  //   console.log('box',box);
  //   let text = box.innerHTML;
  //   text = text.replace(
  //     /(<span class="spam-word" style="border-bottom:3px solid red;">|<\/span>)/gim,
  //     ""
  //   );

  //   let newText = text;
  //   for (let i = 0; i < spamArray.length; i++) {
  //     const regex = new RegExp(`\\b${spamArray[i]}\\b`, "gi");

  //     newText = newText.replace(
  //       regex,
  //       '<span class="spam-word" style="border-bottom:3px solid red;">$&</span>'
  //     );
  //   }

  //   box.innerHTML = newText;
  //   return;
  // }

  function highlightSpam (spamArray) {
    // Get the Quill editor content
    const editor = document.querySelector('.ql-editor')
    if (!editor) {
      return
    }
    let html = editor.innerHTML
    // Remove previous highlights
    html = html.replace(/<span class="sw" style="background-color: yellow;">(.*?)<\/span>/gi, '$1')

    // Function to escape special characters for regex
    const escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
    }
    // Highlight new spam words
    spamArray.forEach((word) => {
      const regex = new RegExp(`\\b${escapeRegExp(word)}\\b`, 'gi')
      html = html.replace(regex, '<span class="sw" style="background-color: yellow;">$&</span>')
    })

    // Set modified content back to the editor
    editor.innerHTML = html
  }

  function handleSpamCount (subject, paragraph) {
    const stringToCheck = paragraph + ' ' + subject
    const spamObj = checkSpamWords(stringToCheck)
    highlightSpam(spamObj.spam)
    return spamObj.count
  }

  // function handleUrlCount(paragraph) {
  //   let urlCount = 0
  //   const urlRegex =
  //     /^(?:(?:(?:https?|ftp):)?\/\/)?(?:\S+(?::\S*)?@)?(?:([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}|(?:\d{1,3}\.){3}\d{1,3})(?::\d{2,5})?(?:\/[^\s]*)?$/
  //   const wordsArray = paragraph?.trim()?.split(/\s+/)
  //   wordsArray?.forEach((ele) => {
  //     if (urlRegex.test(ele)) urlCount += 1
  //   })
  //   return urlCount
  // }

  // const handleCountUrlLength = (content) => {
  //   const parser = new DOMParser();
  //   const doc = parser.parseFromString(content, "text/html");
  //   const links = doc.querySelectorAll("a");
  //   const totalLinksCount = links.length;
  //   return totalLinksCount;
  // };

  const handleCountUrlLength = (content) => {
    const linkMatches = content?.match(/(?:https?:\/\/|www\.)\S+|<a\s+.*?<\/a>/gi)
    const totalLinksCount = linkMatches ? linkMatches.length : 0
    return totalLinksCount
  }

  function handleSubmit (event) {
    event.preventDefault()
    const subjectCountBar = editorSubject
    const wordCountBar = editorContent
    setSubjectCount(subjectCountBar.split(/\s+/).filter(Boolean).length)
    setWordCount(wordCountBar.split(/\s+/).filter(Boolean).length)
    setReadingTime(() => handleReadingTime(wordCountBar))
    // setUrlCount(() => handleUrlCount(wordCountBar));
    setQuestionCount(() => handleQuestions(wordCountBar))
    setSpamCount(() => handleSpamCount(wordCountBar))
    const urlCountResult = handleCountUrlLength(editorContent)
    setUrlCount(urlCountResult)
  }

  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleEditorClick = () => {
    setCursorLoaction(1)
  }

  const handleSubjectClick = () => {
    setCursorLoaction(0)
  }

  const insertContent = (value) => {
    if (cursorLocation === 0) {
      setEditorSubject(editorSubject + value)
    } else {
      setEditorContent(editorContent + value)
      // if (editorRef.current) {
      // setEditorSubject(editorContent + value);
      //   const editor = editorRef.current;
      //   editor.insertContent(value);
      // }
    }
  }

  const insertUnsubscribeLink = () => {
    const currentState = editorContent
    const unsubscribeLink = `<a href='UNSUBSCRIBE'>${unsubscribeText}</a>`
    const newContent = currentState + unsubscribeLink
    setEditorContent(newContent)
    // setEditorContent(`<a href='UNSUBSCRIBE'>${unsubscribeText}</a>`)
    // if (editorRef.current) {
    //   const editor = editorRef.current;
    //   editor.insertContent(`<a href='UNSUBSCRIBE'>${unsubscribeText}</a>`);
    //   setUnsubscribeText("Click here to unsubscribe");
    // }
    setIsUnsubscribeOpen(false)
  }
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const handleCloseModal = () => {
    setOpenModal(false)
    setError(false)
    setManualImportText('')
  }

  // const style = {
  //   position: 'absolute',
  //   top: '50%',
  //   left: '50%',
  //   transform: 'translate(-50%, -50%)',
  //   width: 450,
  //   bgcolor: 'background.paper',
  //   borderRadius: '5px',
  //   boxShadow: 24,
  //   p: 4
  // }

  const [leadsData, setLeadsData] = useState('')
  const [lessThanArray, setLessThanArray] = useState([])
  const [getLeads] = useGetLeadsMutation()
  const campaignSequences = async () => {
    try {
      const response = await getLeads({
        id: campaign._id
      })
      setLeadsData(response.data)
    } catch (error) {
      console.error('Error fetching leads:', error)
    }
  }
  useEffect(() => {
    campaignSequences()
  }, [])

  useEffect(() => {
    if (leadsData) {
      const results = leadsData?.docs?.map((document) => document.sequence_step)
      const allValuesAreSame = results?.every((value) => value === results[0])
      const commonValue = allValuesAreSame ? results[0] : null
      const newArray = []

      for (let i = 0; i < commonValue; i++) {
        newArray.push(i)
      }

      setLessThanArray(newArray)
    }
  }, [leadsData])

  // const [value, setValue] = useState(0)

  // const handleChange = (event, newValue) => {
  //   setValue(newValue)
  // }

  // const handleKeyDown = (e) => {
  //   if (e.key === 'Enter') {
  //     const enteredValue = currentInput.trim()
  //     const isValueValid = testEmailAccounts.includes(enteredValue)
  //     if (!isValueValid) {
  //       e.preventDefault()
  //       toast.error('Please select a valid account from the dropdown')
  //     } else {
  //       const isValueValid = testEmailAccounts.includes(enteredValue)
  //       if (isValueValid) {
  //         formik.setFieldValue('testEmailAccounts', [
  //           ...formik.values.testEmailAccounts,
  //           enteredValue
  //         ])
  //         setCurrentInput('')
  //       }
  //     }
  //   }
  // }

  const currentUrl = window.location.href
  // const result = currentUrl.match(/^https:\/\/[^\/]+/)
  const result = currentUrl.match(/^https:\/\/[^/]+/)
  const extractedBaseUrl = result ? result[0] : null

  const handleCustomIconClick = () => {
    setTempOpenModal(true)
  }

  const handleUnsubscribeClick = () => {
    setIsUnsubscribeOpen(true)
  }

  const containerStyle = {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '36vh',
    padding: '1px',
    boxSizing: 'border-box'
  }

  const [openHtml, setOpenHtml] = useState(false)

  const handleClickOpenHtml = () => {
    setOpenHtml(true)
  }

  const handleCloseHtml = () => {
    setOpenHtml(false)
  }

  const handleConvertSpinTax = async (prompt, promptSubject) => {
    try {
      if (prompt === '') {
        toast.error('Template body cannot be empty.')
      } else {
        let body = await spinTaxEmail({
          prompt: 'Subject: ' + promptSubject + prompt
        }).unwrap()
        const subjectPattern = /Subject:(.*?)<br>/
        const subjectMatch = body?.match(subjectPattern)
        let subject = subjectMatch
          ? subjectMatch[1]
            .replace(/<\/?[^>]+(>|$)/g, '')
            .replace(/&nbsp;/g, ' ')
            .replace(/\*\*/g, '')
            .trim()
          : 'Subject not found'
        const dearPattern = /dear/i
        const dearMatch = body.match(dearPattern)
        const startIndex = dearMatch ? dearMatch.index : 0
        if (!dearMatch) {
          body = body.replace(/Subject:.*?<br>/i, '').replace(/Email:.*?<br>/i, '')
        }
        body = body.substring(startIndex)
        if (!body.trim().startsWith('<br>{{spin|')) {
          body = `{{spin|${body}`
        }
        setEditorContent(removeLeadingBrTags(body))

        if (!subject.startsWith('{{spin') && subject !== 'Subject not found') {
          subject = '{{spin|Subject:' + subject
        }
        setEditorSubject(subject)
      }
    } catch (err) {
      const errorMessage =
        err?.error?.message || err?.data?.error?.message || 'An unknown error occurred'
      toast.error(errorMessage)
    }
  }

  const quillRef = useRef(null)

  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor()
      editor.clipboard.dangerouslyPasteHTML(editorContent)
      editor.disable() // Disable editor for direct HTML editing
    }
  }, [editorContent])

  const firstName = leadsData?.docs?.[0]?.firstName
  const lastName = leadsData?.docs?.[0]?.lastName
  const title = leadsData?.docs?.[0]?.title
  const email = leadsData?.docs?.[0]?.email
  const website = leadsData?.docs?.[0]?.website
  const companyName = leadsData?.docs?.[0]?.companyName
  const phone = leadsData?.docs?.[0]?.phone
  const location = leadsData?.docs?.[0]?.location
  const firstAccount = accounts[0]
  const first = firstAccount?.name?.first
  const last = firstAccount?.name?.last
  const signature = firstAccount?.signature
  const customVariables = leadsData?.docs?.[0]?.variables || []
  const variableMap = customVariables?.reduce((acc, variable) => {
    acc[variable?.variableTitle] = variable?.variableValue
    return acc
  }, {})

  const replacePTags = (html) => {
    return html
  }

  function cleanCustomVariables (content, variableMap) {
    const customVariableRegex = /{{(<.*?>)?(.*?)}}/g

    return content.replace(customVariableRegex, (match, p1, p2) => {
      const cleanedVariableName = p2.replace(/<.*?>/g, '')
      return variableMap[cleanedVariableName] || match
    })
  }

  const convertSpintaxToPreview = (content) => {
    // Function to randomly select one option from spintax variations
    const resolveSpintax = (text) => {
      return text?.replace(/{{spin\|(.*?)}}/g, (match, group) => {
        const options = group.split('|')
        const randomIndex = Math.floor(Math.random() * options.length)
        return options[randomIndex]
      })
    }

    // Remove highlight spans that were used to show spintax in the editor
    const removeHighlights = (text) => {
      return text.replace(
        /<span class="sw" style="background-color:\s*yellow;">(.*?)<\/span>/g,
        '$1'
      )
    }

    // First resolve all spintax variations
    const resolvedContent = resolveSpintax(content)
    // Then remove any highlight spans
    const cleanContent = removeHighlights(resolvedContent)

    return cleanContent
  }

  let updatedContent = cleanCustomVariables(editorContent, {
    firstName: firstName || 'David',
    senderName: first + ' ' + last || 'Noah',
    lastName: lastName || 'Loo',
    title: title || 'Owner',
    companyName: companyName || 'Successai',
    website: website || 'www.Success.ai',
    email: email || 'test@rmail.com',
    signature: signature || '',
    location: location || 'USA',
    phone: phone || '*******99',
    ...variableMap
  })

  updatedContent = replacePTags(updatedContent)

  const updatedEditorSubject = cleanCustomVariables(editorSubject, {
    firstName: firstName || 'David',
    senderName: first + ' ' + last || 'Noah',
    lastName: lastName || 'Loo',
    title: title || 'Owner',
    companyName: companyName || 'Successai',
    website: website || 'www.Success.ai',
    email: email || 'test@rmail.com',
    signature: signature || '',
    location: location || 'USA',
    phone: phone || '*******99',
    ...variableMap
  })

  const handleVariantClick = (variantId) => {
    setSelectedVariant(variantId)
  }

  return (
    <>
      <Box
        sx={{
          display: campaign?.sequences?.length ? 'flex' : 'none',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          flexDirection: 'column'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 700,
              lineHeight: '20px',
              letterSpacing: '0px',
              color: `${theme.palette.primary.primaryText}`
            }}
          >
            Total Steps in Sequence: {campaign?.sequences?.length}
          </Typography>
          {canEdit && (
            <Button
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark,
                  boxShadow: 10
                },
                textAlign: 'left',
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '18px',
                letterSpacing: '0em',
                color: 'white',
                backgroundColor: `${theme.palette.primary.main}`,
                borderRadius: '8px',
                px: 1.5,
                py: 1.5
              }}
              variant='outlined'
              size='large'
              // onClick={handleAddStepClick}
              onClick={() => {
                handleOpenSequenceStepModal()
                setSequenceId(null)
              }}
            >
              Add Step
            </Button>
          )}
        </Box>
        <Box sx={{ width: '100%', mt: 2 }}>
          <DragDropContext onDragEnd={canEdit ? handleDragEnd : ''}>
            <Droppable droppableId='droppable' direction='vertical'>
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <Box
                    sx={{
                      maxHeight: '82vh',
                      overflowY: 'auto',
                      backgroundColor: theme.palette.primary.contrastText,
                      boxShadow: 1,
                      padding: 2
                    }}
                  >
                    {campaign?.sequences?.map((sequence, index) => (
                      <Step
                        key={sequence._id}
                        completed={lessThanArray.includes(index)}
                        expanded
                        sx={{ '& div': { mb: 0 } }}
                      >
                        <DraggableSequence
                          sequence={sequence}
                          index={index}
                          showWaitDays={index !== 0}
                          showDelete={campaign?.sequences.length > 0}
                          onEditClick={handleEditClick}
                          campaignId={campaign?._id}
                          setEditingLinkedInBody={setEditingLinkedInBody}
                          setEditingLinkedInMessage={setEditingLinkedInMessage}
                          canEdit={canEdit}
                          handleOpenSequenceStepModal={handleOpenSequenceStepModal}
                          setSequenceId={setSequenceId}
                          updateVariant={updateVariant}
                          selectedVariant={selectedVariant}
                          onVariantClick={handleVariantClick}
                          campaignStatus={campaign?.status}
                          theme={theme}
                        />
                      </Step>
                    ))}
                  </Box>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
      </Box>
      {!campaign?.sequences?.length && (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              flexDirection: 'column',
              backgroundColor: 'white',
              height: 'calc(100vh - 220px)',
              boxShadow: '0px 12px 15px 0px #4B71970D',
              borderRadius: '12px',
              px: { xs: 2, sm: 0 }
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <HeroIcon />
            </Box>

            <Typography
              sx={{
                fontSize: '20px',
                fontWeight: 700,
                lineHeight: '25px',
                letterSpacing: '0em',
                color: `${theme.palette.primary.primaryText}`,
                mt: 3,
                textAlign: 'center'
              }}
            >
              Start by creating a new sequence here
            </Typography>
            <Typography
              sx={{
                fontSize: '13px',
                fontWeight: 400,
                lineHeight: '16px',
                letterSpacing: '0em',
                color: '#8181B0',
                mt: 2,
                textAlign: 'center'
              }}
            >
              Try to keep yours emails short, sweet and personal
            </Typography>
            {canEdit && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  mt: 4
                }}
              >
                <Button
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '&:hover': {
                      backgroundColor: theme.palette.primary.dark,
                      boxShadow: 10
                    },
                    textAlign: 'left',
                    fontSize: '14px',
                    fontWeight: 700,
                    lineHeight: '18px',
                    letterSpacing: '0em',
                    color: 'white',
                    backgroundColor: `${theme.palette.primary.main}`,
                    borderRadius: '8px',
                    px: 1.5,
                    py: 1.5
                  }}
                  variant='outlined'
                  size='large'
                  // onClick={handleAddStepClick}
                  onClick={() => {
                    handleOpenSequenceStepModal()
                    setSequenceId(null)
                  }}
                >
                  <Box
                    sx={{ mr: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  />
                  Add step
                </Button>
              </Box>
            )}
          </Box>
        </>
      )}
      <AddStepDialog
        open={openSequenceStepModal}
        onClose={handleCloseSequenceStepModal}
        handleAddStepClick={handleAddStepClick}
        handleCustomIconClick={handleCustomIconClick}
        setReactQuillPlaceholder={setReactQuillPlaceholder}
        setStartAnimation={setStartAnimation}
        setSendInviteLinkedInModal={setSendInviteLinkedInModal}
        setSendMessageLinkedInModal={setSendMessageLinkedInModal}
        getAllLinkedInAccounts={getAllLinkedInAccounts}
        sequenceId={sequenceId}
      />
      {/* <Dialog
        open={openConnectLinkedIn}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenConnectLinkedIn(false)}
        aria-describedby="alert-dialog-slide-description"
        sx={{ zIndex: '10' }}
      >
        <DialogTitle>{"Connect Your LinkedIn Account"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Connect with your linkedin account to send messages and connection requests
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="email"
            name="email"
            label="LinkedIn Email Address"
            type="email"
            fullWidth
            variant="standard"
            onChange={(e) => setLinkedInEmail(e.target.value)}
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="password"
            name="password"
            label="LinkedIn Password"
            type="password"
            fullWidth
            variant="standard"
            onChange={(e) => setLinkedInPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConnectLinkedIn(false)}>Cancel</Button>
          {isLinkedInConnecting ? <CircularProgress /> : <Button onClick={() => handleConnectLinkedInAccount()}>Connect</Button>}
        </DialogActions>
      </Dialog> */}
      <Dialog
        fullWidth
        open={sendInviteLinkedInModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setSendInviteLinkedInModal(false)}
        aria-describedby='alert-dialog-slide-description'
        maxWidth='xs'
      >
        <IconButton
          aria-label='close'
          onClick={() => {
            setSendInviteLinkedInModal(false)
            setAllLinkedInAccounts([])
          }}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500]
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ padding: 3 }}>
          {/* {linkedInAccountId && allLinkedInAccounts.length > 0 && (
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              fullWidth
              value={linkedInAccountId}
              label='Age'
              onChange={(e) => setLinkedInAccountId(e.target.value)}
            >
              {allLinkedInAccounts?.map((account) => (
                <MenuItem
                  value={account.account_id}
                  key={account.account_id}
                >{`${account.account_obj.email}(${account.account_id})`}
                </MenuItem>
              ))}
            </Select>
          )} */}
          {allLinkedInAccounts?.length > 0 &&
            (
              <>
                This action will create a sequence in your campaign which will send <strong>LinkedIn Connection Request</strong> to the leads in this Campaign. Click continue to proceed.
              </>
            )}
          {!gettingAllLinkedInAccounts && allLinkedInAccounts?.length === 0 && (
            <Typography>
              No LinkedIn accounts found. Please connect a LinkedIn account to continue.
              <br />
              <br />
              Click <Link to='/accounts/connect'>here</Link> to connect a LinkedIn account
            </Typography>
          )}
          {gettingAllLinkedInAccounts && <CircularProgress />}
        </DialogContent>
        {linkedInAccountId && (
          <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button variant='outlined' onClick={() => handleSaveSequenceClickLinkedIn('invite')}>
              Continue
            </Button>
            {/* {linkedInSearchedUserData && (sendingInvitationRequest ? <CircularProgress /> : <Button onClick={handleSendInvitationLinkedIn}>Send Invitation</Button>)} */}
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        fullWidth
        open={sendMessageLinkedInModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setSendMessageLinkedInModal(false)}
        aria-describedby='alert-dialog-slide-description'
        maxWidth='xs'
      >
        <IconButton
          aria-label='close'
          onClick={() => setSendMessageLinkedInModal(false)}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500]
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ padding: 3 }}>
          {allLinkedInAccounts?.length > 0 &&
            (
              <>
                This action will create a sequence in your campaign which will send <strong>LinkedIn Message</strong> to the leads in this Campaign. Click continue to proceed.
              </>
            )}
          {!gettingAllLinkedInAccounts && allLinkedInAccounts.length === 0 && (
            <Typography>
              No LinkedIn accounts found. Please connect a LinkedIn account to continue.
              <br />
              <br />
              Click <Link to='/accounts/connect'>here</Link> to connect a LinkedIn account
            </Typography>
          )}
          {gettingAllLinkedInAccounts && <CircularProgress />}
        </DialogContent>
        {linkedInAccountId && (
          <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button variant='outlined' onClick={() => handleSaveSequenceClickLinkedIn('message')}>
              Continue
            </Button>
            {/* {linkedInSearchedUserData && (sendingInvitationRequest ? <CircularProgress /> : <Button onClick={handleSendInvitationLinkedIn}>Send Invitation</Button>)} */}
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        fullWidth
        open={showWhatsAppAccountSelectModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setShowWhatsAppAccountSelectModal(false)}
        aria-describedby='alert-dialog-slide-description'
        maxWidth='xs'
      >
        <DialogTitle>Select Account</DialogTitle>
        <IconButton
          aria-label='close'
          onClick={() => setShowWhatsAppAccountSelectModal(false)}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500]
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {(whatsAppAccountId && allWhatsAppAccounts && (
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              fullWidth
              value={whatsAppAccountId}
              label='Age'
              onChange={(e) => setWhatsAppAccountId(e.target.value)}
            >
              {allWhatsAppAccounts?.map((account, index) => (
                <MenuItem value={account.account_id} key={`${account.account_id}-${index}`}>
                  {account?.account_obj?.name}
                </MenuItem>
              ))}
            </Select>
          )) || <CircularProgress />}
        </DialogContent>
        {whatsAppAccountId && (
          <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button variant='outlined' onClick={() => handleSaveSequenceClickLinkedIn()}>
              Continue
            </Button>
            {/* {linkedInSearchedUserData && (sendingInvitationRequest ? <CircularProgress /> : <Button onClick={handleSendInvitationLinkedIn}>Send Invitation</Button>)} */}
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        open={isEditorDialogOpen || editingLinkedinSequence}
        onClose={handleCloseSequenceEditor}
        fullWidth
        maxWidth='md'
        sx={{ backgroundColor: 'rgba(4, 4, 30, 0.5)' }}
        disableEnforceFocus
        fullScreen={isMobile}
      >
        <DialogTitle
          sx={{
            textAlign: 'center',
            fontSize: '20px',
            fontWeight: 700,
            lineHeight: '25px',
            letterSpacing: '0em',
            color: `${theme.palette.primary.primaryText}`,
            position: 'relative'
          }}
        >
          <Typography sx={{ fontSize: '20px', fontWeight: '700' }}>
            {editingLinkedInBody
              ? 'Send Linkedin Invitation Request'
              : editingLinkedInMessage
                ? 'Send Linkedin Message'
                : 'Send automatic email'}
          </Typography>
          <IconButton
            sx={{ position: 'absolute', right: 0, top: 0 }}
            onClick={handleCloseSequenceEditor}
          >
            <CloseOutlined />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: '100%',
              borderRadius: 2,
              border: '1px solid rgba(0,0,0,0.1)',
              p: 2,
              pb: 0,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <form onSubmit={handleSubmit} style={{ width: '100%' }} variant='primary'>
              <Box
                sx={{
                  width: '100%',

                  justifyContent: 'center',
                  alignContent: 'center',
                  display: 'flex'
                }}
              >
                <Grid
                  container
                  // spacing={3}
                  maxWidth='md'
                  sx={{ position: 'relative', ml: 0, mt: 0 }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                      px: 2,
                      py: 1,
                      backgroundColor: theme.palette.grey[100],
                      // borderRadius: "16px",
                      borderTopLeftRadius: '16px',
                      borderTopRightRadius: '16px'
                    }}
                  >
                    <Grid item xs={12} md={8} sx={{ borderRadius: '10px' }}>
                      {!editingLinkedinSequence && (
                        <Stack spacing={2}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              width: '100%'
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: '13px',
                                fontWeight: 700,
                                lineHeight: '16px',
                                color: `${theme.palette.primary.primaryText}`,
                                mr: 2
                              }}
                            >
                              Subject:
                            </Typography>
                            <TextField
                              fullWidth
                              variant='outlined'
                              sx={{
                                '& div input': {
                                  border: 'none',
                                  fontWeight: 600
                                },
                                '& div fieldset': {
                                  border: 'none'
                                }
                              }}
                              placeholder={
                                sequenceStepCount > 1
                                  ? "Leave empty to use previous step's subject"
                                  : 'Your subject'
                              }
                              name='subject'
                              value={editorSubject}
                              onChange={(e) => setEditorSubject(e.target.value)}
                              onClick={handleSubjectClick}
                            />
                          </Box>
                        </Stack>
                      )}
                      {/* <Hidden mdUp>
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={showParams ? "long-menu" : undefined}
                          aria-expanded={showParams ? "true" : undefined}
                          aria-haspopup="true"
                          sx={{ border: 2, position: "absolute", top: 10, right: 10 }}
                          onClick={() => {
                            setShowParams((prev) => !prev);
                          }}
                        >
                          <MoreVertOutlined />
                        </IconButton>
                      </Hidden> */}
                    </Grid>
                    <Grid item xs={4} sx={{ display: { xs: 'none', md: 'block' } }}>
                      <Box
                        sx={{
                          // borderLeft: "1px solid rgba(0,0,0,0.1)",
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          pl: 1,
                          width: '100%',
                          height: '100%'
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <Button
                            variant='contained'
                            sx={{
                              cursor: 'pointer'
                            }}
                            onClick={() => {
                              handleConvertSpinTax(editorContent, editorSubject)
                            }}
                            disabled={!canEdit && isSpinTaxEmailLoading}
                          >
                            {isSpinTaxEmailLoading
                              ? (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    py: 0.55
                                  }}
                                >
                                  {' '}
                                  <CircularProgress size={16} thickness={5} sx={{ color: 'white' }} />
                                </Box>
                                )
                              : (
                                  'SPINTAX'
                                )}
                          </Button>
                        </Box>

                        <Tooltip title='' arrow placement='top'>
                          <Button
                            onClick={() => {
                              getEmailBodyFromPrompt(editorContent)
                            }}
                            variant='outlined'
                            sx={{
                              ml: 1,
                              mr: 1,
                              borderColor,
                              border: aiButtonAnimation
                                ? '2px solid red'
                                : `1px solid ${theme.palette.primary.primaryText}`
                            }}
                            disabled={!canEdit}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: 24,
                                height: 24
                              }}
                            >
                              {isWriteEmailLoading
                                ? (
                                  <CircularProgress size={16} thickness={5} />
                                  )
                                : (
                                  <OpenAiIcon />
                                  )}
                            </Box>
                          </Button>
                        </Tooltip>

                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <Button
                            variant='contained'
                            sx={{
                              cursor: 'pointer'
                            }}
                            onClick={() => {
                              handleSaveSequenceClick()
                              setEditingLinkedInBody(false)
                              setEditingLinkedInMessage(false)
                            }}
                            disabled={!canEdit}
                          >
                            {isCreateSequenceLoading ||
                              isUpdateSequenceLoading ||
                              isCreateVariantLoading ||
                              isUpdateVariantLoading
                              ? (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    py: 0.55
                                  }}
                                >
                                  {' '}
                                  <CircularProgress size={16} thickness={5} sx={{ color: 'white' }} />
                                </Box>
                                )
                              : (
                                  'Save'
                                )}
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>

                  {!editingLinkedinSequence && (
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        px: 2,
                        py: 1,
                        backgroundColor: theme.palette.grey[100],
                        borderBottomLeftRadius: '16px',
                        borderBottomRightRadius: '16px'
                      }}
                    >
                      <Grid item xs={12} md={8} sx={{ borderRadius: '10px' }} />
                      <Grid item xs={4} sx={{ display: { xs: 'none', md: 'block' } }}>
                        <Box
                          sx={{
                            // borderLeft: "1px solid rgba(0,0,0,0.1)",
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            pl: 1,
                            width: '100%',
                            height: '100%'
                          }}
                        >
                          <Tooltip title='Preview Email' arrow placement='top'>
                            <Button
                              onClick={handleClickOpenHtml}
                              variant='outlined'
                              sx={{
                                mr: 1
                              }}
                              disabled={!canEdit}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  width: 24,
                                  height: 24
                                }}
                              >
                                <RemoveRedEyeIcon />
                              </Box>
                            </Button>
                          </Tooltip>
                          <Tooltip title='Templates Email' arrow placement='top'>
                            <Button
                              onClick={handleCustomIconClick}
                              variant='outlined'
                              sx={{
                                mr: 1
                              }}
                              disabled={!canEdit}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  width: 24,
                                  height: 24
                                }}
                              >
                                <AutoAwesomeMosaicIcon />
                              </Box>
                            </Button>
                          </Tooltip>
                          <Tooltip title='Insert Unsubscribe Link' arrow placement='top'>
                            <Button
                              onClick={handleUnsubscribeClick}
                              variant='outlined'
                              sx={{
                                mr: 1
                              }}
                              disabled={!canEdit}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  width: 24,
                                  height: 24
                                }}
                              >
                                <UnsubscribeIcon />
                              </Box>
                            </Button>
                          </Tooltip>
                          <Tooltip
                            title={
                              cursorLocation === 1
                                ? 'Insert variables in body'
                                : 'Insert variables in subject'
                            }
                            arrow
                            placement='top'
                          >
                            <Button
                              variant='contained'
                              disabled={(variables && variables.length === 0) || !canEdit}
                              sx={{
                                backgroundColor: theme.palette.primary.light,
                                '&:hover': {
                                  backgroundColor: theme.palette.primary.light
                                },
                                mr: 1
                              }}
                              onClick={handleClick}
                            >
                              <BoltIcon
                                color={
                                  variables && variables.length === 0
                                    ? theme.palette.primary.contrastText
                                    : theme.palette.primary.main
                                }
                              />
                            </Button>
                          </Tooltip>
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                  {isMobile && (
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: { xs: 'wrap', sm: 'nowrap' },
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: 'auto%',
                          height: '100%',
                          my: 0.5
                        }}
                      >
                        <Tooltip title='Preview Email' arrow placement='top'>
                          <Button
                            onClick={handleClickOpenHtml}
                            variant='outlined'
                            sx={{
                              mr: 1
                            }}
                            disabled={!canEdit}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: 24,
                                height: 24
                              }}
                            >
                              <RemoveRedEyeIcon />
                            </Box>
                          </Button>
                        </Tooltip>
                        <Tooltip
                          title={
                            cursorLocation === 1
                              ? 'Insert variables in body'
                              : 'Insert variables in subject'
                          }
                          arrow
                          placement='top'
                        >
                          <Button
                            variant='contained'
                            disabled={(variables && variables.length === 0) || !canEdit}
                            sx={{
                              backgroundColor: theme.palette.primary.light,
                              '&:hover': {
                                backgroundColor: theme.palette.primary.light
                              },
                              mr: { xs: 0.5, sm: 1 },
                              mb: { xs: 0.5, sm: 0 }
                            }}
                            onClick={handleClick}
                          >
                            <BoltIcon
                              color={
                                variables && variables.length === 0
                                  ? theme.palette.primary.contrastText
                                  : theme.palette.primary.main
                              }
                            />
                          </Button>
                        </Tooltip>
                        <Tooltip title='' arrow placement='top'>
                          <Button
                            onClick={() => {
                              getEmailBodyFromPrompt(editorContent)
                            }}
                            variant='outlined'
                            sx={{
                              mr: { xs: 0.5, sm: 1 },
                              mb: { xs: 0.5, sm: 0 },
                              borderColor: `${theme.palette.primary.primaryText}`
                            }}
                            disabled={!canEdit}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: 24,
                                height: 24
                              }}
                            >
                              {isWriteEmailLoading
                                ? (
                                  <CircularProgress size={16} thickness={5} />
                                  )
                                : (
                                  <OpenAiIcon />
                                  )}
                            </Box>
                          </Button>
                        </Tooltip>
                        <Button
                          onClick={handleCustomIconClick}
                          variant='outlined'
                          sx={{
                            mr: { xs: 0.5, sm: 1 },
                            mb: { xs: 0.5, sm: 0 }
                          }}
                          disabled={!canEdit}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              width: 24,
                              height: 24
                            }}
                          >
                            <AutoAwesomeMosaicIcon />
                          </Box>
                        </Button>
                        <Button
                          onClick={handleUnsubscribeClick}
                          variant='outlined'
                          sx={{
                            mr: { xs: 0.5, sm: 1 },
                            mb: { xs: 0.5, sm: 0 }
                          }}
                          disabled={!canEdit}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              width: 24,
                              height: 24
                            }}
                          >
                            <UnsubscribeIcon />
                          </Box>
                        </Button>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flex: 1,
                            minWidth: { xs: '100%', sm: 'auto' },
                            mb: { xs: 0.5, sm: 0 }
                          }}
                        >
                          <Button
                            variant='contained'
                            sx={{
                              cursor: 'pointer',
                              flex: { xs: 1, sm: 'auto' }
                            }}
                            onClick={() => {
                              handleSaveSequenceClick()
                            }}
                            disabled={!canEdit}
                          >
                            {isCreateSequenceLoading ||
                              isUpdateSequenceLoading ||
                              isCreateVariantLoading ||
                              isUpdateVariantLoading
                              ? (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    py: 0.55
                                  }}
                                >
                                  {' '}
                                  <CircularProgress size={16} thickness={5} sx={{ color: 'white' }} />
                                </Box>
                                )
                              : (
                                  'Save'
                                )}
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  )}

                  <Grid
                    item
                    xs={12}
                    sm={editingLinkedinSequence ? 0 : 8}
                    sx={{
                      py: 1,
                      minHeight: { xs: '500px', sm: 'fit-content' }
                    }}
                  >
                    {/* <Editor
                      apiKey={config.TINYMCE_EDITOR_API}
                      onEditorChange={(value) => {
                        const modifiedHtml = value.replace(
                          /href="\.\.\//,
                          `href="${extractedBaseUrl}/`
                        );
                        setEditorContent(modifiedHtml);
                        hasMoreThanFiveWords(modifiedHtml);
                      }}
                      onClick={handleEditorClick}
                      value={editorContent.replace(/<p>Subject:[^<]*<br><br>/, "")}
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      init={{
                        height: "100%",
                        selector: "textarea",
                        init_instance_callback: function (editor) {
                          const freeTiny = document.querySelector(".tox .tox-notification--in");
                          if (freeTiny) {
                            freeTiny.style.display = "none";
                          }

                          const statusBarTextContainer = document.querySelector(
                            ".tox .tox-statusbar__text-container"
                          );
                          statusBarTextContainer.style.display = "none";
                          const statusBar = document.querySelector(".tox .tox-statusbar");
                          statusBar.style.border = "none";
                        },
                        menubar: false,
                        plugins: [
                          "mentions advlist autolink lists link image charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media paste code help wordcount",
                          "autolink",
                          "link","emoticons"
                        ],
                        toolbar:
                          "undo redo | formatselect | " +
                          "bold italic backcolor | link insertUnsubscribeLink insertTemplate | alignleft aligncenter " +
                          "alignright alignjustify | bullist numlist outdent indent | " +
                          " removeformat | emoticons",
                        setup: function (editor) {
                          editor.ui.registry.addIcon(
                            "unsubscribeIcon",
                            '<svg height="24" width="24"><path d="M14 8c0-2.21-1.79-4-4-4S6 5.79 6 8s1.79 4 4 4 4-1.79 4-4zm3 2v2h6v-2h-6zM2 18v2h16v-2c0-2.66-5.33-4-8-4s-8 1.34-8 4z"></path></svg>'
                          );

                          editor.ui.registry.addIcon(
                            "templateIcon",
                            '<svg height="24" width="24"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-14h2v6h-2zm0 8h2v2h-2z"></path></svg>'
                          );

                          editor.ui.registry.addButton("insertUnsubscribeLink", {
                            icon: "unsubscribeIcon",
                            tooltip: "Insert unsubscribe link",
                            onAction: () => setIsUnsubscribeOpen(true),
                          });
                          const iconString = renderToString(<AutoAwesomeMosaicIcon />);
                          editor.ui.registry.addIcon("autoAwesomeMosaicIcon", iconString);
                          editor.ui.registry.addButton("insertTemplate", {
                            icon: "autoAwesomeMosaicIcon",
                            tooltip: "Insert template",
                            onAction: () => {
                              setTempOpenModal(true);
                            },
                          });
                        },
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px, }",
                        emoticons_append: {
                          custom_mind_explode: {
                            keywords: ["brain", "mind", "explode", "blown"],
                            char: "🤯",
                          },
                        },
                      }}
                    /> */}
                    <Box style={containerStyle}>
                      <QuillToolbar />
                      <Box sx={{ height: '90%' }}>
                        <ReactQuill
                          ref={quillEditorRef}
                          modules={modules}
                          placeholder={reactQuillPlaceholder}
                          formats={formats}
                          theme='snow'
                          onChange={(value) => {
                            const modifiedHtml = value.replace(
                              /href="\.\.\//,
                              `href="${extractedBaseUrl}/`
                            )
                            setEditorContent(modifiedHtml)
                            hasMoreThanFiveWords(modifiedHtml)
                          }}
                          value={editorContent.replace(/<p>Subject:[^<]*<br><br>/, '')}
                          onFocus={handleEditorClick}
                          style={{ height: '100%' }}
                        />
                      </Box>
                      <Box style={{ marginTop: 50 }}>
                        <textarea
                          className='ql-html-editor'
                          style={{
                            zIndex: +1,
                            marginTop: !isMobile ? '25%' : '60%',
                            height: '100%',
                            width: '100%',
                            // margin: '0px',
                            background: 'rgb(29, 29, 29)',
                            boxSizing: 'border-box',
                            color: 'rgb(204, 204, 204)',
                            fontSize: '15px',
                            outline: 'none',
                            padding: '20px',
                            lineHeight: '24px',
                            fontFamily: 'Consolas, Menlo, Monaco, "Courier New", monospace',
                            position: 'absolute',
                            top: '0',
                            border: 'none',
                            display: 'none'
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  {!editingLinkedinSequence && (
                    <Grid item xs={12} sm={4}>
                      <Stack spacing={3} sx={{ p: 2 }}>
                        <Typography
                          sx={{
                            fontSize: '16px',
                            fontWeight: 700,
                            lineHeight: '20px',
                            color: `${theme.palette.primary.primaryText}`
                          }}
                        >
                          Email template insights
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            flexDirection: 'column'
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              width: '100%'
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: '13px',
                                fontWeight: 700,
                                lineHeight: '16px',
                                color: `${theme.palette.primary.primaryText}`
                              }}
                            >
                              Subject Count
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: '13px',
                                fontWeight: 700,
                                lineHeight: '16px',
                                color: '#8181B0'
                              }}
                            >
                              {subjectCount}
                            </Typography>
                          </Box>

                          <CustomCounterProgress
                            countOf={subjectCount}
                            maxCountOf={maxSubjectCount}
                            minRange={3}
                            maxRange={5}
                            barColor='#00bcd4'
                          />
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            flexDirection: 'column'
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              width: '100%'
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: '13px',
                                fontWeight: 700,
                                lineHeight: '16px',
                                color: `${theme.palette.primary.primaryText}`
                              }}
                            >
                              Word Count
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: '13px',
                                fontWeight: 700,
                                lineHeight: '16px',
                                color: '#8181B0'
                              }}
                            >
                              {wordCount}
                            </Typography>
                          </Box>

                          <CustomCounterProgress
                            countOf={wordCount}
                            maxCountOf={maxWordCount}
                            minRange={16}
                            maxRange={150}
                            barColor='#ffc400'
                          />
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            flexDirection: 'column'
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              width: '100%'
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: '13px',
                                fontWeight: 700,
                                lineHeight: '16px',
                                color: `${theme.palette.primary.primaryText}`
                              }}
                            >
                              Reading time
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: '13px',
                                fontWeight: 700,
                                lineHeight: '16px',
                                color: '#8181B0'
                              }}
                            >
                              {readingTime}
                            </Typography>
                          </Box>

                          <CustomCounterProgress
                            countOf={readingTime}
                            maxCountOf={maxReadingTime}
                            minRange={11}
                            maxRange={60}
                            barColor='#4caf50'
                          />
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            flexDirection: 'column'
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              width: '100%'
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: '13px',
                                fontWeight: 700,
                                lineHeight: '16px',
                                color: `${theme.palette.primary.primaryText}`
                              }}
                            >
                              URL Count
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: '13px',
                                fontWeight: 700,
                                lineHeight: '16px',
                                color: '#8181B0'
                              }}
                            >
                              {urlCount}
                            </Typography>
                          </Box>

                          <CustomCounterProgress
                            countOf={urlCount}
                            maxCountOf={maxLinks}
                            minRange={0}
                            maxRange={1}
                            barColor='#6d1b7b'
                          />
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            flexDirection: 'column'
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              width: '100%'
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: '13px',
                                fontWeight: 700,
                                lineHeight: '16px',
                                color: `${theme.palette.primary.primaryText}`
                              }}
                            >
                              Question Count
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: '13px',
                                fontWeight: 700,
                                lineHeight: '16px',
                                color: '#8181B0'
                              }}
                            >
                              {questionCount}
                            </Typography>
                          </Box>

                          <CustomCounterProgress
                            countOf={questionCount}
                            maxCountOf={maxQuestions}
                            minRange={0}
                            maxRange={2}
                            barColor='#e040fb'
                          />
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            flexDirection: 'column'
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              width: '100%'
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: '13px',
                                fontWeight: 700,
                                lineHeight: '16px',
                                color: `${theme.palette.primary.primaryText}`
                              }}
                            >
                              Spam word count
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: '13px',
                                fontWeight: 700,
                                lineHeight: '16px',
                                color: '#8181B0'
                              }}
                            >
                              {spamCount}
                            </Typography>
                          </Box>

                          <CustomCounterProgress
                            countOf={spamCount}
                            maxCountOf={maxSpams}
                            minRange={0}
                            maxRange={15}
                            barColor='#ff5722'
                          />
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            mt: 1,
                            width: 'auto'
                          }}
                        >
                          <Box>
                            {editorContent !== ''
                              ? (
                                <Button
                                  sx={{ px: 1, py: 1, width: 'auto' }}
                                  color='primary'
                                  variant='contained'
                                  type='Button'
                                  onClick={handleOptimizeClick}
                                  disabled={isOptimizeEmailLoading || !canEdit}
                                >
                                  {isOptimizeEmailLoading
                                    ? (
                                      <CircularProgress size={25} thickness={5} />
                                      )
                                    : (
                                        'Optimize'
                                      )}
                                </Button>
                                )
                              : (
                                <></>
                                )}
                            <Dialog
                              open={openModal}
                              onClose={handleCloseModal}
                              disableEnforceFocus
                              maxWidth='sm'
                              minWidth='xs'
                              fullWidth
                              sx={{ backgroundColor: 'rgba(0,0,0,0.3)' }}
                            >
                              <DialogTitle sx={{ position: 'relative' }}>
                                <Typography variant='h6' component='h2'>
                                  Test Email
                                </Typography>
                                <IconButton
                                  sx={{ position: 'absolute', right: 0, top: 0 }}
                                  onClick={handleCloseModal}
                                >
                                  <CloseOutlined />
                                </IconButton>
                              </DialogTitle>
                              <DialogContent sx={{ overflow: 'hidden' }}>
                                <Typography
                                  sx={{
                                    width: '100%',
                                    textAlign: 'left',
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    lineHeight: '10px',
                                    color: `${theme.palette.primary.primaryText}`,
                                    mt: 2,
                                    mb: 1,
                                    ml: '1px'
                                  }}
                                >
                                  From
                                </Typography>
                                <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
                                  <Box
                                    sx={{
                                      mt: 2,
                                      width: '100%',
                                      backgroundColor: 'white',
                                      '& div': {},
                                      '& div fieldset': {
                                        borderRadius: '8px',
                                        border: '1px solid #E4E4E5'
                                      },
                                      '& div input': {
                                        py: 2,
                                        fontSize: '13px',
                                        fontWeight: 400,
                                        lineHeight: '16px',
                                        letterSpacing: '0em',
                                        '&::placeholder': {
                                          color: 'rgba(40, 40, 123, 0.5)'
                                        }
                                      }
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '100%',
                                        minWidth: { xs: '100%', sm: '350px' },
                                        mt: 'px'
                                      }}
                                    >
                                      {' '}
                                      <Autocomplete
                                        freeSolo
                                        multiple
                                        disableCloseOnSelect
                                        options={testEmailAccounts}
                                        getOptionLabel={(option) => option}
                                        filterSelectedOptions
                                        value={formik.values.testEmailAccounts}
                                        onChange={(_, newValue) => {
                                          if (
                                            Array.isArray(newValue) &&
                                            newValue.every((value) =>
                                              testEmailAccounts.includes(value)
                                            )
                                          ) {
                                            handleFieldSelectionAndValidation(newValue)
                                          } else if (newValue.length < 1) {
                                            handleFieldSelectionAndValidation(newValue)
                                          }
                                        }}
                                        onBlur={formik.handleBlur('testEmailAccounts')}
                                        // onKeyDown={handleKeyDown}
                                        // onInputChange={(event, newInputValue) => {
                                        //   setCurrentInput(newInputValue);
                                        // }}
                                        renderOption={(props, option, { selected }) => (
                                          <li
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'flex-start',
                                              alignItems: 'center',
                                              px: 0
                                            }}
                                            {...props}
                                          >
                                            <Checkbox
                                              icon={<OffCheckboxCustomIcon />}
                                              checkedIcon={<OnCheckboxCustomIcon />}
                                              style={{ marginRight: 8 }}
                                              checked={selected}
                                            />
                                            <Typography
                                              sx={{
                                                fontSize: '13px',
                                                fontWeight: 500,
                                                lineHeight: '16px',
                                                color: `${theme.palette.primary.primaryText}`
                                              }}
                                            >
                                              {' '}
                                              {option}
                                            </Typography>
                                          </li>
                                        )}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            placeholder='Select...'
                                            fullWidth
                                            variant='outlined'
                                            error={
                                              !!(
                                                formik.touched.testEmailAccounts &&
                                                formik.errors.testEmailAccounts
                                              )
                                            }
                                            helperText={
                                              formik.touched.testEmailAccounts &&
                                              formik.errors.testEmailAccounts
                                            }
                                            name='testEmailAccounts'
                                            sx={{
                                              backgroundColor: 'white',
                                              width: '100%',
                                              '& div': { pl: 0.3, minHeight: '40px' },
                                              '& div fieldset': {
                                                borderRadius: '8px',
                                                border: '1px solid #E4E4E5'
                                              },
                                              '& div input': {
                                                py: 1.5,
                                                fontSize: '13px',
                                                fontWeight: 400,
                                                lineHeight: '16px',
                                                letterSpacing: '0em',
                                                '&::placeholder': {
                                                  color: 'rgba(40, 40, 123, 0.5)'
                                                }
                                              },
                                              '& label': {
                                                fontSize: '14px',
                                                fontWeight: 700,
                                                lineHeight: '18px',
                                                letterSpacing: '0px',
                                                color: `${theme.palette.primary.primaryText}`
                                              }
                                              // ml: 1,
                                              // boxShadow: 10,
                                            }}
                                            size='small'
                                          />
                                        )}
                                        sx={{
                                          // maxWidth: { xs: "100%", sm: "420px" },
                                          height: '100%',
                                          // minWidth: { xs: "100%", sm: "350px" },
                                          width: '100%'
                                        }}
                                      />
                                    </Box>
                                  </Box>
                                </form>
                                <Typography
                                  sx={{
                                    width: '100%',
                                    textAlign: 'left',
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    lineHeight: '10px',
                                    color: `${theme.palette.primary.primaryText}`,
                                    mt: 2,
                                    mb: 1,
                                    ml: '1px'
                                  }}
                                >
                                  To
                                </Typography>
                                <TextField
                                  placeholder='example@mail.com'
                                  fullWidth
                                  variant='outlined'
                                  sx={{
                                    mt: 1,
                                    width: '100%',

                                    backgroundColor: 'white',
                                    '& div': { pl: showData ? 1 : undefined },
                                    '& div fieldset': {
                                      borderRadius: '8px',
                                      border: '1px solid #E4E4E5'
                                    },
                                    '& div input': {
                                      p: 1.3,
                                      px: 3,
                                      fontSize: '13px',
                                      fontWeight: 400,
                                      lineHeight: '16px',
                                      letterSpacing: '0em',
                                      '&::placeholder': {
                                        color: 'rgba(40, 40, 123, 0.5)'
                                      }
                                    }
                                  }}
                                  value={manualImportText}
                                  onChange={(e) => setManualImportText(e.target.value)}
                                  error={error}
                                  helperText={error ? 'Field is required' : ''}
                                  onFocus={handleFocus}
                                />
                              </DialogContent>
                              <DialogActions sx={{ px: '24px', pb: 2 }}>
                                <Stack direction='row' spacing={2} justifyContent='flex-end' mt={2}>
                                  <Button
                                    disabled={buttonDisabled}
                                    variant='contained'
                                    onClick={() => {
                                      handleSaveSequenceClick(formik, 'Test')
                                    }}
                                    sx={{ minWidth: '85px' }}
                                  >
                                    <Typography variant='h6' component='h6'>
                                      {isLaunchingCampaign
                                        ? (
                                          <CircularProgress
                                            size={16}
                                            thickness={5}
                                            sx={{ color: 'white' }}
                                          />
                                          )
                                        : (
                                            'Send'
                                          )}
                                    </Typography>
                                  </Button>
                                  <Button
                                    variant='outlined'
                                    onClick={handleCloseModal}
                                    sx={{ minWidth: '85px' }}
                                  >
                                    <Typography variant='h6' component='h6'>
                                      Cancel
                                    </Typography>
                                  </Button>
                                </Stack>
                              </DialogActions>
                            </Dialog>

                            {/* templat */}

                            <Dialog
                              open={tempOpenModal}
                              onClose={() => setTempOpenModal(false)}
                              fullWidth
                              maxWidth='lg'
                              sx={{ backgroundColor: 'rgba(4, 4, 30, 0.5)' }}
                              disableEnforceFocus
                              fullScreen={isMobile}
                            // aria-labelledby="modal-modal-title"
                            // aria-describedby="modal-modal-description"
                            >
                              <DialogTitle sx={{ position: 'relative', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography
                                  variant='h4'
                                  component='h2'
                                  sx={{
                                    color: `${theme.palette.primary.primaryText}`,
                                    fontWeight: 700,
                                    fontSize: '20px'
                                  }}
                                >
                                  {/* <AutoAwesomeMosaicIcon /> Templates Library */}
                                  Templates Library
                                </Typography>
                                <IconButton onClick={() => setTempOpenModal(false)}>
                                  <CloseOutlined />
                                </IconButton>
                              </DialogTitle>
                              <DialogContent sx={{ pb: 0, overflow: 'hidden' }}>
                                <Grid
                                  container
                                  sx={{
                                    bgcolor: 'background.paper',
                                    gap: { xs: 2, md: 0 },
                                    height: '100%'
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    md={3}
                                    sx={{
                                      // flexGrow: 1,
                                      bgcolor: 'background.paper',

                                      height: 'fit-content',
                                      border: '1px solid #E4E4E5',
                                      borderRadius: '12px',
                                      transition: 'all 1s ease',
                                      maxHeight: 'calc(100vh - 230px)',

                                      overflow: 'auto',
                                      scrollbarWidth: 'thin',
                                      '&::-webkit-scrollbar': {
                                        width: '8px'
                                      },
                                      '&::-webkit-scrollbar-thumb': {
                                        backgroundColor: '#8492a6',
                                        borderRadius: '4px'
                                      },
                                      '&::-webkit-scrollbar-track': {
                                        backgroundColor: '#E2E9E9',
                                        borderRadius: '15px'
                                      }
                                      //  display: "flex",
                                      // flexDirection: { xs: "column", md: "row" },
                                      // height: 624,
                                    }}
                                  >
                                    {/* <Tabs
                                    orientation={isMobile ? "horizontal" : "vertical"}
                                    variant="scrollable"
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="Vertical tabs example"
                                    sx={{
                                      borderRight: 1,
                                      borderColor: "divider",
                                      width: "100%",

                                      "& .MuiTabs-indicator": { display: "none" },
                                      p: { xs: 0.5, md: 2 },
                                    }}
                                  >
                                    {templateEmails.map((item, index) => (
                                      <Tab
                                        onClick={() => {
                                          setSelectedTemplate(item.id);
                                        }}
                                        icon={<DescriptionOutlined />}
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "flex-start",
                                          alignItems: "center",
                                          textAlign: "left",
                                          gap: 2,
                                          minHeight: "52px",

                                          color: `${theme.palette.primary.primaryText}`,
                                          borderRadius: "8px",
                                          fontSize: "14px",
                                          fontWeight: "700",
                                          "&.Mui-selected": {
                                            backgroundColor: theme.palette.grey[200],
                                            color: theme.palette.primary.main,
                                          },
                                        }}
                                        // label={
                                        //   item.subject.length > 22
                                        //     ? `${item.subject.substring(0, 22)}...`
                                        //     : item.subject
                                        // }
                                        label={item.subject}
                                        {...a11yProps(index)}
                                        key={index}
                                      />
                                    ))}
                                  </Tabs> */}
                                    <TemplateSection
                                      isMobile={isMobile}
                                      setSelectedTemplateObject={setSelectedTemplateObject}
                                      selectedTemplateObject={selectedTemplateObject}
                                      templates={templates}
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={9}>
                                    {/* {templateEmails.map((item, index) => (
                                    <TabPanel key={index} value={value} index={index}>
                                      <Box
                                        sx={{
                                          flexGrow: 1,
                                          bgcolor: "background.paper",
                                          display: "flex",

                                          mt: 1,
                                          mb: { xs: 1, md: 0 },
                                        }}
                                      >
                                        <Typography
                                          variant="h4"
                                          component="h2"
                                          sx={{
                                            color: `${theme.palette.primary.primaryText}`,
                                            lineHeight: 1.5,
                                            fontWeight: 700,
                                            fontSize: "16px",
                                            px: 2,
                                          }}
                                        >
                                          {item.subject}
                                        </Typography>
                                      </Box>
                                      <Divider sx={{ mt: "13px" }} />
                                      <Box
                                        sx={{
                                          // bgcolor: "#E2E9E9",
                                          px: "16px",
                                          borderRadius: "15px",
                                          // height: 450,
                                          height: {
                                            xs: "calc(100vh - 260px)",
                                            md: "calc(100vh - 275px)",
                                          },
                                          overflow: "auto",
                                          scrollbarWidth: "thin",
                                          "&::-webkit-scrollbar": {
                                            width: "8px",
                                          },
                                          "&::-webkit-scrollbar-thumb": {
                                            backgroundColor: "#8492a6",
                                            borderRadius: "4px",
                                          },
                                          "&::-webkit-scrollbar-track": {
                                            backgroundColor: "#E2E9E9",
                                            borderRadius: "15px",
                                          },
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            color: `${theme.palette.primary.primaryText}`,
                                            fontSize: "13px",
                                            fontWeight: "500",
                                          }}
                                          dangerouslySetInnerHTML={{ __html: item.body }}
                                        />
                                      </Box>
                                    </TabPanel>
                                  ))} */}
                                    <Box
                                      sx={{
                                        border: '1px solid #E4E4E5',
                                        borderRadius: '12px',
                                        ml: { xs: 0, md: 3 }
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          flexGrow: 1,
                                          bgcolor: 'background.paper',
                                          display: 'flex',

                                          mt: 1,
                                          mb: { xs: 1, md: 0 }
                                        }}
                                      >
                                        <Typography
                                          variant='h4'
                                          component='h2'
                                          sx={{
                                            color: `${theme.palette.primary.primaryText}`,
                                            lineHeight: 1.5,
                                            fontWeight: 700,
                                            fontSize: '16px',
                                            px: 2
                                          }}
                                        >
                                          {selectedTemplateObject.subject}
                                        </Typography>
                                      </Box>
                                      <Divider sx={{ mt: '13px' }} />
                                      <Box
                                        sx={{
                                          // bgcolor: "#E2E9E9",
                                          px: '16px',
                                          borderRadius: '15px',
                                          // height: 450,
                                          height: {
                                            xs: 'calc(100vh - 260px)',
                                            md: 'calc(100vh - 275px)'
                                          },
                                          overflow: 'auto',
                                          scrollbarWidth: 'thin',
                                          '&::-webkit-scrollbar': {
                                            width: '8px'
                                          },
                                          '&::-webkit-scrollbar-thumb': {
                                            backgroundColor: '#8492a6',
                                            borderRadius: '4px'
                                          },
                                          '&::-webkit-scrollbar-track': {
                                            backgroundColor: '#E2E9E9',
                                            borderRadius: '15px'
                                          }
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            color: `${theme.palette.primary.primaryText}`,
                                            fontSize: '13px',
                                            fontWeight: '500'
                                          }}
                                          dangerouslySetInnerHTML={{
                                            __html: selectedTemplateObject.body
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </DialogContent>
                              <DialogActions>
                                <Stack
                                  mt={2}
                                  sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    gap: 2,
                                    px: { xs: '16px' },
                                    mb: '16px'
                                  }}
                                >
                                  <Button
                                    sx={{ m: 0, width: '48%' }}
                                    variant='outlined'
                                    onClick={() => {
                                      // if (selectedTemplate !== null) {
                                      //   const selectedTemplateObj = templateEmails.find(
                                      //     (item) => item.id === selectedTemplate
                                      //   );
                                      //   if (selectedTemplateObj) {
                                      //     const clipboardText = selectedTemplateObj.body
                                      //       .replace(/<\/?p>/g, "")
                                      //       .replace(/<br\s?\/?>/g, "\n");
                                      //     navigator.clipboard
                                      //       .writeText(clipboardText)
                                      //       .then(() => {
                                      //         toast.success("Email copied to clipboard");
                                      //       })
                                      //       .catch((err) => {
                                      //         toast.error("Unable to copy to clipboard");
                                      //       });
                                      //   }
                                      // }
                                      if (selectedTemplateObject !== null) {
                                        const clipboardText = selectedTemplateObject.body
                                          .replace(/<\/?p>/g, '')
                                          .replace(/<br\s?\/?>/g, '\n')
                                        navigator.clipboard
                                          .writeText(clipboardText)
                                          .then(() => {
                                            toast.success('Email copied to clipboard')
                                          })
                                          .catch((_err) => {
                                            toast.error('Unable to copy to clipboard')
                                          })
                                      }
                                    }}
                                  >
                                    <Typography variant='h6' component='h6'>
                                      Copy
                                    </Typography>
                                  </Button>
                                  <Button
                                    variant='contained'
                                    onClick={() => {
                                      // if (selectedTemplate !== null) {
                                      //   const selectedTemplateObj = templateEmails.find(
                                      //     (item) => item.id === selectedTemplate
                                      //   );
                                      //   if (selectedTemplateObj) {
                                      //     setEditorContent(selectedTemplateObj.body);
                                      //     setEditorSubject(selectedTemplateObj.subject);
                                      //     setTempOpenModal(false);
                                      //   }
                                      // }
                                      if (selectedTemplateObject !== null) {
                                        setEditorContent(selectedTemplateObject.body)
                                        setEditorSubject(selectedTemplateObject.subject)
                                        setTempOpenModal(false)
                                        toast.success('Template Pasted')
                                      }
                                    }}
                                    sx={{ cursor: 'pointer', width: '48%' }}
                                  >
                                    <Typography variant='h6' component='h6'>
                                      Use Template
                                    </Typography>
                                  </Button>
                                </Stack>
                              </DialogActions>
                            </Dialog>
                            <Dialog
                              fullScreen={isMobile}
                              fullWidth
                              maxWidth='lg'
                              open={openHtml}
                              onClose={handleCloseHtml}
                              sx={{
                                '& .MuiDialog-paper': {
                                  height: isMobile ? '100vh' : '80vh',
                                  maxHeight: isMobile ? '100vh' : '80vh',
                                  width: '100%',
                                  overflowX: 'hidden',
                                  overflowY: 'auto',
                                  paddingRight: isMobile ? '16px' : '0px',
                                  backgroundColor: 'white'
                                }
                              }}
                            >
                              <Grid
                                container
                                spacing={isMobile ? 1 : 3}
                                sx={{ margin: isMobile ? 0 : 1, padding: isMobile ? 1 : 2 }}
                              >
                                <IconButton
                                  sx={{ position: 'absolute', right: 0, top: 0, zIndex: 10 }}
                                  onClick={() => setOpenHtml(false)}
                                >
                                  <CloseOutlined />
                                </IconButton>

                                <Grid item xs={12} sm={3}>
                                  <Typography
                                    sx={{
                                      fontWeight: '700',
                                      marginBottom: isMobile ? '10px' : '20px',
                                      fontSize: isMobile ? '18px' : '20px',
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <Send sx={{ marginRight: isMobile ? '5px' : '10px' }} />
                                    Test Email
                                  </Typography>

                                  <Typography
                                    sx={{
                                      textAlign: 'left',
                                      fontSize: isMobile ? '12px' : '14px',
                                      fontWeight: 500,
                                      color: `${theme.palette.primary.primaryText}`,
                                      mt: 2,
                                      mb: 1
                                    }}
                                  >
                                    From
                                  </Typography>

                                  <Autocomplete
                                    freeSolo
                                    multiple
                                    disableCloseOnSelect
                                    options={testEmailAccounts}
                                    getOptionLabel={(option) => option}
                                    filterSelectedOptions
                                    value={formik.values.testEmailAccounts}
                                    onChange={(_, newValue) =>
                                      handleFieldSelectionAndValidation(newValue)}
                                    onBlur={formik.handleBlur('testEmailAccounts')}
                                    renderOption={(props, option, { selected }) => (
                                      <li
                                        {...props}
                                        style={{ display: 'flex', alignItems: 'center' }}
                                      >
                                        <Checkbox
                                          icon={<OffCheckboxCustomIcon />}
                                          checkedIcon={<OnCheckboxCustomIcon />}
                                          checked={selected}
                                          style={{ marginRight: 8 }}
                                        />
                                        <Typography
                                          sx={{
                                            fontSize: '13px',
                                            fontWeight: 500,
                                            color: `${theme.palette.primary.primaryText}`
                                          }}
                                        >
                                          {option}
                                        </Typography>
                                      </li>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder='Select...'
                                        variant='outlined'
                                        error={
                                          formik.touched.testEmailAccounts &&
                                          !!formik.errors.testEmailAccounts
                                        }
                                        helperText={
                                          formik.touched.testEmailAccounts &&
                                          formik.errors.testEmailAccounts
                                        }
                                        sx={{
                                          backgroundColor: 'white',
                                          '& div fieldset': {
                                            borderRadius: '8px',
                                            border: '1px solid #E4E4E5'
                                          },
                                          '& div input': { py: 1.5, fontSize: '13px' }
                                        }}
                                      />
                                    )}
                                  />

                                  <Typography
                                    sx={{
                                      textAlign: 'left',
                                      fontSize: isMobile ? '12px' : '14px',
                                      fontWeight: 500,
                                      color: `${theme.palette.primary.primaryText}`,
                                      mt: 2,
                                      mb: 1
                                    }}
                                  >
                                    To
                                  </Typography>
                                  <TextField
                                    placeholder='example@mail.com'
                                    fullWidth
                                    variant='outlined'
                                    value={manualImportText}
                                    onChange={(e) => setManualImportText(e.target.value)}
                                    error={
                                      formik.touched.manualImportText && manualImportText === ''
                                    }
                                    helperText={
                                      formik.touched.manualImportText && manualImportText === ''
                                        ? 'Field is required'
                                        : ''
                                    }
                                    sx={{
                                      backgroundColor: 'white',
                                      '& div fieldset': {
                                        borderRadius: '8px',
                                        border: '1px solid #E4E4E5'
                                      },
                                      '& div input': { p: 1.5, fontSize: '13px' }
                                    }}
                                  />

                                  <Box sx={{ mt: 3 }}>
                                    <Button
                                      fullWidth
                                      variant='contained'
                                      disabled={buttonDisabled}
                                      onClick={() => handleSaveSequenceClick(formik, 'Test')}
                                      sx={{ height: '40px' }}
                                    >
                                      {isLaunchingCampaign
                                        ? (
                                          <CircularProgress
                                            size={16}
                                            thickness={5}
                                            sx={{ color: 'white' }}
                                          />
                                          )
                                        : (
                                            'Send Test Email'
                                          )}
                                    </Button>
                                  </Box>
                                </Grid>

                                <Grid item xs={12} sm={8}>
                                  <Typography
                                    sx={{
                                      fontWeight: '700',
                                      marginBottom: isMobile ? '10px' : '20px',
                                      fontSize: isMobile ? '18px' : '20px',
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <Email sx={{ marginRight: isMobile ? '5px' : '10px' }} />
                                    Email Preview
                                  </Typography>

                                  <Typography
                                    sx={{
                                      fontWeight: '700',
                                      fontSize: isMobile ? '14px' : '16px',
                                      marginBottom: '20px',
                                      color: `${theme.palette.primary.primaryText}`
                                    }}
                                  >
                                    Subject: {convertSpintaxToPreview(updatedEditorSubject)}
                                  </Typography>

                                  <Box
                                    sx={{
                                      padding: 2,
                                      backgroundColor: 'rgba(245, 245, 245, 0.8)',
                                      borderRadius: '8px',
                                      height: isMobile
                                        ? 'calc(80vh - 250px)'
                                        : 'calc(80vh - 250px)',
                                      maxHeight: isMobile
                                        ? 'calc(80vh - 250px)'
                                        : 'calc(80vh - 250px)',
                                      overflowY: 'auto'
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        color: `${theme.palette.primary.primaryText}`,
                                        fontSize: '13px',
                                        fontWeight: '500'
                                      }}
                                      className='ql-editor'
                                      dangerouslySetInnerHTML={{
                                        __html: convertSpintaxToPreview(updatedContent)
                                      }}
                                    />
                                  </Box>
                                </Grid>
                              </Grid>
                            </Dialog>
                          </Box>
                          <Button
                            sx={{ px: 2, ml: 2 }}
                            color='primary'
                            variant='outlined'
                            type='submit'
                            id='submit-btn'
                            disabled={editorContent === '' || !canEdit}
                          // onClick={handleSubmit}
                          >
                            Check Email
                          </Button>
                        </Box>
                      </Stack>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </form>
          </Box>
        </DialogContent>
      </Dialog>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{ mt: 0.5 }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            // width: "fit-content",
            p: 1,
            width: '280px'
          }}
        >
          {variables?.map((item) => {
            return (
              <Button
                key={item.value}
                value={item.value}
                onClick={(e) => insertContent(e.currentTarget.getAttribute('value'))}
                fullWidth
                sx={{
                  py: 1,
                  px: 1,
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  color: '#101828',
                  fontSize: '13px'
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <Typography
                    sx={{
                      color: `${theme.palette.primary.primaryText}`,
                      fontSize: '13px',
                      fontWeight: 700,
                      linHeight: '16px',
                      letterSpacing: '0px'
                    }}
                    value={item.value}
                  >
                    {item.key}
                  </Typography>
                  <Typography
                    sx={{
                      color: '#8181B0',
                      fontSize: '11px',
                      fontWeight: 700,
                      linHeight: '14px',
                      letterSpacing: '0px',
                      ml: 1
                    }}
                    value={item.value}
                  >
                    {item.value}
                  </Typography>
                </Box>
              </Button>
            )
          })}
        </Box>
      </Popover>
      <Dialog
        open={isUnsubscribeOpen}
        onClose={() => setIsUnsubscribeOpen(false)}
        maxWidth='xs'
        fullWidth
      >
        <DialogTitle
          sx={{
            fontSize: '20px',
            fontWeight: 700,
            lineHeight: '28px',
            color: `${theme.palette.primary.primaryText}`
          }}
        >
          Insert Unsubscribe Link
        </DialogTitle>
        <DialogContent>
          <InputLabel>Display as</InputLabel>
          <TextField
            id='standard-basic'
            variant='standard'
            value={unsubscribeText}
            fullWidth
            onChange={(e) => setUnsubscribeText(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsUnsubscribeOpen(false)}>Cancel</Button>
          <Button onClick={insertUnsubscribeLink}>Insert Unsubscribe Link</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CampaignSequences
