import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  TextField,
  Typography
} from '@mui/material'
import { toast } from 'react-hot-toast'
import EmailIcon from '@mui/icons-material/Email'
import CallIcon from '@mui/icons-material/Call'
import EventIcon from '@mui/icons-material/Event'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { useRemoveDealMutation, useAddDealValueMutation } from 'src/services/leads-service'
import { ActionButton } from './ActionButton'

const ActionButtons = ({
  email,
  call,
  meeting,
  leadCampaignId,
  labelId,
  valuePerDeal,
  user,
  selectedUserColumnId,
  updateDealInPipelineData
}) => {
  const [menuAnchor, setMenuAnchor] = useState(null)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [isDealValueModalOpen, setIsDealValueModalOpen] = useState(false)
  const [dealValue, setDealValue] = useState('')
  const [removeDeal] = useRemoveDealMutation()
  const [addDealValue] = useAddDealValueMutation()

  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget)
  }

  const handleMenuClose = () => {
    setMenuAnchor(null)
  }

  const handleDeleteClick = () => {
    setIsDeleteDialogOpen(true)
    handleMenuClose()
  }

  const handleConfirmDelete = async () => {
    setIsDeleteDialogOpen(false)
    try {
      const data = {
        leadCampaignId,
        labelId
      }
      await removeDeal(data)
      toast.success('Deal removed successfully.')

      // optimistically update the UI
      updateDealInPipelineData({
        type: 'delete',
        deal: user,
        oldColumnId: selectedUserColumnId
      })
    } catch (error) {
      toast.error('Failed to remove deal.')
    }
  }

  const handleCancelDelete = () => {
    setIsDeleteDialogOpen(false)
  }

  const handleAddDealValue = () => {
    setIsDealValueModalOpen(true)
    handleMenuClose()
  }

  useEffect(() => {
    if (isDealValueModalOpen) {
      if (dealValue !== '') {
        // Keep existing dealValue if it exists
        return
      }
      // Otherwise use the valuePerDeal prop
      setDealValue(valuePerDeal !== null && valuePerDeal !== undefined ? String(valuePerDeal) : '')
    }
  }, [isDealValueModalOpen, valuePerDeal])

  const handleSaveDealValue = async () => {
    const numericValue = parseFloat(dealValue)

    if (isNaN(numericValue) || numericValue < 0) {
      toast.error('Please enter a valid non-negative number.')
      return
    }
    const res = await addDealValue({ leadId: leadCampaignId, labelId, dealValue: numericValue })
    if (res.error) {
      toast.error('Failed to set deal value.')
      return
    }
    toast.success(`Deal value set to $${numericValue}`)
    setIsDealValueModalOpen(false)

    // optimistically update the UI
    updateDealInPipelineData({
      type: 'valueChange',
      deal: { id: user.id },
      updatedValue: numericValue,
      oldColumnId: selectedUserColumnId
    })
  }

  const handleActionClick = (type, value) => {
    if (typeof window !== 'undefined') {
      if (value) {
        switch (type) {
          case 'email':
            window.location.href = `mailto:${value}`
            break
          case 'call':
            window.location.href = `tel:${value}`
            break
          case 'meeting':
            // Open meeting link
            break
          default:
            break
        }
      } else {
        toast.error(`No ${type} provided.`)
      }
    }
  }

  return (
    <Box display='flex' alignItems='center' mb={2}>
      {/* Email Button */}
      <ActionButton
        type='email'
        value={email}
        onClick={() => handleActionClick('email', email)}
        icon={<EmailIcon />}
        label='Email'
      />

      {/* Call Button */}
      <ActionButton
        type='call'
        value={call}
        onClick={() => handleActionClick('call', call)}
        icon={<CallIcon />}
        label='Call'
      />

      {/* Meeting Button */}
      <ActionButton
        type='meeting'
        value={meeting}
        onClick={() => handleActionClick('meeting', meeting)}
        icon={<EventIcon />}
        label='Meeting'
      />

      {/* Three Dots Icon - Only show when person details are shown */}
      {leadCampaignId && (
        <IconButton
          onClick={handleMenuOpen}
          sx={{
            border: '1px solid #D1D5DB',
            borderRadius: '8px',
            padding: '8px',
            backgroundColor: 'white',
            '&:hover': {
              backgroundColor: '#F9FAFB'
            }
          }}
        >
          <MoreVertIcon sx={{ color: '#6B7280' }} />
        </IconButton>
      )}

      {/* Dropdown Menu */}
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
        PaperProps={{
          elevation: 3,
          sx: {
            borderRadius: '8px',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            mt: 1
          }
        }}
      >
        <MenuItem
          onClick={handleAddDealValue}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            fontWeight: '500'
          }}
        >
          <EditIcon />
          {valuePerDeal ? 'Edit' : 'Add'} deal value
        </MenuItem>
        <MenuItem
          onClick={handleDeleteClick}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            color: '#EF4444',
            fontWeight: '500'
          }}
        >
          <DeleteIcon sx={{ color: '#EF4444' }} />
          Delete this deal
        </MenuItem>
      </Menu>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={isDeleteDialogOpen}
        onClose={handleCancelDelete}
        PaperProps={{
          sx: {
            borderRadius: '10px'
          }
        }}
      >
        <DialogTitle sx={{ fontWeight: '700' }}>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this deal? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancel</Button>
          <Button
            onClick={handleConfirmDelete}
            variant='contained'
            sx={{ backgroundColor: '#EF4444', color: '#fff' }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Deal Value Modal */}
      <Modal open={isDealValueModalOpen} onClose={() => setIsDealValueModalOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: '12px',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
            p: 3
          }}
        >
          <Typography variant='h6' fontWeight='600' sx={{ mb: 2 }}>
            {valuePerDeal ? 'Edit Deal Value' : 'Set Deal Value'}
          </Typography>
          <TextField
            fullWidth
            variant='outlined'
            type='number'
            placeholder='Enter deal value'
            value={dealValue}
            inputProps={{ min: '0' }}
            onChange={(e) => {
              const value = e.target.value
              const numValue = Number(value)
              if (value === '' || (numValue >= 0 && !isNaN(numValue))) {
                setDealValue(value)
              }
            }}
            InputProps={{
              startAdornment: (
                <Typography variant='body2' sx={{ mr: 1 }}>
                  $
                </Typography>
              )
            }}
            sx={{ mb: 2 }}
          />
          <Box display='flex' justifyContent='flex-end' gap={2}>
            <Button onClick={() => setIsDealValueModalOpen(false)} variant='outlined'>
              Cancel
            </Button>
            <Button onClick={handleSaveDealValue} variant='contained'>
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default ActionButtons
